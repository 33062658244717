import React, { Fragment, useEffect, useState } from 'react';

import { Button } from '@material-ui/core';
import { RMShipmentInfo } from 'redux/reducers/rmShipment/types';
import TableGrid from 'components/common/TableGrid';
import { getRmShipmentsAction } from 'redux/reducers/rmShipment/actions';
import moment from 'moment';
import { rmShipmentTypedSelector } from 'redux/reducers/rmShipment/rmShipmentReducer';
import { useDispatch } from 'react-redux';

export interface WarehouseReportBinListTableProps {
    filterText?: string;
    exportCsv?: boolean;
    showMenu?: boolean;
    onCloseMenu?: any;
    onExportCsv?: any;
    exportCsvFileName?: string;
    onClickedRMRRID?: (rmrrID: string) => void;
}

const WarehouseReportBinListTable = (props: WarehouseReportBinListTableProps) => {
    const { onClickedRMRRID, filterText, exportCsv, onExportCsv, exportCsvFileName } = props;

    const dispatcher = useDispatch();
    const shipments = rmShipmentTypedSelector<Array<RMShipmentInfo>>((state) => state.rmshipment.shipments);

    const [shipmentList, setShipmentList] = useState<Array<RMShipmentInfo>>([]);
    const [isShipmentAcquired, setIsShipmentAcquire] = useState(false);

    useEffect(() => {
        if (shipments.length > 0) {
            let completeShipments = shipments.filter((shipment) => (!!!shipment.unloadingDatetime ? false : true));
            setShipmentList(completeShipments);
            setIsShipmentAcquire(true);
        } else if (shipments.length === 0 && !isShipmentAcquired) {
            dispatcher(getRmShipmentsAction());
            setIsShipmentAcquire(true);
        }
    }, [shipments]); // eslint-disable-line

    const rmrrIdRenderer = (params: { value: string }) => {
        return (
            <Button
                style={{ color: '#009fdc' }}
                onClick={() => {
                    if (onClickedRMRRID) {
                        onClickedRMRRID(params.value);
                    }
                }}>
                {params.value}
            </Button>
        );
    };

    const dateRenderer = (date: any) => {
        if (date.value) {
            return moment(date.value).format('MMM DD, YY hh:mm');
        }
        return '';
    };

    const columnDefs = [
        {
            headerName: 'RMRR #',
            field: 'rmrrId',
            minWidth: 300,
            filter: 'agTextColumnFilter',
            cellRendererFramework: rmrrIdRenderer,
            headerTooltip: 'RMRR #',
        },
        {
            headerName: 'RM Name',
            field: 'rmName',
            minWidth: 200,
            filter: 'agTextColumnFilter',
            headerTooltip: 'RM Name',
        },
        {
            headerName: 'Supplier',
            field: 'supplierName',
            minWidth: 200,
            filter: 'agTextColumnFilter',
            headerTooltip: 'Supplier',
        },
        {
            headerName: 'Truck Plate#',
            field: 'truckPlateNum',
            minWidth: 100,
            filter: 'agTextColumnFilter',
            headerTooltip: 'Truck Plate#',
        },
        {
            headerName: 'Date of Creation',
            field: 'inputDateTime',
            minWidth: 100,
            filter: 'agDateColumnFilter',
            cellRenderer: dateRenderer,
            headerTooltip: 'Date of Creation',
            getQuickFilterText: dateRenderer,
        },
        {
            headerName: 'Date of Approval',
            field: 'approvalDatetime',
            minWidth: 100,
            filter: 'agDateColumnFilter',
            cellRenderer: dateRenderer,
            headerTooltip: 'Date of Approval',
            getQuickFilterText: dateRenderer,
        },
        {
            headerName: 'Unloaded Date/Time',
            field: 'unloadingDatetime',
            minWidth: 100,
            filter: 'agDateColumnFilter',
            cellRenderer: dateRenderer,
            headerTooltip: 'Unloaded Date/Time',
            getQuickFilterText: dateRenderer,
        },

        {
            headerName: 'Unit Price',
            field: 'rmUnitPrice',
            minWidth: 100,
            filter: 'agTextColumnFilter',
            hide: true,
            headerTooltip: 'Unit Price',
        },
        {
            headerName: 'Net Weight',
            field: 'netWeight',
            minWidth: 100,
            filter: 'agNumberColumnFilter',
            hide: true,
            headerTooltip: 'Net Weight',
        },
        {
            headerName: 'Truck Scale Number',
            field: 'truckScaleNum',
            minWidth: 100,
            filter: 'agTextColumnFilter',
            hide: true,
            headerTooltip: 'Truck Scale Number',
        },
    ];

    return (
        <Fragment>
            <TableGrid
                rowData={shipmentList}
                columnDefs={columnDefs}
                filterText={filterText}
                exportCsv={exportCsv}
                onExportCsv={onExportCsv}
                exportCsvFileName={exportCsvFileName}
                height={600}
            />
        </Fragment>
    );
};

export default WarehouseReportBinListTable;
