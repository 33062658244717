import {
    ADD_PREMIX_TEMPLATE,
    ADD_PRODUCTION_ORDER,
    DELETE_PREMIX_TEMPLATE,
    DELETE_PRODUCTION_ORDER,
    GET_All_PREMIX,
    GET_All_PRODUCTION_ORDER,
    PATCH_PREMIX_INFO,
    PATCH_PRODUCTION_ORDER_INFO,
    PremixInfoRequest,
    PremixInfoState,
    ProductionOrderInfoRequest,
} from "./types";

import { Action } from "redux";
import AppController from "controller/application";
import { SET_API_MESSAGE } from "../apiMessage/types";
import { ThunkAction } from "redux-thunk";

async function processGetAllPremix() {
    return AppController.getAllPremix();
}

async function processGetAllProductionOrder() {
    return AppController.getAllProductionOrder();
}

async function processPostNewPremix(parameters: PremixInfoRequest) {
    return AppController.postNewPremix(parameters);
}

async function processPostNewProductionOrder(parameters: ProductionOrderInfoRequest) {
    return AppController.postNewProductionOrder(parameters);
}

async function processPatchPremixInfo(parameters: PremixInfoRequest, premixName: string) {
    return AppController.patchPremixInfo(parameters, premixName);
}

async function processPatchProductionOrderInfo(parameters: ProductionOrderInfoRequest, productionOrderId: string) {
    return AppController.patchProductionOrderInfo(parameters, productionOrderId);
}

async function processDeletePremix(premixName: string) {
    return AppController.deletePremix(premixName);
}

async function processDeleteProductionOrder(productionOrderId: string) {
    return AppController.deleteProductionOrder(productionOrderId);
}

export function getAllPremixAction(): ThunkAction<void, PremixInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processGetAllPremix();
        dispatch({
            type: GET_All_PREMIX,
            payload: resp,
        });
    };
}

export function getAllProductionOrderAction(): ThunkAction<void, PremixInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processGetAllProductionOrder();
        dispatch({
            type: GET_All_PRODUCTION_ORDER,
            payload: resp,
        });
    };
}

export function addPremixAction(
    parameters: PremixInfoRequest
): ThunkAction<void, PremixInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        await processPostNewPremix(parameters);
        dispatch({
            type: ADD_PREMIX_TEMPLATE,
            payload: {},
        });
    };
}

export function addProductionOrderAction(
    parameters: ProductionOrderInfoRequest
): ThunkAction<void, PremixInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        await processPostNewProductionOrder(parameters);
        dispatch({
            type: ADD_PRODUCTION_ORDER,
            payload: {},
        });
    };
}

export function patchPremixInfoAction(
    parameters: PremixInfoRequest,
    premixName: string
): ThunkAction<void, PremixInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processPatchPremixInfo(parameters, premixName);
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: resp.response.data.reason,
                    variant: "error",
                },
            });
        } else {
            dispatch({
                type: PATCH_PREMIX_INFO,
                payload: {},
            });
        }
    };
}

export function patchProductionOrderInfoAction(
    parameters: ProductionOrderInfoRequest,
    productionOrderId: string
): ThunkAction<void, PremixInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processPatchProductionOrderInfo(parameters, productionOrderId);
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: resp.response.data.reason,
                    variant: "error",
                },
            });
        } else {
            dispatch({
                type: PATCH_PRODUCTION_ORDER_INFO,
                payload: {},
            });
        }
    };
}

export function deletePremixAction(premixName: string): ThunkAction<void, PremixInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processDeletePremix(premixName);
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: resp.response.data.reason,
                    variant: "error",
                },
            });
        } else {
            dispatch({
                type: DELETE_PREMIX_TEMPLATE,
                payload: {},
            });
        }
    };
}

export function deleteProductionOrderAction(
    productionOrderId: string
): ThunkAction<void, PremixInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processDeleteProductionOrder(productionOrderId);
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: resp.response.data.reason,
                    variant: "error",
                },
            });
        } else {
            dispatch({
                type: DELETE_PRODUCTION_ORDER,
                payload: {},
            });
        }
    };
}
