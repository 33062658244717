import {
    useSelector,
    TypedUseSelectorHook
} from 'react-redux'

import {
    APIMessageInfoState,
    APIMessageReducerType,
    APIMessageActionTypes,
    SET_API_MESSAGE
} from './types'

import {
    LOGOUT
} from 'redux/reducers/rootReducer/types'

import moment from 'moment'

const initialState: APIMessageInfoState = {
    message: '',
    variant: ''
}

export const apiMessageTypedSelector: TypedUseSelectorHook<APIMessageReducerType> = useSelector;

export function apiMessageReducer(
    state = initialState,
    action: APIMessageActionTypes
): APIMessageInfoState {

    let dateTime = moment(new Date()).format('MM-DD-YYYY:HH:mm:ss')
    switch (action.type) {

        case SET_API_MESSAGE:
            return {
                message: `[${dateTime}] ${action.payload.message}`,
                variant: action.payload.variant
            }

        case LOGOUT:
            return initialState

        default:
            return state
    }

}