import { Button, Grid } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import TableGrid from 'components/common/TableGrid';
import { getRmShipmentsAction } from 'redux/reducers/rmShipment/actions';
import moment from 'moment';
import { rmShipmentTypedSelector } from 'redux/reducers/rmShipment/rmShipmentReducer';
import { useDispatch } from 'react-redux';
import DeleteConfirmation from 'components/common/DeleteConfirmation';
import { GridApi } from 'ag-grid';

export interface RMShipmentListTableProps {
  filterText: string;
  exportCsv?: boolean;
  showMenu: boolean;
  onCloseMenu: any;
  onExportCsv?: any;
  exportCsvFileName?: string;
  onClickedRMRRID?: (rmrrID: string) => void;
  onDeleteRMRRID?: (rmrrID: string) => void;
}

interface RowInfo {
    api?: GridApi;
    data?: any;
}

const RMShipmentListTable = (props: RMShipmentListTableProps) => {
  const {
    filterText,
    exportCsv,
    onExportCsv,
    exportCsvFileName,
    onDeleteRMRRID,
  } = props;

  const [forDeleteRow, setForDeleteRow] = useState<RowInfo | any>({});
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(
    false
  );

  const dispatcher = useDispatch();
  const shipments = rmShipmentTypedSelector<Array<any>>(
    (state) => state.rmshipment.shipments
  );
  const [isShipmentAcquired, setIsShipmentAcquire] = useState(false);
  const [shipmentList, setShipmentList] = useState<Array<any>>([]);
  const [hideColumn, setHideColumn] = useState<Array<string>>([
    'rmUnitPrice',
    'netWeight',
    'truckScaleNum',
    'letterOfGuarantee',
  ]);
  const [showColumn, setShowColumn] = useState<Array<string>>([
    'rmrrId',
    'rmName',
    'supplierName',
    'truckPlateNum',
    'processingStatus',
    'inputDateTime',
    'approvalDatetime',
  ]);

  useEffect(() => {}, [hideColumn, showColumn]);

  useEffect(() => {
    if (shipments.length > 0) {
      setShipmentList(shipments);
      setIsShipmentAcquire(true);
    } else if (shipments.length === 0 && !isShipmentAcquired) {
      dispatcher(getRmShipmentsAction());
      setIsShipmentAcquire(true);
    }
  }, [shipments]); // eslint-disable-line

  const rmrrIdRenderer = (params: { value: string }) => {
    return (
      <Button
        style={{ color: '#009fdc' }}
        onClick={() => {
          if (props.onClickedRMRRID) {
            props.onClickedRMRRID(params.value);
          }
        }}
      >
        {params.value}
      </Button>
    );
  };

  const dateRenderer = (date: any) => {
    if (date.value) {
      return moment(date.value).format('MMM DD, YY hh:mm');
    }
    return '';
  };

  const letterOfGuaranteeLink = (params: { value: string }) => {
    if (params.value) {
      return (
        <Button style={{ color: '#009fdc' }} href={params.value}>
          {'Download'}
        </Button>
      );
    }
    return null;
  };

  const columnDefs = [
    {
      headerName: 'RMRR #',
      field: 'rmrrId',
      minWidth: 300,
      filter: 'agTextColumnFilter',
      cellRendererFramework: rmrrIdRenderer,
      headerTooltip: 'RMRR #',
    },
    {
      headerName: 'RM Name',
      field: 'rmName',
      minWidth: 200,
      filter: 'agTextColumnFilter',
      headerTooltip: 'RM Name',
    },
    {
      headerName: 'Supplier',
      field: 'supplierName',
      minWidth: 200,
      filter: 'agTextColumnFilter',
      headerTooltip: 'Supplier',
    },
    {
      headerName: 'Truck Plate#',
      field: 'truckPlateNum',
      minWidth: 100,
      filter: 'agTextColumnFilter',
      headerTooltip: 'Truck Plate#',
    },
    {
      headerName: 'Status',
      field: 'processingStatus',
      minWidth: 100,
      filter: 'agTextColumnFilter',
      headerTooltip: 'Status',
    },
    {
      headerName: 'Date of Creation',
      field: 'inputDateTime',
      minWidth: 100,
      filter: 'agDateColumnFilter',
      cellRenderer: dateRenderer,
      headerTooltip: 'Date of Creation',
      getQuickFilterText: dateRenderer,
    },
    {
      headerName: 'Date of Approval',
      field: 'approvalDatetime',
      minWidth: 100,
      filter: 'agDateColumnFilter',
      cellRenderer: dateRenderer,
      headerTooltip: 'Date of Approval',
      getQuickFilterText: dateRenderer,
    },
    {
      headerName: 'Unit Price',
      field: 'rmUnitPrice',
      minWidth: 100,
      filter: 'agTextColumnFilter',
      hide: true,
      headerTooltip: 'Unit Price',
    },
    {
      headerName: 'Net Weight',
      field: 'netWeight',
      minWidth: 100,
      filter: 'agNumberColumnFilter',
      hide: true,
      headerTooltip: 'Net Weight',
    },
    {
      headerName: 'Truck Scale Number',
      field: 'truckScaleNum',
      minWidth: 100,
      filter: 'agTextColumnFilter',
      hide: true,
      headerTooltip: 'Truck Scale Number',
    },
    {
      headerName: 'Letter of Guarantee',
      field: 'letterOfGuarantee',
      minWidth: 100,
      filter: 'agTextColumnFilter',
      hide: true,
      cellRendererFramework: letterOfGuaranteeLink,
      headerTooltip: 'Letter of Guarantee',
    },
  ];

  const handleChange = (event: any) => {
    let changed = event.target.value;
    columnDefs.forEach((def) => {
      if (def.headerName === changed) {
        let showColList = [...showColumn];
        let hideColList = [...hideColumn];
        if (hideColumn.includes(def.field)) {
          showColList.push(def.field);
          hideColList.splice(hideColList.indexOf(def.field), 1);
        } else {
          hideColList.push(def.field);
          showColList.splice(showColList.indexOf(def.field), 1);
        }
        setShowColumn(showColList);
        setHideColumn(hideColList);
      }
    });
  };

  const hideShowCol = () => {
    return (
      <div>
        <Dialog
          disableEscapeKeyDown
          open={props.showMenu}
          onClose={props.onCloseMenu}
        >
          <DialogTitle>Show/Hide Column</DialogTitle>
          <DialogContent>
            {columnDefs.map((def) => (
              <MenuItem key={def.headerName} value={def.headerName}>
                <Checkbox
                  color="primary"
                  value={def.headerName}
                  checked={showColumn.indexOf(def.field) > -1}
                  onChange={handleChange}
                />
                <ListItemText
                  primary={def.headerName}
                  onClick={() =>
                    handleChange({
                      target: { value: def.headerName },
                    })
                  }
                />
              </MenuItem>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={props.onCloseMenu} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  return (
    <Fragment>
      <Grid container>
        <Grid item>{hideShowCol()}</Grid>
      </Grid>

      <TableGrid
        rowData={shipmentList}
        columnDefs={columnDefs}
        filterText={filterText}
        exportCsv={exportCsv}
        onExportCsv={onExportCsv}
        exportCsvFileName={exportCsvFileName}
        hideColumn={hideColumn}
        showColumn={showColumn}
        height={600}
        deleteColumn={onDeleteRMRRID ? true : false}
        onDeleteRow={(row) => {
            setForDeleteRow(row);
            setOpenDeleteConfirmation(true);
            return false;
        }}
      />
      {openDeleteConfirmation && (
        <DeleteConfirmation
          title="Delete Truck Entry"
          data={forDeleteRow}
          onDelete={(data: any) => {
            if (
                onDeleteRMRRID
                ? onDeleteRMRRID(forDeleteRow!.data!.rmrrId)
                : false
            ) {
              let { data, api } = forDeleteRow;

              api!.updateRowData({ remove: [data] });
              api!.refreshCells({ force: true });
            }

            setOpenDeleteConfirmation(false);
            setForDeleteRow({});
          }}
          onCancel={(data: any) => {
            setOpenDeleteConfirmation(false);
            setForDeleteRow({});
          }}
          message={forDeleteRow!.data!.rmrrId}
        />
      )}
    </Fragment>
  );
};

export default RMShipmentListTable;
