import {
    useSelector,
    TypedUseSelectorHook
} from 'react-redux'

import {
    RMShipmentActionTypes,
    RMShipmentReducerType,
    RMShipmentInfoState,
    GET_RM_SHIPMENTS,
    GET_WAREHOUSE_INFO,
    GET_RM_INFO_LIST
} from './types'
import { LOGOUT } from 'redux/reducers/rootReducer/types';

const initialState: RMShipmentInfoState = {
    shipments: [],
    warehouseInfo: [],
    rmList: [],
}

export const rmShipmentTypedSelector: TypedUseSelectorHook<RMShipmentReducerType> = useSelector;

export function rmShipmentReducer(
    state = initialState,
    action: RMShipmentActionTypes
): RMShipmentInfoState {

    switch (action.type) {
        case GET_RM_SHIPMENTS:

            return {
                shipments: action.payload,
                warehouseInfo: state.warehouseInfo,
                rmList: state.rmList
            }

        case GET_WAREHOUSE_INFO:

            return {
                shipments: state.shipments,
                warehouseInfo: action.payload,
                rmList: state.rmList
            }

        case GET_RM_INFO_LIST:

            return {
                shipments: state.shipments,
                warehouseInfo: state.warehouseInfo,
                rmList: action.payload,
            }

        case LOGOUT:

            return initialState

        default:
            return state
    }

}