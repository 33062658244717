import { Button, Card, CardContent, Grid, Paper, Typography } from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core/styles';

import LineGraph from 'components/common/LineGraph';
import ListItems from 'components/common/ListItems';
import MomentUtils from '@date-io/moment';
import { RMShipmentInfo } from 'redux/reducers/rmShipment/types';
import { RawMaterial } from 'redux/reducers/rmShipment/types';
import { StockInfo } from 'redux/reducers/rmStocks/types';
import { TransferTransactionInfo } from 'redux/reducers/finishedGoods/types';
import { finishedGoodsTypedSelector } from 'redux/reducers/finishedGoods/finishedGoodsReducer';
import { getStockTransactionsRawMaterialLocation } from 'redux/reducers/finishedGoods/actions';
import { getWarehouseLocationStocksAction } from 'redux/reducers/rmStocks/actions';
import moment from 'moment';
import { rmShipmentTypedSelector } from 'redux/reducers/rmShipment/rmShipmentReducer';
import { stocksTypedSelector } from 'redux/reducers/rmStocks/stocksReducer';
import { useDispatch } from 'react-redux';

export interface ContentProps extends WithStyles<typeof styles> {}

const RMDashboard = (props: ContentProps) => {
    const { classes } = props;
    const dispatcher = useDispatch();

    const rmList = rmShipmentTypedSelector<Array<RawMaterial>>((state) => state.rmshipment.rmList);
    const shipments = rmShipmentTypedSelector<Array<RMShipmentInfo>>((state) => state.rmshipment.shipments);
    const stocks = stocksTypedSelector<Array<StockInfo>>((state) => state.stocks.stocks);
    const transactions = finishedGoodsTypedSelector<Array<TransferTransactionInfo>>(
        (state) => state.finishedGoods.transactions
    );

    const [selectedRM, setSelectedRM] = useState('');
    const [rmNameList, setRmNameList] = useState<Array<string>>([]);
    const [remainingStock, setRemainingStock] = useState(0);
    const [lowestPrice, setLowestPrice] = useState(0);
    const [averagePrice, setAveragePrice] = useState('');
    const [lowestPriceSupplier, setLowestPriceSupplier] = useState('');
    const [lowestPriceDate, setLowestPriceDate] = useState('');

    const [priceDatesList, setPriceDatesList] = useState<Array<string>>([]);
    const [priceDataList, setPriceDataList] = useState<Array<number>>([]);
    const [stockDatesList, setStockDatesList] = useState<Array<string>>([]);
    const [stockDataList, setStockDataList] = useState<Array<number>>([]);

    const [fromDate, setFromDate] = useState<string | null>(null);
    const [toDate, setToDate] = useState<string | null>(null);

    useEffect(() => {}, [rmNameList, remainingStock, lowestPrice, averagePrice, lowestPriceSupplier, lowestPriceDate]);

    useEffect(() => {
        if (rmList.length > 0) {
            setRmNameList(rmList.map((rm) => rm.rmName));
            setSelectedRM(rmList[0].rmName);
        }
    }, [rmList]);

    const sortDate = (first: RMShipmentInfo | StockInfo, second: RMShipmentInfo | StockInfo) => {
        if (first.inputDateTime > second.inputDateTime) return 1;
        if (first.inputDateTime < second.inputDateTime) return -1;
        return 0;
    };

    useEffect(() => {
        // Compute the selected rmName price information

        let dateList: string[] = [];
        let priceList: Array<number> = [];

        let filteredShipment = shipments.filter((shipment) => shipment.rmName === selectedRM);
        filteredShipment.sort(sortDate);
        if (fromDate !== null) {
            filteredShipment = filteredShipment.filter((shipment) => shipment.inputDateTime >= fromDate);
        }
        if (toDate !== null) {
            filteredShipment = filteredShipment.filter((shipment) => shipment.inputDateTime <= toDate);
        }

        let total: number = 0;
        let len: number = 0;
        let lowest: number = -1;

        filteredShipment.forEach((shipment) => {
            dateList.push(moment(shipment.inputDateTime).format('MM-DD-YYYY'));
            priceList.push(shipment.rmUnitPrice ? shipment.rmUnitPrice : 0);
            if (typeof shipment.rmUnitPrice === 'string') {
                let price = parseInt(shipment.rmUnitPrice);
                total += price;
                len++;
                if (lowest === -1 || lowest > price) {
                    lowest = price;
                    setLowestPrice(price);
                    setLowestPriceSupplier(shipment.supplierName);
                    setLowestPriceDate(moment(shipment.inputDateTime).format('MM-DD-YYYY'));
                }
            }
        });

        setPriceDatesList(dateList);
        setPriceDataList(priceList);
        if (len > 0) {
            let ave: number = total / len;
            setAveragePrice(ave.toFixed(2));
        }
    }, [selectedRM, shipments, fromDate, toDate]);

    useEffect(() => {
        // Get Stock Transfers of selected RM
        let filteredShipment = shipments.filter((shipment) => {
            return shipment.rmName === selectedRM && shipment.processingStatus === 'COMPLETE';
        });

        if (filteredShipment.length > 0) {
            // Assume that all RM is same type is in same location
            let warehouseId = '';
            let locationId = '';
            filteredShipment.forEach((a) => {
                if (a.warehouseId && a.locationId) {
                    warehouseId = a.warehouseId;
                    locationId = a.locationId;
                }
            });
            if (warehouseId && locationId) {
                dispatcher(getWarehouseLocationStocksAction(warehouseId, locationId));
                dispatcher(getStockTransactionsRawMaterialLocation(warehouseId, locationId, selectedRM));
            }
        }
    }, [selectedRM, dispatcher, shipments]);

    useEffect(() => {
        // Creat list of dates and transfer transactions

        let sorted = stocks.sort(sortDate);
        if (fromDate !== null) {
            sorted = sorted.filter((shipment) => shipment.inputDateTime >= fromDate);
        }
        if (toDate !== null) {
            sorted = sorted.filter((shipment) => shipment.inputDateTime <= toDate);
        }
        let total = 0;
        let netWeightList: number[] = [];
        let netWeightListDates: string[] = [];
        sorted.forEach((a) => {
            total += a.netWeight;
            const found = transactions.find((b) => {
                return b.rmrrId === a.rmrrId && a.inputDateTime === b.transferDatetime;
            });
            if (found) {
                total -= found.transferredWeight;
            }
            netWeightList.push(total);
            netWeightListDates.push(moment(a.inputDateTime).format('MM-DD-YYYY'));
        });
        setStockDatesList(netWeightListDates);
        setStockDataList(netWeightList);
        setRemainingStock(total);
    }, [stocks, transactions, fromDate, toDate]);

    const cardEntry = (title: string, data: string | number) => {
        return (
            <Card style={{ height: 120 }}>
                <CardContent>
                    <Typography style={{ color: '#009fdc' }} align='center' variant='subtitle1'>
                        {title}
                    </Typography>
                    <Typography variant='h6'>{'\n'}</Typography>
                    <Typography style={{ color: '#009fdc' }} align='center' variant='h5'>
                        {data}
                    </Typography>
                </CardContent>
            </Card>
        );
    };

    const selectedRMChange = (rm: string) => {
        setRemainingStock(0);
        setLowestPrice(0);
        setAveragePrice('');
        setLowestPriceSupplier('');
        setLowestPriceDate('');
        setStockDatesList([]);
        setStockDataList([]);
        setSelectedRM(rm);
    };

    const renderContent = () => {
        return (
            <div className={classes.contentWrapper}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <ListItems
                            title='Raw Material Name'
                            dataList={rmNameList}
                            selected={selectedRM}
                            setter={(data) => selectedRMChange(data)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Grid item xs={12}>
                            <Typography style={{ color: '#009fdc', paddingTop: 25 }} align='center' variant='subtitle1'>
                                {'Date Range'}
                            </Typography>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DateTimePicker
                                    margin='dense'
                                    fullWidth
                                    value={fromDate}
                                    label={'From'}
                                    inputVariant='outlined'
                                    onChange={(date) => setFromDate(moment(date).toISOString())}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DateTimePicker
                                    margin='dense'
                                    fullWidth
                                    value={toDate}
                                    label={'To'}
                                    inputVariant='outlined'
                                    onChange={(date) => setToDate(moment(date).toISOString())}
                                />
                            </MuiPickersUtilsProvider>
                            <div style={{ display: 'flex', justifyContent: 'center', paddingTop: 15 }}>
                                <Button
                                    size='small'
                                    className={classes.contentButton}
                                    color='primary'
                                    variant='contained'
                                    onClick={() => {
                                        setFromDate(null);
                                        setToDate(null);
                                    }}>
                                    Clear Dates
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} spacing={2} style={{ paddingTop: 20 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                {cardEntry('Raw Material', selectedRM)}
                            </Grid>
                            <Grid item xs={6}>
                                {cardEntry('Lowest Price', lowestPrice)}
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                {cardEntry('Lowest Price Supplier', lowestPriceSupplier)}
                            </Grid>
                            <Grid item xs={6}>
                                {cardEntry('Lowest Price Date', lowestPriceDate)}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={4} spacing={2} style={{ paddingTop: 20 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                {cardEntry('Average Price', averagePrice)}
                            </Grid>
                            <Grid item xs={6}>
                                {cardEntry('Remaining Stock', remainingStock)}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <LineGraph labels={priceDatesList} data={priceDataList} title={`Price of ${selectedRM}`} />
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <LineGraph labels={stockDatesList} data={stockDataList} title={`Stock of ${selectedRM}`} />
                    </Grid>
                </Grid>
            </div>
        );
    };

    return <Paper className={classes.paper}>{renderContent()}</Paper>;
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: '99%',
            margin: 'auto',
            overflow: 'hidden',
            padding: 5,
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: 'rgb(31,49,69)',
            alignSelf: 'center',
            padding: 20,
            paddingTop: 10,
            paddingBottom: 10,
        },
        contentWrapper: {
            margin: '0px 0px',
        },
    });

export default withStyles(styles)(RMDashboard);
