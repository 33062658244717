import {
    AppBar,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Paper,
    TextField,
    Theme,
    Toolbar,
    Tooltip,
    Typography,
    WithStyles,
    createStyles,
    withStyles,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { SupplierInfo, SupplierInfoList } from "redux/reducers/purchasing/types";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from "@material-ui/icons/Search";
import SupplierAddView from "./SupplierAddView";
import SupplierDetailsView from "./SupplierDetailsView";
import SupplierListTable from "./SupplierListTable";
import SupplierUpdateView from "./SupplierUpdateView";
import { getAllSuppliersAction, deleteSupplierAction } from "redux/reducers/purchasing/actions";
import { purchasingTypedSelector } from "redux/reducers/purchasing/purchasingReducer";
import { useDispatch } from "react-redux";

export interface ContentProps extends WithStyles<typeof styles> {}

const viewState = {
    supplierListView: "supplierListView",
    supplierShowDetailsView: "showDetailsView",
    supplierAddView: "addSupplier",
    supplierUpdateView: "updateSupplier",
};
let _gSupplierList: Array<SupplierInfo> | [] = [];
const SupplierListView = (props: ContentProps) => {
    const { classes } = props;
    const [filterText, setfilterText] = useState("");
    const [supplierList, setsupplierList] = useState<Array<SupplierInfo> | []>([]);
    const [clickedSupplierId, setClickedSupplierId] = useState("");
    const [clickedSupplierDetails, setClickedSupplierDetails] = useState<SupplierInfo | undefined>(undefined);
    const [onReload, setOnReload] = useState(false);
    const [isAcquired, setIsAcquired] = useState(false);
    const dispatcher = useDispatch();
    const [addFormHasData, setAddFormHasData] = useState(false);
    const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);
    const [view, setView] = useState(viewState.supplierListView);

    const supplierListResponse = purchasingTypedSelector<SupplierInfoList>((state) => state.purchasing.suppliers);

    const isView = (checkView: string) => {
        return view === checkView;
    };

    useEffect(() => {
        if (supplierListResponse.suppliersList.length > 0) {
            setsupplierList(supplierListResponse.suppliersList);
            _gSupplierList = supplierListResponse.suppliersList;
        } else if (!isAcquired) {
            setIsAcquired(true);
            dispatcher(getAllSuppliersAction());
        }
    }, [supplierListResponse, isAcquired, dispatcher]);

    useEffect(() => {
        if (supplierList.length > 0) {
            setTimeout(() => {
                setOnReload(false);
            }, 500);
        }
    }, [supplierList]);

    const onClickedSupplier = (supplierId: string) => {
        setClickedSupplierId(supplierId);
        setClickedSupplierDetails(_gSupplierList.find((data) => data.supplierId === supplierId));
        setView(viewState.supplierShowDetailsView);
    };

    const onCloseConfirmCancelModal = () => {
        setShowConfirmCancelModal(false);
    };

    const revertToListView = () => {
        setTimeout(() => {
            dispatcher(getAllSuppliersAction());
        }, 1000);

        setClickedSupplierId("");
        setClickedSupplierDetails(undefined);
        setAddFormHasData(false);
        setView(viewState.supplierListView);
    };

    const confirmCancelInAddUpdate = () => {
        return (
            <div>
                <Dialog
                    disableEscapeKeyDown={true}
                    disableBackdropClick={true}
                    open={showConfirmCancelModal}
                    onClose={onCloseConfirmCancelModal}
                >
                    <DialogTitle>
                        <IconButton
                            className={classes.rightButton}
                            aria-label="close"
                            onClick={onCloseConfirmCancelModal}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="h6" component="h1" align="center">
                            {"This action will discard the current input information. Proceed?"}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                onCloseConfirmCancelModal();
                                if (isView(viewState.supplierUpdateView)) {
                                    setView(viewState.supplierShowDetailsView);
                                } else {
                                    revertToListView();
                                }
                            }}
                            color="primary"
                            variant="contained"
                            fullWidth={true}
                        >
                            Yes
                        </Button>
                        <Button
                            onClick={onCloseConfirmCancelModal}
                            color="primary"
                            variant="contained"
                            fullWidth={true}
                        >
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    const fetchAndReloadList = () => {
        setsupplierList([]);
        setOnReload(true);
        dispatcher(getAllSuppliersAction());
    }

    const rendersupplierListTableButton = () => {
        return (
            <Grid item>
                <Tooltip title="Create Request">
                    <IconButton onClick={() => setView(viewState.supplierAddView)}>
                        <NoteAddIcon className={classes.block} color="inherit" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Reload">
                    <IconButton
                        onClick={fetchAndReloadList}
                    >
                        {onReload ? (
                            <CircularProgress size="24px" />
                        ) : (
                            <RefreshIcon className={classes.block} color="inherit" />
                        )}
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    };

    const rendersupplierListTableSearch = () => {
        return (
            <Fragment>
                <Grid item>
                    <SearchIcon className={classes.block} color="inherit" />
                </Grid>
                <Grid item xs>
                    <TextField
                        fullWidth
                        placeholder="Search"
                        InputProps={{
                            disableUnderline: true,
                            className: classes.searchInput,
                        }}
                        onChange={(event) => setfilterText(event.target.value)}
                    />
                </Grid>
            </Fragment>
        );
    };

    const rendersupplierListTableContainer = () => {
        return (
            <Grid container spacing={2} alignItems="center">
                {rendersupplierListTableSearch()}
                {rendersupplierListTableButton()}
            </Grid>
        );
    };

    const onDeleteSupplier = (supplierId : string) => {
        dispatcher(deleteSupplierAction(supplierId))
        return true;
    }

    const renderSupplierListTable = () => {
        return (
            <div className={classes.contentWrapper}>
                <SupplierListTable
                    onClickedSupplier={onClickedSupplier}
                    onDeleteSupplier={onDeleteSupplier}
                    supplierList={supplierList}
                    filterText={filterText}
                />
            </div>
        );
    };

    const renderSupplierDetailsContainer = () => {
        return (
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Tooltip title="Return / Cancel">
                        <IconButton onClick={revertToListView}>
                            <ArrowBackIosIcon className={classes.block} color="inherit" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    {clickedSupplierId && (
                        <Typography color="primary" variant="h5" component="h1">
                            {`Supplier Details`}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        );
    };

    const renderSupplierDetailsView = () => {
        return (
            <div className={classes.contentWrapper}>
                <SupplierDetailsView
                    clickedSupplierDetails={clickedSupplierDetails}
                    onClickedUpdateButton={() => {
                        setView(viewState.supplierUpdateView);
                    }}
                />
            </div>
        );
    };

    const renderSupplierAddContainer = () => {
        return (
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Tooltip title="Cancel">
                        <IconButton
                            onClick={() => {
                                if (addFormHasData) {
                                    setShowConfirmCancelModal(true);
                                    confirmCancelInAddUpdate();
                                } else {
                                    revertToListView();
                                }
                            }}
                        >
                            <ArrowBackIosIcon className={classes.block} color="inherit" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Typography color="primary" variant="h5" component="h1">
                        {"Create Supplier Information"}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    const onAddFormSetData = () => {
        setAddFormHasData(true);
    };

    const renderSupplierAddView = () => {
        return (
            <div className={classes.contentWrapper}>
                <SupplierAddView
                    onFormClose={revertToListView}
                    onAddFormSetData={onAddFormSetData}
                    onCancel={() => {
                        if (addFormHasData) {
                            setShowConfirmCancelModal(true);
                            confirmCancelInAddUpdate();
                        } else {
                            revertToListView();
                        }
                    }}
                />
            </div>
        );
    };

    const renderSupplierUpdateContainer = () => {
        return (
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Tooltip title="Cancel">
                        <IconButton
                            onClick={() => {
                                setShowConfirmCancelModal(true);
                                confirmCancelInAddUpdate();
                            }}
                        >
                            <ArrowBackIosIcon className={classes.block} color="inherit" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Typography color="primary" variant="h5" component="h1">
                        {`Update Supplier Information `}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    const renderSupplierUpdateView = () => {
        return (
            <div className={classes.contentWrapper}>
                <SupplierUpdateView
                    onFormClose={revertToListView}
                    onCancel={() => {
                        setShowConfirmCancelModal(true);
                        confirmCancelInAddUpdate();
                    }}
                    clickedSupplierDetails={clickedSupplierDetails}
                />
            </div>
        );
    };

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                <Toolbar>
                    {isView(viewState.supplierListView) && rendersupplierListTableContainer()}
                    {isView(viewState.supplierShowDetailsView) &&
                        clickedSupplierDetails &&
                        renderSupplierDetailsContainer()}
                    {(isView(viewState.supplierAddView) || isView(viewState.supplierUpdateView)) &&
                        confirmCancelInAddUpdate()}
                    {isView(viewState.supplierAddView) && renderSupplierAddContainer()}
                    {isView(viewState.supplierUpdateView) && renderSupplierUpdateContainer()}
                </Toolbar>
            </AppBar>
            {isView(viewState.supplierListView) && renderSupplierListTable()}
            {isView(viewState.supplierShowDetailsView) && clickedSupplierDetails && renderSupplierDetailsView()}
            {isView(viewState.supplierAddView) && renderSupplierAddView()}
            {isView(viewState.supplierUpdateView) && clickedSupplierDetails && renderSupplierUpdateView()}
        </Paper>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: "99%",
            margin: "auto",
            overflow: "hidden",
            alignContent: "center",
        },
        searchBar: {
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: "block",
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: "rgb(31,49,69)",
            alignSelf: "center",
        },
        contentWrapper: {
            margin: "0px 0px",
        },
        rightButton: {
            float: "right",
        },
    });

export default withStyles(styles)(SupplierListView);
