import { Typography } from "@material-ui/core";
import React, { forwardRef, useImperativeHandle } from "react";
import moment from 'moment';

export default forwardRef((props: any, ref: any) => {
  useImperativeHandle(ref, () => {
    return {};
  });

  return (
    <Typography variant='body2' style={{ paddingTop: 10 }}>
        {props.value && (moment(props.value).format('MMM DD, YYYY HH:mm'))}
    </Typography>
);
});
