import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';

import EventIcon from '@material-ui/icons/Event';
import MomentUtils from '@date-io/moment';
import { RMTruckEntries } from 'redux/reducers/truckEntries/types';
import TableGrid from 'components/common/TableGrid';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import DeleteConfirmation from 'components/common/DeleteConfirmation';
import { GridApi } from 'ag-grid';

export interface RMTruckEntriesListTableProps {
  filterText: string;
  exportCsv?: boolean;
  onExportCsv?: any;
  truckEntires: Array<RMTruckEntries>;
  exportCsvFileName?: string;
  onUpdate: (update: any) => void;
  onDeleteTruckEntry?: (truckEntry: RMTruckEntries) => void;
}

interface RowInfo {
    api?: GridApi;
    data?: RMTruckEntries;
  }
  
const RMTruckEntriesListTable = (props: RMTruckEntriesListTableProps) => {
  const {
    filterText,
    exportCsv,
    onExportCsv,
    truckEntires,
    onUpdate,
    exportCsvFileName,
    onDeleteTruckEntry,
  } = props;

  const [sortedData, setSortedData] = useState<Array<any>>([]);
  const [updatedOutData, setUpdatedOutData] = useState<any>({});
  const [forDeleteRow, setForDeleteRow] = useState<RowInfo | any>({});
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(
    false
  );

  useEffect(() => {
    let sortedList = truckEntires.sort((a, b) => {
      if (a.truckTimeIn > b.truckTimeIn) return -1;
      if (a.truckTimeIn < b.truckTimeIn) return 1;
      return 0;
    });
    setSortedData(sortedList);
  }, [truckEntires]);

  useEffect(() => {
    if (updatedOutData.truckTimeOut) {
      onUpdate(updatedOutData);
      setUpdatedOutData({});
    }
  }, [updatedOutData, onUpdate]);

  useEffect(() => {}, [sortedData]);
  const { enqueueSnackbar } = useSnackbar();

  const dateRenderer = (date: any) => {
    if (date.value) {
      return (
        <Typography variant="body2" style={{ paddingTop: 10 }}>
          {moment(date.value).format('MMM DD, YYYY HH:mm')}
        </Typography>
      );
    }
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DateTimePicker
          disableFuture
          ampm={false}
          value={null}
          onChange={(event) => {
            if (
              moment(event).toISOString() <=
              moment(date.data.truckTimeIn).toISOString()
            ) {
              enqueueSnackbar(
                'OUT Date and Time must be after IN Date and Time',
                { variant: 'warning' }
              );
            } else {
              setUpdatedOutData({
                truckTimeIn: date.data.truckTimeIn,
                truckTimeOut: moment(event).toISOString(),
                plateNum: date.data.plateNum,
                supplierName: date.data.supplierName,
              });
            }
          }}
          format="MMM DD, YYYY HH:mm"
          inputVariant="standard"
          onClose={() => {}}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton>
                  <EventIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </MuiPickersUtilsProvider>
    );
  };

  useEffect(() => {}, [truckEntires]);

  const columnDefs = [
    {
      headerName: 'Supplier Name',
      field: 'supplierName',
      filter: 'agTextColumnFilter',
      headerTooltip: 'Supplier Name',
    },
    {
      headerName: 'Plate Number',
      field: 'plateNumber',
      filter: 'agTextColumnFilter',
      headerTooltip: 'Plate Number',
    },
    {
      headerName: 'Truck Date/Time IN',
      field: 'truckTimeIn',
      filter: 'agDateColumnFilter',
      headerTooltip: 'Truck Date/Time IN',
      cellRendererFramework: dateRenderer,
    },
    {
      headerName: 'Truck Date/Time OUT',
      field: 'truckTimeOut',
      filter: 'agDateColumnFilter',
      headerTooltip: 'Truck Date/Time OUT',
      cellRendererFramework: dateRenderer,
    },
  ];

  return (
    <Fragment>
      <Grid container>
        <Grid item />
      </Grid>

      <TableGrid
        rowData={sortedData}
        columnDefs={columnDefs}
        filterText={filterText}
        exportCsv={exportCsv}
        onExportCsv={onExportCsv}
        exportCsvFileName={exportCsvFileName}
        height={600}
        deleteColumn={onDeleteTruckEntry ? true : false}
        onDeleteRow={(row) => {
            setForDeleteRow(row);
            setOpenDeleteConfirmation(true);
            return false;
        }}
      />
      {openDeleteConfirmation && (
        <DeleteConfirmation
          title="Delete Truck Entry"
          data={forDeleteRow}
          onDelete={(data: any) => {
            if (
                onDeleteTruckEntry
                ? onDeleteTruckEntry(forDeleteRow!.data)
                : false
            ) {
              let { data, api } = forDeleteRow;

              api!.updateRowData({ remove: [data] });
              api!.refreshCells({ force: true });
            }

            setOpenDeleteConfirmation(false);
            setForDeleteRow({});
          }}
          onCancel={(data: any) => {
            setOpenDeleteConfirmation(false);
            setForDeleteRow({});
          }}
          message={moment(forDeleteRow!.data!.truckTimeIn).format(
            'MMM DD, YYYY HH:mm'
          )}
        />
      )}
    </Fragment>
  );
};

export default RMTruckEntriesListTable;
