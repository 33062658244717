import {
    AppBar,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Paper,
    TextField,
    Theme,
    Toolbar,
    Tooltip,
    Typography,
    WithStyles,
    createStyles,
    withStyles,
} from "@material-ui/core";
import { PurchaseOrderInfo, PurchaseOrderInfoList } from "redux/reducers/purchasing/types";
import React, { Fragment, useEffect, useState } from "react";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import PurchaseOrderAddView from "./PurchaseOrderAddView";
import PurchaseOrderDetailsView from "./PurchaseOrderDetailsView";
import PurchaseOrderListTable from "./PurchaseOrderListTable";
import PurchaseOrderUpdateView from "./PurchaseOrderUpdateView";
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from "@material-ui/icons/Search";
import { getAllPurchaseOrdersAction, deletePurchaseOrderAction } from "redux/reducers/purchasing/actions";
import { purchasingTypedSelector } from "redux/reducers/purchasing/purchasingReducer";
import { useDispatch } from "react-redux";

export interface ContentProps extends WithStyles<typeof styles> {}

const viewState = {
    purchaseOrderListView: "orderListView",
    purchaseOrderShowDetailsView: "showDetailsView",
    purchaseOrderAddView: "addPurchaseOrder",
    purchaseOrderUpdateView: "updatePurchaseOrder",
};

let _gPurchaseOrderList: Array<PurchaseOrderInfo> | [] = [];
const PurchaseOrderListView = (props: ContentProps) => {
    const { classes } = props;
    const [filterText, setfilterText] = useState("");
    const [clickedOrderId, setClickedOrderId] = useState("");
    const [clickedOrderDetails, setClickedOrderDetails] = useState<PurchaseOrderInfo | undefined>(undefined);
    const [purchaseOrderList, setPurchaseOrderList] = useState<Array<PurchaseOrderInfo> | []>([]);
    const [onReload, setOnReload] = useState(false);
    const [isAcquired, setIsAcquired] = useState(false);
    const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);
    const [view, setView] = useState(viewState.purchaseOrderListView);
    const dispatcher = useDispatch();
    const [addFormHasData, setAddFormHasData] = useState(false);

    const purchaseOrderListResponse = purchasingTypedSelector<PurchaseOrderInfoList>(
        (state) => state.purchasing.purchaseOrders
    );

    const isView = (checkView: string) => {
        return view === checkView;
    };

    useEffect(() => {
        if (purchaseOrderListResponse.ordersList.length > 0) {
            setPurchaseOrderList(purchaseOrderListResponse.ordersList);
            _gPurchaseOrderList = purchaseOrderListResponse.ordersList;
        } else if (!isAcquired) {
            setIsAcquired(true);
            dispatcher(getAllPurchaseOrdersAction());
        }
    }, [purchaseOrderListResponse, isAcquired, dispatcher]);

    useEffect(() => {
        if (purchaseOrderList.length > 0) {
            setTimeout(() => {
                setOnReload(false);
            }, 500);
        }
    }, [purchaseOrderList]);

    const onClickedPurchaseOrder = (orderId: string) => {
        setClickedOrderId(orderId);
        setClickedOrderDetails(_gPurchaseOrderList.find((data) => data.orderId === orderId));
        setView(viewState.purchaseOrderShowDetailsView);
    };

    const revertToListView = () => {
        setTimeout(() => {
            dispatcher(getAllPurchaseOrdersAction());
        }, 1000);

        setClickedOrderId("");
        setClickedOrderDetails(undefined);
        setAddFormHasData(false);
        setView(viewState.purchaseOrderListView);
    };

    const onCloseConfirmCancelModal = () => {
        setShowConfirmCancelModal(false);
    };

    const confirmCancelInAddUpdate = () => {
        return (
            <div>
                <Dialog
                    disableEscapeKeyDown={true}
                    disableBackdropClick={true}
                    open={showConfirmCancelModal}
                    onClose={onCloseConfirmCancelModal}
                >
                    <DialogTitle>
                        <IconButton
                            className={classes.rightButton}
                            aria-label="close"
                            onClick={onCloseConfirmCancelModal}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="h6" component="h1" align="center">
                            {"This action will discard the current input information. Proceed?"}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                onCloseConfirmCancelModal();
                                if (isView(viewState.purchaseOrderUpdateView)) {
                                    setView(viewState.purchaseOrderShowDetailsView);
                                } else {
                                    revertToListView();
                                }
                            }}
                            color="primary"
                            variant="contained"
                            fullWidth={true}
                        >
                            Yes
                        </Button>
                        <Button
                            onClick={onCloseConfirmCancelModal}
                            color="primary"
                            variant="contained"
                            fullWidth={true}
                        >
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    const fetchAndReloadList = () => {
        setPurchaseOrderList([]);
        _gPurchaseOrderList = [];
        setOnReload(true);
        dispatcher(getAllPurchaseOrdersAction());
    }

    const renderPurchaseOrderListTableButton = () => {
        return (
            <Grid item>
                <Tooltip title="Create Order">
                    <IconButton onClick={() => setView(viewState.purchaseOrderAddView)}>
                        <NoteAddIcon className={classes.block} color="inherit" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Reload">
                    <IconButton
                        onClick={fetchAndReloadList}
                    >
                        {onReload ? (
                            <CircularProgress size="24px" />
                        ) : (
                            <RefreshIcon className={classes.block} color="inherit" />
                        )}
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    };

    const renderPurchaseOrderListTableSearch = () => {
        return (
            <Fragment>
                <Grid item>
                    <SearchIcon className={classes.block} color="inherit" />
                </Grid>
                <Grid item xs>
                    <TextField
                        fullWidth
                        placeholder="Search"
                        InputProps={{
                            disableUnderline: true,
                            className: classes.searchInput,
                        }}
                        onChange={(event) => setfilterText(event.target.value)}
                    />
                </Grid>
            </Fragment>
        );
    };

    const renderPurchaseOrderListTableContainer = () => {
        return (
            <Grid container spacing={2} alignItems="center">
                {renderPurchaseOrderListTableSearch()}
                {renderPurchaseOrderListTableButton()}
            </Grid>
        );
    };

    const onDeletePurchaseOrder = (id : string) => {
        dispatcher(deletePurchaseOrderAction(id))
        return true;
    }

    const renderPurchaseOrderListTable = () => {
        return (
            <div className={classes.contentWrapper}>
                <PurchaseOrderListTable
                    onClickedPurchaseOrder={onClickedPurchaseOrder}
                    onDeletedPurchaseOrder={onDeletePurchaseOrder}
                    purchaseOrderList={purchaseOrderList}
                    filterText={filterText}
                />
            </div>
        );
    };

    const renderPurchaseOrderDetailsContainer = () => {
        return (
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Tooltip title="Return / Cancel">
                        <IconButton onClick={revertToListView}>
                            <ArrowBackIosIcon className={classes.block} color="inherit" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    {clickedOrderId && (
                        <Typography color="primary" variant="h5" component="h1">
                            {`Order # ${clickedOrderId} `}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        );
    };

    const renderPurchaseOrderDetailsView = () => {
        return (
            <div className={classes.contentWrapper}>
                <PurchaseOrderDetailsView
                    clickedPurchaseOrder={clickedOrderDetails}
                    onClickedUpdateButton={() => {
                        setView(viewState.purchaseOrderUpdateView);
                    }}
                />
            </div>
        );
    };

    const renderPurchaseOrderAddContainer = () => {
        return (
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Tooltip title="Cancel">
                        <IconButton
                            onClick={() => {
                                if (addFormHasData) {
                                    setShowConfirmCancelModal(true);
                                    confirmCancelInAddUpdate();
                                } else {
                                    revertToListView();
                                }
                            }}
                        >
                            <ArrowBackIosIcon className={classes.block} color="inherit" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Typography color="primary" variant="h5" component="h1">
                        {" Create Purchase Order"}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    const onAddFormSetData = () => {
        setAddFormHasData(true);
    };

    const renderPurchaseOrderAddView = () => {
        return (
            <div className={classes.contentWrapper}>
                <PurchaseOrderAddView
                    onFormClose={revertToListView}
                    onAddFormSetData={onAddFormSetData}
                    onCancel={() => {
                        if (addFormHasData) {
                            setShowConfirmCancelModal(true);
                            confirmCancelInAddUpdate();
                        } else {
                            revertToListView();
                        }
                    }}
                />
            </div>
        );
    };

    const renderPurchaseOrderUpdateContainer = () => {
        return (
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Tooltip title="Cancel">
                        <IconButton
                            onClick={() => {
                                setShowConfirmCancelModal(true);
                                confirmCancelInAddUpdate();
                            }}
                        >
                            <ArrowBackIosIcon className={classes.block} color="inherit" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Typography color="primary" variant="h5" component="h1">
                        {`Update Order # ${clickedOrderId} `}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    const renderPurchaseOrderUpdateView = () => {
        return (
            <div className={classes.contentWrapper}>
                <PurchaseOrderUpdateView
                    onFormClose={revertToListView}
                    onCancel={() => {
                        setShowConfirmCancelModal(true);
                        confirmCancelInAddUpdate();
                    }}
                    clickedPurchaseOrder={clickedOrderDetails}
                />
            </div>
        );
    };

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                <Toolbar>
                    {isView(viewState.purchaseOrderListView) && renderPurchaseOrderListTableContainer()}
                    {isView(viewState.purchaseOrderShowDetailsView) &&
                        clickedOrderDetails &&
                        renderPurchaseOrderDetailsContainer()}
                    {(isView(viewState.purchaseOrderAddView) || isView(viewState.purchaseOrderUpdateView)) &&
                        confirmCancelInAddUpdate()}
                    {isView(viewState.purchaseOrderAddView) && renderPurchaseOrderAddContainer()}
                    {isView(viewState.purchaseOrderUpdateView) && renderPurchaseOrderUpdateContainer()}
                </Toolbar>
            </AppBar>
            {isView(viewState.purchaseOrderListView) && renderPurchaseOrderListTable()}
            {isView(viewState.purchaseOrderShowDetailsView) && clickedOrderDetails && renderPurchaseOrderDetailsView()}
            {isView(viewState.purchaseOrderAddView) && renderPurchaseOrderAddView()}
            {isView(viewState.purchaseOrderUpdateView) && clickedOrderDetails && renderPurchaseOrderUpdateView()}
        </Paper>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: "99%",
            margin: "auto",
            overflow: "hidden",
            alignContent: "center",
        },
        searchBar: {
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: "block",
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: "rgb(31,49,69)",
            alignSelf: "center",
        },
        contentWrapper: {
            margin: "0px 0px",
        },
        rightButton: {
            float: "right",
        },
    });

export default withStyles(styles)(PurchaseOrderListView);
