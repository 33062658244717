import { Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { Canvass, CanvassItem } from 'redux/reducers/canvass/types';
import {
    ItemsToRequisitionInfo,
    PurchaseRequestInfo,
    PurchaseRequestInfoList,
    PurchaseRequestStatus,
    SupplierInfoList,
} from 'redux/reducers/purchasing/types';
import React, { Fragment, useEffect, useState } from 'react';
import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import { createNewCanvassAction, updateCanvassByIDAction } from 'redux/reducers/canvass/actions';
import { getAllPurchaseRequestsAction, getAllSuppliersAction } from 'redux/reducers/purchasing/actions';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import CanvassItemsListTable from './CanvassItemsListTable';
import SearchIcon from '@material-ui/icons/Search';
import SelectComponent from 'components/common/Select';
import UpdateIcon from '@material-ui/icons/Update';
import { purchasingTypedSelector } from 'redux/reducers/purchasing/purchasingReducer';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

export interface ContentProps extends WithStyles<typeof styles> {
    clickedCanvassID: string;
    onClickedUpdateButton?: () => void;
    onCancel: () => void;
    inputCanvassListItems?: CanvassItem[];
    inputPreparedBy: string;
    inputApprovedBy: string;
}

let _gCanvassListItems: CanvassItem[] = [];

const CanvassSheetDetailsView = (props: ContentProps) => {
    const dispatcher = useDispatch();

    const { clickedCanvassID, classes, onCancel, inputCanvassListItems, inputPreparedBy, inputApprovedBy } = props;

    const [requestId, setRequestID] = useState('');
    const [isRequestListAcquired, setIsRequestListIsAcquired] = useState(false);
    const [purchaseRequestList, setPurchaseRequestList] = useState<Array<PurchaseRequestInfo>>([]);
    const [purchaseRequestItemList, setPurchaseRequestItemList] = useState<Array<ItemsToRequisitionInfo>>([]);

    const [itemCode, setItemCode] = useState('');
    const [itemDescription, setItemDescription] = useState('');
    const [supplierName, setSupplierName] = useState('');
    const [itemUnitPrice, setItemUnitPrice] = useState(0);

    const [preparedBy, setPreparedBy] = useState('');

    const [isSuppliersInfoAcquired, setIsSuppliersInfoAcquired] = useState(false);

    const [canvassListItems, setCanvassListItems] = useState<Array<CanvassItem>>([]);
    const [itemsListFilter, setItemsListFilter] = useState('');

    const purchaseRequests = purchasingTypedSelector<PurchaseRequestInfoList>((s) => s.purchasing.purchaseRequests);
    const supplierList = purchasingTypedSelector<SupplierInfoList>((s) => s.purchasing.suppliers);

    const [itemQuantity, setIitemQuantity] = useState(0);
    const [itemQuantityHelper, setItemQuantityHelper] = useState('');


    useEffect(() => {
        if (purchaseRequests.requestsList.length > 0) {
            setPurchaseRequestList(purchaseRequests.requestsList);
        } else if (!isRequestListAcquired) {
            setIsRequestListIsAcquired(true);
            dispatcher(getAllPurchaseRequestsAction());
        }
    }, [purchaseRequests, isRequestListAcquired, dispatcher]);

    useEffect(() => {
        if (!isSuppliersInfoAcquired) {
            setIsSuppliersInfoAcquired(true);
            dispatcher(getAllSuppliersAction());
        }
    }, [supplierList, isSuppliersInfoAcquired, dispatcher]);

    useEffect(() => {
        if (requestId !== '' && purchaseRequestList.length > 0) {
            const request = purchaseRequestList.find((item) => item.requestId === requestId);
            if (request) {
                setPurchaseRequestItemList(request.itemsList);
            }
        }
    }, [requestId, purchaseRequestList]);

    useEffect(() => {
        if (inputPreparedBy) {
            setPreparedBy(inputPreparedBy);
        }
    }, [inputPreparedBy]);

    useEffect(() => {
        if (inputApprovedBy) {
            setApprovedBy(inputApprovedBy);
        }
    }, [inputApprovedBy]);

    useEffect(() => {
        if (
            inputCanvassListItems &&
            inputCanvassListItems.length > 0 &&
            supplierList &&
            supplierList.suppliersList.length > 0
        ) {
            const updatedList = inputCanvassListItems.map((i) => ({
                ...i,
                supplierName: supplierList.suppliersList.filter((s) => s.supplierId === i.supplierId)[0].supplierName,
            }));
            setCanvassListItems(updatedList);
            _gCanvassListItems = [...updatedList];
        }
    }, [inputCanvassListItems, supplierList]);

    const [itemSelectHelper, setItemSelectHelper] = useState('');
    const renderItemsSelect = () => {
        return (
            <SelectComponent
                title={'Item'}
                selectList={purchaseRequestItemList.map((item) => item.itemDescription)}
                setter={(val) => {
                    setItemCode(purchaseRequestItemList.filter((p) => p.itemDescription === val)[0].itemCode);
                    setItemDescription(val);
                    setItemSelectHelper('');
                }}
                currentValue={itemDescription}
                error={itemSelectHelper !== ''}
                codeHelperText={itemSelectHelper}
            />
        );
    };

    const [supplierSelectHelper, setSupplierSelectHelper] = useState('');
    const renderSupplierSelect = () => {
        return (
            <SelectComponent
                title={'Supplier'}
                selectList={supplierList.suppliersList
                    .filter((s) => s.itemsList.filter((i) => i.itemCode === itemCode).length > 0)
                    .map((m) => m.supplierName)}
                setter={(val) => {
                    setSupplierName(val);
                    setSupplierSelectHelper('');
                }}
                currentValue={supplierName}
                error={supplierSelectHelper !== ''}
                codeHelperText={supplierSelectHelper}
            />
        );
    };

    const [priceHelper, setPriceHelper] = useState('');
    const renderItemUnitPrice = () => {
        return (
            <TextField
                label={'Unit Price'}
                value={itemUnitPrice}
                onChange={(event) => {
                    setItemUnitPrice(parseInt(event.target.value as string));
                    setPriceHelper('');
                }}
                fullWidth
                helperText={priceHelper}
                error={priceHelper !== ''}
            />
        );
    };

    const renderItemQuantity = () => {
        return (
            <TextField
                label={'Order Quantity'}
                type={"number"}
                value={itemQuantity}
                onChange={(event) => {
                    setIitemQuantity(parseInt(event.target.value as string));
                    setItemQuantityHelper('');
                }}
                fullWidth
                helperText={itemQuantityHelper}
                error={itemQuantityHelper !== ''}
            />
        );
    };

    const addCanvasItem = () => {
        let invalid = false;
        if (!!!itemDescription) {
            setItemSelectHelper('Please select an item');
            invalid = true;
        }
        if (!!!supplierName) {
            setSupplierSelectHelper('Please select a supplier');
            invalid = true;
        }
        if (itemUnitPrice <= 0) {
            setPriceHelper('Please provide unit price');
            invalid = true;
        }
        if (itemQuantity <= 0) {
            setItemQuantityHelper('Please provide number of units');
            invalid = true;
        }
        if (invalid) {
            return;
        }
        setcanvassListHelper('');
        const addItem = {
            itemDescription,
            itemCode: itemCode,
            supplierName,
            supplierId: supplierList.suppliersList.filter((s) => s.supplierName === supplierName)[0].supplierId,
            itemUnitPrice,
            itemQuantity,
            itemCanvassApproved: false,
            itemCanvassApprovedStatus: 'Not Approved',
        };

        setItemCode('');
        setItemDescription('');
        setSupplierName('');
        setItemUnitPrice(0);
        setIitemQuantity(0);

        setCanvassListItems([...canvassListItems, addItem]);
        _gCanvassListItems = [...canvassListItems, addItem];
    };

    const isObjectEqual = (o1: any, o2: any) => {
        for (let p in o1) {
            if (o1.hasOwnProperty(p)) {
                if (o1[p] !== o2[p]) {
                    return false;
                }
            }
        }
        for (let p in o2) {
            if (o2.hasOwnProperty(p)) {
                if (o1[p] !== o2[p]) {
                    return false;
                }
            }
        }
        return true;
    };

    const deleteListItem = (item: CanvassItem) => {
        const filteredItems = _gCanvassListItems.filter((i) => !isObjectEqual(i, item));
        setCanvassListItems([...filteredItems]);
        _gCanvassListItems = [...filteredItems];
    };

    const updateItem = (oldItem: CanvassItem, newStatus: string) => {
        const updatedItem = _gCanvassListItems.map((i) =>
            isObjectEqual(i, oldItem)
                ? {
                      ...oldItem,
                      itemCanvassApprovedStatus: newStatus,
                      itemCanvassApproved: newStatus === 'Approved' ? true : false,
                  }
                : i
        );
        setCanvassListItems([...updatedItem]);
        _gCanvassListItems = [...updatedItem];
    };

    const renderAddItemArea = () => {
        if (!!!requestId && !!!clickedCanvassID) {
            return (
                <Paper className={classes.paper} style={{ padding: 10 }}>
                    Select request ID before adding canvass items
                </Paper>
            );
        }
        return (
            <Paper className={classes.paper} style={{ padding: 10 }} elevation={5}>
                {!!!clickedCanvassID && (
                    <Grid container spacing={2} direction='row' justify='space-evenly' alignItems='stretch'>
                        <Grid item xs={2}>
                            {renderItemsSelect()}
                        </Grid>
                        <Grid item xs={2}>
                            {renderSupplierSelect()}
                        </Grid>
                        <Grid item xs={2}>
                            {renderItemUnitPrice()}
                        </Grid>
                        <Grid item xs={2}>
                            {renderItemQuantity()}
                        </Grid>
                        <Grid item xs={2} justify='center' alignItems='center'>
                            <Button
                                style={{ paddingTop: 12 }}
                                onClick={addCanvasItem}
                                color='primary'
                                variant='contained'
                                fullWidth={true}
                                startIcon={<AddCircleIcon />}>
                                Add Item
                            </Button>
                        </Grid>
                    </Grid>
                )}

                <Grid container spacing={2} direction='row' justify='flex-start' alignItems='stretch'>
                    <Grid item>
                        <SearchIcon className={classes.block} color='inherit' />
                    </Grid>
                    <Grid item xs={11}>
                        <TextField
                            fullWidth
                            placeholder='Search'
                            InputProps={{
                                disableUnderline: true,
                                className: classes.searchInput,
                            }}
                            onChange={(event) => setItemsListFilter(event.target.value)}
                        />
                    </Grid>
                </Grid>
                <div className={classes.contentWrapper}>
                    <CanvassItemsListTable
                        itemsList={canvassListItems}
                        filterText={itemsListFilter}
                        deleteItem={deleteListItem}
                        updateItem={updateItem}
                        disableDelete={clickedCanvassID !== ''}
                    />
                    {canvassListHelper && (
                        <Typography variant='caption' display='block' gutterBottom color='error'>
                            {canvassListHelper}
                        </Typography>
                    )}
                </div>
            </Paper>
        );
    };

    const [requestIDHelper, setrequestIDHelper] = useState('');
    const [preparedByHelper, setpreparedByHelper] = useState('');
    const [approvedBy, setApprovedBy] = useState('');
    const [approvedByHelper, setapprovedByHelper] = useState('');
    const renderID = () => {
        return (
            <Grid container spacing={2} alignItems='center'>
                {!!!clickedCanvassID && (
                    <Grid item xs={6}>
                        <SelectComponent
                            title={'Referenced Request ID'}
                            selectList={purchaseRequestList
                                .filter((request) => request.requestStatus !== PurchaseRequestStatus.CLOSED)
                                .map((request) => request.requestId)}
                            setter={(val) => {
                                setRequestID(val);
                                setrequestIDHelper('');
                            }}
                            currentValue={requestId}
                            error={requestIDHelper !== ''}
                            codeHelperText={requestIDHelper}
                        />
                    </Grid>
                )}

                <Grid item xs={6}>
                    <TextField
                        label={'Prepared By'}
                        value={preparedBy}
                        disabled={clickedCanvassID !== ''}
                        onChange={(event) => {
                            setPreparedBy(event.target.value);
                            setpreparedByHelper('');
                        }}
                        fullWidth={true}
                        helperText={preparedByHelper}
                        error={preparedByHelper !== ''}
                    />
                </Grid>
                {clickedCanvassID && (
                    <Grid item xs={6}>
                        <TextField
                            label={'Approved By'}
                            value={approvedBy}
                            onChange={(event) => {
                                setApprovedBy(event.target.value);
                                setapprovedByHelper('');
                            }}
                            fullWidth={true}
                            helperText={approvedByHelper}
                            error={approvedByHelper !== ''}
                        />
                    </Grid>
                )}
            </Grid>
        );
    };

    const [canvassListHelper, setcanvassListHelper] = useState('');
    const createNewCanvass = () => {
        let invalid = false;
        if (!!!requestId) {
            setrequestIDHelper('Please select a request ID');
            invalid = true;
        }
        if (!!!preparedBy) {
            setpreparedByHelper('Please input prepared by');
            invalid = true;
        }
        if (canvassListItems.length === 0) {
            setcanvassListHelper('Item list is empty');
            invalid = true;
        }
        if (invalid) {
            return;
        }

        const canvass: Canvass = {
            requestId,
            preparedBy,
            itemsList: canvassListItems,
        };
        dispatcher(createNewCanvassAction(canvass));
        enqueueSnackbar(`Creating new canvass..`, { variant: 'info' });
        onCancel();
    };

    const cancelCreateNewCanvass = () => {
        onCancel();
    };

    const updateCanvass = () => {
        if (!!!approvedBy) {
            setapprovedByHelper('Please input approved by');
            return;
        }
        const canvass: Canvass = {
            requestId: clickedCanvassID,
            preparedBy: inputPreparedBy,
            approvedBy,
            itemsList: _gCanvassListItems,
        };
        dispatcher(updateCanvassByIDAction(clickedCanvassID, canvass));
        enqueueSnackbar(`Updating canvass ${clickedCanvassID}`, { variant: 'info' });
        onCancel();
    };

    const { enqueueSnackbar } = useSnackbar();
    const renderActionButtons = () => {
        return (
            <Fragment>
                <Grid container spacing={2} alignItems='center'>
                    {!!!clickedCanvassID && (
                        <Grid item xs={6}>
                            <Button
                                onClick={createNewCanvass}
                                color='primary'
                                variant='contained'
                                fullWidth={true}
                                startIcon={<AddCircleIcon />}>
                                Create
                            </Button>
                        </Grid>
                    )}
                    {clickedCanvassID && (
                        <Grid item xs={6}>
                            <Button
                                onClick={updateCanvass}
                                color='primary'
                                variant='contained'
                                fullWidth={true}
                                startIcon={<UpdateIcon />}>
                                Update
                            </Button>
                        </Grid>
                    )}
                    <Grid item xs={6}>
                        <Button
                            onClick={cancelCreateNewCanvass}
                            color='primary'
                            variant='contained'
                            startIcon={<CancelIcon />}
                            fullWidth={true}>
                            Cancel
                        </Button>
                    </Grid>
                </Grid>
            </Fragment>
        );
    };

    return (
        <Grid
            container
            direction='column'
            justify='space-evenly'
            alignItems='stretch'
            spacing={2}
            style={{ padding: 10 }}>
            <Grid item>{renderID()}</Grid>
            <Grid item>{renderAddItemArea()}</Grid>
            <Grid item>{renderActionButtons()}</Grid>
        </Grid>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: '99%',
            margin: 'auto',
            overflow: 'hidden',
            alignContent: 'center',
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: 'rgb(31,49,69)',
            alignSelf: 'center',
        },
        contentWrapper: {
            margin: '0px 0px',
        },
    });

export default withStyles(styles)(CanvassSheetDetailsView);
