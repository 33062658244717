import {
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FGBatchInfo, FGQAAnalysisInfo } from 'redux/reducers/finishedGoods/types';
import React, { useEffect, useState } from 'react';
import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import {
    addFGQAAnalysisResult,
    addOrderInfoAction,
    createFgBatchAction,
    getFGOrder,
    getFGQASampleAnalysisAction,
    getFgBatchPerLineAction,
} from 'redux/reducers/finishedGoods/actions';

import AddBoxIcon from '@material-ui/icons/AddBox';
import FinishedGoodsTransactionDetails from './FinishedGoodsTransactionDetails';
import { LoggedUser } from 'utils/loggedUser';
import MomentUtils from '@date-io/moment';
import SelectComponent from 'components/common/Select';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import TodayIcon from '@material-ui/icons/Today';
import { finishedGoodsTypedSelector } from 'redux/reducers/finishedGoods/finishedGoodsReducer';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

export interface ContentProps extends WithStyles<typeof styles> {
    submit: boolean;
    onSubmit: () => void;
    onRevert: () => void;
    clickedBatchID: string | null;
}

const FinishedGoodsBatchEntry = (props: ContentProps) => {
    const { submit, onSubmit, onRevert, clickedBatchID } = props;
    const dispatcher = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [mfgDate, setMfgDate] = useState('');
    const [batchId, setBatchId] = useState('');
    const [prodCode, setProdCode] = useState('');
    const [lineNum, setLineNum] = useState('');
    const [lineNumExist, setLineNumExist] = useState(false);
    const [bagNum, setBagNum] = useState('');

    const [isDisabled, setIsDisabled] = useState(false);

    const role = LoggedUser.getRole();
    const fullName = LoggedUser.getFullName();

    const checkComplete = () => {
        if (mfgDate === '' || prodCode === '' || lineNum === '' || bagNum === '') {
            enqueueSnackbar('Please complete the form', { variant: 'warning' });
            onSubmit();
            return false;
        }
        return true;
    };

    const finishedGoodsList = finishedGoodsTypedSelector<Array<FGBatchInfo>>((state) => state.finishedGoods.fgBatch);
    const [fgBatchInfo, setFgBatchInfo] = useState<FGBatchInfo | null>(null);
    useEffect(() => {
        const info = finishedGoodsList.find((fg) => fg.batchId === clickedBatchID);
        if (info) {
            setFgBatchInfo(info);
            setMfgDate(info.mfgDate);
            setBatchId(info.batchId);
            setProdCode(info.prodCode);
            setLineNum(info.lineNum);
            setLineNumExist(true);
            setBagNum(info.bagNum);
            setIsDisabled(true);
            setCheckedBy(info.qaCheckedBy);
            setNotedBy(info.qaNotedBy);
        } else {
            setIsDisabled(false);
        }
    }, [clickedBatchID, finishedGoodsList]);

    useEffect(() => {
        if (submit) {
            if (checkComplete()) {
                dispatcher(
                    createFgBatchAction({
                        mfgDate: moment(mfgDate).toISOString(),
                        prodCode,
                        lineNum,
                        bagNum,
                    })
                );
                onSubmit();
                onRevert();
            } else {
                // TODO notify
            }
        }
    }, [submit, onSubmit]); // eslint-disable-line

    const setSelectedLineNum = (event: any) => {
        setLineNum(event !== 'Reworked' ? event.replace('Line ', '') : event);
    };

    const lineNumSelect = () => {
        let lineNumList: any[] = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'Reworked'];
        return (
            <SelectComponent
                title={'Line Number'}
                selectList={lineNumList.map((line) => (line !== 'Reworked' ? `Line ${line}` : line))}
                setter={setSelectedLineNum}
                disabled={lineNumExist}
                currentValue={lineNum !== 'Reworked' ? `Line ${lineNum}` : lineNum}
            />
        );
    };

    const entryFields = [
        {
            entry: [
                {
                    label: 'Batch #',
                    type: 'text',
                    value: batchId,
                    show: batchId !== '',
                },
            ],
        },
        {
            entry: [
                {
                    label: 'Manufacturing Date',
                    type: 'date',
                    setter: setMfgDate,
                    value: mfgDate ? moment(mfgDate).toISOString() : null,
                },
                {
                    label: 'Product Code',
                    type: 'text',
                    setter: setProdCode,
                    value: prodCode,
                    show: true,
                },
            ],
        },
        {
            entry: [
                { select: lineNumSelect },
                {
                    label: '# of Bags',
                    type: 'text',
                    setter: setBagNum,
                    value: bagNum,
                    show: true,
                },
            ],
        },
    ];

    const [checkedBy, setCheckedBy] = useState('');
    const [notedBy, setNotedBy] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const isQARep = () => {
        return role === 'QA Representative' || role === 'Administrator';
    };

    const QARepSelectInput = (initialInput: string, title: string, setter: any) => {
        let list = [];
        if (initialInput) {
            list.push(initialInput);
        }
        if (initialInput !== fullName && isQARep()) {
            list.push(fullName);
        }

        return (
            <SelectComponent
                title={title}
                selectList={list}
                setter={setter}
                disabled={!isQARep() || (fgBatchInfo && fgBatchInfo.qaAnalysisResultTime ? true : false)}
                currentValue={initialInput}
            />
        );
    };

    const sampleAnalysisSummaryFields = [
        {
            entry: [
                {
                    select: () => QARepSelectInput(checkedBy, 'Checked By', setCheckedBy),
                },
                {
                    select: () => QARepSelectInput(notedBy, 'Noted By', setNotedBy),
                },
            ],
        },
    ];

    const setQAResult = (result: string) => {
        if (fgBatchInfo && fgBatchInfo.batchId && checkedBy && notedBy) {
            setIsSubmitting(true);
            dispatcher(
                addFGQAAnalysisResult(fgBatchInfo.batchId, {
                    qaCheckedBy: checkedBy,
                    qaNotedBy: notedBy,
                    qaAnalysisResult: result,
                })
            );
            setTimeout(() => {
                dispatcher(getFgBatchPerLineAction(fgBatchInfo.lineNum));
            }, 300);
        } else {
            enqueueSnackbar('Please set Checked By and Noted By', {
                variant: 'warning',
            });
        }
    };

    const renderQAResultButton = () => {
        if (isSubmitting) {
            return (
                <Grid container alignItems='center' spacing={1}>
                    <CircularProgress style={{ margin: 'auto' }} />
                </Grid>
            );
        }
        return (
            <Grid container alignItems='center' spacing={1}>
                <Grid item xs={12} sm={6}>
                    <Button
                        fullWidth
                        variant='contained'
                        color='primary'
                        style={{ background: '#4ec624' }}
                        onClick={() => setQAResult('ACCEPT')}>
                        <ThumbUpIcon />
                        Approve
                    </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Button
                        fullWidth
                        variant='contained'
                        color='primary'
                        style={{ background: '#ff0000' }}
                        onClick={() => setQAResult('REJECT')}>
                        <ThumbDownIcon />
                        Reject
                    </Button>
                </Grid>
            </Grid>
        );
    };

    const renderSection = (entries: Array<any>, title: string, align?: 'center') => {
        return (
            <Container style={{ padding: 10 }}>
                {title && (
                    <Typography align={align ? align : 'inherit'} color='inherit' variant='h5' component='h6'>
                        {title}
                    </Typography>
                )}

                {entries.map(({ entry }) => (
                    <Grid container alignItems='center' spacing={1}>
                        {entry.map(({ label, setter, value, type, select, show }: any) => {
                            return (
                                <Grid item xs={12} sm={6}>
                                    {label && label.includes('Date') && (
                                        <DateTimePicker
                                            margin='dense'
                                            fullWidth
                                            label={label}
                                            value={value}
                                            disabled={isDisabled}
                                            onChange={(date) => setter(date)}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position='start'>
                                                        <IconButton>
                                                            <TodayIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                    {label && !label.includes('Date') && show && (
                                        <TextField
                                            fullWidth
                                            margin='dense'
                                            value={value}
                                            label={label}
                                            type={type}
                                            onChange={(event) => setter(event.target.value)}
                                            disabled={isDisabled}
                                        />
                                    )}
                                    {select && select()}
                                </Grid>
                            );
                        })}
                    </Grid>
                ))}
            </Container>
        );
    };

    const [openOrder, setOpenOrder] = useState(false);
    const [customerName, setCustomerName] = useState('');
    const [customerNameHelperText, setCustomerNameHelperText] = useState('');

    const [orderBagNum, setOrderBagNum] = useState(0);
    const [bagNumHelperText, setBagNumHelperText] = useState('');

    const executeAddOrderInfo = () => {
        if (customerName === '') {
            setCustomerNameHelperText('Customer Name is needed');
        }
        if (orderBagNum <= 0) {
            setBagNumHelperText('# of Bag should be greater than 0');
        }

        if (customerName !== '' && orderBagNum > 0) {
            dispatcher(
                addOrderInfoAction(batchId, {
                    customerName,
                    bagNum: orderBagNum,
                })
            );
            setTimeout(() => dispatcher(getFGOrder(batchId)), 500);
            setCustomerName('');
            setBagNumHelperText('');
            setOpenOrder(false);
        }
    };

    const orderModal = () => {
        return (
            <div>
                <Dialog
                    disableEscapeKeyDown
                    open={openOrder}
                    onClose={() => {
                        setCustomerName('');
                        setBagNumHelperText('');
                        setOpenOrder(false);
                    }}>
                    <DialogTitle>Order Info</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            margin='dense'
                            style={{ minWidth: 300 }}
                            type={'string'}
                            label={'Customer Name'}
                            variant='outlined'
                            helperText={customerNameHelperText}
                            error={customerNameHelperText !== ''}
                            onChange={(event) => {
                                setCustomerNameHelperText('');
                                setCustomerName(event.target.value as string);
                            }}
                        />
                        <TextField
                            fullWidth
                            margin='dense'
                            style={{ minWidth: 300 }}
                            type={'number'}
                            label={'# of Bags'}
                            variant='outlined'
                            helperText={bagNumHelperText}
                            error={bagNumHelperText !== ''}
                            onChange={(event) => {
                                setBagNumHelperText('');
                                setOrderBagNum(parseInt(event.target.value as any));
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={executeAddOrderInfo} color='primary' variant='contained'>
                            Add Order Info
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    const renderFGStockOrder = (batchId: string) => {
        const title = 'Stock Orders';
        return (
            <Container style={{ padding: 10, paddingTop: 30 }}>
                <div>
                    <Typography
                        style={{ display: 'inline-block' }}
                        align={'inherit'}
                        color='inherit'
                        variant='h5'
                        component='h6'>
                        {title}
                    </Typography>
                    <Tooltip title='Add Stock Order' style={{ display: 'inline-block', float: 'right' }}>
                        <IconButton
                            onClick={() => {
                                setOpenOrder(true);
                            }}>
                            <AddBoxIcon color='inherit' />
                        </IconButton>
                    </Tooltip>
                </div>
                <FinishedGoodsTransactionDetails batchId={batchId} />
            </Container>
        );
    };

    const qaAnalyisInfo = finishedGoodsTypedSelector<FGQAAnalysisInfo>((state) => state.finishedGoods.qaAnalysis);
    useEffect(() => {
        if (clickedBatchID) {
            dispatcher(getFGQASampleAnalysisAction(clickedBatchID));
        }
    }, [clickedBatchID, dispatcher]);

    useEffect(() => {}, [qaAnalyisInfo]);

    return (
        <Container>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {renderSection(entryFields, 'Summary Details')}
                {fgBatchInfo &&
                    fgBatchInfo.batchId &&
                    qaAnalyisInfo &&
                    qaAnalyisInfo.analysisTime &&
                    renderSection(sampleAnalysisSummaryFields, 'Sample Analysis Summary (QA / LAB)')}
                {fgBatchInfo &&
                    fgBatchInfo.batchId &&
                    !fgBatchInfo.qaAnalysisResult &&
                    qaAnalyisInfo &&
                    qaAnalyisInfo.analysisTime &&
                    renderQAResultButton()}
                {fgBatchInfo &&
                    fgBatchInfo.batchId &&
                    fgBatchInfo.unloadingDatetime &&
                    renderFGStockOrder(fgBatchInfo.batchId)}
                <div style={{ height: 10 }} />
                {orderModal()}
            </MuiPickersUtilsProvider>
        </Container>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: '99%',
            margin: 'auto',
            overflow: 'hidden',
            alignContent: 'center',
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: 'rgb(31,49,69)',
            alignSelf: 'center',
        },
        contentWrapper: {
            margin: '0px 0px',
        },
    });

export default withStyles(styles)(FinishedGoodsBatchEntry);
