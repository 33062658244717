import {
    GET_All_PURCHASE_ORDERS,
    GET_All_PURCHASE_REQUESTS,
    GET_All_SUPPLIERS,
    GET_DELIVERY_SCHEDULE,
    PurchasingActionTypes,
    PurchasingInfoState,
    PurchasingReducerType,
} from "./types";
import { TypedUseSelectorHook, useSelector } from "react-redux";

import { LOGOUT } from "redux/reducers/rootReducer/types";

const initialState: PurchasingInfoState = {
    deliverySchedule: {
        schedulesList: [],
        LastEvaluatedKey: null,
    },
    suppliers: {
        suppliersList: [],
        LastEvaluatedKey: null,
    },
    purchaseRequests: {
        requestsList: [],
        LastEvaluatedKey: null,
    },
    purchaseOrders: {
        ordersList: [],
        LastEvaluatedKey: null,
    },
};

export const purchasingTypedSelector: TypedUseSelectorHook<PurchasingReducerType> = useSelector;

export function purchasingReducer(state = initialState, action: PurchasingActionTypes): PurchasingInfoState {
    switch (action.type) {
        case GET_DELIVERY_SCHEDULE:
            return {
                deliverySchedule: action.payload,
                suppliers: state.suppliers,
                purchaseRequests: state.purchaseRequests,
                purchaseOrders: state.purchaseOrders,
            };
        case GET_All_SUPPLIERS:
            return {
                deliverySchedule: state.deliverySchedule,
                suppliers: action.payload,
                purchaseRequests: state.purchaseRequests,
                purchaseOrders: state.purchaseOrders,
            };
        case GET_All_PURCHASE_REQUESTS:
            return {
                deliverySchedule: state.deliverySchedule,
                suppliers: state.suppliers,
                purchaseRequests: action.payload,
                purchaseOrders: state.purchaseOrders,
            };
        case GET_All_PURCHASE_ORDERS:
            return {
                deliverySchedule: state.deliverySchedule,
                suppliers: state.suppliers,
                purchaseRequests: state.purchaseRequests,
                purchaseOrders: action.payload,
            };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
}
