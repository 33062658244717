import { Paper, Theme, Typography, WithStyles, createStyles, withStyles } from '@material-ui/core';
import React, { Fragment } from 'react';
import { useEffect, useState } from 'react';

import { LoggedUser } from 'utils/loggedUser';
import RMShipmentListView from './RMShipmentList/RMShipmentListView';
import RMTruckEntriesMainView from './RMTruckEntries/RMTruckEntriesMainView';
import RawMaterialsHeader from './RawMaterialsHeader';
import WarehouseRelocationView from './WarehouseRelocation/WarehouseRelocationView';
import WarehouseReportBinView from './WarehouseReportBin/WarehouseReportBinView';
import WarehouseStockCardView from './WarehouseStockCard/WarehouseStockCardView';
import { RoleFeatures } from 'redux/reducers/users/types';

export interface RMShipmentMainProps extends WithStyles<typeof styles> {
    onDrawerToggle: () => void;
    mainClass: any;
    select: boolean;
    onSelect: () => void;
}

const RMShipmentMain = (props: RMShipmentMainProps) => {
    const { classes } = props;

    const TAB1_TITLE = 'Truck Entries';
    const TAB2_TITLE = 'Shipment List';
    const TAB3_TITLE = 'Warehouse Stock Card';
    const TAB4_TITLE = 'Warehouse Receiving Report Bin';
    const TAB5_TITLE = 'Warehouse Relocation';

    const tabsTitle = [
        { id: TAB1_TITLE, featureRole: RoleFeatures.RM_TRUCK_ENTRIES },
        { id: TAB2_TITLE, featureRole: RoleFeatures.RM_SHIPMENT_LIST },
        { id: TAB3_TITLE, featureRole: RoleFeatures.RM_STOCK_CARD },
        { id: TAB4_TITLE, featureRole: RoleFeatures.RM_RECEIVING_REPORT_BIN },
        { id: TAB5_TITLE, featureRole: RoleFeatures.RM_WAREHOUSE_RELOCATION },
    ];

    const [currentTab, setCurrentTab] = useState(0);
    const onTabsChange = (id: number) => {
        setReload(!reload);
        setCurrentTab(id);
    };

    const [reload, setReload] = useState(false);
    const onReload = () => {
        onSelect();
    };

    const { select, onSelect } = props;
    useEffect(() => {
        if (select) {
            setCurrentTab(0);
        }
    }, [select]);

    const isAccessAllowed = (featureRole: RoleFeatures) => {
        let userRoleFeatures = LoggedUser.getFeatureAccessPermission();
        if (userRoleFeatures.includes(RoleFeatures.ALL) || userRoleFeatures.includes(featureRole)) {
            return true;
        }
        return false;
    }

    const displayTabTitle = (title: string) => {
        return (
            <Typography style={{ paddingBottom: 10 }} color='inherit' variant='h5' component='h1'>
                {title}
            </Typography>
        );
    };

    const displayRMTruckEntriesMainView = (title: string) => (
        <Fragment>
            {displayTabTitle(title)}
            <RMTruckEntriesMainView />
        </Fragment>
    );

    const displayRMShipmentListView = (title: string) => (
        <Fragment>
            {displayTabTitle(title)}
            <RMShipmentListView pageReload={reload} onPageReload={onReload} />
        </Fragment>
    );

    const displayWarehouseStockCardView = (title: string) => (
        <Fragment>
            {displayTabTitle(title)}
            <WarehouseStockCardView pageReload={reload} onPageReload={onReload} />
        </Fragment>
    );

    const displayWarehouseReportBinView = (title: string) => (
        <Fragment>
            {displayTabTitle(title)}
            <WarehouseReportBinView pageReload={reload} onPageReload={onReload} />
        </Fragment>
    );

    const displaceWarehouseRelocation = (title: string) => (
        <Fragment>
            {displayTabTitle(title)}
            <WarehouseRelocationView pageReload={reload} onPageReload={onReload} />
        </Fragment>
    );

    const tabEntries = [
        {
            tabTitle: TAB1_TITLE,
            displayFn: displayRMTruckEntriesMainView,
            featureRole: RoleFeatures.RM_TRUCK_ENTRIES
        },
        {
            tabTitle: TAB2_TITLE,
            displayFn: displayRMShipmentListView,
            featureRole: RoleFeatures.RM_SHIPMENT_LIST
        },
        {
            tabTitle: TAB3_TITLE,
            displayFn: displayWarehouseStockCardView,
            featureRole: RoleFeatures.RM_STOCK_CARD
        },
        {
            tabTitle: TAB4_TITLE,
            displayFn: displayWarehouseReportBinView,
            featureRole: RoleFeatures.RM_RECEIVING_REPORT_BIN
        },
        {
            tabTitle: TAB5_TITLE,
            displayFn: displaceWarehouseRelocation,
            featureRole: RoleFeatures.RM_WAREHOUSE_RELOCATION
        },
    ];

    return (
        <Fragment>
            <Fragment>
                <RawMaterialsHeader
                    onDrawerToggle={props.onDrawerToggle}
                    onTabsChange={onTabsChange}
                    tabsTitle={tabsTitle}
                    selectedTab={currentTab}
                />
                <main className={props.mainClass}>
                    {tabEntries.filter(item => isAccessAllowed(item.featureRole) ).map(
                        ({ tabTitle, displayFn }) => tabsTitle[currentTab].id === tabTitle && displayFn(tabTitle)
                    )}
                </main>
            </Fragment>
        </Fragment>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            margin: 'auto',
            padding: 50,
            alignContent: 'center',
            alignItems: 'center',
        },
    });

export default withStyles(styles)(RMShipmentMain);
