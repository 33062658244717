import { AppBar, Grid, IconButton, Paper, TextField, Toolbar, Tooltip } from '@material-ui/core';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { Fragment, useEffect, useState } from 'react';
import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import {
    deleteFGTruckEntryAction,
    getFGTruckEntriesAction,
    patchFGTruckEntriesAction,
    postFGTruckEntriesAction,
} from 'redux/reducers/truckEntries/actions';

import CircularProgress from '@material-ui/core/CircularProgress';
import { FGTruckEntries } from 'redux/reducers/truckEntries/types';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FinishedGoodTruckEntriesListTable from './FinishedGoodTruckEntriesListTable';
import MomentUtils from '@date-io/moment';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import { truckEntriesTypedSelector } from 'redux/reducers/truckEntries/truckEntriesReducer';
import { useDispatch } from 'react-redux';

export interface ContentProps extends WithStyles<typeof styles> {}

const FinishedGoodTruckEntriesMainView = (props: ContentProps) => {
    const { classes } = props;
    const dispatcher = useDispatch();

    const [filterText, setfilterText] = useState('');
    const [exportCsv, setExportCsv] = useState(false);
    const [exportCsvFileName, setExportCsvFileName] = useState('');
    const [openNew, setOpenNew] = useState(false);

    const fgTruckEntries = truckEntriesTypedSelector<Array<FGTruckEntries>>(
        (state) => state.truckEntries.fgTruckEntries
    );
    const [fgTruckEntriesList, setFgTruckEntriesList] = useState<Array<FGTruckEntries> | []>([]);

    const [isAcquired, setIsAcquired] = useState(false);

    const [onReload, setOnReload] = useState(false);

    useEffect(() => {
        if (fgTruckEntries.length === 0 && !isAcquired) {
            dispatcher(getFGTruckEntriesAction());
            setIsAcquired(true);
        } else {
            setFgTruckEntriesList(fgTruckEntries);
        }
    }, [fgTruckEntries, isAcquired, dispatcher]);

    useEffect(() => {
        setTimeout(() => setOnReload(false), 500);
    }, [fgTruckEntries]);

    const renderHeaderSearch = () => {
        return (
            <Fragment>
                <Grid item>
                    <SearchIcon className={classes.block} color='inherit' />
                </Grid>
                <Grid item xs>
                    <TextField
                        fullWidth
                        placeholder='Search'
                        InputProps={{
                            disableUnderline: true,
                            className: classes.searchInput,
                        }}
                        onChange={(event) => setfilterText(event.target.value)}
                    />
                </Grid>
            </Fragment>
        );
    };

    const exectureNewTruckIn = () => {
        if (newCustomer === '') {
            setCustomerHelperText('Please input customer name');
        }

        if (newTruckPlateNum === '') {
            setPlateNumHelperText('Please input truck plate number');
        }

        if (newDateIn === null) {
            setNewDateInHelperText('Please input IN date and time');
        }

        if (newCustomer !== '' && newTruckPlateNum !== '' && newDateIn) {
            dispatcher(
                postFGTruckEntriesAction({
                    customerName: newCustomer,
                    plateNumber: newTruckPlateNum,
                    truckTimeIn: newDateIn,
                })
            );
            setTimeout(() => dispatcher(getFGTruckEntriesAction()), 500);
            setNewCustomer('');
            setNewTruckPlateNum('');
            setNewDateIn(null);
            setOpenNew(false);
        }
    };

    const [newCustomer, setNewCustomer] = useState('');
    const [customerHelperText, setCustomerHelperText] = useState('');
    const [newTruckPlateNum, setNewTruckPlateNum] = useState('');
    const [plateNumHelperText, setPlateNumHelperText] = useState('');
    const [newDateIn, setNewDateIn] = useState<string | null>(null);
    const [newDateInHelperText, setNewDateInHelperText] = useState('');

    const onModalClose = () => {
        setOpenNew(false);
        setCustomerHelperText('');
        setNewCustomer('');
        setPlateNumHelperText('');
        setNewTruckPlateNum('');
        setNewDateInHelperText('');
        setNewDateIn(null);
    };

    const newModal = () => {
        return (
            <div>
                <Dialog disableEscapeKeyDown open={openNew} onClose={onModalClose}>
                    <DialogTitle>Truck IN Details</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            margin='dense'
                            style={{ minWidth: 200 }}
                            type={'text'}
                            label={'Customer Name'}
                            variant='outlined'
                            helperText={customerHelperText}
                            error={customerHelperText !== ''}
                            onChange={(event) => {
                                setCustomerHelperText('');
                                setNewCustomer(event.target.value);
                            }}
                        />
                        <TextField
                            fullWidth
                            margin='dense'
                            style={{ minWidth: 200 }}
                            type={'text'}
                            label={'Truck Plate Number'}
                            variant='outlined'
                            helperText={plateNumHelperText}
                            error={plateNumHelperText !== ''}
                            onChange={(event) => {
                                setPlateNumHelperText('');
                                setNewTruckPlateNum(event.target.value);
                            }}
                        />
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DateTimePicker
                                ampm={false}
                                disableFuture
                                fullWidth
                                margin='dense'
                                value={newDateIn}
                                onChange={(event) => {
                                    setNewDateInHelperText('');
                                    setNewDateIn(moment(event).toISOString());
                                }}
                                label={'Date and Time IN'}
                                format='MMM DD, YYYY HH:mm'
                                inputVariant='outlined'
                                helperText={newDateInHelperText}
                                error={newDateInHelperText !== ''}
                            />
                        </MuiPickersUtilsProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={exectureNewTruckIn} color='primary' variant='contained'>
                            Add New Truck IN
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    const onExportCsv = () => {
        setExportCsv(false);
    };

    const fetchAndReloadList = () => {
        setOnReload(true);
        setFgTruckEntriesList([])
        dispatcher(getFGTruckEntriesAction());
    };

    const renderHeaderButton = () => {
        return (
            <Grid item>
                <Tooltip title='New Truck Entry'>
                    <IconButton onClick={() => setOpenNew(true)}>
                        <NoteAddIcon className={classes.block} color='inherit' />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Export to CSV'>
                    <IconButton
                        onClick={() => {
                            setExportCsvFileName(
                                `FGTruckEntriesList-${moment(new Date()).format('MM-DD-YYYY:HH:mm:ss')}`
                            );
                            setExportCsv(true);
                        }}>
                        <FileCopyIcon className={classes.block} color='inherit' />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Reload'>
                    <IconButton
                        onClick={fetchAndReloadList}>
                        {onReload ? (
                            <CircularProgress size='24px' />
                        ) : (
                            <RefreshIcon className={classes.block} color='inherit' />
                        )}
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    };

    const renderContainer = () => {
        return (
            <Grid container spacing={2} alignItems='center'>
                {renderHeaderSearch()}
                {renderHeaderButton()}
            </Grid>
        );
    };

    const onUpdate = (data: FGTruckEntries) => {
        if (data.truckTimeOut) {
            setOnReload(true);
            dispatcher(patchFGTruckEntriesAction(data.truckTimeIn, data.truckTimeOut));
        }
        setTimeout(() => dispatcher(getFGTruckEntriesAction()), 500);
    };


    const onDeleteTruckEntry = (truckEntry: FGTruckEntries) => {
        dispatcher(deleteFGTruckEntryAction(truckEntry.truckTimeIn))
        return true;
    }

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.searchBar} position='static' color='default' elevation={0}>
                <Toolbar>{renderContainer()}</Toolbar>
            </AppBar>
            <FinishedGoodTruckEntriesListTable
                filterText={filterText}
                truckEntries={fgTruckEntriesList}
                exportCsv={exportCsv}
                onExportCsv={onExportCsv}
                exportCsvFileName={exportCsvFileName}
                onUpdate={onUpdate}
                onDeleteTruckEntry={onDeleteTruckEntry}
            />
            {newModal()}
        </Paper>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: '99%',
            margin: 'auto',
            overflow: 'hidden',
            alignContent: 'center',
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: 'rgb(31,49,69)',
            alignSelf: 'center',
        },
        contentWrapper: {
            margin: '0px 0px',
        },
    });

export default withStyles(styles)(FinishedGoodTruckEntriesMainView);
