import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
        paper: {
            padding: theme.spacing(2),
            textAlign: 'center',
            background: '#1f3145',
            color: 'white',
            borderRadius: 0,
        }
    }),
);

export interface ListItemsProps {
    title: String;
    dataList?: Array<string>;
    selected?: String;
    setter: (data: string) => void;
}

const ListItems = (props: ListItemsProps) => {
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = useState(-1);

    const handleListItemClick = (index: number) => {
        setSelectedIndex(index);
        if (props.dataList) {
            props.setter(props.dataList[index])
        }
    };

    useEffect(() => {
        let selectedIndex = -1
        const finIndex = (data: String) => data === props.selected;
        if (props.dataList && props.dataList.length > 0) {
            selectedIndex = props.dataList.findIndex(finIndex);
        }
        setSelectedIndex(selectedIndex)
    }, [props.selected, props.dataList])

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>{props.title}</Paper>
            <List component="nav" aria-label="main folders" id={`list-${props.title}`} key={`list-${props.title}`}
                style={{ maxHeight: window.innerHeight / 4, overflow: 'auto' }}>
                {props.dataList &&
                    props.dataList.map((value: String, index: number, array: String[]) => (
                        <ListItem
                            button
                            id={`listItem-${value.replace(/ /g, '')}`}
                            key={`listItem-${value.replace(/ /g, '')}`}
                            selected={selectedIndex === index}
                            onClick={(event) => handleListItemClick(index)}>
                            <ListItemText primary={value} />
                        </ListItem>
                    ))
                }
            </List>
        </div>
    );
}

export default ListItems;