import { LogoutAction } from 'redux/reducers/rootReducer/types';

export interface StockInfo {
    rmrrId: string;
    rmCode: string;
    rmName: string;
    netWeight: number;
    bagNum: number | null;
    truckScaleNum: string;
    warehouseId: string;
    warehouseLocId: string;
    supplierName: string;
    inputDateTime: string;
    total?: number;
    firstShift?: number;
    secondShift?: number;
    thirdShift?: number;
}

export interface StockRelocationInfo {
    srcWarehouse: string;
    srcLocation: string;
    dstWarehouse: string;
    dstLocation: string;
    relocatedWeight: number;
}

export interface StockRelocationResponse {
    srcWarehouse?: string;
    srcLocation?: string;
    dstWarehouse?: string;
    dstLocation?: string;
    relocatedWeight?: number;
    rmCode?: string;
    message?: string;
    reason?: string;
}

export interface StocksInfoState {
    stocks: Array<StockInfo>;
    relocationStatus: StockRelocationResponse;
}

export interface StocksReducerType {
    stocks: StocksInfoState;
}

export const GET_WAREHOUSE_LOCATION_STOCKS = 'GET_WAREHOUSE_LOCATION_STOCKS';
export const GET_RM_STOCKS = 'GET_RM_STOCKS';
export const CLEAR_STOCKS = 'CLEAR_STOCKS';
export const RELOCATE_RM_WAREHOUSE = 'RELOCATE_RM_WAREHOUSE';
export const CLEAR_RELOCATE_WAREHOUSE = 'CLEAR_RELOCATE_WAREHOUSE';

interface GetWarehouseLocationStockAction {
    type: typeof GET_WAREHOUSE_LOCATION_STOCKS;
    payload: Array<StockInfo>;
}

interface GetRmStockAction {
    type: typeof GET_RM_STOCKS;
    payload: Array<StockInfo>;
}

interface ClearStockAction {
    type: typeof CLEAR_STOCKS;
    payload: [];
}

interface RelocateStockAction {
    type: typeof RELOCATE_RM_WAREHOUSE;
    payload: StockRelocationInfo;
}

interface ClearRelocateStockAction {
    type: typeof CLEAR_RELOCATE_WAREHOUSE;
    payload: undefined;
}

export type StocksActionTypes =
    | GetWarehouseLocationStockAction
    | GetRmStockAction
    | ClearStockAction
    | RelocateStockAction
    | ClearRelocateStockAction
    | LogoutAction;
