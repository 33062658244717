import React, { forwardRef, useImperativeHandle } from "react";
import { green, red } from "@material-ui/core/colors";

import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";

export default forwardRef((props: any, ref: any) => {
    useImperativeHandle(ref, () => {
        return {};
    });

    return props.value === true ? (
        <CheckCircleIcon style={{ color: green[500], marginTop: 5 }} />
    ) : (
        <RemoveCircleIcon style={{ color: red[500], marginTop: 5 }} />
    );
});
