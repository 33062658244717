import React, { Fragment } from "react";

import { Button } from "@material-ui/core";
import { PremixInfo } from "redux/reducers/premix/types";
import TableGrid from "components/common/TableGrid";

export interface ContentProps {
    filterText: string;
    premixList: Array<PremixInfo>;
    onClickedPremix: (premixName: string) => void;
}

const PremixListTable = (props: ContentProps) => {
    const { filterText, premixList, onClickedPremix } = props;

    const premixNameRenderer = (params: { value: string }) => {
        return (
            <Button
                style={{ color: "#009fdc" }}
                onClick={() => {
                    if (onClickedPremix) {
                        onClickedPremix(params.value);
                    }
                }}
            >
                {params.value}
            </Button>
        );
    };

    const itemsListConcatenatedRenderer = (data: any) => {
        let itemList = "";
        if (data.value.length > 0) {
            data.value.forEach((item: any, index: number) => {
                if (index > 0) {
                    itemList += ", ";
                }
                let itemWithDescription = `${item.rmCode} (${item.rmWeight} kgs)`;
                itemList = itemList + itemWithDescription;
            });
        }
        return itemList;
    };

    const columnDefs = [
        {
            headerName: "Name",
            field: "premixName",
            minWidth: 150,
            filter: "agTextColumnFilter",
            cellRendererFramework: premixNameRenderer,
            sort: "asc",
        },
        {
            headerName: "Feed Type",
            field: "feedType",
            minWidth: 150,
            filter: "agTextColumnFilter",
            headerTooltip: "Feed Type",
        },
        {
            headerName: "Line",
            field: "line",
            minWidth: 150,
            filter: "agTextColumnFilter",
            headerTooltip: "Line",
        },
        {
            headerName: "Materials",
            field: "rawMaterials",
            minWidth: 500,
            maxWidth: 1000,
            filter: "agTextColumnFilter",
            headerTooltip: "Items",
            cellRenderer: itemsListConcatenatedRenderer,
        },
        {
            headerName: "Prepared By",
            field: "preparedBy",
            minWidth: 250,
            filter: "agTextColumnFilter",
            headerTooltip: "Prepared",
        },
        {
            headerName: "Date Prepared",
            field: "datePrepared",
            minWidth: 150,
            filter: "agTextColumnFilter",
            headerTooltip: "Date Prepared",
        },
        {
            headerName: "Checked By",
            field: "checkedBy",
            minWidth: 250,
            filter: "agTextColumnFilter",
            headerTooltip: "Checked By",
        },
        {
            headerName: "Noted By",
            field: "notedBy",
            minWidth: 250,
            filter: "agTextColumnFilter",
            headerTooltip: "Noted By",
        },
        {
            headerName: "Total Weight",
            minWidth: 150,
            filter: "agTextColumnFilter",
            headerTooltip: "Total Weight",
            valueGetter: (param: any) => {
                return param.data.rmTotalWeight + " kgs";
            },
        },
        {
            headerName: "Revision #",
            field: "revisionNumber",
            minWidth: 150,
            filter: "agTextColumnFilter",
            headerTooltip: "Revision #",
        },
    ];

    return (
        <Fragment>
            <TableGrid rowData={premixList} columnDefs={columnDefs} filterText={filterText} height={600} />
        </Fragment>
    );
};

export default PremixListTable;
