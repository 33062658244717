import React, { forwardRef, useImperativeHandle } from "react";

import { Button } from "@material-ui/core";

export default forwardRef((props: any, ref: any) => {
    useImperativeHandle(ref, () => {
        return {};
    });

    const deleteRow = () => {
        let {data, onDelete, api} = props;

        if (onDelete) {
            if (!onDelete(props)) {
                return;
            } 
        }
        
        api.updateRowData({ remove: [data] });
        api.refreshCells({ force: true });
    };

    return (
        <Button onClick={deleteRow} color={"primary"} variant={"contained"} size={"small"}>
            Delete
        </Button>
    );
});
