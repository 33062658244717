import React, { Fragment } from 'react';

import { LoggedUser } from 'utils/loggedUser';
import TabsAppBar from 'components/appbar/TabsAppBar';
import TitleAppBar from 'components/appbar/TitleAppBar';
import UserAppBar from 'components/appbar/UserAppBar';

interface RegisterUserHeaderProps {
    onDrawerToggle: () => void;
    onTabsChange: (id: number) => void;
    tabsTitle: Array<{ id: string }>;
}

const RegisterUserHeader = (props: RegisterUserHeaderProps) => {
    const { onDrawerToggle, onTabsChange, tabsTitle } = props;
    const userFullName = LoggedUser.getFullName();
    const role = LoggedUser.getRole();

    return (
        <Fragment>
            <UserAppBar username={`${userFullName}`} role={role} onDrawerToggle={onDrawerToggle} />
            <TitleAppBar title='Account Management' />
            <TabsAppBar tabs={tabsTitle} onTabsChange={onTabsChange} />
        </Fragment>
    );
};

export default RegisterUserHeader;
