import { LogoutAction } from "redux/reducers/rootReducer/types"


export enum RoleDepartments {
    ADMIN = 'Administrator',
    EXEC = 'Executive',
    IPCD = 'Process Control',
    MIS = 'MIS',
    PRD = 'Production',
    PROC = 'Procurement',
    QA = 'Quality Assurance',
    SMD = 'Storage Management',
    SALES = 'Sales',
    ACCNT = 'Accounting',
    FNN = 'Formulation and Nutrition',
    UTIL = 'Utility',
    MAINT = 'Maintenance',
    GENUSER = 'General User',
}

export enum RoleFeatures {

    ALL = 'ALL',
    RM_TRUCK_ENTRIES = 'RM Truck Entries',
    RM_SHIPMENT_LIST = 'RM Shipment List',
    RM_STOCK_CARD = 'RM Stock Card',
    RM_RECEIVING_REPORT_BIN = 'RM Receiving Report Bin',
    RM_WAREHOUSE_RELOCATION = 'RM Warehouse Relocation',
    FG_TRUCK_ENTRIES = 'FG Truck Entries',
    FG_LIST = 'FG List',
    FG_WAREHOUSE_STOCK = 'FG Warehouse Stock',
    SP_INVENTORY_LIST = 'SP Inventory List',
    PROC_PR = 'Procurement Purchase Request',
    PROC_CANVASS_SHEET = 'Procurement Canvass Sheet',
    PROC_PURCHASE_ORDER = 'Procurement Purchase Order',
    PROC_SUPPLIER_LIST = 'Procurement Supplier List',
    PREMIX = 'Premix',
    MATERIAL_DELIVERY = 'Material Delivery',
    MATERIAL_DELIVERY_TRACKER = 'Material Delivery Tracker',
    DASHBOARD = 'Dashboard',
    USER_LIST = 'Users List',
    USER_REGISTRATION = 'Users Registration',
}

export enum RolePermissions {

    ADMINISTRATOR = 'Administrator',
    ACCOUNTING_HEAD = 'Accounting Head',
    ACCOUNTING_REPRESENTATIVE = 'Accounting Representative',
    EXECUTIVE = 'Executive',
    FNN_HEAD = 'FNN Head',
    FNN_REPRESENTATIVE = 'FNN Representative',
    IPCD_REPRESENTATIVE = 'IPCD Representatives',
    MAINTENANCE_HEAD = 'Maintenance Head',
    MAINTENANCE_REPRESENTATIVE = 'Maintenance Representative',
    IT_HEAD = 'IT Head',
    PLANT_OPERATIONS_HEAD = 'Plant Operations Head',
    PRD_HEAD = 'PRD Head',
    PRD_REPRESENTATIVE = 'PRD Representative',
    PRD_QUALITY_CONTROL_UNIT_REP = 'PRD Quality Control Unit Rep',
    PRD_SUPERVISOR = 'PRD Supervisor',
    PRD_OPERATOR = 'PRD Operator',
    PURCHASER_HEAD = 'Purchaser Head',
    PURCHASER_REPRESENTATIVE = 'Purchaser Representative',
    QA_DEPT_HEAD = 'QA Dept Head',
    QA_SUPERVISOR = 'QA Supervisor',
    QA_REPRESENTATIVE = 'QA Representative',
    SALES_HEAD = 'Sales Head',
    SALES_AGENT = 'Sales Agent',
    SMD_HEAD = 'SMD Head',
    SMD_CHECKER = 'SMD Checker',
    SMD_WAREHOUSE_REPRESENTATIVE = 'SMD Warehouse Representative',
    SMD_RM_REPRESENTATIVE = 'SMD RM Representative',
    SMD_FG_REPRESENTATIVE = 'SMD FG Representative',
    SMD_SPARE_PARTS_REPRESENTATIVE = 'SMD SPARE PARTS Representative',
    UTILITIES_HEAD = 'Utilities Head',
    UTILITIES_REPRESENTATIVE = 'Utilities Representative',
    GENERAL_USER = 'General User',
}

export const RolePermissionsToRoleDepartmentMap = new Map<RolePermissions, RoleDepartments>([
    [RolePermissions.ADMINISTRATOR, RoleDepartments.ADMIN],
    [RolePermissions.ACCOUNTING_HEAD, RoleDepartments.ACCNT],
    [RolePermissions.ACCOUNTING_REPRESENTATIVE, RoleDepartments.ACCNT],
    [RolePermissions.EXECUTIVE, RoleDepartments.EXEC],
    [RolePermissions.FNN_HEAD, RoleDepartments.FNN],
    [RolePermissions.FNN_REPRESENTATIVE, RoleDepartments.FNN],
    [RolePermissions.IPCD_REPRESENTATIVE, RoleDepartments.IPCD],
    [RolePermissions.MAINTENANCE_HEAD, RoleDepartments.MAINT],
    [RolePermissions.MAINTENANCE_REPRESENTATIVE, RoleDepartments.MAINT],
    [RolePermissions.IT_HEAD, RoleDepartments.MIS],
    [RolePermissions.PLANT_OPERATIONS_HEAD, RoleDepartments.PRD],
    [RolePermissions.PRD_HEAD, RoleDepartments.PRD],
    [RolePermissions.PRD_REPRESENTATIVE, RoleDepartments.PRD],
    [RolePermissions.PRD_QUALITY_CONTROL_UNIT_REP, RoleDepartments.PRD],
    [RolePermissions.PRD_SUPERVISOR, RoleDepartments.PRD],
    [RolePermissions.PRD_OPERATOR, RoleDepartments.PRD],
    [RolePermissions.PURCHASER_HEAD, RoleDepartments.PROC],
    [RolePermissions.PURCHASER_REPRESENTATIVE, RoleDepartments.PROC],
    [RolePermissions.QA_DEPT_HEAD, RoleDepartments.QA],
    [RolePermissions.QA_SUPERVISOR, RoleDepartments.QA],
    [RolePermissions.QA_REPRESENTATIVE, RoleDepartments.QA],
    [RolePermissions.SALES_HEAD, RoleDepartments.SALES],
    [RolePermissions.SALES_AGENT, RoleDepartments.SALES],
    [RolePermissions.SMD_HEAD, RoleDepartments.SMD],
    [RolePermissions.SMD_CHECKER, RoleDepartments.SMD],
    [RolePermissions.SMD_WAREHOUSE_REPRESENTATIVE, RoleDepartments.SMD],
    [RolePermissions.SMD_RM_REPRESENTATIVE, RoleDepartments.SMD],
    [RolePermissions.SMD_FG_REPRESENTATIVE, RoleDepartments.SMD],
    [RolePermissions.SMD_SPARE_PARTS_REPRESENTATIVE, RoleDepartments.SMD],
    [RolePermissions.UTILITIES_HEAD, RoleDepartments.UTIL],
    [RolePermissions.UTILITIES_REPRESENTATIVE, RoleDepartments.UTIL],
    [RolePermissions.GENERAL_USER, RoleDepartments.GENUSER],
]);

export const RoleDepartmentToFeatureToMap = new Map<RoleDepartments, RoleFeatures[]>([
    [
        RoleDepartments.ADMIN,
        [
            RoleFeatures.ALL,
        ]
    ],
    [
        RoleDepartments.EXEC,
        [
            RoleFeatures.PROC_PR,
            RoleFeatures.DASHBOARD
        ]
    ],
    [
        RoleDepartments.IPCD,
        [
            RoleFeatures.PROC_PR
        ]
    ],
    [
        RoleDepartments.MIS,
        [
            RoleFeatures.PROC_PR,
            RoleFeatures.USER_LIST,
            RoleFeatures.USER_REGISTRATION
        ]
    ],
    [
        RoleDepartments.PRD,
        [
            RoleFeatures.PROC_PR,
            RoleFeatures.FG_LIST
        ]
    ],
    [
        RoleDepartments.PROC,
        [
            RoleFeatures.RM_SHIPMENT_LIST,
            RoleFeatures.PROC_PR,
            RoleFeatures.PROC_CANVASS_SHEET,
            RoleFeatures.PROC_PURCHASE_ORDER,
            RoleFeatures.PROC_SUPPLIER_LIST,
            RoleFeatures.MATERIAL_DELIVERY,
            RoleFeatures.MATERIAL_DELIVERY_TRACKER
        ]
    ],
    [
        RoleDepartments.SMD,
        [
            RoleFeatures.RM_TRUCK_ENTRIES,
            RoleFeatures.RM_SHIPMENT_LIST,
            RoleFeatures.RM_STOCK_CARD,
            RoleFeatures.RM_RECEIVING_REPORT_BIN,
            RoleFeatures.RM_WAREHOUSE_RELOCATION,
            RoleFeatures.FG_TRUCK_ENTRIES,
            RoleFeatures.FG_LIST,
            RoleFeatures.PROC_PR,
            RoleFeatures.SP_INVENTORY_LIST,
            RoleFeatures.MATERIAL_DELIVERY
        ]
    ],
    [
        RoleDepartments.SALES,
        [
            RoleFeatures.PROC_PR,
        ]
    ],
    [
        RoleDepartments.ACCNT,
        [
            RoleFeatures.PROC_PR,
        ]
    ],
    [
        RoleDepartments.FNN,
        [
            RoleFeatures.PROC_PR,
        ]
    ],
    [
        RoleDepartments.UTIL,
        [
            RoleFeatures.PROC_PR,
        ]
    ],
    [
        RoleDepartments.MAINT,
        [
            RoleFeatures.PROC_PR,
        ]
    ],
    [
        RoleDepartments.GENUSER,
        [
            RoleFeatures.PROC_PR,
        ]
    ],
]);


export interface UserRoleInfo {
    roleName: string;
    rmAccess: string;
    fgAccess: string;
    rmChecklistAccess: string;
    fgChecklistAccess: string;
    qcRMAccess: string;
    qcFGAccess: string;
    prodAccess: string;
    dashAccess: string;
}

export interface RolesInfoState {
    roles: UserRoleInfo[]
}

export interface UserInfo {
    email?: string;
    userRole?: string;
    userFirstName?: string;
    userLastName?: string;
    userStatus?: string;
}

export interface UserInfoState {
    user: UserInfo,
    users: UserInfo[],
    roles: UserRoleInfo[],
}

export interface UserReducerType {
    users: UserInfoState
    roles: RolesInfoState
}

export const GET_ALL_REGISTERED_USERS = 'GET_ALL_REGISTERED_USERS'
export const GET_SINGLE_USER = 'GET_SINGLE_USER'
export const REGISTER_SINGLE_USER = 'REGISTER_SINGLE_USER'
export const PATCH_SINGLE_USER_INFO = 'PATCH_SINGLE_USER_INFO'
export const GET_ALL_ROLES_INFO = 'GET_ALL_ROLES_INFO'

interface GetAllRegisteredUserAction {
    type: typeof GET_ALL_REGISTERED_USERS
    payload: UserInfoState
}

interface GetSingleUserAction {
    type: typeof GET_SINGLE_USER
    payload: UserInfo
}

interface RegisterSingleUserAction {
    type: typeof REGISTER_SINGLE_USER
    payload: {}
}

interface PatchSingleUserAction {
    type: typeof PATCH_SINGLE_USER_INFO
    payload: {}
}

interface GetAllRolesInfoAction {
    type: typeof GET_ALL_ROLES_INFO
    payload: RolesInfoState
}

export type UserRegistrationActionTypes = GetAllRegisteredUserAction | GetSingleUserAction |
    RegisterSingleUserAction | PatchSingleUserAction | GetAllRolesInfoAction | LogoutAction