import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles";

import { Check } from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import { ProductionOrderInfo } from "redux/reducers/premix/types";
import TableGrid from "components/common/TableGrid";
import UpdateIcon from "@material-ui/icons/Update";
import { deleteProductionOrderAction } from "redux/reducers/premix/actions";
import { useDispatch } from "react-redux";

export interface ContentProps extends WithStyles<typeof styles> {
    clickedProductionOrder?: ProductionOrderInfo;
    onClickedUpdateButton: () => void;
    onClickedDeleteButton: () => void;
}

interface ProductionOrderForDisplay {
    name: string;
    quantity?: string;
    isUsed?: Array<boolean>;
    batchNumber?: Array<number | undefined>;
    time?: Array<string | undefined>;
    totalPrep?: string;
}
let _gForDisplay: Array<ProductionOrderForDisplay> = [];
const ProductionOrderDetailsView = (props: ContentProps) => {
    const { classes, clickedProductionOrder, onClickedUpdateButton, onClickedDeleteButton } = props;
    const [deleteMode, setDeleteMode] = useState(false);
    const dispatcher = useDispatch();
    const [dataForDisplay, setDataForDisplay] = useState<Array<ProductionOrderForDisplay>>([]);

    const topOptions: any = {
        alignedGrids: [],
        defaultColDef: {
            editable: true,
            sortable: true,
            resizable: true,
            filter: true,
            flex: 1,
            minWidth: 100,
        },
    };
    const bottomOptions1: any = {
        alignedGrids: [],
        defaultColDef: {
            editable: true,
            sortable: true,
            resizable: true,
            filter: true,
            flex: 1,
            minWidth: 100,
        },
    };

    const bottomOptions2: any = {
        alignedGrids: [],
        defaultColDef: {
            editable: true,
            sortable: true,
            resizable: true,
            filter: true,
            flex: 1,
            minWidth: 100,
        },
    };

    topOptions.alignedGrids.push(bottomOptions1, bottomOptions2);
    bottomOptions1.alignedGrids.push(topOptions, bottomOptions2);
    bottomOptions2.alignedGrids.push(topOptions, bottomOptions1);

    useEffect(() => {
        if (dataForDisplay) {
            _gForDisplay = [...dataForDisplay];
        }
    }, [dataForDisplay]);

    useEffect(() => {
        if (clickedProductionOrder) {
            clickedProductionOrder.premixes.forEach((premix) => {
                let isUsedArr: Array<boolean> = [];
                for (let i = 0; i < 30; i++) {
                    isUsedArr[i] = false;
                }
                _gForDisplay = [..._gForDisplay, { name: premix.premixName, isUsed: isUsedArr }];
                premix.rawMaterials.forEach((element) => {
                    let strQty = element.rmWeight.toString() + " kg";
                    let dateChecked: Array<boolean> = [];
                    for (let i = 0; i < 30; i++) {
                        dateChecked[i] = false;
                    }
                    if (element.rmDatesChecked) {
                        element.rmDatesChecked.forEach((date) => {
                            dateChecked[Number(date) - 1] = true;
                        });
                    }
                    _gForDisplay = [
                        ..._gForDisplay,
                        {
                            name: "> " + element.rmCode,
                            quantity: strQty,
                            isUsed: dateChecked,
                            totalPrep: element.rmTotPrep,
                        },
                    ];
                });
                let subTotalStr = premix.rmSubtotal.toString() + " kg";
                _gForDisplay = [..._gForDisplay, { name: "SUB-TOTAL", quantity: subTotalStr, isUsed: isUsedArr }];
            });
            let isUsedArr: Array<boolean> = [];
            for (let i = 0; i < 30; i++) {
                isUsedArr[i] = false;
            }
            let totalStr = clickedProductionOrder.rmTotalWeight.toString() + " kg";
            _gForDisplay = [..._gForDisplay, { name: "TOTAL", quantity: totalStr, isUsed: isUsedArr }];
            setDataForDisplay(_gForDisplay);
        }
    }, [clickedProductionOrder]);

    const onCloseDeleteDialog = () => {
        setDeleteMode(false);
    };

    const renderDeleteDialog = () => {
        return (
            <div>
                <Dialog
                    disableEscapeKeyDown={true}
                    disableBackdropClick={true}
                    open={deleteMode}
                    onClose={onCloseDeleteDialog}
                >
                    <DialogTitle>
                        <IconButton className={classes.rightButton} aria-label="close" onClick={onCloseDeleteDialog}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="h6" component="h1" align="center">
                            {"Are you sure you want to delete this information?"}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                dispatcher(deleteProductionOrderAction(clickedProductionOrder!.prodOrderId));
                                onCloseDeleteDialog();
                                onClickedDeleteButton();
                            }}
                            color="primary"
                            variant="contained"
                            fullWidth={true}
                        >
                            Yes
                        </Button>
                        <Button onClick={onCloseDeleteDialog} color="primary" variant="contained" fullWidth={true}>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    const renderTable1 = () => {
        return (
            <Fragment>
                <Grid item container>
                    <Grid item xs={2}>
                        <TextField
                            label={"Feed Type"}
                            value={clickedProductionOrder!.feedType}
                            inputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label={"Line"}
                            value={clickedProductionOrder!.line}
                            inputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label={"DIE"}
                            value={clickedProductionOrder!.die ? clickedProductionOrder!.die : " "}
                            inputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label={"Date"}
                            value={clickedProductionOrder!.date}
                            inputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label={"Premix Checker"}
                            value={clickedProductionOrder!.premixChecker}
                            inputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label={"Shift"}
                            value={clickedProductionOrder!.shift ? clickedProductionOrder!.shift : " "}
                            inputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Fragment>
        );
    };

    const renderTable2 = () => {
        return (
            <Fragment>
                <Grid item container>
                    <Grid item xs={2}>
                        <TextField
                            label={"Revision #"}
                            value={clickedProductionOrder!.revisionNumber}
                            inputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label={"Prepared By"}
                            value={clickedProductionOrder!.preparedBy}
                            inputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label={"Date Prepared"}
                            value={clickedProductionOrder!.datePrepared}
                            inputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label={"Received By"}
                            value={clickedProductionOrder!.receivedBy ? clickedProductionOrder!.receivedBy : " "}
                            inputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label={"Date"}
                            value={clickedProductionOrder!.dateReceived ? clickedProductionOrder!.dateReceived : " "}
                            inputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Fragment>
        );
    };

    const renderFormulationInputTable = () => {
        let formulationColumnDefs = [
            {
                headerName: "Formulation Input",
                field: "name",
                minWidth: 200,
                filter: "agTextColumnFilter",
                headerTooltip: "Name",
            },
            {
                headerName: "Value",
                field: "value",
                minWidth: 100,
                filter: "agTextColumnFilter",
                headerTooltip: "Value",
            },
        ];
        return (
            <Fragment>
                <Grid item xs={4}>
                    <Typography variant="h6" component="h4" align="center">
                        {"Formulation Input"}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TableGrid
                        rowData={clickedProductionOrder!.formulationInput}
                        columnDefs={formulationColumnDefs}
                        headerHeight={0}
                        height={180}
                    />
                </Grid>
            </Fragment>
        );
    };

    const checkedDateRenderer = (rowData: any) => {
        let colId = Number(rowData!.column!.colDef!.headerName);
        return rowData!.data!.isUsed[colId - 1] === true && <Check />;
    };

    const renderLargeTable = () => {
        interface ColDef {
            headerName: string;
            field?: string;
            minWidth: number;
            filter?: string;
            headerTooltip?: string;
            valueGetter?: () => string;
            pinned?: string;
            cellRendererFramework?: any;
        }
        let columnDefsForDisplay: Array<ColDef> = [
            {
                headerName: "Raw Materials",
                field: "name",
                minWidth: 300,
                filter: "agTextColumnFilter",
                headerTooltip: "Raw Materials",
                pinned: "left",
            },
            {
                headerName: "Quantity",
                field: "quantity",
                minWidth: 150,
                filter: "agTextColumnFilter",
                headerTooltip: "Quantity",
                pinned: "left",
            },
        ];

        for (let i = 0; i < 30; i++) {
            let addColumn = {
                headerName: (i + 1).toString(),
                minWidth: 80,
                valueGetter: (param: any) => {
                    return param.data.isUsed[i] ? "yes" : "";
                },
                cellRendererFramework: checkedDateRenderer,
            } as ColDef;
            columnDefsForDisplay = [...columnDefsForDisplay, addColumn];
        }
        let totalPrepColumn = {
            headerName: "Total Prep",
            field: "totalPrep",
            minWidth: 150,
            filter: "agTextColumnFilter",
            headerTooltip: "Total Prep",
        } as ColDef;
        columnDefsForDisplay = [...columnDefsForDisplay, totalPrepColumn];

        return (
            <Fragment>
                <Grid item xs={12}>
                    <TableGrid
                        rowData={dataForDisplay}
                        columnDefs={columnDefsForDisplay}
                        height={300}
                        gridOptions={topOptions}
                        suppressHorizontalScroll={true}
                    />
                </Grid>
            </Fragment>
        );
    };

    const renderTimeTable = () => {
        let timeDisplay: Array<ProductionOrderForDisplay> = [];
        let timeList: Array<string | undefined> = [];
        for (let i = 0; i < 30; i++) {
            timeList[i] = undefined;
        }
        if (clickedProductionOrder!.rmDateTime) {
            clickedProductionOrder!.rmDateTime.forEach((element) => {
                timeList[Number(element.date) - 1] = element.value;
            });
        }
        timeDisplay = [...timeDisplay, { name: "Time", time: timeList }];
        interface ColDef {
            headerName: string;
            field?: string;
            minWidth: number;
            filter?: string;
            headerTooltip?: string;
            valueGetter?: () => string;
            pinned?: string;
            cellRendererFramework?: any;
        }
        let columnDefsForDisplay: Array<ColDef> = [
            {
                headerName: "Raw Materials",
                field: "name",
                minWidth: 300,
                filter: "agTextColumnFilter",
                headerTooltip: "Raw Materials",
                pinned: "left",
            },
            {
                headerName: "Quantity",
                field: "quantity",
                minWidth: 150,
                filter: "agTextColumnFilter",
                headerTooltip: "Quantity",
                pinned: "left",
            },
        ];

        for (let i = 0; i < 30; i++) {
            let addColumn = {
                headerName: (i + 1).toString(),
                minWidth: 80,
                valueGetter: (param: any) => {
                    return param.data.time[i] ? param.data.time[i] : "";
                },
            } as ColDef;
            columnDefsForDisplay = [...columnDefsForDisplay, addColumn];
        }
        let totalPrepColumn = {
            headerName: "Total Prep",
            field: "totalPrep",
            minWidth: 150,
            filter: "agTextColumnFilter",
            headerTooltip: "Total Prep",
        } as ColDef;
        columnDefsForDisplay = [...columnDefsForDisplay, totalPrepColumn];

        return (
            <Fragment>
                <Grid item xs={12}>
                    <TableGrid
                        rowData={timeDisplay}
                        columnDefs={columnDefsForDisplay}
                        height={60}
                        headerHeight={0}
                        gridOptions={bottomOptions1}
                        suppressHorizontalScroll={true}
                    />
                </Grid>
            </Fragment>
        );
    };

    const renderBatchNumberTable = () => {
        let batchNumberDisplay: Array<ProductionOrderForDisplay> = [];
        let batchNumber: Array<number | undefined> = [];
        for (let i = 0; i < 30; i++) {
            batchNumber[i] = undefined;
        }
        if (clickedProductionOrder!.batchNumber) {
            clickedProductionOrder!.batchNumber.forEach((element) => {
                batchNumber[Number(element.date) - 1] = element.value;
            });
        }
        batchNumberDisplay = [...batchNumberDisplay, { name: "Batch Number", batchNumber: batchNumber }];
        interface ColDef {
            headerName: string;
            field?: string;
            minWidth: number;
            filter?: string;
            headerTooltip?: string;
            valueGetter?: () => string;
            pinned?: string;
            cellRendererFramework?: any;
        }
        let columnDefsForDisplay: Array<ColDef> = [
            {
                headerName: "Raw Materials",
                field: "name",
                minWidth: 300,
                filter: "agTextColumnFilter",
                headerTooltip: "Raw Materials",
                pinned: "left",
            },
            {
                headerName: "Quantity",
                field: "quantity",
                minWidth: 150,
                filter: "agTextColumnFilter",
                headerTooltip: "Quantity",
                pinned: "left",
            },
        ];

        for (let i = 0; i < 30; i++) {
            let addColumn = {
                headerName: (i + 1).toString(),
                minWidth: 80,
                valueGetter: (param: any) => {
                    return param.data.batchNumber[i] ? param.data.batchNumber[i].toString() : "";
                },
            } as ColDef;
            columnDefsForDisplay = [...columnDefsForDisplay, addColumn];
        }
        let totalPrepColumn = {
            headerName: "Total Prep",
            field: "totalPrep",
            minWidth: 150,
            filter: "agTextColumnFilter",
            headerTooltip: "Total Prep",
        } as ColDef;
        columnDefsForDisplay = [...columnDefsForDisplay, totalPrepColumn];

        return (
            <Fragment>
                <Grid item xs={12}>
                    <TableGrid
                        rowData={batchNumberDisplay}
                        columnDefs={columnDefsForDisplay}
                        height={60}
                        headerHeight={0}
                        gridOptions={bottomOptions2}
                    />
                </Grid>
            </Fragment>
        );
    };

    const renderProductionOrderDetails = () => {
        return (
            <Fragment>
                <Grid container spacing={2} alignItems="center">
                    <Grid item container xs={6} spacing={5}>
                        {renderTable1()}
                        {renderTable2()}
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={4}>
                        {renderFormulationInputTable()}
                    </Grid>
                    <Grid item container xs={12} spacing={2}>
                        {renderLargeTable()}
                    </Grid>
                    <Grid item container xs={12} spacing={2}>
                        {renderTimeTable()}
                    </Grid>
                    <Grid item container xs={12} spacing={2}>
                        {renderBatchNumberTable()}
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            onClick={onClickedUpdateButton}
                            color="primary"
                            variant="contained"
                            fullWidth={true}
                            startIcon={<UpdateIcon />}
                        >
                            Update
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            onClick={() => {
                                setDeleteMode(true);
                            }}
                            color="primary"
                            variant="contained"
                            fullWidth={true}
                            startIcon={<DeleteIcon />}
                        >
                            Delete
                        </Button>
                    </Grid>
                </Grid>
            </Fragment>
        );
    };

    return (
        <div style={{ padding: 10 }}>
            {renderProductionOrderDetails()}
            {renderDeleteDialog()}
        </div>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: "99%",
            margin: "auto",
            overflow: "hidden",
            alignContent: "center",
        },
        searchBar: {
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: "block",
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: "rgb(31,49,69)",
            alignSelf: "center",
        },
        contentWrapper: {
            margin: "0px 0px",
        },
        rightButton: {
            float: "right",
        },
    });

export default withStyles(styles)(ProductionOrderDetailsView);
