import { LogoutAction } from 'redux/reducers/rootReducer/types';

export enum DeliveryStatus {
    SCHEDULED = 'Scheduled',
    DELIVERED = 'Delivered',
    DELAYED = 'Delayed',
    FAILED = 'Failed',
}

export enum PurchaseOrderStatus {
    PENDING = 'Pending',
    APPROVED = 'Approved',
    REJECTED = 'Rejected',
    CANCELLED = 'Cancelled',
    COMPLETED = 'Completed',
    REFUNDED = 'Refunded',
}

export enum PurchaseRequestStatus {
    OPEN = 'Open',
    APPROVAL = 'For Approval',
    ONGOING = 'Ongoing',
    APPROVED = 'Approved PO',
    FOR_UPDATE = 'For Update',
    CANCELLED = 'Cancelled',
    CLOSED = 'Closed',
}

export enum SupplierLocationOption {
    LOCAL = 'Local',
    INTERNATIONAL = 'International',
}

export interface ItemInfo {
    itemCode: string;
    itemDescription: string;
}

export interface SupplierInfo {
    supplierId: string;
    supplierName: string;
    supplierLocation: string;
    itemsList: Array<ItemInfo>;
    createDate: string;
    updateDate: string;
}
export interface SupplierInfoRequest {
    supplierName?: string;
    supplierLocation?: string;
    itemsList?: Array<ItemInfo>;
}

export interface SupplierInfoList {
    suppliersList: Array<SupplierInfo>;
    LastEvaluatedKey: null;
}

export interface ItemsToRequisitionInfo extends ItemInfo {
    itemUnit: string;
    itemQuantity: number;
    itemType: string;
    attachments?: Array<string>;
}

export interface ItemsToDeliverInfoBase extends ItemInfo {
    itemUnit: string;
    itemQuantity: number;
}

export interface ItemsToDeliverInfoWithOrderId extends ItemsToDeliverInfoBase {
    orderId: string;
}

export interface OrderedItemWithPriceInfo extends ItemsToDeliverInfoBase {
    itemUnitPrice: number;
    itemTotalPrice: number;
}

export interface DeliveryScheduleInfo {
    scheduleId?: string;
    deliveryDate: string;
    timeSlot: string;
    supplierName: string;
    truckNo: string;
    orderId?: string;
    sender?: string;
    recipient?: string;
    requestor?: string;
    procurementRepresentative?: string;
    itemsList: Array<ItemsToDeliverInfoWithOrderId>;
    scheduleStatus: string;
    createDate?: string;
    updateDate?: string;
}

export interface DeliveryScheduleInfoRequest {
    deliveryDate: string;
    timeSlot: string;
    supplierName?: string;
    truckNo?: string;
    orderId?: string;
    sender?: string;
    recipient?: string;
    requestor?: string;
    procurementRepresentative?: string;
    itemsList?: Array<ItemsToDeliverInfoBase>;
    scheduleStatus?: string;
}

export interface DeliveryScheduleInfoList {
    schedulesList: Array<DeliveryScheduleInfo>;
    LastEvaluatedKey: null;
}

export interface PurchaseOrderInfo {
    orderId: string;
    requestId: string;
    supplierName: string;
    terms: string;
    shipTo: string;
    reviewedBy: string;
    preparedBy: string;
    approvedBy: string;
    itemsList: Array<OrderedItemWithPriceInfo>;
    createDate: string;
    updateDate: string;
    orderStatus: string;
    total: number;
    sender: string;
    recipient: string;
    requestor: string;
    procurementRepresentative: string;
}

export interface PurchaseOrderInfoRequest {
    requestId: string;
    supplierName: string;
    terms: string;
    shipTo: string;
    reviewedBy: string;
    preparedBy: string;
    approvedBy?: string;
    itemsList: Array<OrderedItemWithPriceInfo>;
    total: number;
}

export interface PurchaseOrderInfoUpdateRequest {
    approvedBy?: string;
    orderStatus: string;
}

export interface PurchaseRequestInfo {
    requestId: string;
    originDept: string;
    purpose: string;
    requisitionedBy: string;
    approvedBy: string;
    itemsList: Array<ItemsToRequisitionInfo>;
    createDate: string;
    updateDate: string;
    approveDate: string;
    closeDate: string;
    needDate: string;
    installedUsedDate: string;
    requestStatus: PurchaseRequestStatus;
    remarks: string;
}

export interface PurchaseRequestInfoRequest {
    originDept: string;
    purpose: string;
    requisitionedBy: string;
    approvedBy?: string;
    itemsList: Array<ItemsToRequisitionInfo>;
    approveDate?: string | null;
    closeDate?: string | null;
    needDate?: string | null;
    installedUsedDate?: string | null;
    requestStatus?: PurchaseRequestStatus | null;
    remarks?: string;
}

export interface PurchaseOrderInfoList {
    ordersList: Array<PurchaseOrderInfo>;
    LastEvaluatedKey: null;
}

export interface PurchaseRequestInfoList {
    requestsList: Array<PurchaseRequestInfo>;
    LastEvaluatedKey: null;
}
export interface DeletePurchaseRequestAtttachmentParams {
    requestId: string;
    fileName: string;
}
export interface GetPurchasingPresignedUrlParams {
    requestId: string;
    fileNames: Array<string>;
}

export interface PurchasingInfoState {
    deliverySchedule: DeliveryScheduleInfoList;
    suppliers: SupplierInfoList;
    purchaseRequests: PurchaseRequestInfoList;
    purchaseOrders: PurchaseOrderInfoList;
}

export interface PurchasingReducerType {
    purchasing: PurchasingInfoState;
}

export const GET_DELIVERY_SCHEDULE = 'GET_DELIVERY_SCHEDULE';
export const GET_All_SUPPLIERS = 'GET_All_SUPPLIERS';
export const GET_All_PURCHASE_REQUESTS = 'GET_All_PURCHASE_REQUESTS';
export const GET_All_PURCHASE_ORDERS = 'GET_All_PURCHASE_ORDERS';
export const ADD_DELIVERY_SCHEDULE = 'ADD_DELIVERY_SCHEDULE';
export const ADD_SUPPLIER = 'ADD_SUPPLIER';
export const ADD_PURCHASE_REQUEST = 'ADD_PURCHASE_REQUEST';
export const ADD_PURCHASE_ORDER = 'ADD_PURCHASE_ORDER';
export const PATCH_DELIVERY_SCHEDULE_INFO = 'PATCH_DELIVERY_SCHEDULE_INFO';
export const PATCH_SUPPLIER_INFO = 'PATCH_SUPPLIER_INFO';
export const PATCH_PURCHASE_REQUEST_INFO = 'PATCH_PURCHASE_REQUEST_INFO';
export const PATCH_PURCHASE_ORDER_INFO = 'PATCH_PURCHASE_ORDER_INFO';
export const DELETE_DELIVERY_SCHEDULE = 'DELETE_DELIVERY_SCHEDULE_INFO';
export const DELETE_PURCHASE_REQUISITION = 'DELETE_PURCHASE_REQUISITION_INFO';
export const DELETE_PURCHASE_ORDER = 'DELETE_PURCHASE_ORDER_INFO';
export const DELETE_SUPPLIERS = 'DELETE_SUPPLIERS_INFO';

interface GetDeliverySchedule {
    type: typeof GET_DELIVERY_SCHEDULE;
    payload: DeliveryScheduleInfoList;
}

interface GetAllSuppliers {
    type: typeof GET_All_SUPPLIERS;
    payload: SupplierInfoList;
}

interface GetAllPurchaseOrders {
    type: typeof GET_All_PURCHASE_ORDERS;
    payload: PurchaseOrderInfoList;
}

interface GetAllPurchaseRequests {
    type: typeof GET_All_PURCHASE_REQUESTS;
    payload: PurchaseRequestInfoList;
}

export type PurchasingActionTypes =
    | GetDeliverySchedule
    | GetAllSuppliers
    | GetAllPurchaseRequests
    | GetAllPurchaseOrders
    | LogoutAction;
