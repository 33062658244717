import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FGBatchInfo, FGWarehouseLocationInfo } from 'redux/reducers/finishedGoods/types';
import React, { Fragment, useEffect, useState } from 'react';
import { addFGBatchUnloadingDetailsAction, getFGWarehouseLocationAction } from 'redux/reducers/finishedGoods/actions';

import MomentUtils from '@date-io/moment';
import SelectComponent from 'components/common/Select';
import { finishedGoodsTypedSelector } from 'redux/reducers/finishedGoods/finishedGoodsReducer';
import moment from 'moment';
import { useDispatch } from 'react-redux';

interface UnloadingDetailsProps {
    batchId: string | null;
    open: boolean;
    onClose: () => void;
    batchInfo?: FGBatchInfo | null;
}

const FinishedGoodsUnloadingDetails = (props: UnloadingDetailsProps) => {
    const { batchId, open, onClose, batchInfo } = props;
    const dispatcher = useDispatch();

    useEffect(() => {}, [batchId, open, onClose]);

    const warehouseLocation = finishedGoodsTypedSelector<Array<FGWarehouseLocationInfo>>(
        (state) => state.finishedGoods.wahouseLocation
    );

    useEffect(() => {
        if (batchInfo) {
            setWarehouseId(batchInfo.warehouseId);
            setLocationId(batchInfo.locationId);
            setUnloadingDateTime(batchInfo.unloadingDatetime);
            setUnloadedBy(batchInfo.unloadedBy);
        }
    }, [batchInfo]);

    useEffect(() => {
        if (warehouseLocation.length === 0) {
            dispatcher(getFGWarehouseLocationAction());
        }
    }, [warehouseLocation, dispatcher]);

    const [warehouseId, setWarehouseId] = useState('');
    const [warehouseHelperText, setWarehouseHelperText] = useState('');
    const [locationId, setLocationId] = useState('');
    const [locationHelperText, setLocationHelperText] = useState('');
    const [unloadedBy, setUnloadedBy] = useState('');
    const [unloadedByHelperText, setUnloadedByHelperText] = useState('');
    const [unloadingDatetime, setUnloadingDateTime] = useState('');
    const [unloadingDateHelper, setUnloadingDateHelper] = useState('');

    const handleWarehouseChange = (event: any) => {
        setWarehouseHelperText('');
        setWarehouseId(event as string);
    };

    const warehouseSelect = () => {
        let warehouseList: Array<string> = [];
        warehouseLocation.forEach((info) => {
            if (!warehouseList.some((warehouseId) => warehouseId === info.warehouseId)) {
                warehouseList.push(info.warehouseId);
            }
        });

        return (
            <SelectComponent
                title={'Warehouse'}
                selectList={warehouseList}
                setter={handleWarehouseChange}
                currentValue={warehouseId}
                error={warehouseHelperText !== ''}
            />
        );
    };

    const handleLocationChange = (event: any) => {
        setLocationHelperText('');
        setLocationId(event as string);
    };

    const locationSelect = () => {
        let locationList = warehouseLocation.filter((info) => {
            return info.warehouseId === warehouseId;
        });

        return (
            <SelectComponent
                title={'Location'}
                selectList={locationList.map((info) => info.locationId)}
                setter={handleLocationChange}
                currentValue={locationId}
                error={locationHelperText !== ''}
            />
        );
    };

    const executeUnload = () => {
        if (warehouseId === '') {
            setWarehouseHelperText('Please select Warehouse');
        }
        if (locationId === '') {
            setLocationHelperText('Please select Location');
        }
        if (unloadedBy === '') {
            setUnloadedByHelperText('Please set Unloaded By');
        }
        if (unloadingDatetime === '') {
            setUnloadingDateHelper('Please select unloading date/tiome');
        }

        if (warehouseId && locationId && unloadedBy && unloadingDatetime && batchId) {
            dispatcher(
                addFGBatchUnloadingDetailsAction(batchId, { warehouseId, locationId, unloadedBy, unloadingDatetime })
            );
            onClose();
        }
    };

    const renderModal = () => {
        return (
            <Dialog disableEscapeKeyDown open={open} onClose={onClose}>
                <DialogTitle>Unloading Details</DialogTitle>
                <DialogContent>
                    {warehouseSelect()}
                    {locationSelect()}
                    <TextField
                        fullWidth
                        margin='dense'
                        style={{ minWidth: 300 }}
                        type={'string'}
                        label={'Unloaded By'}
                        variant='outlined'
                        value={unloadedBy}
                        helperText={unloadedByHelperText}
                        error={unloadedByHelperText !== ''}
                        onChange={(data) => {
                            setUnloadedBy(data.target.value);
                            setUnloadedByHelperText('');
                        }}
                    />

                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DateTimePicker
                            margin='dense'
                            fullWidth
                            value={unloadingDatetime === '' ? null : unloadingDatetime}
                            label={'Unloading Date/Time'}
                            inputVariant='outlined'
                            helperText={unloadingDateHelper}
                            error={unloadingDateHelper !== ''}
                            onChange={(date) => {
                                setUnloadingDateTime(moment(date).toISOString());
                                setUnloadingDateHelper('');
                            }}
                        />
                    </MuiPickersUtilsProvider>
                </DialogContent>
                {batchInfo && !batchInfo.unloadingDatetime && (
                    <DialogActions>
                        <Button onClick={executeUnload} color='primary' variant='contained'>
                            Unload Finished Goods
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        );
    };

    return <Fragment>{renderModal()}</Fragment>;
};

export default FinishedGoodsUnloadingDetails;
