import { CalendarToday, Dashboard, EventAvailable, ShoppingCart } from "@material-ui/icons";
import { LocationInfo, RawMaterial } from "redux/reducers/rmShipment/types";
import { MuiThemeProvider, WithStyles, createMuiTheme, createStyles, withStyles } from "@material-ui/core/styles";
import React, { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { getRMInfoListAction, getWarehouseInfoAction } from "redux/reducers/rmShipment/actions";
import { getSingleUserAction, getUserRolesInfoAction } from "redux/reducers/users/actions";

import CircularProgress from "@material-ui/core/CircularProgress";
import Copyright from "components/common/Copyright";
import CssBaseline from "@material-ui/core/CssBaseline";
import DashboardMain from "views/Dashboard/DashboardMain";
import DeliveryScheduleMain from "views/MaterialDelivery/DeliverySchedule/DeliveryScheduleMain";
import DeliveryTrackerMain from "./MaterialDelivery/DeliveryTracker/DeliveryTrackerMain";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import FinishedGoodsMain from "views/InventoryList/FinishedGoods/FinishedGoodsMain";
import GroupIcon from "@material-ui/icons/Group";
import Hidden from "@material-ui/core/Hidden";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import { LoggedUser } from "utils/loggedUser";
import MixerPremixMain from "./MixerPremix/MixerPremixMain";
import Navigator from "components/common/Navigator";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PurchasingMain from "./Purchasing/PurchasingMain";
import RMShipmentMain from "views/InventoryList/RMShipment/RMShipmentMain";
import RegisterUserMain from "views/AccountManagement/RegisterUsers/RegisterUserMain";
import { SERVICE_DOMAIN } from "utils/urls";
import SparePartsMain from "views/InventoryList/SpareParts/SparePartsMain";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import UserListMain from "views/AccountManagement/UsersList/UserListMain";
import { apiMessageTypedSelector } from "redux/reducers/apiMessage/apiMessageReducer";
import { getAllRegisteredUserAction } from "redux/reducers/users/actions";
import { rmShipmentTypedSelector } from "redux/reducers/rmShipment/rmShipmentReducer";
import { setCookie } from "utils/cookies";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { userRegistrationTypedSelector } from "redux/reducers/users/usersReducer";
import { RoleFeatures } from "redux/reducers/users/types";

export interface MainProps extends WithStyles<typeof styles> { }

const Main = (props: MainProps) => {
    const { classes } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const users = userRegistrationTypedSelector<Array<any>>((state) => state.users.users);
    const logggedUser = userRegistrationTypedSelector((state) => state.users.user);
    const warehouseInfo = rmShipmentTypedSelector<Array<LocationInfo>>((state) => state.rmshipment.warehouseInfo);
    const rmList = rmShipmentTypedSelector<Array<RawMaterial>>((state) => state.rmshipment.rmList);

    const dispatcher = useDispatch();

    const [isPreparing, setPreparing] = useState(true);
    const [permission, setPermission] = useState<any>({});
    const [permissionAcquired, setPermissionAcquired] = useState(false);

    useEffect(() => {
        /* Need to update information from DB */
        if (!logggedUser.userFirstName || !logggedUser.userLastName) {
            dispatcher(getSingleUserAction(LoggedUser.getEmail()));
        } else {
            let domain = window.location.hostname;
            if (domain !== "localhost") {
                domain = "." + SERVICE_DOMAIN;
            }
            setCookie("firstName", logggedUser.userFirstName, domain);
            setCookie("lastName", logggedUser.userLastName, domain);
        }
    }, [logggedUser, dispatcher]);

    useLayoutEffect(() => {
        if (users.length === 0) {
            dispatcher(getUserRolesInfoAction());
            dispatcher(getAllRegisteredUserAction());
        } else {
            setPreparing(false);
            if (!permissionAcquired) {
                setPermissionAcquired(true);
                setTimeout(() => {
                    setPermission(LoggedUser.getPermission());
                }, 500);
            }
        }
    }, [users, permissionAcquired, dispatcher]);

    const [navCategories, setNavCategories] = useState<Array<any>>([]);

    const [activeNav, setActiveNav] = useState("");
    const [select, setSelect] = useState(false);

    useLayoutEffect(() => {
        setNavCategories([
            {
                id: "Inventory List",
                allowedUser: "All",
                featureRoles: [
                    RoleFeatures.RM_TRUCK_ENTRIES,
                    RoleFeatures.RM_SHIPMENT_LIST,
                    RoleFeatures.RM_STOCK_CARD,
                    RoleFeatures.RM_RECEIVING_REPORT_BIN,
                    RoleFeatures.RM_WAREHOUSE_RELOCATION,
                    RoleFeatures.FG_TRUCK_ENTRIES,
                    RoleFeatures.FG_LIST,
                    RoleFeatures.SP_INVENTORY_LIST,
                ],
                children: [
                    {
                        childId: "Raw Materials",
                        navIcon: <LocalShippingIcon />,
                        allowed: true,
                        featureRoles: [
                            RoleFeatures.RM_TRUCK_ENTRIES,
                            RoleFeatures.RM_SHIPMENT_LIST,
                            RoleFeatures.RM_STOCK_CARD,
                            RoleFeatures.RM_RECEIVING_REPORT_BIN,
                            RoleFeatures.RM_WAREHOUSE_RELOCATION,
                        ]
                    },
                    {
                        childId: "Finished Goods",
                        navIcon: <FastfoodIcon />,
                        allowed: true,
                        featureRoles: [
                            RoleFeatures.FG_TRUCK_ENTRIES,
                            RoleFeatures.FG_LIST,
                        ]
                    },
                    {
                        childId: "Spare Parts",
                        navIcon: <LocalMallIcon />,
                        allowed: true,
                        featureRoles: [
                            RoleFeatures.SP_INVENTORY_LIST,
                        ]
                    },
                ],
            },
            {
                id: "Account Management",
                allowedUser: "Administrator, IT Head",
                featureRoles: [
                    RoleFeatures.USER_LIST,
                    RoleFeatures.USER_REGISTRATION,
                ],
                children: [
                    {
                        childId: "Users List",
                        navIcon: <GroupIcon />,
                        allowed: true,
                        featureRoles: [
                            RoleFeatures.USER_LIST,
                        ]
                    },
                    {
                        childId: "Register New Users",
                        navIcon: <PersonAddIcon />,
                        allowed: true,
                        featureRoles: [
                            RoleFeatures.USER_REGISTRATION,
                        ]
                    },
                ],
            },
            {
                id: "Dashboard",
                allowedUser: "All",
                featureRoles: [
                    RoleFeatures.DASHBOARD,
                ],
                children: [
                    {
                        childId: "Dashboard",
                        navIcon: <TrendingUpIcon />,
                        allowed: true,
                        featureRoles: [
                            RoleFeatures.DASHBOARD,
                        ]
                    },
                ],
            },
            {
                id: "Material Delivery",
                allowedUser: "All",
                featureRoles: [
                    RoleFeatures.MATERIAL_DELIVERY,
                    RoleFeatures.MATERIAL_DELIVERY_TRACKER,
                ],
                children: [
                    {
                        childId: "Delivery Schedule",
                        navIcon: <CalendarToday />,
                        allowed: true,
                        featureRoles: [
                            RoleFeatures.MATERIAL_DELIVERY,
                        ]
                    },
                    {
                        childId: "Delivery Tracker",
                        navIcon: <EventAvailable />,
                        allowed: true,
                        featureRoles: [
                            RoleFeatures.MATERIAL_DELIVERY_TRACKER,
                        ]
                    },
                ],
            },
            {
                id: "Purchasing",
                allowedUser: "All",
                featureRoles: [
                    RoleFeatures.PROC_PR,
                    RoleFeatures.PROC_CANVASS_SHEET,
                    RoleFeatures.PROC_PURCHASE_ORDER,
                    RoleFeatures.PROC_SUPPLIER_LIST,
                ],
                children: [
                    {
                        childId: "Purchasing",
                        navIcon: <ShoppingCart />,
                        allowed: true,
                        featureRoles: [
                            RoleFeatures.PROC_PR,
                            RoleFeatures.PROC_CANVASS_SHEET,
                            RoleFeatures.PROC_PURCHASE_ORDER,
                            RoleFeatures.PROC_SUPPLIER_LIST,
                        ]
                    }
                ],
            },
            {
                id: "Mixer Premix",
                allowedUser: "All",
                featureRoles: [
                    RoleFeatures.PREMIX,
                ],
                children: [
                    {
                        childId: "Premix",
                        navIcon: <Dashboard />,
                        allowed: true,
                        featureRoles: [
                            RoleFeatures.PREMIX,
                        ]
                    }
                ],
            },
        ]);
        setActiveNav("Raw Materials");
    }, [permission, isPreparing]);

    useLayoutEffect(() => { }, [navCategories, activeNav]);

    useLayoutEffect(() => {
        if (rmList.length === 0) {
            dispatcher(getRMInfoListAction());
        }
    }, [rmList, dispatcher]);

    useLayoutEffect(() => {
        if (warehouseInfo.length === 0) {
            dispatcher(getWarehouseInfoAction());
        }
    }, [warehouseInfo, dispatcher]);

    const apiResponse = apiMessageTypedSelector((state) => state.message.message);
    const apiResponseType = apiMessageTypedSelector((state) => state.message.variant);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    useLayoutEffect(() => {
        if (apiResponse !== "") {
            if (apiResponseType === "error") {
                const key = enqueueSnackbar(`${apiResponse}`, {
                    variant: apiResponseType,
                    persist: true,
                    onClick: () => {
                        closeSnackbar(key);
                    },
                });
            } else if (apiResponseType === "success" || apiResponseType === "warning" || apiResponseType === "info") {
                enqueueSnackbar(`${apiResponse}`, { variant: apiResponseType });
            }
        }
    }, [apiResponse, apiResponseType, enqueueSnackbar, closeSnackbar]);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const onNavigate = (navId: string) => {
        setSelect(true);
        setActiveNav(navId);
    };

    const onSelect = () => {
        setSelect(false);
    };

    return (
        <MuiThemeProvider theme={theme}>
            <div className={classes.root}>
                <CssBaseline />
                <nav className={classes.drawer}>
                    <Hidden smUp implementation="js">
                        <Navigator
                            PaperProps={{ style: { width: drawerWidth } }}
                            categories={navCategories}
                            onNavigate={onNavigate}
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                        />
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Navigator
                            PaperProps={{ style: { width: drawerWidth } }}
                            onNavigate={onNavigate}
                            categories={navCategories}
                        />
                    </Hidden>
                </nav>
                <div className={classes.app}>
                    {navCategories.length === 0 || isPreparing ? (
                        <CircularProgress style={{ margin: "auto" }} />
                    ) : (
                        <Fragment>
                            {activeNav === navCategories[0].children[0].childId && (
                                <RMShipmentMain
                                    mainClass={classes.main}
                                    onDrawerToggle={handleDrawerToggle}
                                    onSelect={onSelect}
                                    select={select}
                                />
                            )}
                            {activeNav === navCategories[0].children[1].childId && (
                                <FinishedGoodsMain mainClass={classes.main} onDrawerToggle={handleDrawerToggle} />
                            )}
                            {activeNav === navCategories[0].children[2].childId && (
                                <SparePartsMain mainClass={classes.main} onDrawerToggle={handleDrawerToggle} />
                            )}
                            {activeNav === navCategories[1].children[0].childId && (
                                <UserListMain mainClass={classes.main} onDrawerToggle={handleDrawerToggle} />
                            )}
                            {activeNav === navCategories[1].children[1].childId && (
                                <RegisterUserMain mainClass={classes.main} onDrawerToggle={handleDrawerToggle} />
                            )}
                            {activeNav === navCategories[2].children[0].childId && (
                                <DashboardMain mainClass={classes.main} onDrawerToggle={handleDrawerToggle} />
                            )}
                            {activeNav === navCategories[3].children[0].childId && (
                                <DeliveryScheduleMain mainClass={classes.main} onDrawerToggle={handleDrawerToggle} />
                            )}
                            {activeNav === navCategories[3].children[1].childId && (
                                <DeliveryTrackerMain mainClass={classes.main} onDrawerToggle={handleDrawerToggle} />
                            )}
                            {activeNav === navCategories[4].children[0].childId && (
                                <PurchasingMain mainClass={classes.main} onDrawerToggle={handleDrawerToggle} />
                            )}
                            {activeNav === navCategories[5].children[0].childId && (
                                <MixerPremixMain mainClass={classes.main} onDrawerToggle={handleDrawerToggle} />
                            )}
                        </Fragment>
                    )}
                    <footer className={classes.footer}>
                        <Copyright />
                    </footer>
                </div>
            </div>
        </MuiThemeProvider>
    );
};

let theme = createMuiTheme({
    palette: {
        primary: {
            light: "#63ccff",
            main: "rgb(31,49,69)",
            dark: "#006db3",
        },
        secondary: {
            light: "#009fdc",
            main: "#009fdc",
            dark: "#009fdc",
        },
    },
    typography: {
        h5: {
            fontWeight: 500,
            fontSize: 26,
            letterSpacing: 0.5,
        },
    },
    shape: {
        borderRadius: 8,
    },
    props: {
        MuiTab: {
            disableRipple: true,
        },
    },
    mixins: {
        toolbar: {
            minHeight: 48,
        },
    },
});

theme = {
    ...theme,
    overrides: {
        MuiDrawer: {
            paper: {
                background: "linear-gradient(0deg, rgba(0,159,219,1) 0%, rgba(20,42,73,1) 100%)",
            },
        },
        MuiButton: {
            label: {
                textTransform: "none",
            },
            contained: {
                boxShadow: "none",
                "&:active": {
                    boxShadow: "none",
                },
            },
        },
        MuiTabs: {
            root: {
                marginLeft: theme.spacing(1),
            },
            indicator: {
                height: 3,
                borderTopLeftRadius: 3,
                borderTopRightRadius: 3,
                backgroundColor: theme.palette.common.white,
            },
        },
        MuiTab: {
            root: {
                textTransform: "none",
                margin: "0 16px",
                minWidth: 0,
                padding: 0,
                [theme.breakpoints.up("md")]: {
                    padding: 0,
                    minWidth: 0,
                },
            },
        },
        MuiIconButton: {
            root: {
                padding: theme.spacing(1),
            },
        },
        MuiTooltip: {
            tooltip: {
                borderRadius: 4,
            },
        },
        MuiDivider: {
            root: {
                backgroundColor: "#404854",
            },
        },
        MuiListItemText: {
            primary: {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
        MuiListItemIcon: {
            root: {
                color: "inherit",
                marginRight: 0,
                "& svg": {
                    fontSize: 20,
                },
            },
        },
        MuiAvatar: {
            root: {
                width: 32,
                height: 32,
            },
        },
    },
};

const drawerWidth = 256;

const styles = createStyles({
    root: {
        display: "flex",
        minHeight: "100vh",
    },
    drawer: {
        [theme.breakpoints.up("sm")]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    app: {
        flex: 1,
        display: "flex",
        flexDirection: "column",
    },
    main: {
        flex: 1,
        padding: theme.spacing(2, 4),
        background: "#eaeff1",
    },
    footer: {
        padding: theme.spacing(2),
        background: "#eaeff1",
    },
});

export default withStyles(styles)(Main);
