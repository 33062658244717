import { Button, Grid, Typography } from '@material-ui/core';
import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import TableGrid from 'components/common/TableGrid';
import moment from 'moment';

export interface WarehouseStockListTableProps {
    filterText?: string;
    exportCsv?: boolean;
    showMenu?: boolean;
    onCloseMenu?: any;
    onExportCsv?: any;
    exportCsvFileName?: string;
    data: Array<any>;
}

const WarehouseStockListTable = (props: WarehouseStockListTableProps) => {
    const { data, exportCsv, onExportCsv, exportCsvFileName } = props;

    const [rowData, setRowData] = useState<Array<any>>([]);
    const [isAcquiring, setIsAcquiring] = useState(false);

    useEffect(() => {
        setIsAcquiring(true);
        setTimeout(() => {
            let newData = data;
            let firstShiftTotal = 0;
            let secondShiftTotal = 0;
            let thirdShiftTotal = 0;
            let salesShiftTotal = 0;
            data.forEach((a) => {
                firstShiftTotal += a.firstShift ? a.firstShift : 0;
                secondShiftTotal += a.secondShift ? a.secondShift : 0;
                thirdShiftTotal += a.thirdShift ? a.thirdShift : 0;
                salesShiftTotal += a.salesShift ? a.salesShift : 0;
            });
            newData.push({
                inputDateTime: 'TOTAL',
                firstShift: firstShiftTotal,
                secondShift: secondShiftTotal,
                thirdShift: thirdShiftTotal,
                salesShift: salesShiftTotal,
            });
            setRowData(newData);
        }, 500);
    }, [data]);

    useLayoutEffect(() => {
        setTimeout(() => {
            setIsAcquiring(false);
        }, 500);
    }, [rowData]);

    const dateRenderer = (date: any) => {
        if (date.value !== 'TOTAL') {
            return <Button style={{ color: '#009fdc' }}>{moment(date.value).format('MM/DD/YYYY')}</Button>;
        }
        return (
            <Typography color='inherit' variant='h5'>
                {'TOTAL'}
            </Typography>
        );
    };

    const columnDefs: Array<any> = [
        {
            headerName: 'Date',
            field: 'inputDateTime',
            filter: 'agDateColumnFilter',
            headerTooltip: 'Date',
            cellRendererFramework: dateRenderer,
        },
        {
            headerName: 'Delivery',
            children: [
                {
                    headerName: 'Supplier',
                    field: 'supplierName',
                    filter: 'agTextColumnFilter',
                    headerTooltip: 'Supplier',
                },
                {
                    headerName: '# of Bags',
                    field: 'bagNum',
                    filter: 'agNumberColumnFilter',
                    headerTooltip: '# of Bags',
                },
                {
                    headerName: 'Net Weight (in kilos)',
                    field: 'netWeight',
                    filter: 'agNumberColumnFilter',
                    headerTooltip: 'Net Weight (in kilos)',
                },
            ],
        },
        {
            headerName: 'Total (in Kilos)',
            field: 'total',
            filter: 'agNumberColumnFilter',
            headerTooltip: 'Total (in Kilos)',
        },
        {
            headerName: 'Transferred (in kilos)',
            children: [
                {
                    headerName: '1st Shift',
                    field: 'firstShift',
                    filter: 'agNumberColumnFilter',
                    headerTooltip: '1st Shift',
                },
                {
                    headerName: '2nd Shift',
                    field: 'secondShift',
                    filter: 'agNumberColumnFilter',
                    headerTooltip: '2nd Shift',
                },
                {
                    headerName: '3rd Shift',
                    field: 'thirdShift',
                    filter: 'agNumberColumnFilter',
                    headerTooltip: '3rd Shift',
                },
                {
                    headerName: 'Sales',
                    field: 'salesShift',
                    filter: 'agNumberColumnFilter',
                    headerTooltip: 'Sales',
                },
            ],
        },
    ];

    return (
        <Fragment>
            <Grid container>{isAcquiring && <CircularProgress style={{ margin: 'auto' }} />}</Grid>
            {isAcquiring === false && (
                <TableGrid
                    rowData={rowData}
                    columnDefs={columnDefs}
                    height={600}
                    exportCsv={exportCsv}
                    onExportCsv={onExportCsv}
                    exportCsvFileName={exportCsvFileName}
                />
            )}
        </Fragment>
    );
};

export default WarehouseStockListTable;
