import { apiMessageReducer } from 'redux/reducers/apiMessage/apiMessageReducer';
import { canvassReducer } from '../canvass/canvassReducer';
import { combineReducers } from 'redux';
import { finishedGoodsReducer } from 'redux/reducers/finishedGoods/finishedGoodsReducer';
import { premixReducer } from '../premix/premixReducer';
import { purchasingReducer } from '../purchasing/purchasingReducer';
import { rmShipmentReducer } from 'redux/reducers/rmShipment/rmShipmentReducer';
import { sparePartsReducer } from 'redux/reducers/spareParts/sparePartsReducer';
import { stocksReducer } from 'redux/reducers/rmStocks/stocksReducer';
import { truckEntriesReducer } from 'redux/reducers/truckEntries/truckEntriesReducer';
import { usersReducer } from 'redux/reducers/users/usersReducer';

const rootReducer = combineReducers({
    rmshipment: rmShipmentReducer,
    stocks: stocksReducer,
    finishedGoods: finishedGoodsReducer,
    users: usersReducer,
    message: apiMessageReducer,
    truckEntries: truckEntriesReducer,
    spareParts: sparePartsReducer,
    purchasing: purchasingReducer,
    premix: premixReducer,
    canvass: canvassReducer,
});

export default rootReducer;
