import React from 'react';
import { AppBar, Grid, Toolbar, Typography } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

interface TitleAppBarProps extends WithStyles<typeof styles> {
    title: string;
    subTitle?: string;
}

const TitleAppBar = (props: TitleAppBarProps) => {

    const { classes, title, subTitle } = props;

    return (
        <AppBar
            component="div"
            className={classes.secondaryBar}
            color="primary"
            position="static"
            elevation={0}>
            <Toolbar>
                <Grid container style={{ flexGrow: 1 }} spacing={2}>
                    <Grid item xs={12}>
                        <Grid container justify="flex-start" spacing={2}>
                            <Grid item>
                                <Typography color="inherit" variant="h4">
                                    {title}
                                </Typography>
                            </Grid>
                            <Grid item >
                                <Typography color="inherit" variant="body1" style={{ paddingTop: 15 }}>
                                    {subTitle}
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar >
    )
}

const styles = (theme: Theme) => createStyles({
    secondaryBar: {
        zIndex: 0,
    }
});

export default withStyles(styles)(TitleAppBar);