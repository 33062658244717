import { Container, Grid, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { RMAnalysisInfo, RMShipmentInfo } from 'redux/reducers/rmShipment/types';
import React, { Fragment, useEffect, useState } from 'react';

import { LoggedUser } from 'utils/loggedUser';
import MomentUtils from '@date-io/moment';
import SelectComponent from 'components/common/Select';
import TodayIcon from '@material-ui/icons/Today';
import moment from 'moment';
import { patchRmAnalysisInfoAction } from 'redux/reducers/rmShipment/actions';
import { rmShipmentTypedSelector } from 'redux/reducers/rmShipment/rmShipmentReducer';
import { useDispatch } from 'react-redux';

interface QAChecklistProps {
    rmrrId: string;
    submit?: boolean;
    onSubmit?: () => void;
    onShowSubmit: (show: boolean) => void;
    onQAFileAvailable: (qaFile: string) => void;
}

const QAChecklistForm = (props: QAChecklistProps) => {
    const { rmrrId, submit, onSubmit, onShowSubmit, onQAFileAvailable } = props;
    const dispatcher = useDispatch();
    const shipments = rmShipmentTypedSelector<Array<RMShipmentInfo>>((state) => state.rmshipment.shipments);

    const role = LoggedUser.getRole();
    const fullName = LoggedUser.getFullName();

    const [productCode, setProductCode] = useState('');
    const [rmaNum, setRmaNum] = useState('');
    const [rmName, setRmName] = useState('');
    const [supplierName, setSupplierName] = useState('');
    const [truckPlateNum, setTruckPlateNum] = useState('');
    const [vanNum, setVanNum] = useState('');
    const [inputDateTime, setInputDateTime] = useState('');
    const [timeCollected1, setTimeCollected1] = useState('');
    const [timeCollected2, setTimeCollected2] = useState('');
    const [timeIn, setTimeIn] = useState('');
    const [timeOut, setTimeOut] = useState('');
    const [cp, setCp] = useState(0);
    const [cpNir, setCpNir] = useState(0);
    const [mc, setMc] = useState(0);
    const [mcNir, setMcNir] = useState(0);
    const [cfat, setCfat] = useState(0);
    const [cfNir, setCfNir] = useState(0);
    const [ca, setCa] = useState(0);
    const [caNir, setCaNir] = useState(0);
    const [crudeFiber, setCrudeFiber] = useState(0);
    const [crudeFiberNir, setCrudeFiberNir] = useState(0);
    const [av, setAv] = useState(0);
    const [ffa, setFfa] = useState(0);
    const [starch, setStarch] = useState(0);
    const [glutenPercent, setGlutenPercent] = useState(0);
    const [pH, setPH] = useState(0);
    const [tempCelsius, setTempCelsius] = useState(0);
    const [urease, setUrease] = useState(0);
    const [tvbn, setTVBN] = useState(0);
    const [percentBrix, setPercentBrix] = useState(0);
    const [percentImpurities, setPercentImpurities] = useState(0);
    const [afla, setAfla] = useState(0);
    const [salmonella, setSalmonella] = useState('Negative (-)');
    const [waterActivity, setWaterActivity] = useState(0);
    const [density, setDensity] = useState(0);
    const [mesh10, setMesh10] = useState(0);
    const [mesh20, setMesh20] = useState(0);
    const [mesh30, setMesh30] = useState(0);
    const [mesh40, setMesh40] = useState(0);
    const [mesh60, setMesh60] = useState(0);
    const [sampleClass, setSampleClass] = useState('');
    const [sampler, setSampler] = useState('');
    const [sampleCollectionTime, setSampleCollectionTime] = useState('');
    const [analyst, setAnalyst] = useState('');
    const [sampleAnalysisTime, setSampleAnalysisTime] = useState('');
    const [status, setStatus] = useState('');
    const [statusSetTime, setStatusSetTime] = useState(moment(new Date()).toISOString());
    const [remarks, setRemarks] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const [qaFile, setqaFile] = useState('');
    useEffect(() => {
        if (qaFile !== '') {
            onQAFileAvailable(qaFile);
        }
    }, [qaFile, onQAFileAvailable]);

    const isDisabled = () => {
        return (role !== 'QA Representative' && role !== 'Administrator') || isSubmitted;
    };

    const fixedField = [
        {
            entry: [
                { label: 'Product Code', type: 'text', value: productCode, disabled: true },
                { label: 'RMA #', type: 'text', value: rmaNum, disabled: true },
                { label: 'RMRR #', type: 'text', value: rmrrId, disabled: true },
                { label: 'Raw Material', type: 'text', value: rmName, disabled: true },
            ],
        },
        {
            entry: [
                { label: 'Supplier', type: 'text', value: supplierName, disabled: true },
                { label: 'Plate #', type: 'text', value: truckPlateNum, disabled: true },
                { label: 'Van #', type: 'text', value: vanNum, disabled: true },
                { label: 'Time Received RMRR', type: 'text', value: inputDateTime, disabled: true },
            ],
        },
    ];

    const dateField = [
        {
            entry: [
                {
                    label: 'Time Collected 1',
                    type: 'date',
                    setter: setTimeCollected1,
                    value: timeCollected1,
                    disabled: isDisabled(),
                },
                {
                    label: 'Time Collected 2',
                    type: 'date',
                    setter: setTimeCollected2,
                    value: timeCollected2,
                    disabled: isDisabled(),
                },
                { label: 'Time In', type: 'date', setter: setTimeIn, value: timeIn, disabled: isDisabled() },
                { label: 'Time Out', type: 'date', setter: setTimeOut, value: timeOut, disabled: isDisabled() },
            ],
        },
    ];

    const salmonellaSelect = () => {
        const SalmonellaList = ['Positive (+)', 'Negative (-)'];
        return (
            <SelectComponent
                title={'Salmonella'}
                selectList={SalmonellaList}
                setter={setSalmonella}
                disabled={isDisabled()}
                currentValue={salmonella}
            />
        );
    };

    const QAChecklistfields = [
        {
            entry: [
                { label: 'CP', type: 'number', setter: setCp, value: cp, disabled: isDisabled() },
                { label: 'CP NIR', type: 'number', setter: setCpNir, value: cpNir, disabled: isDisabled() },
                { label: 'MC', type: 'number', setter: setMc, value: mc, disabled: isDisabled() },
                { label: 'MC NIR', type: 'number', setter: setMcNir, value: mcNir, disabled: isDisabled() },
                { label: 'Cfat', type: 'number', setter: setCfat, value: cfat, disabled: isDisabled() },
                { label: 'CF NIR', type: 'number', setter: setCfNir, value: cfNir, disabled: isDisabled() },
            ],
        },
        {
            entry: [
                { label: 'CA', type: 'number', setter: setCa, value: ca, disabled: isDisabled() },
                { label: 'CA NIR', type: 'number', setter: setCaNir, value: caNir, disabled: isDisabled() },
                {
                    label: 'Crude Fiber',
                    type: 'number',
                    setter: setCrudeFiber,
                    value: crudeFiber,
                    disabled: isDisabled(),
                },
                {
                    label: 'Crude Fiber NIR',
                    type: 'number',
                    setter: setCrudeFiberNir,
                    value: crudeFiberNir,
                    disabled: isDisabled(),
                },
                { label: 'AV', type: 'number', setter: setAv, value: av, disabled: isDisabled() },
                { label: 'FFA', type: 'number', setter: setFfa, value: ffa, disabled: isDisabled() },
            ],
        },
        {
            entry: [
                { label: 'Starch %', type: 'number', setter: setStarch, value: starch, disabled: isDisabled() },
                {
                    label: 'Gluten %',
                    type: 'number',
                    setter: setGlutenPercent,
                    value: glutenPercent,
                    disabled: isDisabled(),
                },
                { label: 'pH', type: 'number', setter: setPH, value: pH, disabled: isDisabled() },
                {
                    label: 'Temp.°C',
                    type: 'number',
                    setter: setTempCelsius,
                    value: tempCelsius,
                    disabled: isDisabled(),
                },
                { label: 'Urease', type: 'number', setter: setUrease, value: urease, disabled: isDisabled() },
                { label: 'TVBN mg/100g', type: 'number', setter: setTVBN, value: tvbn, disabled: isDisabled() },
            ],
        },
        {
            entry: [
                { label: '%Brix', type: 'number', setter: setPercentBrix, value: percentBrix, disabled: isDisabled() },
                {
                    label: '%Impurities',
                    type: 'number',
                    setter: setPercentImpurities,
                    value: percentImpurities,
                    disabled: isDisabled(),
                },
                { label: 'Afla (ppb)', type: 'number', setter: setAfla, value: afla, disabled: isDisabled() },
                { select: salmonellaSelect },
                {
                    label: 'Water Activity %',
                    type: 'number',
                    setter: setWaterActivity,
                    value: waterActivity,
                    disabled: isDisabled(),
                },
                { label: 'Density', type: 'number', setter: setDensity, value: density, disabled: isDisabled() },
            ],
        },
        {
            entry: [
                { label: 'Mesh 10', type: 'number', setter: setMesh10, value: mesh10, disabled: isDisabled() },
                { label: 'Mesh 20', type: 'number', setter: setMesh20, value: mesh20, disabled: isDisabled() },
                { label: 'Mesh 30', type: 'number', setter: setMesh30, value: mesh30, disabled: isDisabled() },
                { label: 'Mesh 40', type: 'number', setter: setMesh40, value: mesh40, disabled: isDisabled() },
                { label: 'Mesh 60', type: 'number', setter: setMesh60, value: mesh60, disabled: isDisabled() },
                { label: 'Class', type: 'text', setter: setSampleClass, value: sampleClass, disabled: isDisabled() },
            ],
        },
    ];

    const handleStatusNameChange = (event: any) => {
        setStatus(event as string);
        setStatusSetTime(moment(new Date()).toISOString());
    };

    const statusSelect = () => {
        const StatusList = ['REJECTED', 'DISCOUNTED', 'PICK-UP', 'CANCELLED', 'PENDING', 'RECONSIDERED'];
        return (
            <SelectComponent
                title={'Status'}
                selectList={StatusList}
                setter={handleStatusNameChange}
                disabled={isDisabled()}
                currentValue={status}
                variant={'outlined'}
            />
        );
    };

    const analystSelect = () => {
        let AnalystList = [];
        if (analyst !== '') {
            AnalystList.push(analyst);
        }
        if (!isDisabled()) {
            AnalystList.push(fullName);
        } else {
            onShowSubmit(false);
        }

        return (
            <SelectComponent
                title={'Analyzed By'}
                selectList={AnalystList}
                setter={setAnalyst}
                disabled={isDisabled()}
                currentValue={analyst}
                variant={'outlined'}
            />
        );
    };

    const sampleAnalysisSummaryFields = [
        {
            entry: [
                { label: 'Sampler', type: 'text', setter: setSampler, value: sampler, disabled: isDisabled() },
                {
                    label: 'Sample Collection Time',
                    setter: setSampleCollectionTime,
                    value: sampleCollectionTime,
                    disabled: isDisabled(),
                },
            ],
        },
        {
            entry: [
                { select: analystSelect },
                {
                    label: 'Sample Analysis Time',
                    setter: setSampleAnalysisTime,
                    value: sampleAnalysisTime,
                    disabled: isDisabled(),
                },
            ],
        },
        {
            entry: [
                { select: statusSelect },
                { label: 'Remarks', type: 'text', setter: setRemarks, value: remarks, disabled: isDisabled() },
            ],
        },
    ];

    useEffect(() => {
        if (rmrrId && rmrrId !== '' && shipments && shipments.length > 0) {
            const shipment = shipments.find((ship) => ship.rmrrId === rmrrId);

            if (shipment) {
                setSupplierName(shipment.supplierName);
                setRmName(shipment.rmName);
                setRmaNum(shipment.itemNum);
                setProductCode(`RM ${moment(shipment.inputDateTime).format('YY MMDD')}`);
                setTruckPlateNum(shipment.truckPlateNum);
                setInputDateTime(moment(shipment.inputDateTime).format('MMM DD, YY hh:mm'));

                if (shipment.truckInspectionInfo && shipment.truckInspectionInfo.containerNum) {
                    setVanNum(shipment.truckInspectionInfo.containerNum);
                }
                if (shipment.analysisInfo && shipment.analysisInfo.rmAnalysisInfo) {
                    setqaFile(shipment.analysisInfo.qaAttachment ? shipment.analysisInfo.qaAttachment : '');
                    const info = shipment.analysisInfo.rmAnalysisInfo;
                    setTimeCollected1(info.rmaTimeOne);
                    setTimeCollected2(info.rmaTimeTwo);
                    setTimeIn(info.rmaTimeIn);
                    setTimeOut(info.rmaTimeOut);
                    setCp(info.rmaCP);
                    setCpNir(info.rmaCPNIR);
                    setMc(info.rmaMC);
                    setMcNir(info.rmaMCNIR);
                    setCfat(info.rmaCfat);
                    setCfNir(info.rmaCFNIR);
                    setCa(info.rmaCA);
                    setCaNir(info.rmaCANIR);
                    setCrudeFiber(info.rmaCrudeFiber);
                    setCrudeFiberNir(info.rmaCrudeFiberNIR);
                    setAv(info.rmaAV);
                    setFfa(info.rmaFFA);
                    setStarch(info.rmaStarch);
                    setGlutenPercent(info.rmaGluten);
                    setPH(info.rmaPH);
                    setTempCelsius(info.rmaTemp);
                    setUrease(info.rmaUrease);
                    setTVBN(info.rmaTVBN);
                    setPercentBrix(info.rmaBrix);
                    setPercentImpurities(info.rmaImpurities);
                    setAfla(info.rmaAfla);
                    setSalmonella(info.rmaSalmonella);
                    setWaterActivity(info.rmaWaterActivity);
                    setDensity(info.rmaDensity);
                    setMesh10(info.rmaMeshTen);
                    setMesh20(info.rmaMeshTwenty);
                    setMesh30(info.rmaMeshThirty);
                    setMesh40(info.rmaMeshForty);
                    setMesh60(info.rmaMeshSixty);
                    setSampleClass(info.rmaClass);
                    setSampler(info.rmaSampler);
                    setAnalyst(info.rmaAnalyst);
                    setStatus(info.rmaStatus);
                    setRemarks(info.rmaRemarks);
                    setIsSubmitted(info.rmaRemarks ? true : false);
                    if (shipment.analysisInfo.collectionDatetime) {
                        setSampleCollectionTime(shipment.analysisInfo.collectionDatetime);
                    }
                    if (shipment.analysisInfo.analysisDatetime) {
                        setSampleAnalysisTime(shipment.analysisInfo.analysisDatetime);
                    }
                    if (shipment.analysisInfo.analysisResultDatetime) {
                        setStatusSetTime(shipment.analysisInfo.analysisResultDatetime);
                    }
                }
            }
        }
    }, [shipments, rmrrId]);

    const getAnalysisInfo = (): RMAnalysisInfo => {
        return {
            collectedBy: sampler,
            collectionDatetime: sampleCollectionTime,
            analyzedBy: analyst,
            analysisDatetime: sampleAnalysisTime,
            analysisResult: status,
            analysisResultDatetime: statusSetTime,
            remarks: remarks,
            rmAnalysisInfo: {
                productCode: productCode,
                rmaNumber: rmaNum,
                rmrrId: rmrrId,
                rmName: rmName,
                supplierName: supplierName,
                truckPlateNum: truckPlateNum,
                rmaVanNumber: vanNum,
                inputDateTime: inputDateTime,
                rmaTimeOne: timeCollected1,
                rmaTimeTwo: timeCollected2,
                rmaTimeIn: timeIn,
                rmaTimeOut: timeOut,
                rmaCP: cp,
                rmaCPNIR: cpNir,
                rmaMC: mc,
                rmaMCNIR: mcNir,
                rmaCfat: cfat,
                rmaCFNIR: cfNir,
                rmaCA: ca,
                rmaCANIR: caNir,
                rmaCrudeFiber: crudeFiber,
                rmaCrudeFiberNIR: crudeFiberNir,
                rmaAV: av,
                rmaFFA: ffa,
                rmaStarch: starch,
                rmaGluten: glutenPercent,
                rmaPH: pH,
                rmaTemp: tempCelsius,
                rmaUrease: urease,
                rmaUreaTest: 0,
                rmaTVBN: tvbn,
                rmaBrix: percentBrix,
                rmaImpurities: percentImpurities,
                rmaAfla: afla,
                rmaSalmonella: salmonella,
                rmaWaterActivity: waterActivity,
                rmaDensity: density,
                rmaMeshTen: mesh10,
                rmaMeshTwenty: mesh20,
                rmaMeshThirty: mesh30,
                rmaMeshForty: mesh40,
                rmaMeshSixty: mesh60,
                rmaClass: sampleClass,
                rmaSampler: sampler,
                rmaAnalyst: analyst,
                rmaStatus: status,
                rmaRemarks: remarks,
            },
        };
    };

    useEffect(() => {
        if (onSubmit && submit && submit === true) {
            let analysisInfo = getAnalysisInfo();
            if (rmrrId) {
                dispatcher(patchRmAnalysisInfoAction(analysisInfo, rmrrId));
            }
            onSubmit();
        }
    }, [submit, onSubmit]); // eslint-disable-line

    const renderSection = (entries: Array<any>, xs: 12 | 6 | 3, sm: 6 | 3 | 2) => {
        return (
            <Container style={{ padding: 5 }}>
                {entries.map(({ entry }) => (
                    <Grid container alignItems='center' spacing={1}>
                        {entry.map(({ label, setter, value, radio, type, menu, select, disabled }: any) => {
                            return (
                                <Grid item xs={xs} sm={sm}>
                                    {label && label.includes('Time') && value && (
                                        <DateTimePicker
                                            margin='dense'
                                            fullWidth
                                            label={label}
                                            value={value}
                                            inputVariant='outlined'
                                            disabled={isDisabled() || disabled}
                                            onChange={(date) => setter(date)}
                                        />
                                    )}
                                    {label && label.includes('Time') && !!!value && (
                                        <DateTimePicker
                                            margin='dense'
                                            fullWidth
                                            label={label}
                                            value={null}
                                            inputVariant='outlined'
                                            onChange={(date) => setter(date)}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position='start'>
                                                        <IconButton>
                                                            <TodayIcon />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    )}
                                    {label && !label.includes('Time') && (
                                        <TextField
                                            fullWidth
                                            margin='dense'
                                            value={value}
                                            label={label}
                                            type={type}
                                            onChange={(event) => setter(event.target.value)}
                                            disabled={isDisabled() || disabled}
                                            variant='outlined'
                                        />
                                    )}
                                    {radio && radio()}
                                    {menu && menu()}
                                    {select && select()}
                                </Grid>
                            );
                        })}
                    </Grid>
                ))}
            </Container>
        );
    };

    return (
        <Fragment>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {renderSection(fixedField, 6, 3)}
                {renderSection(dateField, 6, 3)}
                {renderSection(QAChecklistfields, 3, 2)}
                {renderSection(sampleAnalysisSummaryFields, 12, 6)}
            </MuiPickersUtilsProvider>
        </Fragment>
    );
};

export default QAChecklistForm;
