import ApiController from './api-controller';
import { AxiosPromise } from 'axios';
import { StockRelocationInfo } from 'redux/reducers/rmStocks/types';

class StocksController extends ApiController {
    getWarehouseLocationStocks<Data = any>(warehouse: string, location: string): AxiosPromise<Data> {
        return this._instance.get(`services/rm/warehouse/${warehouse}/location/${location}/stock`);
    }

    getRmStocks<Data = any>(rmName: string): AxiosPromise<Data> {
        return this._instance.get(`services/rm/${rmName}/stock`);
    }

    relocateRMWarehouse<Data = any>(relocationInfo: StockRelocationInfo, rm: string): AxiosPromise<Data> {
        const { srcWarehouse, srcLocation, dstWarehouse, dstLocation, relocatedWeight } = relocationInfo;
        return this._instance.patch(`services/rm/relocation/${rm}`, {
            srcWarehouse,
            srcLocation,
            dstWarehouse,
            dstLocation,
            relocatedWeight,
        });
    }
}

export default StocksController;
