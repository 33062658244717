import { LogoutAction } from "../rootReducer/types"

export interface APIMessageInfoState {
    message: string;
    variant: string;
}

export interface APIMessageReducerType {
    message: APIMessageInfoState;
}

export const SET_API_MESSAGE = 'SET_API_MESSAGE'

interface SetApiMessageAction {
    type: typeof SET_API_MESSAGE
    payload: APIMessageInfoState
}

export type APIMessageActionTypes = SetApiMessageAction | LogoutAction