import { AppBar, FormControl, Grid, IconButton, Paper, TextField, Toolbar, Tooltip } from '@material-ui/core';
import { FGWarehouseLocationInfo, FGWarehouseLocationStock } from 'redux/reducers/finishedGoods/types';
import React, { Fragment, useEffect, useState } from 'react';
import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import {
    getFGStockByWarehouseLocationAction,
    getFGWarehouseLocationAction,
} from 'redux/reducers/finishedGoods/actions';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import FinishedGoodWarehouseStockListTable from './FinishedGoodWarehouseStockListTable';
import SelectComponent from 'components/common/Select';
import { finishedGoodsTypedSelector } from 'redux/reducers/finishedGoods/finishedGoodsReducer';
import moment from 'moment';
import { useDispatch } from 'react-redux';

export interface ContentProps extends WithStyles<typeof styles> {}

const FinishedGoodWarehouseStockView = (props: ContentProps) => {
    const { classes } = props;
    const dispatcher = useDispatch();

    const warehouseLocation = finishedGoodsTypedSelector<Array<FGWarehouseLocationInfo>>(
        (state) => state.finishedGoods.wahouseLocation
    );

    const stocks = finishedGoodsTypedSelector<Array<FGWarehouseLocationStock>>(
        (state) => state.finishedGoods.warehouseLocationStock
    );

    const [warehouse, setWarehouse] = useState('');
    const [location, setLocation] = useState('');
    const [productCode, setProductCode] = useState('');

    const [exportCsv, setExportCsv] = useState(false);

    useEffect(() => {
        if (warehouseLocation.length === 0) {
            dispatcher(getFGWarehouseLocationAction());
        }
    }, [warehouseLocation, dispatcher]);

    const warehouseNameSelect = () => {
        let warehouseList: Array<string> = [];
        warehouseLocation.forEach((info) => {
            if (!warehouseList.some((warehouseId) => warehouseId === info.warehouseId)) {
                warehouseList.push(info.warehouseId);
            }
        });

        return (
            <Fragment>
                <Grid item xs>
                    <SelectComponent
                        title={'Warehouse'}
                        selectList={warehouseList}
                        setter={setWarehouse}
                        currentValue={warehouse}
                    />
                </Grid>
            </Fragment>
        );
    };

    const handleLocationChange = (event: any) => {
        setLocation(event as string);
        setTimeout(() => {
            dispatcher(getFGStockByWarehouseLocationAction(warehouse, event as string));
        }, 100);
    };

    const locationSelect = () => {
        let locationList = warehouseLocation.filter((info) => {
            return info.warehouseId === warehouse;
        });

        return (
            <Fragment>
                <Grid item xs>
                    <SelectComponent
                        title={'Location'}
                        selectList={locationList.map((info) => info.locationId)}
                        setter={handleLocationChange}
                        currentValue={location}
                    />
                </Grid>
            </Fragment>
        );
    };

    const onExportCsv = () => {
        setExportCsv(false);
    };

    const productCodeInputText = () => {
        return (
            <Fragment>
                <Grid item xs>
                    <FormControl fullWidth>
                        <TextField
                            fullWidth
                            margin='dense'
                            style={{ minWidth: 100, marginLeft: 10 }}
                            type='text'
                            label='Product Code'
                            value={productCode}
                            onChange={(event) => {
                                setProductCode(event.target.value);
                            }}
                        />
                    </FormControl>
                </Grid>
            </Fragment>
        );
    };
    const [exportCsvFileName, setExportCsvFileName] = useState('');
    const renderWarehouseExportButton = () => {
        return (
            <Grid item>
                <Tooltip title='Export to CSV'>
                    <IconButton
                        onClick={() => {
                            let csvName = 'FGWarehouseStock';
                            if (warehouse) {
                                csvName += '-' + warehouse;
                            }
                            if (location) {
                                csvName += '-' + location;
                            }
                            setExportCsvFileName(`${csvName}-${moment(new Date()).format('MM-DD-YYYY:HH:mm:ss')}`);
                            setExportCsv(true);
                        }}>
                        <FileCopyIcon className={classes.block} color='inherit' />
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    };

    const renderWarehouseHeader = () => {
        return (
            <Grid container spacing={3} alignItems='center'>
                {warehouseNameSelect()}
                {locationSelect()}
                {productCodeInputText()}
                {renderWarehouseExportButton()}
            </Grid>
        );
    };

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.searchBar} position='static' color='default' elevation={0}>
                <Toolbar>{renderWarehouseHeader()}</Toolbar>
            </AppBar>
            <FinishedGoodWarehouseStockListTable
                data={stocks}
                exportCsv={exportCsv}
                onExportCsv={onExportCsv}
                exportCsvFileName={exportCsvFileName}
            />
        </Paper>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: '99%',
            margin: 'auto',
            overflow: 'hidden',
            alignContent: 'center',
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: 'rgb(31,49,69)',
            alignSelf: 'center',
        },
        contentWrapper: {
            margin: '0px 16px',
        },
    });

export default withStyles(styles)(FinishedGoodWarehouseStockView);
