import { Button, Checkbox, Grid, TextField, Typography } from '@material-ui/core';
import {
    DateBatchNumberPair,
    DateTimePair,
    FormulaValuePair,
    PremixInProductionOrderInfo,
    PremixInfo,
    PremixInfoList,
    ProductionOrderInfoRequest,
    RawMaterialInProductionOrderInfo,
} from 'redux/reducers/premix/types';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { Fragment, useEffect, useState } from 'react';
import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import { addProductionOrderAction, getAllPremixAction } from 'redux/reducers/premix/actions';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import Alert from '@material-ui/lab/Alert';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteRowRenderer from 'components/common/renderers/DeleteRowRenderer';
import MomentUtils from '@date-io/moment';
import NumericEditor from 'components/common/editors/NumericEditor';
import { RowNode } from 'ag-grid';
import SelectComponent from 'components/common/Select';
import TableGrid from 'components/common/TableGrid';
import moment from 'moment';
import { premixTypedSelector } from 'redux/reducers/premix/premixReducer';
import { useDispatch } from 'react-redux';

export interface ContentProps extends WithStyles<typeof styles> {
    onFormClose: () => void;
    onAddFormSetData: () => void;
    onCancel: () => void;
}

interface ProductionOrderForDisplay {
    name: string;
    id: number;
    isPremixName?: boolean;
    isPremixContent?: boolean;
    isPremixSubtotal?: boolean;
    isEditable: boolean;
    quantity?: string;
    isUsed?: Array<boolean>;
    totalPrep?: string;
    weight?: number;
    subTotal?: number;
}

interface TimeForDisplay {
    emptyCell: string;
    name: string;
    time?: Array<string>;
}

interface BatchForDisplay {
    emptyCell: string;
    name: string;
    batch?: Array<number>;
}
let _gDisplayedPremixList: Array<ProductionOrderForDisplay> = [];
const ProductionOrderAddView = (props: ContentProps) => {
    const { classes, onFormClose, onAddFormSetData, onCancel } = props;
    const [newFeedType, setNewFeedType] = useState('');
    const [newFeedTypeHelper, setNewFeedTypeHelper] = useState('');
    const [newLine, setNewLine] = useState('');
    const [newLineHelper, setNewLineHelper] = useState('');
    const [newDie, setNewDie] = useState('');
    const [newDate, setNewDate] = useState<Date>(new Date());
    const [newPremixChecker, setNewPremixChecker] = useState('');
    const [newShift, setNewShift] = useState('');
    const [newRevisionNumber, setNewRevisionNumber] = useState(0);
    const [newPreparedBy, setNewPreparedBy] = useState('');
    const [newPreparedByHelper, setNewPreparedByHelper] = useState('');
    const [newDatePrepared, setNewDatePrepared] = useState<Date>(new Date());
    const [newReceivedBy, setNewReceivedBy] = useState('');
    const [newDateReceived, setNewDateReceived] = useState<Date | undefined>(undefined);
    const [newFormulationInput, setNewFormulationInput] = useState<Array<FormulaValuePair>>([]);
    const [newDisplayedPremixList, setNewDisplayedPremixList] = useState<Array<ProductionOrderForDisplay>>([]);
    const [newTimeList, setNewTimeList] = useState<Array<TimeForDisplay>>([{ emptyCell: ' ', name: 'Time', time: [] }]);
    const [newBatchList, setNewBatchList] = useState<Array<BatchForDisplay>>([
        { emptyCell: ' ', name: 'Batch Number', batch: [] },
    ]);
    const [newTotalWeight, setNewTotalWeight] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const dispatcher = useDispatch();
    const [premixList, setPremixList] = useState<Array<PremixInfo> | []>([]);
    const [selectedPremixName, setSelectedPremixName] = useState('');
    const [premixNameList, setPremixNameList] = useState<Array<string>>([]);
    const [isAcquired, setIsAcquired] = useState(false);
    const premixListResponse = premixTypedSelector<PremixInfoList>((state) => state.premix.premixList);

    const topOptions: any = {
        alignedGrids: [],
        defaultColDef: {
            editable: true,
            sortable: true,
            resizable: true,
            filter: true,
            flex: 1,
            minWidth: 100,
        },
    };
    const bottomOptions1: any = {
        alignedGrids: [],
        defaultColDef: {
            editable: true,
            sortable: true,
            resizable: true,
            filter: true,
            flex: 1,
            minWidth: 100,
        },
    };

    const bottomOptions2: any = {
        alignedGrids: [],
        defaultColDef: {
            editable: true,
            sortable: true,
            resizable: true,
            filter: true,
            flex: 1,
            minWidth: 100,
        },
    };

    topOptions.alignedGrids.push(bottomOptions1, bottomOptions2);
    bottomOptions1.alignedGrids.push(topOptions, bottomOptions2);
    bottomOptions2.alignedGrids.push(topOptions, bottomOptions1);

    useEffect(() => {
        if (premixListResponse.premixes.length > 0) {
            setPremixList(premixListResponse.premixes);
            setPremixNameList(premixListResponse.premixes.map((premix) => premix.premixName));
        } else if (!isAcquired) {
            setIsAcquired(true);
            dispatcher(getAllPremixAction());
            setNewTimeList([{ emptyCell: ' ', name: 'Time', time: [] }]);
            setNewBatchList([{ emptyCell: ' ', name: 'Batch Number', batch: [] }]);
        }
    }, [premixListResponse, isAcquired, dispatcher]);

    useEffect(() => {
        if (newDisplayedPremixList) {
            _gDisplayedPremixList = [...newDisplayedPremixList];
        }
    }, [newDisplayedPremixList]);

    let FormulationInputColumnDefs = [
        {
            headerName: 'Action',
            width: 100,
            cellRenderer: 'deleteRowRenderer',
            suppressSizeToFit: true,
        },
        {
            headerName: 'Formulation Input',
            field: 'name',
            minWidth: 200,
            filter: 'agTextColumnFilter',
            headerTooltip: 'Name',
            editable: true,
        },
        {
            headerName: 'Value',
            field: 'value',
            minWidth: 100,
            filter: 'agTextColumnFilter',
            headerTooltip: 'Value',
            editable: true,
            cellEditor: 'numericEditor',
        },
    ];

    useEffect(() => {
        if (
            newFeedType !== '' ||
            newLine !== '' ||
            newDie !== '' ||
            newPremixChecker !== '' ||
            newShift !== '' ||
            newPreparedBy !== '' ||
            newReceivedBy !== '' ||
            newDateReceived !== undefined ||
            newFormulationInput.length > 0
        ) {
            onAddFormSetData();
        }
    }, [
        newFeedType,
        newLine,
        newDie,
        newPremixChecker,
        newShift,
        newPreparedBy,
        newReceivedBy,
        newDateReceived,
        newFormulationInput,
        onAddFormSetData,
    ]);

    const onCellValueChangedFormulationInput = (event: any) => {
        let rowData: Array<FormulaValuePair> = [];
        event.api.forEachNode((node: RowNode) => rowData.push(node.data));
        setNewFormulationInput(rowData);
    };

    const onRowDataUpdatedFormulationInput = (event: any) => {
        let rowData: Array<FormulaValuePair> = [];
        event.api.forEachNode((node: RowNode) => rowData.push(node.data));
        setNewFormulationInput(rowData);
    };

    const isFormulationListValid = () => {
        let ret = true;

        // Copy the contents of the formulation list but remove empty rows
        let filteredFormulationList: Array<FormulaValuePair> = [];
        newFormulationInput.forEach((element) => {
            // if at least one attribute is not undefined
            if (element.name || element.value) {
                let addItem = {
                    name: element.name ? element.name! : '',
                    value: element.value ? element.value! : 0,
                };
                filteredFormulationList = [...filteredFormulationList, addItem];
            }
        });

        // Check if empty list
        if (filteredFormulationList.length === 0) {
            setErrorMessage('No formulation input added');
            return false;
        }

        // Check for duplicate formulation name
        let seen = new Set();
        var hasDuplicates = filteredFormulationList.some(function(currentItem) {
            return seen.size === seen.add(currentItem.name).size;
        });

        if (!hasDuplicates) {
            // Check for empty value
            for (let i = 0; i < filteredFormulationList.length; i++) {
                let element = filteredFormulationList[i];
                let rowItemName = element.name;
                if (rowItemName === '') {
                    setErrorMessage('Please set the name for : Formulation ' + (i + 1));
                    ret = false;
                    break;
                }
                if (element.value <= 0) {
                    setErrorMessage('Please set the value for ' + rowItemName);
                    ret = false;
                    break;
                }
            }
        } else {
            setErrorMessage('Duplicate formulation input detected');
            ret = false;
        }

        return ret;
    };

    const resetHelpers = () => {
        setNewFeedTypeHelper('');
        setNewLineHelper('');
        setNewPreparedByHelper('');
        setErrorMessage('');
    };

    const checkInputValidity = () => {
        let isFormulationValid = isFormulationListValid();
        if (
            newFeedType === '' ||
            newLine === '' ||
            newPreparedBy === '' ||
            isFormulationValid === false ||
            newDisplayedPremixList.length <= 0
        ) {
            if (newFeedType === '') setNewFeedTypeHelper('Please set value');
            if (newLine === '') setNewLineHelper('Please set value');
            if (newPreparedBy === '') setNewPreparedByHelper('Please set value');
            if (newDisplayedPremixList.length <= 0) setErrorMessage('Please add premix to the list');

            return false;
        }
        return true;
    };

    const convertDisplayedPremixes = () => {
        let converted: Array<PremixInProductionOrderInfo> = [];
        let groupingsInDisplay = newDisplayedPremixList.map((premix) => premix.id);
        groupingsInDisplay = groupingsInDisplay.filter((item, i, ar) => ar.indexOf(item) === i);

        groupingsInDisplay.forEach((element) => {
            let id = element;
            let groupById = newDisplayedPremixList.filter((premix) => premix.id === id);
            let addPremix: PremixInProductionOrderInfo = {
                premixName: '',
                rawMaterials: [],
                rmSubtotal: 0,
            };
            groupById.forEach((rowInTable) => {
                if (rowInTable.isPremixName) {
                    addPremix.premixName = rowInTable.name;
                } else if (rowInTable.isPremixSubtotal) {
                    addPremix.rmSubtotal = rowInTable.subTotal!;
                } else if (rowInTable.isPremixContent) {
                    let materialName = rowInTable.name;
                    let materialWeight = rowInTable.weight!;
                    let totalPrep = rowInTable.totalPrep ? rowInTable.totalPrep : '';
                    let datesChecked: Array<string> = [];
                    if (rowInTable.isUsed) {
                        for (let i = 0; i < rowInTable.isUsed.length; i++) {
                            if (rowInTable.isUsed[i]) datesChecked.push((i + 1).toString());
                        }
                    }
                    let matInProd: RawMaterialInProductionOrderInfo = {
                        rmCode: materialName,
                        rmWeight: materialWeight,
                        rmDatesChecked: datesChecked,
                        rmTotPrep: totalPrep,
                    };
                    addPremix.rawMaterials.push(matInProd);
                }
            });
            converted.push(addPremix);
        });

        return converted;
    };

    const convertDateTime = () => {
        let converted: Array<DateTimePair> = [];
        // expect only 1 time list
        newTimeList.forEach((timeList) => {
            for (let i = 0; i < 30; i++) {
                if (timeList.time![i]) {
                    if (timeList.time![i] !== '') {
                        converted = [...converted, { date: (i + 1).toString(), value: timeList.time![i] }];
                    }
                }
            }
        });

        return converted;
    };

    const convertBatchNumber = () => {
        let converted: Array<DateBatchNumberPair> = [];
        // expect only 1 batch list
        newBatchList.forEach((batchList) => {
            for (let i = 0; i < 30; i++) {
                if (batchList.batch![i]) {
                    converted = [...converted, { date: (i + 1).toString(), value: Number(batchList.batch![i]) }];
                }
            }
        });

        return converted;
    };

    const addNewProductionOrder = () => {
        if (checkInputValidity()) {
            let filteredFormulationList: Array<FormulaValuePair> = [];
            newFormulationInput.forEach((element) => {
                // if at least one attribute is not undefined
                if (element.name || element.value) {
                    let addItem = {
                        name: element.name ? element.name! : '',
                        value: element.value ? element.value! : 0,
                    };
                    filteredFormulationList = [...filteredFormulationList, addItem];
                }
            });
            let strDate = moment(newDatePrepared!).format('YYYY-MM-DD');
            let dateYearMonthStr = moment(newDate!).format('YYYY-MM');
            let dateReceived = '';
            if (newDateReceived) dateReceived = moment(newDateReceived).format('YYYY-MM-DD');

            let convertedPremixes = convertDisplayedPremixes();

            let convertedDateTime = convertDateTime();

            let convertedBatchNumber = convertBatchNumber();

            let addProductionOrderRequest: ProductionOrderInfoRequest = {
                feedType: newFeedType,
                line: newLine,
                die: newDie,
                date: dateYearMonthStr,
                premixChecker: newPremixChecker,
                shift: newShift,
                revisionNumber: newRevisionNumber,
                preparedBy: newPreparedBy,
                datePrepared: strDate,
                receivedBy: newReceivedBy,
                dateReceived: dateReceived,
                formulationInput: newFormulationInput,
                premixes: convertedPremixes,
                rmDateTime: convertedDateTime,
                batchNumber: convertedBatchNumber,
                rmTotalWeight: newTotalWeight,
            };
            dispatcher(addProductionOrderAction(addProductionOrderRequest));
            resetHelpers();
            onFormClose();
        }
    };

    const renderTable1 = () => {
        return (
            <Fragment>
                <Grid item container>
                    <Grid item xs={2}>
                        <TextField
                            label={'Feed Type'}
                            value={newFeedType}
                            helperText={newFeedTypeHelper}
                            error={newFeedTypeHelper !== ''}
                            onChange={(event) => {
                                setNewFeedType(event.target.value);
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label={'Line'}
                            value={newLine}
                            helperText={newLineHelper}
                            error={newLineHelper !== ''}
                            onChange={(event) => {
                                setNewLine(event.target.value);
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label={'DIE'}
                            value={newDie}
                            onChange={(event) => {
                                setNewDie(event.target.value);
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <DatePicker
                            disableFuture={false}
                            value={newDate}
                            views={['year', 'month']}
                            onChange={(date) => {
                                setNewDate(moment(date).toDate());
                            }}
                            format='MMM, YYYY'
                            inputVariant='standard'
                            onClose={() => {}}
                            label={'Date'}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label={'Premix Checker'}
                            value={newPremixChecker}
                            onChange={(event) => {
                                setNewPremixChecker(event.target.value);
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label={'Shift'}
                            value={newShift}
                            onChange={(event) => {
                                setNewShift(event.target.value);
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Fragment>
        );
    };

    const renderTable2 = () => {
        return (
            <Fragment>
                <Grid item container>
                    <Grid item xs={2}>
                        <TextField
                            label={'Revision #'}
                            value={newRevisionNumber}
                            type={'number'}
                            onChange={(event: any) => {
                                setNewRevisionNumber(event.target.value);
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label={'Prepared By'}
                            value={newPreparedBy}
                            helperText={newPreparedByHelper}
                            error={newPreparedByHelper !== ''}
                            onChange={(event) => {
                                setNewPreparedBy(event.target.value);
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <DatePicker
                            disableFuture={false}
                            value={newDatePrepared}
                            onChange={(date) => {
                                setNewDatePrepared(moment(date).toDate());
                            }}
                            format='MMM DD, YYYY'
                            inputVariant='standard'
                            onClose={() => {}}
                            label={'Date Prepared'}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label={'Received By'}
                            value={newReceivedBy}
                            onChange={(event) => {
                                setNewReceivedBy(event.target.value);
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DatePicker
                            disableFuture={false}
                            value={newDateReceived ? newDateReceived : null}
                            emptyLabel={'--:--'}
                            onChange={(date) => {
                                setNewDateReceived(moment(date).toDate());
                            }}
                            format='MMM DD, YYYY'
                            inputVariant='standard'
                            onClose={() => {}}
                            label={'Date Received'}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Fragment>
        );
    };

    const renderFormulationInputTable = () => {
        return (
            <Fragment>
                <Grid item xs={4}>
                    <Typography variant='h6' component='h4' align='center'>
                        {'Formulation Input'}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TableGrid
                        rowData={newFormulationInput}
                        columnDefs={FormulationInputColumnDefs}
                        height={180}
                        showAddRowButton={true}
                        onCellValueChanged={onCellValueChangedFormulationInput}
                        onRowDataUpdated={onRowDataUpdatedFormulationInput}
                        frameworkComponents={{
                            deleteRowRenderer: DeleteRowRenderer,
                            numericEditor: NumericEditor,
                        }}
                    />
                </Grid>
            </Fragment>
        );
    };

    const handleSelectedPremix = (event: any) => {
        setSelectedPremixName(event as string);
    };

    const addPremixToList = () => {
        if (selectedPremixName !== '') {
            let selected = premixList.find((premix) => premix.premixName === selectedPremixName);
            if (selected) {
                let currentDisplay = [...newDisplayedPremixList];
                currentDisplay = [
                    ...currentDisplay,
                    {
                        name: selected.premixName,
                        isEditable: false,
                        id: newDisplayedPremixList.length,
                        isPremixName: true,
                    },
                ];
                let subTotal = 0;
                selected.rawMaterials.forEach((element) => {
                    subTotal = subTotal + Number(element.rmWeight);
                    let strQty = element.rmWeight.toString() + ' kg';
                    let dateChecked: Array<boolean> = [];
                    for (let i = 0; i < 30; i++) {
                        dateChecked[i] = false;
                    }
                    currentDisplay = [
                        ...currentDisplay,
                        {
                            name: element.rmCode,
                            quantity: strQty,
                            isEditable: true,
                            isUsed: dateChecked,
                            id: newDisplayedPremixList.length,
                            weight: Number(element.rmWeight),
                            isPremixContent: true,
                        },
                    ];
                });
                let totalWeight = newTotalWeight + subTotal;
                setNewTotalWeight(totalWeight);
                let subTotalStr = subTotal.toString() + ' kg';
                currentDisplay = [
                    ...currentDisplay,
                    {
                        name: 'SUB-TOTAL',
                        quantity: subTotalStr,
                        isEditable: false,
                        id: newDisplayedPremixList.length,
                        isPremixSubtotal: true,
                        subTotal: subTotal,
                    },
                ];
                setNewDisplayedPremixList(currentDisplay);
            }
        }
    };

    const renderPremixSelection = () => {
        return (
            <Fragment>
                <Grid item xs={3}>
                    <SelectComponent
                        title={'Choose Premix'}
                        selectList={premixNameList}
                        setter={handleSelectedPremix}
                        currentValue={selectedPremixName}
                        variant={'standard'}
                    />
                </Grid>
                <Grid item xs={3} alignItems={'flex-end'} className={classes.buttonJustifiedEnd}>
                    <Button
                        disabled={selectedPremixName === ''}
                        onClick={() => {
                            addPremixToList();
                        }}
                        color='primary'
                        variant='contained'>
                        Add to list
                    </Button>
                </Grid>
            </Fragment>
        );
    };

    const checkedDateRenderer = (rowData: any) => {
        let colId = Number(rowData!.column!.colDef!.headerName);
        return (
            rowData!.data!.isEditable === true && (
                <Checkbox
                    checked={rowData!.data!.isUsed[colId - 1]}
                    onChange={() => {
                        rowData!.data!.isUsed[colId - 1] = !rowData!.data!.isUsed[colId - 1];
                    }}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
            )
        );
    };

    const deleteButtonRenderer = (rowData: any) => {
        return (
            rowData!.data!.isPremixName === true && (
                <Button
                    onClick={() => {
                        let currentDisplay = _gDisplayedPremixList.filter((entry) => entry.id !== rowData!.data!.id);
                        setNewDisplayedPremixList(currentDisplay);
                        let total = 0;
                        currentDisplay.forEach((element) => {
                            if (element.isPremixContent) {
                                total = total + Number(element.weight);
                            }
                        });
                        setNewTotalWeight(total);
                    }}
                    color='primary'
                    variant='contained'
                    fullWidth={true}>
                    Delete
                </Button>
            )
        );
    };

    const renderLargeTable = () => {
        interface ColDef {
            headerName: string;
            field?: string;
            minWidth?: number;
            width?: number;
            filter?: string;
            headerTooltip?: string;
            valueGetter?: () => string;
            pinned?: string;
            cellRendererFramework?: any;
            editable?: boolean;
            suppressSizeToFit?: boolean;
        }
        let columnDefsForDisplay: Array<ColDef> = [
            {
                headerName: 'Action',
                field: 'name',
                width: 100,
                filter: 'agTextColumnFilter',
                headerTooltip: 'Action',
                pinned: 'left',
                cellRendererFramework: deleteButtonRenderer,
                suppressSizeToFit: true,
            },
            {
                headerName: 'Raw Materials',
                field: 'name',
                minWidth: 300,
                filter: 'agTextColumnFilter',
                headerTooltip: 'Raw Materials',
                pinned: 'left',
            },
            {
                headerName: 'Quantity',
                field: 'quantity',
                minWidth: 150,
                filter: 'agTextColumnFilter',
                headerTooltip: 'Quantity',
                pinned: 'left',
            },
        ];

        for (let i = 0; i < 30; i++) {
            let addColumn = {
                headerName: (i + 1).toString(),
                minWidth: 80,
                cellRendererFramework: checkedDateRenderer,
                editable: true,
            } as ColDef;
            columnDefsForDisplay = [...columnDefsForDisplay, addColumn];
        }
        let totalPrepColumn = {
            headerName: 'Total Prep',
            field: 'totalPrep',
            minWidth: 150,
            filter: 'agTextColumnFilter',
            headerTooltip: 'Total Prep',
            editable: true,
        } as ColDef;
        columnDefsForDisplay = [...columnDefsForDisplay, totalPrepColumn];

        return (
            <Fragment>
                <Grid item xs={12}>
                    <TableGrid
                        rowData={newDisplayedPremixList}
                        columnDefs={columnDefsForDisplay}
                        height={300}
                        suppressHorizontalScroll={true}
                        gridOptions={topOptions}
                    />
                </Grid>
            </Fragment>
        );
    };

    const renderTotalWeight = () => {
        return (
            <Fragment>
                <Grid item xs={12}>
                    <TextField
                        label={'Total'}
                        variant={'outlined'}
                        value={newTotalWeight}
                        inputProps={{
                            readOnly: true,
                        }}
                    />
                </Grid>
            </Fragment>
        );
    };

    const renderTimeTable = () => {
        interface ColDef {
            headerName: string;
            field?: string;
            minWidth?: number;
            width?: number;
            filter?: string;
            headerTooltip?: string;
            valueGetter?: (param: any) => string;
            valueSetter?: (param: any) => boolean;
            pinned?: string;
            cellRendererFramework?: any;
        }
        let columnDefsForDisplay: Array<ColDef> = [
            {
                headerName: 'Action',
                field: 'emptyCell',
                minWidth: 100,
                filter: 'agTextColumnFilter',
                headerTooltip: 'Action',
                pinned: 'left',
            },
            {
                headerName: 'Raw Materials',
                field: 'name',
                minWidth: 300,
                filter: 'agTextColumnFilter',
                headerTooltip: 'Raw Materials',
                pinned: 'left',
            },
            {
                headerName: 'Quantity',
                field: 'quantity',
                minWidth: 150,
                filter: 'agTextColumnFilter',
                headerTooltip: 'Quantity',
                pinned: 'left',
            },
        ];

        for (let i = 0; i < 30; i++) {
            let addColumn = {
                headerName: (i + 1).toString(),
                minWidth: 80,
                valueGetter: (param: any) => {
                    let colId = Number(param!.column!.colDef!.headerName);
                    return param!.data!.time[colId - 1];
                },
                valueSetter: (param: any) => {
                    let colId = Number(param!.column!.colDef!.headerName);
                    param!.data!.time[colId - 1] = param!.newValue;
                    return true;
                },
                editable: true,
            } as ColDef;
            columnDefsForDisplay = [...columnDefsForDisplay, addColumn];
        }
        let totalPrepColumn = {
            headerName: 'Total Prep',
            field: 'totalPrep',
            minWidth: 150,
            filter: 'agTextColumnFilter',
            headerTooltip: 'Total Prep',
        } as ColDef;
        columnDefsForDisplay = [...columnDefsForDisplay, totalPrepColumn];

        return (
            <Fragment>
                <Grid item xs={12}>
                    <TableGrid
                        rowData={newTimeList}
                        columnDefs={columnDefsForDisplay}
                        height={60}
                        headerHeight={0}
                        suppressHorizontalScroll={true}
                        gridOptions={bottomOptions1}
                    />
                </Grid>
            </Fragment>
        );
    };

    const renderBatchTable = () => {
        interface ColDef {
            headerName: string;
            field?: string;
            minWidth?: number;
            width?: number;
            filter?: string;
            headerTooltip?: string;
            valueGetter?: (param: any) => string;
            valueSetter?: (param: any) => boolean;
            pinned?: string;
            cellEditor?: any;
        }
        let columnDefsForDisplay: Array<ColDef> = [
            {
                headerName: 'Action',
                field: 'emptyCell',
                minWidth: 100,
                filter: 'agTextColumnFilter',
                headerTooltip: 'Action',
                pinned: 'left',
            },
            {
                headerName: 'Raw Materials',
                field: 'name',
                minWidth: 300,
                filter: 'agTextColumnFilter',
                headerTooltip: 'Raw Materials',
                pinned: 'left',
            },
            {
                headerName: 'Quantity',
                field: 'quantity',
                minWidth: 150,
                filter: 'agTextColumnFilter',
                headerTooltip: 'Quantity',
                pinned: 'left',
            },
        ];

        for (let i = 0; i < 30; i++) {
            let addColumn = {
                headerName: (i + 1).toString(),
                minWidth: 80,
                valueGetter: (param: any) => {
                    if (param) {
                        let colId = Number(param!.column!.colDef!.headerName);
                        return param!.data!.batch[colId - 1] ? param!.data!.batch[colId - 1].toString() : '';
                    }
                    return '';
                },
                valueSetter: (param: any) => {
                    let colId = Number(param!.column!.colDef!.headerName);
                    param!.data!.batch[colId - 1] = param!.newValue;
                    return true;
                },
                cellEditor: 'numericEditor',
                editable: true,
            } as ColDef;
            columnDefsForDisplay = [...columnDefsForDisplay, addColumn];
        }
        let totalPrepColumn = {
            headerName: 'Total Prep',
            field: 'totalPrep',
            minWidth: 150,
            filter: 'agTextColumnFilter',
            headerTooltip: 'Total Prep',
        } as ColDef;
        columnDefsForDisplay = [...columnDefsForDisplay, totalPrepColumn];

        return (
            <Fragment>
                <Grid item xs={12}>
                    <TableGrid
                        rowData={newBatchList}
                        columnDefs={columnDefsForDisplay}
                        frameworkComponents={{
                            numericEditor: NumericEditor,
                        }}
                        height={60}
                        headerHeight={0}
                        gridOptions={bottomOptions2}
                    />
                </Grid>
            </Fragment>
        );
    };

    const renderProductionOrderDetails = () => {
        return (
            <Fragment>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Grid container spacing={2} alignItems='center'>
                        <Grid item container xs={6} spacing={5}>
                            {renderTable1()}
                            {renderTable2()}
                        </Grid>
                        <Grid item xs={2} />
                        <Grid item xs={4}>
                            {renderFormulationInputTable()}
                        </Grid>
                        <Grid item container xs={12} spacing={2}>
                            {renderPremixSelection()}
                        </Grid>
                        <Grid item container xs={12} spacing={2}>
                            {renderLargeTable()}
                        </Grid>
                        <Grid item container xs={12} spacing={2}>
                            {renderTotalWeight()}
                        </Grid>
                        <Grid item container xs={12} spacing={2}>
                            {renderTimeTable()}
                        </Grid>
                        <Grid item container xs={12} spacing={2}>
                            {renderBatchTable()}
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                onClick={() => {
                                    resetHelpers();
                                    addNewProductionOrder();
                                }}
                                color='primary'
                                variant='contained'
                                fullWidth={true}
                                startIcon={<AddCircleIcon />}>
                                Create
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                onClick={onCancel}
                                color='primary'
                                variant='contained'
                                startIcon={<CancelIcon />}
                                fullWidth={true}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            {errorMessage !== '' && <Alert severity='error'>{errorMessage}</Alert>}
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>
            </Fragment>
        );
    };

    return <div style={{ padding: 10 }}>{renderProductionOrderDetails()}</div>;
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: '99%',
            margin: 'auto',
            overflow: 'hidden',
            alignContent: 'center',
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: 'rgb(31,49,69)',
            alignSelf: 'center',
        },
        contentWrapper: {
            margin: '0px 0px',
        },
        buttonJustifiedEnd: {
            paddingTop: '10px',
        },
    });

export default withStyles(styles)(ProductionOrderAddView);
