import { Card, CardContent, Grid, Paper, Typography } from '@material-ui/core';
import { LocationInfo, RawMaterial } from 'redux/reducers/rmShipment/types';
import React, { useEffect, useState } from 'react';
import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core/styles';

import BarGraph from 'components/common/BarGraph';
import DoughnutChart from 'components/common/DoughnutChart';
import ListItems from 'components/common/ListItems';
import { getWarehouseInfoAction } from 'redux/reducers/rmShipment/actions';
import { rmShipmentTypedSelector } from 'redux/reducers/rmShipment/rmShipmentReducer';
import { useDispatch } from 'react-redux';

export interface ContentProps extends WithStyles<typeof styles> {}

const WarehouseDashboard = (props: ContentProps) => {
    const { classes } = props;
    const dispatcher = useDispatch();

    const warehouseInfo = rmShipmentTypedSelector<Array<LocationInfo>>((state) => state.rmshipment.warehouseInfo);
    const rmList = rmShipmentTypedSelector<Array<RawMaterial>>((state) => state.rmshipment.rmList);

    const [warehouseList, setWarehouseList] = useState<Array<string>>([]);
    const [locationList, setLocationList] = useState<Array<string>>([]);
    const [locationListData, setLocationListData] = useState<Array<number>>([]);
    const [selectedWarehouse, setSelectedWarehouse] = useState('');
    const [selectedLocation, setSelectedLocation] = useState('');
    const [rmName, setRmName] = useState('');
    const [capacity, setCapacity] = useState(0);
    const [occupied, setOccupied] = useState(0);

    useEffect(() => {
        if (warehouseInfo.length === 0) {
            dispatcher(getWarehouseInfoAction());
        } else {
            let whList = new Set<string>();
            let locList = new Set<string>();
            warehouseInfo.forEach((item) => {
                if (!whList.has(item.warehouseId)) {
                    whList.add(item.warehouseId);
                }
                if (warehouseInfo[0].warehouseId === item.warehouseId) {
                    if (!locList.has(item.locationId)) {
                        locList.add(item.locationId);
                    }
                }
            });
            setWarehouseList([...whList]);
            setLocationList([...locList]);
            setSelectedWarehouse(warehouseInfo[0].warehouseId);
            setSelectedLocation(warehouseInfo[0].locationId);
        }
    }, [warehouseInfo, dispatcher]);

    useEffect(() => {}, [warehouseList, locationList]);

    useEffect(() => {
        let locList = new Set<string>();
        let locListData: Array<number> = [];
        warehouseInfo.forEach((item) => {
            if (selectedWarehouse === item.warehouseId) {
                if (!locList.has(item.locationId)) {
                    locList.add(item.locationId);
                    locListData.push(Math.floor((item.netWeight / item.weightCapacity) * 100 * 100) / 100);
                }
            }
        });
        let finalList = [...locList];
        setLocationList(finalList);
        setSelectedLocation(finalList[0]);
        setLocationListData(locListData);
    }, [selectedWarehouse, warehouseInfo]);

    useEffect(() => {
        warehouseInfo.forEach((item) => {
            if (selectedLocation === item.locationId) {
                setRmName('No Raw Material Assigned');
                rmList.forEach((rm) => {
                    if (rm.rmCode === item.rmAssignment) setRmName(rm.rmName);
                });
                setCapacity(item.weightCapacity);
                setOccupied(item.netWeight);
            }
        });
    }, [selectedLocation, rmList, warehouseInfo]);

    const cardEntry = (title: string, data: string | number) => {
        return (
            <Card>
                <CardContent>
                    <Typography style={{ color: '#009fdc' }} align='center' variant='subtitle1'>
                        {title}
                    </Typography>
                    <Typography variant='h6'>{'\n'}</Typography>
                    <Typography style={{ color: '#009fdc' }} align='center' variant='h5'>
                        {data}
                    </Typography>
                </CardContent>
            </Card>
        );
    };

    useEffect(() => {}, [locationList, locationListData, selectedLocation]);

    const renderContent = () => {
        return (
            <div className={classes.contentWrapper}>
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <ListItems
                            title='Warehouse'
                            dataList={warehouseList}
                            selected={selectedWarehouse}
                            setter={(data) => setSelectedWarehouse(data)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <ListItems
                            title='Location'
                            dataList={locationList}
                            selected={selectedLocation}
                            setter={(data) => setSelectedLocation(data)}
                        />
                    </Grid>
                    <Grid item xs={4} spacing={2} style={{ paddingTop: 20 }}>
                        <DoughnutChart
                            legend={`${selectedWarehouse} - ${selectedLocation}`}
                            labels={[
                                `Occupied ${Math.round((occupied / capacity) * 100)}%`,
                                `Available ${Math.round(((capacity - occupied) / capacity) * 100)}%`,
                            ]}
                            data={[occupied, capacity - occupied]}
                            height={240}
                            backgroundColor={['#FF6384', '#36A2EB']}
                            hoverBackgroundColor={['#FF6384', '#36A2EB']}
                        />
                    </Grid>
                    <Grid item xs={4} spacing={2} style={{ paddingTop: 20 }}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                {cardEntry('Raw Material', rmName)}
                            </Grid>
                            <Grid item xs={6}>
                                {cardEntry('Weight Capacity', capacity)}
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                {cardEntry('Occupied Capacity', occupied)}
                            </Grid>
                            <Grid item xs={6}>
                                {cardEntry('Available Capacity', capacity - occupied)}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <BarGraph
                            legend={'Percent Occupied'}
                            suggestedMax={100}
                            labels={locationList}
                            data={locationListData}
                            selected={selectedLocation}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    };

    return (
        <Paper className={classes.paper}>
            {/* {renderWarehouseHeader()} */}
            {renderContent()}
        </Paper>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: '99%',
            margin: 'auto',
            overflow: 'hidden',
            padding: 5,
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: 'rgb(31,49,69)',
        },
        contentWrapper: {
            margin: '0px 0px',
        },
    });

export default withStyles(styles)(WarehouseDashboard);
