import ApiController from './api-controller';
import { AxiosPromise } from 'axios';

import { PostSingleUserParams } from './userRegistration-controller'

export interface LoginParams {
    email: string;
    password: string;
}

export interface NewPasswordRequiredParams {
    ChallengeName: string;
    Session: string;
    Username: string;
    NewPassword: string;
}

export interface RefreshTokenParams {
    email: string;
    refresh_token: string;
}

class SessionController extends ApiController {
    login<Data = any>(parameters: LoginParams): AxiosPromise<Data> {
        return this._instance.post('/auth/login', parameters);
    }

    newPassword<Data = any>(parameters: NewPasswordRequiredParams): AxiosPromise<Data> {
        return this._instance.post('/auth/new-password', parameters);
    }

    refreshToken<Data = any>(parameters: RefreshTokenParams): AxiosPromise<Data> {
        return this._instance.post('/auth/refresh_token', parameters);
    }

    postRegisterSingleUser<Data = any>(parameters: PostSingleUserParams): AxiosPromise<Data> {
        return this._instance.post(`/auth/users`, parameters);
    }

    postEnableSingleUser<Data = any>(parameters: PostSingleUserParams): AxiosPromise<Data> {
        return this._instance.post(`/auth/users/${parameters.email}/enable-user`, {
            userRole: parameters.userRole
        });
    }

    postDisableSingleUser<Data = any>(parameters: PostSingleUserParams): AxiosPromise<Data> {
        return this._instance.post(`/auth/users/${parameters.email}/disable-user`, {
            userRole: parameters.userRole
        });
    }

}

export default SessionController;