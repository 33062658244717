import React, { Fragment, useState } from 'react';

import RegisterMultipleUsersView from 'views/AccountManagement/RegisterUsers/RegisterMultipleUsersView';
import RegisterSingleUserView from 'views/AccountManagement/RegisterUsers/RegisterSingleUserView';
import RegisterUserHeader from 'views/AccountManagement/RegisterUsers/RegisterUserHeader';

export interface UserListMainProps {
    onDrawerToggle: () => void;
    mainClass: any;
}

const RegisterUserMain = (props: UserListMainProps) => {
    const tabsTitle = [{ id: 'Register Single User' }, { id: 'Register Multiple Users' }];

    const [currentTab, setCurrentTab] = useState(0);
    const onTabsChange = (id: number) => {
        setCurrentTab(id);
    };

    return (
        <Fragment>
            <RegisterUserHeader
                onDrawerToggle={props.onDrawerToggle}
                onTabsChange={onTabsChange}
                tabsTitle={tabsTitle}
            />
            <main className={props.mainClass}>
                {currentTab === 0 && <RegisterSingleUserView />}
                {currentTab === 1 && <RegisterMultipleUsersView />}
            </main>
        </Fragment>
    );
};

export default RegisterUserMain;
