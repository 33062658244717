import { Container, Grid, TextField, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import SelectComponent from 'components/common/Select';
import { RolePermissions } from 'redux/reducers/users/types';
import { registerSingleUserAction } from 'redux/reducers/users/actions';
import { useDispatch } from 'react-redux';

export interface RegisterSingleUserProps {
    submit?: boolean;
    onSubmit?: any;
}

const RegisterSingleUser = (props: RegisterSingleUserProps) => {
    const { submit, onSubmit } = props;
    const dispatcher = useDispatch();

    const [email, setEmail] = useState('');
    const [role, setRole] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');

    useEffect(() => {
        if (submit && submit === true) {
            if (email !== '' && role !== '' && firstName !== '' && lastName !== '') {
                dispatcher(
                    registerSingleUserAction({
                        email: email,
                        userRole: role,
                        userFirstName: firstName,
                        userLastName: lastName,
                    })
                );
                setEmail('');
                setRole('');
                setFirstName('');
                setLastName('');
            }

            if (onSubmit) {
                onSubmit();
            }
        }
    }, [submit, onSubmit]); // eslint-disable-line

    const userInformationFields = [
        { entry: [{ label: 'First Name', setter: setFirstName, value: firstName }] },
        { entry: [{ label: 'Last Name', setter: setLastName, value: lastName }] },
        { entry: [{ label: 'E-mail Address', setter: setEmail, value: email }] },
    ];

    const roleListOptions = () => {
        return (
            <SelectComponent
                title={'Role'}
                selectList={Object.values(RolePermissions)}
                setter={setRole}
                currentValue={role}
            />
        );
    };

    const renderSection = (title: string, entries: Array<any>, align?: 'center') => {
        return (
            <Container style={{ padding: 10 }}>
                <Typography align={align ? align : 'inherit'} color='inherit' variant='h5' component='h6'>
                    {title}
                </Typography>
                {entries.map(({ entry }) => (
                    <Grid container alignItems='center' spacing={1}>
                        {entry.map(({ label, setter, value }: any) => (
                            <TextField
                                fullWidth
                                margin='dense'
                                value={value}
                                label={label}
                                onChange={(event) => setter(event.target.value)}
                            />
                        ))}
                    </Grid>
                ))}
            </Container>
        );
    };

    return (
        <Container>
            {renderSection('User Information', userInformationFields)}
            {roleListOptions()}
        </Container>
    );
};

export default RegisterSingleUser;
