import React, { Fragment } from 'react';

import { Button } from '@material-ui/core';
import { SparePartsTransaction } from 'redux/reducers/spareParts/types';
import TableGrid from 'components/common/TableGrid';
import moment from 'moment';

export interface SparePartsIssuanceListTableProps {
    filterText: string;
    exportCsv?: boolean;
    showMenu: boolean;
    onCloseMenu: any;
    onExportCsv?: any;
    exportCsvFileName?: string;
    onClickedMisNo?: (misNo: string) => void;
    sparePartsTransactionList: Array<SparePartsTransaction>;
}

const SparePartsIssuanceListTable = (props: SparePartsIssuanceListTableProps) => {
    const { filterText, exportCsv, onExportCsv, sparePartsTransactionList, exportCsvFileName } = props;

    const dateRenderer = (date: any) => {
        if (date.value) {
            return moment(date.value).format('MMM DD, YY hh:mm');
        }
        return '';
    };

    const transactionTypeRenderer = (data: { value: string }) => {
        if (data.value) {
            return data.value.toUpperCase();
        }
        return '';
    };

    const transactionItemsRenderer = (data: any) => {
        let itemList = '';
        if (data.value.length > 0) {
            data.value.forEach((item: any, index: number) => {
                if (index > 0) {
                    itemList += ', ';
                }
                itemList = itemList + item.code;
            });
        }
        return itemList;
    };

    const misNoRenderer = (params: { value: string }) => {
        return (
            <Button
                style={{ color: '#009fdc' }}
                onClick={() => {
                    if (props.onClickedMisNo) {
                        props.onClickedMisNo(params.value);
                    }
                }}>
                {params.value}
            </Button>
        );
    };

    const columnDefs = [
        {
            headerName: 'MIS #',
            field: 'transactionId',
            minWidth: 300,
            filter: 'agTextColumnFilter',
            headerTooltip: 'MIS #',
            cellRendererFramework: misNoRenderer,
        },
        {
            headerName: 'Type',
            field: 'transactionType',
            minWidth: 150,
            filter: 'agTextColumnFilter',
            headerTooltip: 'Transaction Type',
            cellRenderer: transactionTypeRenderer,
            getQuickFilterText: transactionTypeRenderer,
        },
        {
            headerName: 'Spare Parts Code',
            field: 'transactionItems',
            minWidth: 200,
            headerTooltip: 'Spare Parts Code',
            cellRenderer: transactionItemsRenderer,
            getQuickFilterText: transactionItemsRenderer,
        },
        {
            headerName: 'Purpose',
            field: 'purpose',
            minWidth: 200,
            filter: 'agTextColumnFilter',
            headerTooltip: 'Purpose',
        },
        {
            headerName: 'Received By',
            field: 'receivedBy',
            minWidth: 200,
            filter: 'agTextColumnFilter',
            headerTooltip: 'Received By',
        },
        {
            headerName: 'Requested By',
            field: 'requestedBy',
            minWidth: 200,
            filter: 'agTextColumnFilter',
            headerTooltip: 'Requested By',
        },
        {
            headerName: 'Approved By',
            field: 'approvedBy',
            minWidth: 200,
            filter: 'agTextColumnFilter',
            headerTooltip: 'Approved By',
        },
        {
            headerName: 'Noted By',
            field: 'notedBy',
            minWidth: 200,
            filter: 'agTextColumnFilter',
            headerTooltip: 'Noted By',
        },
        {
            headerName: 'Issued By',
            field: 'issuedBy',
            minWidth: 200,
            filter: 'agTextColumnFilter',
            headerTooltip: 'Issued By',
        },
        {
            headerName: 'Date of Creation',
            field: 'createDate',
            minWidth: 200,
            cellRenderer: dateRenderer,
            getQuickFilterText: dateRenderer,
            headerTooltip: 'Creation Date',
        },
        {
            headerName: 'Last Updated',
            field: 'updateDate',
            minWidth: 200,
            cellRenderer: dateRenderer,
            getQuickFilterText: dateRenderer,
            headerTooltip: 'Update Date',
        },
    ];

    return (
        <Fragment>
            <TableGrid
                rowData={sparePartsTransactionList}
                columnDefs={columnDefs}
                filterText={filterText}
                exportCsv={exportCsv}
                onExportCsv={onExportCsv}
                exportCsvFileName={exportCsvFileName}
                height={600}
            />
        </Fragment>
    );
};

export default SparePartsIssuanceListTable;
