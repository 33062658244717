import ApiController from './api-controller';
import { AxiosPromise } from 'axios';

import { RMTruckEntries, FGTruckEntries } from 'redux/reducers/truckEntries/types'

class TruckEntriesController extends ApiController {

    getRMTruckEntries<Data = any>(): AxiosPromise<Data> {
        return this._instance.get(`services/rm/truck`);
    }

    postRMTruckEntries<Data = any>(param: RMTruckEntries): AxiosPromise<Data> {
        return this._instance.post(`services/rm/truck`, param);
    }

    patchRMTruckEntries<Data = any>(inDateTime: string, outDateTime: string): AxiosPromise<Data> {
        return this._instance.patch(`services/rm/truck/${inDateTime}`, { truckTimeOut: outDateTime });
    }

    deleteRMTruckEntry<Data = any>(inDateTime: string): AxiosPromise<Data> {
        return this._instance.delete(`services/rm/truck/${inDateTime}`);
    }
    
    getFGTruckEntries<Data = any>(): AxiosPromise<Data> {
        return this._instance.get(`services/fg/truck`);
    }

    postFGTruckEntries<Data = any>(param: FGTruckEntries): AxiosPromise<Data> {
        return this._instance.post(`services/fg/truck`, param);
    }

    patchFGTruckEntries<Data = any>(inDateTime: string, outDateTime: string): AxiosPromise<Data> {
        return this._instance.patch(`services/fg/truck/${inDateTime}`, { truckTimeOut: outDateTime });
    }

    deleteFGTruckEntry<Data = any>(inDateTime: string): AxiosPromise<Data> {
        return this._instance.delete(`services/fg/truck/${inDateTime}`);
    }
    
}

export default TruckEntriesController;
