import { Button, Grid } from '@material-ui/core';
import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import TableGrid from 'components/common/TableGrid';
import moment from 'moment';

export interface FinishedGoodWarehouseStockListTableProps {
    filterText?: string;
    exportCsv?: boolean;
    showMenu?: boolean;
    onCloseMenu?: any;
    onExportCsv?: any;
    exportCsvFileName?: string;
    data: Array<any>;
}

const FinishedGoodWarehouseStockListTable = (props: FinishedGoodWarehouseStockListTableProps) => {
    const { data, exportCsv, onExportCsv, exportCsvFileName } = props;

    const [rowData, setRowData] = useState<Array<any>>([]);
    const [isAcquiring, setIsAcquiring] = useState(false);

    useEffect(() => {
        setIsAcquiring(true);
        setTimeout(() => {
            setRowData(data);
        }, 500);
    }, [data]);

    useLayoutEffect(() => {
        setTimeout(() => {
            setIsAcquiring(false);
        }, 500);
    }, [rowData]);

    const dateRenderer = (date: any) => {
        return <Button style={{ color: '#009fdc' }}>{moment(date.value).format('MM/DD/YYYY')}</Button>;
    };

    const columnDefs: Array<any> = [
        {
            headerName: 'Date',
            field: 'inputDateTime',
            filter: 'agDateColumnFilter',
            headerTooltip: 'Date',
            cellRendererFramework: dateRenderer,
        },
        {
            headerName: 'Batch Id',
            field: 'batchId',
            filter: 'agTextColumnFilter',
            headerTooltip: 'Batch Id',
            minWidth: 150,
        },
        {
            headerName: 'Product Code',
            field: 'prodCode',
            filter: 'agNumberColumnFilter',
            headerTooltip: 'Product Code',
        },
        {
            headerName: '# of Bags',
            field: 'bagNum',
            filter: 'agNumberColumnFilter',
            headerTooltip: '# of Bags',
        },
        {
            headerName: 'Net Weight',
            field: 'netWeight',
            filter: 'agNumberColumnFilter',
            headerTooltip: 'Net Weight',
        },
    ];

    return (
        <Fragment>
            <Grid container>{isAcquiring && <CircularProgress style={{ margin: 'auto' }} />}</Grid>
            {isAcquiring === false && (
                <TableGrid
                    rowData={rowData}
                    columnDefs={columnDefs}
                    height={600}
                    exportCsv={exportCsv}
                    onExportCsv={onExportCsv}
                    exportCsvFileName={exportCsvFileName}
                />
            )}
        </Fragment>
    );
};

export default FinishedGoodWarehouseStockListTable;
