import { AppBar, Grid, IconButton, Paper, Toolbar, Tooltip, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core/styles';

import AddBoxIcon from '@material-ui/icons/AddBox';
import AddSingleUserForm from './RegisterSingleUserForm';

export interface ContentProps extends WithStyles<typeof styles> {}

const RegisterSingleUserView = (props: ContentProps) => {
    const { classes } = props;
    const [submit, setSubmit] = useState(false);

    const renderAddUserTableButton = () => {
        return (
            <Grid item>
                <Tooltip title='Register New User'>
                    <IconButton onClick={() => setSubmit(true)}>
                        <AddBoxIcon className={classes.block} color='inherit' />
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    };

    const renderAddUserContainer = () => {
        return (
            <Grid container spacing={2} alignItems='center'>
                <Grid item>
                    <Typography color='primary' variant='h5' component='h1'>
                        Register New User
                    </Typography>
                </Grid>
                <Grid item xs />
                {renderAddUserTableButton()}
            </Grid>
        );
    };

    const renderContent = () => {
        return (
            <div className={classes.contentWrapper}>
                <AddSingleUserForm
                    submit={submit}
                    onSubmit={() => {
                        setSubmit(false);
                    }}
                />
            </div>
        );
    };

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.searchBar} position='static' color='default' elevation={0}>
                <Toolbar>{renderAddUserContainer()}</Toolbar>
            </AppBar>
            {renderContent()}
        </Paper>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: '50%',
            minWidth: 500,
            margin: 'auto',
            overflow: 'hidden',
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        contentButton: {
            marginRight: theme.spacing(1),
        },
        contentWrapper: {
            margin: '40px 16px',
        },
    });

export default withStyles(styles)(RegisterSingleUserView);
