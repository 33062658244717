import {
    CLEAR_STOCK_TRANSFER_TRANSACTION,
    FinishedGoodsActionTypes,
    FinishedGoodsInfoState,
    FinishedGoodsReducerType,
    GET_FG_BATCH_PER_LINE,
    GET_FG_ORDER,
    GET_FG_QA_ANALYSIS,
    GET_FG_STOCK_BY_WAREHOUSE_LOCATION,
    GET_FG_WAREHOUSE_LOCATION,
    GET_RM_STOCK_TRANSFER_TRANSACTION,
} from './types';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { LOGOUT } from 'redux/reducers/rootReducer/types';

const initialState: FinishedGoodsInfoState = {
    transactions: [],
    fgBatch: [],
    qaAnalysis: {
        analyzedBy: '',
        analysisTime: '',
        remarks: '',
        checkItems: [],
    },
    wahouseLocation: [],
    stockOrder: [],
    warehouseLocationStock: [],
};

export const finishedGoodsTypedSelector: TypedUseSelectorHook<FinishedGoodsReducerType> = useSelector;

export function finishedGoodsReducer(state = initialState, action: FinishedGoodsActionTypes): FinishedGoodsInfoState {
    switch (action.type) {
        case GET_RM_STOCK_TRANSFER_TRANSACTION:
            return {
                ...state,
                transactions: action.payload,
            };

        case CLEAR_STOCK_TRANSFER_TRANSACTION:
            return {
                ...state,
                transactions: [],
            };

        case GET_FG_BATCH_PER_LINE:
            return {
                ...state,
                fgBatch: action.payload,
            };

        case GET_FG_QA_ANALYSIS:
            return {
                ...state,
                qaAnalysis: action.payload,
            };

        case GET_FG_WAREHOUSE_LOCATION:
            return {
                ...state,
                wahouseLocation: action.payload,
            };

        case GET_FG_STOCK_BY_WAREHOUSE_LOCATION:
            return {
                ...state,
                warehouseLocationStock: action.payload,
            };

        case GET_FG_ORDER:
            return {
                ...state,
                stockOrder: action.payload,
            };

        case LOGOUT:
            return initialState;

        default:
            return state;
    }
}
