import {
    GET_ALL_SPAREPARTS_TRANSACTION,
    GET_ALL_SPAREPARTS_INFO,
    POST_SPAREPARTS_TRANSACTION,
    ADD_NEW_SPAREPARTS_CODE,
    SparePartsTransactionRequest,
    SparePartsInfoState,
} from './types';

import AppController from 'controller/application';
import { ThunkAction } from 'redux-thunk';
import { Action } from 'redux';
import { SET_API_MESSAGE } from '../apiMessage/types';

async function processGetAllSparePartsInfo() {
    return AppController.getAllSparePartsInfo();
}

async function processGetAllSparePartsTransaction() {
    return AppController.getAllSparePartsTranscation();
}

async function processCreateSparePartsTransaction(param: SparePartsTransactionRequest) {
    return AppController.createSparePartsTransaction(param);
}

async function processUpdateSparePartsTransaction(transactionId: string, param: SparePartsTransactionRequest) {
    return AppController.updateSparePartsTransaction(transactionId, param);
}

async function processAddNewSparePartsCode(code: string) {
    return AppController.addNewSparePartsCode(code);
}

async function processGetAllSparePartsTransactionById(id: string) {
    return AppController.getAllSparePartsTranscationById(id);
}

async function processGetAllSparePartsTransactionByCode(code: string) {
    return AppController.getAllSparePartsTranscationByCode(code);
}

export function getAllSparePartsInfo(): ThunkAction<void, SparePartsInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processGetAllSparePartsInfo();
        dispatch({
            type: GET_ALL_SPAREPARTS_INFO,
            payload: resp,
        });
    };
}

export function getAllSparePartsTransaction(): ThunkAction<void, SparePartsInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processGetAllSparePartsTransaction();
        dispatch({
            type: GET_ALL_SPAREPARTS_TRANSACTION,
            payload: resp,
        });
    };
}

export function createSparePartsTransaction(
    param: SparePartsTransactionRequest
): ThunkAction<void, SparePartsInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processCreateSparePartsTransaction(param);
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: !!!resp.response.data.message
                        ? 'Failed creating spare parts transaction'
                        : resp.response.data.message,
                    variant: 'error',
                },
            });
        } else {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: 'Spare parts transaction is created',
                    variant: 'success',
                },
            });
            dispatch({
                type: POST_SPAREPARTS_TRANSACTION,
                payload: null,
            });
        }
    };
}

export function updateSparePartsTransaction(
    transactionId: string,
    param: SparePartsTransactionRequest
): ThunkAction<void, SparePartsInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processUpdateSparePartsTransaction(transactionId, param);
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: !!!resp.response.data.message
                        ? `Failed updating spare parts transaction ${transactionId}`
                        : `${resp.response.data.message} Transaction ID (${transactionId})`,
                    variant: 'error',
                },
            });
        } else {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `Spare parts transaction ${transactionId} is updated`,
                    variant: 'success',
                },
            });
            dispatch({
                type: POST_SPAREPARTS_TRANSACTION,
                payload: null,
            });
        }
    };
}

export function addNewSparePartsCode(code: string): ThunkAction<void, SparePartsInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        await processAddNewSparePartsCode(code);
        dispatch({
            type: ADD_NEW_SPAREPARTS_CODE,
            payload: null,
        });
    };
}

export function getAllSparePartsTransactionById(
    id: string
): ThunkAction<void, SparePartsInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processGetAllSparePartsTransactionById(id);
        dispatch({
            type: GET_ALL_SPAREPARTS_TRANSACTION,
            payload: resp,
        });
    };
}

export function getAllSparePartsTransactionByCode(
    code: string
): ThunkAction<void, SparePartsInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processGetAllSparePartsTransactionByCode(code);
        dispatch({
            type: GET_ALL_SPAREPARTS_TRANSACTION,
            payload: resp,
        });
    };
}
