import { LogoutAction } from "redux/reducers/rootReducer/types"

export interface TruckEntries {
    plateNumber: string;
    truckTimeIn: string;
    truckTimeOut?: string;
}

export interface RMTruckEntries extends TruckEntries {
    supplierName: string;
}

export interface FGTruckEntries extends TruckEntries {
    customerName: string;
}

export interface TruckEntriesInfoState {
    rmTruckEntries: Array<RMTruckEntries>;
    fgTruckEntries: Array<FGTruckEntries>;
}

export interface TruckEntriesReducerType {
    truckEntries: TruckEntriesInfoState
}

export const GET_RM_TRUCK_ENTRIES = 'GET_RM_TRUCK_ENTRIES'
export const POST_RM_TRUCK_ENTRY = 'POST_RM_TRUCK_ENTRY'
export const PATCH_RM_TRUCK_ENTRY = 'PATCH_RM_TRUCK_ENTRY'
export const DELETE_RM_TRUCK_ENTRY = 'DELETE_RM_TRUCK_ENTRY'

export const GET_FG_TRUCK_ENTRIES = 'GET_FG_TRUCK_ENTRIES'
export const POST_FG_TRUCK_ENTRY = 'POST_FG_TRUCK_ENTRY'
export const PATCH_FG_TRUCK_ENTRY = 'PATCH_FG_TRUCK_ENTRY'
export const DELETE_FG_TRUCK_ENTRY = 'DELETE_FG_TRUCK_ENTRY'

interface GetRMTruckEntriesAction {
    type: typeof GET_RM_TRUCK_ENTRIES
    payload: Array<RMTruckEntries>
}

interface GetFGTruckEntriesAction {
    type: typeof GET_FG_TRUCK_ENTRIES
    payload: Array<FGTruckEntries>
}

export type TruckEntriesActionTypes = GetRMTruckEntriesAction | GetFGTruckEntriesAction | LogoutAction