import React, { useState, useEffect } from 'react';
import { AppBar, Tab, Tabs } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';

interface TabEntry {
    id: String
}

interface TabsAppBarProps extends WithStyles<typeof styles> {
    tabs: Array<TabEntry>,
    onTabsChange: (id: number) => void;
    selectedTab?: number;
}

const TabsAppBar = (props: TabsAppBarProps) => {

    const { classes, tabs } = props;
    const [selectedTab, setSelectedTab] = useState(props.selectedTab ? props.selectedTab : 0);

    const handleTabChange = (event: React.ChangeEvent<{}>, newTab: any) => {
        setSelectedTab(newTab);
        props.onTabsChange(newTab);
    }

    useEffect(()=>{
        if (props.selectedTab !== undefined) {
            setSelectedTab(props.selectedTab)
        }
    }, [props.selectedTab])


    return (
        <AppBar
            component="div"
            className={classes.secondaryBar}
            color="primary"
            position="static"
            elevation={0}>
            <Tabs value={selectedTab} onChange={handleTabChange} textColor="inherit">
                {
                    tabs.map(({ id }) =>
                        <Tab textColor="inherit" label={id} />
                    )
                }
            </Tabs>
        </AppBar>
    )
}

const styles = (theme: Theme) => createStyles({
    secondaryBar: {
        zIndex: 0,
    }
});

export default withStyles(styles)(TabsAppBar);