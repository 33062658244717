import React, { Fragment } from 'react';
import { Doughnut } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

export interface DoughnutChartProps {
    labels?: Array<String>;
    data?: Array<Number>;
    legend: string;
    height?: number;
    backgroundColor: Array<string>;
    hoverBackgroundColor: Array<string>;
}

const DoughnutChart = (props: DoughnutChartProps) => {

    const data = {
        labels: props.labels,
        datasets: [{
            data: props.data,
            backgroundColor: props.backgroundColor,
            hoverBackgroundColor: props.hoverBackgroundColor
        }]
    };

    return (
        <Fragment>
            {props.labels &&
                <Doughnut
                    data={data}
                    options={{
                        maintainAspectRatio: false,
                        legend: {
                            display: true
                        },
                        plugins: {
                            datalabels: {
                                color: 'white',
                            }
                        },
                    }}
                    height={props.height}
                />
            }
        </Fragment>
    );
}

export default DoughnutChart;
