import { LogoutAction } from 'redux/reducers/rootReducer/types';

export interface CanvassItem {
    itemCode?: string;
    itemDescription?: string;
    supplierId?: string;
    supplierName?: string;
    itemUnitPrice?: number;
    itemQuantity?: number;
    itemCanvassApproved?: boolean;
    itemCanvassApprovedStatus?: string;
}

export interface Canvass {
    requestId: string;
    preparedBy: string;
    approvedBy?: string;
    createDate?: string;
    updateDate?: string;
    itemsList: Array<CanvassItem>;
}

export interface CanvassInfoState {
    canvassSheetsList: Array<Canvass>;
    canvass: Canvass;
    lastEvaluatedKey: string;
}

export interface CanvassReducerType {
    canvass: CanvassInfoState;
}

export const CREATE_NEW_CANVASS = 'CREATE_NEW_CANVASS';
export const GET_MULTI_CANVASS = 'GET_MULTI_CANVASS';
export const GET_CANVASS_BY_ID = 'GET_CANVASS_BY_ID';
export const UPDATE_CANVASS_BY_ID = 'UPDATE_CANVASS_BY_ID';
export const DELETE_CANVASS_SHEET = 'DELETE_CANVASS_SHEET';

interface CreateNewCanvass {
    type: typeof CREATE_NEW_CANVASS;
    payload: Canvass;
}

interface GetMultipleCanvass {
    type: typeof GET_MULTI_CANVASS;
    payload: {
        canvassSheetsList: Array<Canvass>;
        lastEvaluatedKey: string;
    };
}

interface GetCanvassByID {
    type: typeof GET_CANVASS_BY_ID;
    payload: Canvass;
}

interface UpdateCanvassByID {
    type: typeof UPDATE_CANVASS_BY_ID;
    payload: Canvass;
}

export type CanvassActionTypes =
    | CreateNewCanvass
    | GetMultipleCanvass
    | GetCanvassByID
    | UpdateCanvassByID
    | LogoutAction;
