import {
    ADD_FG_QA_ANALYSIS_RESULT,
    ADD_FG_QA_SAMPLE_ANALYSIS,
    ADD_FG_UNLOADING_DETAILS,
    ADD_ORDER_INFO,
    APPROVE_FG_ORDER_FOR_DISPATCH,
    AddStockTransferTransactionParams,
    CLEAR_STOCK_TRANSFER_TRANSACTION,
    CREATE_FG_BATCH,
    CreateFGBatchParam,
    DISPATCH_FG_ORDER,
    FGBatchOrderInfo,
    FGDispatchApproval,
    FGQAAnalysisInfo,
    FGQAAnalysisResult,
    FGTruckInspectionInfo,
    FGUnloadDetails,
    FinishedGoodsInfoState,
    GET_FG_BATCH_PER_LINE,
    GET_FG_ORDER,
    GET_FG_QA_ANALYSIS,
    GET_FG_STOCK_BY_WAREHOUSE_LOCATION,
    GET_FG_WAREHOUSE_LOCATION,
    GET_RM_STOCK_TRANSFER_TRANSACTION,
    PATCH_TRUCK_CLEANLINESS_INSPECTION,
    DELETE_FG_BATCH_BY_ID,
} from './types';

import { Action } from 'redux';
import AppController from 'controller/application';
import { SET_API_MESSAGE } from 'redux/reducers/apiMessage/types';
import { ThunkAction } from 'redux-thunk';

async function processGetRmStockTransferTransaction(rmrrId: string) {
    return AppController.getRmStockTransferTransaction(rmrrId);
}

async function processAddRmStockTransferTransaction(
    warehouseId: string,
    locationId: string,
    parameters: AddStockTransferTransactionParams
) {
    return AppController.addRmStockTransferTransaction(warehouseId, locationId, parameters);
}

async function processGetStockTransactionsRawMaterialLocation(warehouseId: string, locationId: string, rmName: string) {
    return AppController.getTtockTransactionsRawMaterialLocation(warehouseId, locationId, rmName);
}

async function processGetFGBatchPerLine(line: string) {
    return AppController.getFGBatchPerLine(line);
}

async function processCreateFgBatchAction(param: CreateFGBatchParam) {
    return AppController.createFgBatch(param);
}

async function processPatchTruckCleanlinessInspection(id: string, param: FGTruckInspectionInfo) {
    return AppController.patchTruckCleanlinessInspection(id, param);
}

async function processAddFGQASampleAnalysis(id: string, param: FGQAAnalysisInfo) {
    return AppController.addFGQASampleAnalysis(id, param);
}

async function processAddFGBatchQAAnalysisResult(id: string, param: FGQAAnalysisResult) {
    return AppController.addFGBatchQAAnalysisResult(id, param);
}

async function processGetFGQASampleAnalysis(id: string) {
    return AppController.getFGQASampleAnalysis(id);
}

async function processAddOrderInfo(id: string, param: FGBatchOrderInfo) {
    return AppController.addFGOrderInfo(id, param);
}

async function processGetFGWarehouseLocation() {
    return AppController.getFGWarehouseLocation();
}

async function processGetFGStockByWarehouseLocationAction(warehouse: string, location: string) {
    return AppController.getFGStockByWarehouseLocation(warehouse, location);
}

async function processAddFGBatchUnloadingDetails(id: string, param: FGUnloadDetails) {
    return AppController.addFGBatchUnloadingDetails(id, param);
}

/* 07_Approve FG order for dispatch */
async function processApproveFGOrderForDispatch(issueOrder: string, param: FGDispatchApproval) {
    return AppController.approveFGOrderForDispatch(issueOrder, param);
}

/* 08_Dispatch FG order */
async function processDispatchFGOrder(issueOrder: string, deliveryDate: string) {
    return AppController.dispatchFGOrder(issueOrder, deliveryDate);
}

async function processGetFGOrder(batchId: string) {
    return AppController.getFGOrder(batchId);
}

async function processDeleteFGBatchByID(batchId: string) {
    return AppController.deleteFGBatchByID(batchId);
}

export function getRmStockTransferTransactionAction(
    rmrrId: string
): ThunkAction<void, FinishedGoodsInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processGetRmStockTransferTransaction(rmrrId);
        dispatch({
            type: GET_RM_STOCK_TRANSFER_TRANSACTION,
            payload: resp,
        });
    };
}

export function addRmStockTransferTransactionAction(
    warehouseId: string,
    locationId: string,
    parameters: AddStockTransferTransactionParams
): ThunkAction<void, FinishedGoodsInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processAddRmStockTransferTransaction(warehouseId, locationId, parameters);

        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: 'Cannot transfer. Confirm if shipment is already unloaded/completed',
                    variant: 'error',
                },
            });
        } else {
            setTimeout(async () => {
                let resp = await processGetRmStockTransferTransaction(parameters.stockSourceList[0]);
                dispatch({
                    type: GET_RM_STOCK_TRANSFER_TRANSACTION,
                    payload: resp,
                });
            }, 500);
        }
    };
}

export function getStockTransactionsRawMaterialLocation(
    warehouseId: string,
    locationId: string,
    rmName: string
): ThunkAction<void, FinishedGoodsInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processGetStockTransactionsRawMaterialLocation(warehouseId, locationId, rmName);
        dispatch({
            type: GET_RM_STOCK_TRANSFER_TRANSACTION,
            payload: resp,
        });
    };
}

export function clearStockTransactions(): ThunkAction<void, FinishedGoodsInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        dispatch({
            type: CLEAR_STOCK_TRANSFER_TRANSACTION,
            payload: [],
        });
    };
}

export function getFgBatchPerLineAction(
    line: string
): ThunkAction<void, FinishedGoodsInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processGetFGBatchPerLine(line);
        dispatch({
            type: GET_FG_BATCH_PER_LINE,
            payload: resp,
        });
    };
}

/* 01_Create new FG batch */
export function createFgBatchAction(
    param: CreateFGBatchParam
): ThunkAction<void, FinishedGoodsInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        await processCreateFgBatchAction(param);
        dispatch({
            type: CREATE_FG_BATCH,
            payload: {},
        });
    };
}

/* 02_Add QA sample analysis */
export function addFGQASampleAnalysis(
    id: string,
    param: FGQAAnalysisInfo
): ThunkAction<void, FinishedGoodsInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processAddFGQASampleAnalysis(id, param);
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `Failed to submit FG QA Sample Analysis for batch ${id}`,
                    variant: 'error',
                },
            });
        } else {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `FG QA Sample Analysis for batch ${id} is submitted`,
                    variant: 'success',
                },
            });
            dispatch({
                type: ADD_FG_QA_SAMPLE_ANALYSIS,
                payload: [],
            });
        }
    };
}

/* 03_Add QA analysis result of FG batch */
export function addFGQAAnalysisResult(
    id: string,
    param: FGQAAnalysisResult
): ThunkAction<void, FinishedGoodsInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processAddFGBatchQAAnalysisResult(id, param);
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `Failed to submit FG QA Analysis Result for batch ${id}`,
                    variant: 'error',
                },
            });
        } else {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `FG QA Analysis Result for batch ${id} is submitted`,
                    variant: 'success',
                },
            });
            dispatch({
                type: ADD_FG_QA_ANALYSIS_RESULT,
                payload: null,
            });
        }
    };
}

/* 04_Add FG Batch unloading details */
export function addFGBatchUnloadingDetailsAction(
    id: string,
    param: FGUnloadDetails
): ThunkAction<void, FinishedGoodsInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processAddFGBatchUnloadingDetails(id, param);
        if (resp.response && resp.response.data && resp.response.data.reason) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: resp.response.data.reason,
                    variant: 'error',
                },
            });
        } else {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `FG ${id} is unloaded`,
                    variant: 'success',
                },
            });
            dispatch({
                type: ADD_FG_UNLOADING_DETAILS,
                payload: [],
            });
        }
    };
}

/* 05_Create FG order */
export function addOrderInfoAction(
    id: string,
    param: FGBatchOrderInfo
): ThunkAction<void, FinishedGoodsInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processAddOrderInfo(id, param);
        if (resp.response === 'error') {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: 'Error in creationg Order Info',
                    variant: 'error',
                },
            });
        } else {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: 'Order Info is Added',
                    variant: 'success',
                },
            });
            dispatch({
                type: ADD_ORDER_INFO,
                payload: null,
            });
        }
    };
}

/* 06_Add truck loading inspection details to FG batch */
export function patchTruckCleanlinessInspection(
    id: string,
    param: FGTruckInspectionInfo
): ThunkAction<void, FinishedGoodsInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processPatchTruckCleanlinessInspection(id, param);
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `Failed to submit Truck Cleanliness Checklist of Issue Order ${id}`,
                    variant: 'error',
                },
            });
        } else {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `Truck Cleanliness Checklist for issue order ${id} is submitted`,
                    variant: 'success',
                },
            });
            dispatch({
                type: PATCH_TRUCK_CLEANLINESS_INSPECTION,
                payload: [],
            });
        }
    };
}

/* 07_Approve FG order for dispatch */
export function approveFGOrderForDispatch(
    issueOrder: string,
    param: FGDispatchApproval
): ThunkAction<void, FinishedGoodsInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processApproveFGOrderForDispatch(issueOrder, param);
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `Failed to set status of FG Order for dispatch. Confirm if Issue Order ${issueOrder} is already created`,
                    variant: 'error',
                },
            });
        } else {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `Approval for Issue Order ${issueOrder} is submitted`,
                    variant: 'success',
                },
            });
            dispatch({
                type: APPROVE_FG_ORDER_FOR_DISPATCH,
                payload: [],
            });
        }
    };
}

/* 08_Dispatch FG order */
export function dispatchFGOrder(
    issueOrder: string,
    deliveryDate: string
): ThunkAction<void, FinishedGoodsInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processDispatchFGOrder(issueOrder, deliveryDate);
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `Failed to dispatch Issue Order ${issueOrder}. Confirm that it is already approved`,
                    variant: 'error',
                },
            });
        } else {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `Issue Order ${issueOrder} is dispatched`,
                    variant: 'success',
                },
            });
            dispatch({
                type: DISPATCH_FG_ORDER,
                payload: [],
            });
        }
    };
}

export function getFGOrder(batchId: string): ThunkAction<void, FinishedGoodsInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processGetFGOrder(batchId);
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `Failed to get FG Stock Order ${batchId}`,
                    variant: 'error',
                },
            });
        } else {
            dispatch({
                type: GET_FG_ORDER,
                payload: resp,
            });
        }
    };
}

export function getFGQASampleAnalysisAction(
    id: string
): ThunkAction<void, FinishedGoodsInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processGetFGQASampleAnalysis(id);
        dispatch({
            type: GET_FG_QA_ANALYSIS,
            payload: resp.error ? null : resp,
        });
    };
}

export function getFGWarehouseLocationAction(): ThunkAction<void, FinishedGoodsInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processGetFGWarehouseLocation();
        dispatch({
            type: GET_FG_WAREHOUSE_LOCATION,
            payload: resp,
        });
    };
}

export function getFGStockByWarehouseLocationAction(
    warehouse: string,
    location: string
): ThunkAction<void, FinishedGoodsInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processGetFGStockByWarehouseLocationAction(warehouse, location);
        dispatch({
            type: GET_FG_STOCK_BY_WAREHOUSE_LOCATION,
            payload: resp,
        });
    };
}

export function deleteFGBatchByIDAction(
    batchId: string
): ThunkAction<void, FinishedGoodsInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processDeleteFGBatchByID(batchId);
        
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: resp.response.data.reason,
                    variant: "error",
                },
            });
        } else {
            dispatch({
                type: DELETE_FG_BATCH_BY_ID,
                payload: {},
            });
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `Deleted successfully`,
                    variant: 'success',
                },
            });
        }
    };
}