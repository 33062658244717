import React, { Fragment } from "react";

import { Button } from "@material-ui/core";
import { PurchaseOrderInfo } from "redux/reducers/purchasing/types";
import TableGrid from "components/common/TableGrid";

export interface PurchaseOrderStatusTableProps {
    filterText: string;
    onClickedOrderNo?: (orderId: string) => void;
    purchaseOrderList: Array<PurchaseOrderInfo>;
}

const PurchaseOrderStatusTable = (props: PurchaseOrderStatusTableProps) => {
    const { filterText, onClickedOrderNo, purchaseOrderList } = props;

    const orderIdRenderer = (params: { value: string }) => {
        return (
            <Button
                style={{ color: "#009fdc" }}
                onClick={() => {
                    if (onClickedOrderNo) {
                        onClickedOrderNo(params.value);
                    }
                }}
            >
                {params.value}
            </Button>
        );
    };

    const columnDefs = [
        {
            headerName: "Order #",
            field: "orderId",
            minWidth: 300,
            filter: "agTextColumnFilter",
            headerTooltip: "Order #",
            cellRendererFramework: orderIdRenderer,
        },
        {
            headerName: "Supplier",
            field: "supplierName",
            minWidth: 150,
            filter: "agTextColumnFilter",
            headerTooltip: "Supplier",
        },
    ];

    return (
        <Fragment>
            <TableGrid rowData={purchaseOrderList} columnDefs={columnDefs} filterText={filterText} height={600} />
        </Fragment>
    );
};

export default PurchaseOrderStatusTable;
