import {
    GET_RM_TRUCK_ENTRIES,
    POST_RM_TRUCK_ENTRY,
    PATCH_RM_TRUCK_ENTRY,
    DELETE_RM_TRUCK_ENTRY,
    GET_FG_TRUCK_ENTRIES,
    POST_FG_TRUCK_ENTRY,
    PATCH_FG_TRUCK_ENTRY,
    DELETE_FG_TRUCK_ENTRY,
    RMTruckEntries,
    FGTruckEntries,
    TruckEntriesInfoState
} from './types'

import AppController from "controller/application";
import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'
import { SET_API_MESSAGE } from '../apiMessage/types';

async function processGetRMTruckEntries() {
    return AppController.getRMTruckEntries()
}

async function processPostRMTruckEntries(param: RMTruckEntries) {
    return AppController.postRMTruckEntries(param)
}

async function processPatchRMTruckEntries(inDateTime: string, outDateTime: string) {
    return AppController.patchRMTruckEntries(inDateTime, outDateTime)
}

async function processDeleteRMTruckEntry(inDateTime: string) {
    return AppController.deleteRMTruckEntry(inDateTime)
}

async function processGetFGTruckEntries() {
    return AppController.getFGTruckEntries()
}

async function processPostFGTruckEntries(param: FGTruckEntries) {
    return AppController.postFGTruckEntries(param)
}

async function processPatchFGTruckEntries(inDateTime: string, outDateTime: string) {
    return AppController.patchFGTruckEntries(inDateTime, outDateTime)
}

async function processDeleteFGTruckEntry(inDateTime: string) {
    return AppController.deleteFGTruckEntry(inDateTime)
}

export function getRMTruckEntriesAction():
    ThunkAction<void, TruckEntriesInfoState, unknown, Action<string>> {

    return async dispatch => {
        let resp = await processGetRMTruckEntries()
        dispatch({
            type: GET_RM_TRUCK_ENTRIES,
            payload: resp
        });
    };
}

export function postRMTruckEntriesAction(param: RMTruckEntries):
    ThunkAction<void, TruckEntriesInfoState, unknown, Action<string>> {

    return async dispatch => {
        let resp = await processPostRMTruckEntries(param)
        dispatch({
            type: POST_RM_TRUCK_ENTRY,
            payload: resp
        });
    };
}

export function patchRMTruckEntriesAction(inDateTime: string, outDateTime: string):
    ThunkAction<void, TruckEntriesInfoState, unknown, Action<string>> {

    return async dispatch => {
        let resp = await processPatchRMTruckEntries(inDateTime, outDateTime)
        dispatch({
            type: PATCH_RM_TRUCK_ENTRY,
            payload: resp
        });
    };
}

export function deleteRMTruckEntryAction(
    inDateTime: string
): ThunkAction<void, TruckEntriesInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processDeleteRMTruckEntry(inDateTime);
        
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: resp.response.data.reason,
                    variant: "error",
                },
            });
        } else {
            dispatch({
                type: DELETE_RM_TRUCK_ENTRY,
                payload: {},
            });
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `Deleted successfully`,
                    variant: 'success',
                },
            });
        }
    };
}

export function getFGTruckEntriesAction():
    ThunkAction<void, TruckEntriesInfoState, unknown, Action<string>> {

    return async dispatch => {
        let resp = await processGetFGTruckEntries()
        dispatch({
            type: GET_FG_TRUCK_ENTRIES,
            payload: resp
        });
    };
}

export function postFGTruckEntriesAction(param: FGTruckEntries):
    ThunkAction<void, TruckEntriesInfoState, unknown, Action<string>> {

    return async dispatch => {
        let resp = await processPostFGTruckEntries(param)
        dispatch({
            type: POST_FG_TRUCK_ENTRY,
            payload: resp
        });
    };
}

export function patchFGTruckEntriesAction(inDateTime: string, outDateTime: string):
    ThunkAction<void, TruckEntriesInfoState, unknown, Action<string>> {

    return async dispatch => {
        let resp = await processPatchFGTruckEntries(inDateTime, outDateTime)
        dispatch({
            type: PATCH_FG_TRUCK_ENTRY,
            payload: resp
        });
    };
}

export function deleteFGTruckEntryAction(
    inDateTime: string
): ThunkAction<void, TruckEntriesInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processDeleteFGTruckEntry(inDateTime);
        
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: resp.response.data.reason,
                    variant: "error",
                },
            });
        } else {
            dispatch({
                type: DELETE_FG_TRUCK_ENTRY,
                payload: {},
            });
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `Deleted successfully`,
                    variant: 'success',
                },
            });
        }
    };
}
