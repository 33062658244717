import { Button, Grid, TextField } from "@material-ui/core";
import { PurchaseOrderInfo, PurchaseOrderStatus } from "redux/reducers/purchasing/types";
import React, { Fragment } from "react";
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles";

import TableGrid from "components/common/TableGrid";

export interface ContentProps extends WithStyles<typeof styles> {
    clickedPurchaseOrder?: PurchaseOrderInfo;
    onClickedUpdateButton: () => void;
}

const PurchaseOrderDetailsView = (props: ContentProps) => {
    const { clickedPurchaseOrder, onClickedUpdateButton } = props;

    let columnDefs = [
        {
            headerName: "Item Code",
            field: "itemCode",
            minWidth: 100,
            filter: "agTextColumnFilter",
            headerTooltip: "Item Code",
        },
        {
            headerName: "Description",
            field: "itemDescription",
            minWidth: 250,
            filter: "agTextColumnFilter",
            headerTooltip: "Item Description",
        },
        {
            headerName: "Unit",
            field: "itemUnit",
            minWidth: 50,
            filter: "agTextColumnFilter",
            headerTooltip: "Unit",
        },
        {
            headerName: "Quantity",
            field: "itemQuantity",
            minWidth: 50,
            filter: "agTextColumnFilter",
            headerTooltip: "Quantity",
        },
        {
            headerName: "Unit Price",
            field: "itemUnitPrice",
            minWidth: 50,
            filter: "agTextColumnFilter",
            headerTooltip: "Unit Price",
        },
        {
            headerName: "Total Price",
            field: "itemTotalPrice",
            minWidth: 50,
            filter: "agTextColumnFilter",
            headerTooltip: "Total Price",
        },
    ];

    const renderPurchaseOrderDetails = () => {
        return (
            <Fragment>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <TextField
                            label={"Request #"}
                            value={clickedPurchaseOrder!.requestId}
                            inputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label={"Terms"}
                            value={clickedPurchaseOrder!.terms}
                            inputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label={"Status"}
                            value={clickedPurchaseOrder!.orderStatus}
                            inputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label={"Supplier"}
                            value={clickedPurchaseOrder!.supplierName}
                            inputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label={"Ship To"}
                            value={clickedPurchaseOrder!.shipTo}
                            inputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableGrid rowData={clickedPurchaseOrder!.itemsList} columnDefs={columnDefs} height={300} />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label={"Reviewed By"}
                            value={clickedPurchaseOrder!.reviewedBy}
                            inputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4} />
                    <Grid item xs={2}>
                        <TextField
                            label={"Total"}
                            variant={"outlined"}
                            value={clickedPurchaseOrder!.total}
                            inputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label={"Prepared By"}
                            value={clickedPurchaseOrder!.preparedBy}
                            inputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    { clickedPurchaseOrder!.approvedBy !== undefined &&
                    (
                        <Grid item xs={6}>
                            <TextField
                                label={clickedPurchaseOrder!.orderStatus === PurchaseOrderStatus.APPROVED ? 'Approved By' : 'Rejected By'}
                                value={clickedPurchaseOrder!.approvedBy}
                                inputProps={{
                                    readOnly: true,
                                }}
                                fullWidth
                            />
                        </Grid>
                    )}
                    {(clickedPurchaseOrder!.orderStatus === PurchaseOrderStatus.PENDING ||
                        clickedPurchaseOrder!.orderStatus === PurchaseOrderStatus.APPROVED ) &&
                    (
                        <Grid item xs={12}>
                            <Button
                                onClick={onClickedUpdateButton}
                                color="primary"
                                variant="contained"
                                fullWidth={true}
                            >
                                Update
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </Fragment>
        );
    };

    return <div style={{ padding: 10 }}>{renderPurchaseOrderDetails()}</div>;
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: "99%",
            margin: "auto",
            overflow: "hidden",
            alignContent: "center",
        },
        searchBar: {
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: "block",
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: "rgb(31,49,69)",
            alignSelf: "center",
        },
        contentWrapper: {
            margin: "0px 0px",
        },
    });

export default withStyles(styles)(PurchaseOrderDetailsView);
