import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { SparePartsInfoList, SparePartsTransactionResponse } from 'redux/reducers/spareParts/types';
import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import { getAllSparePartsInfo, getAllSparePartsTransactionByCode } from 'redux/reducers/spareParts/actions';

import BarGraph from 'components/common/BarGraph';
import ListItems from 'components/common/ListItems';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { sparePartsTypedSelector } from 'redux/reducers/spareParts/sparePartsReducer';
import { useDispatch } from 'react-redux';

export interface ContentProps extends WithStyles<typeof styles> {}

const SparePartsDashboard = (props: ContentProps) => {
    const { classes } = props;
    const dispatcher = useDispatch();
    const [fromDate, setFromDate] = useState<string | null>(null);
    const [toDate, setToDate] = useState<string | null>(null);

    const sparePartsInfo = sparePartsTypedSelector<SparePartsInfoList>((state) => state.spareParts.sparePartsInfo);
    const [spCodeList, setSpCodeList] = useState<Array<string>>([]);
    const [selectSpCode, setSelectSpCode] = useState('');
    useEffect(() => {
        if (sparePartsInfo.infoList.length === 0) {
            dispatcher(getAllSparePartsInfo());
        } else {
            const codeList = sparePartsInfo.infoList.map((info) => info.code);
            setSpCodeList(codeList);
            setSelectSpCode(codeList[0]);
        }
    }, [sparePartsInfo, dispatcher]);

    const sparePartsStockList = sparePartsTypedSelector<SparePartsTransactionResponse>(
        (state) => state.spareParts.sparePartsTransaction
    );
    const [stockDateList, setStockDateList] = useState<Array<string>>([]);
    const [stockDataList, setStockDataList] = useState<Array<number>>([]);
    useEffect(() => {
        dispatcher(getAllSparePartsTransactionByCode(selectSpCode));
    }, [selectSpCode, dispatcher]);

    useEffect(() => {
        if (sparePartsStockList.transactions.length > 0) {
            setData();
        }
    }, [sparePartsStockList]); // eslint-disable-line

    const setData = () => {
        let dataList: Array<number> = [];
        sparePartsStockList.transactions.forEach((data) => {
            if (fromDate && data.createDate < fromDate) {
                return;
            }
            if (toDate && data.createDate > toDate) {
                return;
            }
            if (data.transactionItems[0].quantity) {
                if (data.transactionType === 'in') {
                    dataList.push(data.transactionItems[0].quantity);
                }
                dataList.push(-data.transactionItems[0].quantity);
            }
        });
        setStockDataList(dataList);

        let dateList: Array<string> = [];
        sparePartsStockList.transactions.forEach((data) => {
            if (fromDate && data.createDate < fromDate) {
                return;
            }
            if (toDate && data.createDate > toDate) {
                return;
            }
            dateList.push(moment(data.createDate).format('MM-DD-YYYY:HH:mm:ss'));
        });
        setStockDateList(dateList);
    };

    const renderContent = () => {
        return (
            <div className={classes.contentWrapper}>
                <Grid container spacing={2}>
                    <Grid item xs={2}>
                        <ListItems
                            title='Spare Parts Code'
                            dataList={spCodeList}
                            selected={selectSpCode}
                            setter={(data) => {
                                if (data !== selectSpCode) {
                                    setStockDateList([]);
                                    setStockDataList([]);
                                    setSelectSpCode(data);
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Grid item xs={12}>
                            <Typography style={{ color: '#009fdc', paddingTop: 25 }} align='center' variant='subtitle1'>
                                {'Date Range'}
                            </Typography>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DateTimePicker
                                    margin='dense'
                                    fullWidth
                                    value={fromDate}
                                    label={'From'}
                                    inputVariant='outlined'
                                    onChange={(date) => {
                                        setFromDate(moment(date).toISOString());
                                        setData();
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DateTimePicker
                                    margin='dense'
                                    fullWidth
                                    value={toDate}
                                    label={'To'}
                                    inputVariant='outlined'
                                    onChange={(date) => {
                                        setToDate(moment(date).toISOString());
                                        setData();
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    paddingTop: 15,
                                }}>
                                <Button
                                    size='small'
                                    className={classes.contentButton}
                                    color='primary'
                                    variant='contained'
                                    onClick={() => {
                                        setFromDate(null);
                                        setToDate(null);
                                        setData();
                                    }}>
                                    Clear Dates
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <BarGraph
                            legend={'Stock IN/OUT'}
                            labels={stockDateList}
                            data={stockDataList}
                            // selected={selectDateList}
                        />
                    </Grid>
                </Grid>
            </div>
        );
    };

    return <Paper className={classes.paper}>{renderContent()}</Paper>;
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: '99%',
            margin: 'auto',
            overflow: 'hidden',
            padding: 5,
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: 'rgb(31,49,69)',
            alignSelf: 'center',
            padding: 20,
            paddingTop: 10,
            paddingBottom: 10,
        },
        contentWrapper: {
            margin: '0px 0px',
        },
    });

export default withStyles(styles)(SparePartsDashboard);
