import React, { Fragment, useState } from "react";

import FinishedGoodTruckEntriesMainView from "views/InventoryList/FinishedGoods/FinishedGoodTruckEntries/FinishedGoodTruckEntriesMainView";
import FinishedGoodWarehouseStockView from "./FinishedGoodWarehouseStock/FinishedGoodWarehouseStockView";
import FinishedGoodsHeader from "views/InventoryList/FinishedGoods/FinishedGoodsHeader";
import FinishedGoodsListView from "views/InventoryList/FinishedGoods/FinishedGoodsList/FinishedGoodsListMainView";
import { Typography } from "@material-ui/core";

export interface FinishedGoodsMainProps {
    onDrawerToggle: () => void;
    mainClass: any;
}

const FinishedGoodsMain = (props: FinishedGoodsMainProps) => {
    const TAB1_TITLE = "Truck Entries";
    const TAB2_TITLE = "Finished Goods List";
    const TAB3_TITLE = "Warehouse Stocks";
    const tabsTitle = [{ id: TAB1_TITLE }, { id: TAB2_TITLE }, { id: TAB3_TITLE }];

    const [currentTab, setCurrentTab] = useState(0);
    const onTabsChange = (id: number) => {
        setCurrentTab(id);
        return;
    };

    return (
        <Fragment>
            <FinishedGoodsHeader
                onDrawerToggle={props.onDrawerToggle}
                onTabsChange={onTabsChange}
                tabsTitle={tabsTitle}
            />
            <main className={props.mainClass}>
                {tabsTitle[currentTab].id === TAB1_TITLE && (
                    <Fragment>
                        <Typography style={{ paddingBottom: 10 }} color="inherit" variant="h5" component="h1">
                            {TAB1_TITLE}
                        </Typography>
                        <FinishedGoodTruckEntriesMainView />
                    </Fragment>
                )}
                {tabsTitle[currentTab].id === TAB2_TITLE && (
                    <Fragment>
                        <Typography style={{ paddingBottom: 10 }} color="inherit" variant="h5" component="h1">
                            {TAB2_TITLE}
                        </Typography>
                        <FinishedGoodsListView />
                    </Fragment>
                )}
                {tabsTitle[currentTab].id === TAB3_TITLE && (
                    <Fragment>
                        <Typography style={{ paddingBottom: 10 }} color="inherit" variant="h5" component="h1">
                            {TAB3_TITLE}
                        </Typography>
                        <FinishedGoodWarehouseStockView />
                    </Fragment>
                )}
            </main>
        </Fragment>
    );
};

export default FinishedGoodsMain;
