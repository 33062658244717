import {
    GET_All_PREMIX,
    GET_All_PRODUCTION_ORDER,
    PremixActionTypes,
    PremixInfoState,
    PremixReducerType,
} from "./types";
import { TypedUseSelectorHook, useSelector } from "react-redux";

import { LOGOUT } from "redux/reducers/rootReducer/types";

const initialState: PremixInfoState = {
    premixList: {
        premixes: [],
        LastEvaluatedKey: null,
    },
    productionOrderList: {
        prodOrders: [],
        LastEvaluatedKey: null,
    },
};

export const premixTypedSelector: TypedUseSelectorHook<PremixReducerType> = useSelector;

export function premixReducer(state = initialState, action: PremixActionTypes): PremixInfoState {
    switch (action.type) {
        case GET_All_PREMIX:
            return {
                premixList: action.payload,
                productionOrderList: state.productionOrderList,
            };
        case GET_All_PRODUCTION_ORDER:
            return {
                premixList: state.premixList,
                productionOrderList: action.payload,
            };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
}
