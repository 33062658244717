import {
    CREATE_NEW_CANVASS,
    CanvassActionTypes,
    CanvassInfoState,
    CanvassReducerType,
    GET_CANVASS_BY_ID,
    GET_MULTI_CANVASS,
    UPDATE_CANVASS_BY_ID,
} from './types';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { LOGOUT } from 'redux/reducers/rootReducer/types';

const initialState: CanvassInfoState = {
    canvassSheetsList: [],
    canvass: {
        requestId: '',
        preparedBy: '',
        itemsList: [],
    },
    lastEvaluatedKey: '',
};

export const canvassTypedSelector: TypedUseSelectorHook<CanvassReducerType> = useSelector;

export function canvassReducer(state = initialState, action: CanvassActionTypes): CanvassInfoState {
    switch (action.type) {
        case CREATE_NEW_CANVASS:
            return {
                ...initialState,
            };
        case GET_MULTI_CANVASS:
            return {
                ...initialState,
                canvassSheetsList: action.payload.canvassSheetsList,
                lastEvaluatedKey: action.payload.lastEvaluatedKey,
            };
        case GET_CANVASS_BY_ID:
            return {
                ...initialState,
                canvass: action.payload,
            };
        case UPDATE_CANVASS_BY_ID:
            return {
                ...initialState,
                canvass: action.payload,
            };
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
}
