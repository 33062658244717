import { LogoutAction } from 'redux/reducers/rootReducer/types';

export interface RMInfo {
    itemNum: string;
    rmName: string;
    rmCode: string;
    inputDateTime: string;
    rmrrId: string;
    processingStatus: string;
    supplierName: string;
    purchaseOrderNum: string;
    truckPlateNum: string;
    quantity: number;
    quantityUnit: number;
    rmUnitPrice: number;
    netWeight: number;
    truckScaleNum: string;
    letterOfGuarantee?: string;
    approvedBy?: string;
    // Unloading Info
    warehouseId?: string;
    locationId?: string;
    unloadedBy?: string;
    unloadingDatetime?: string;
    receivedBy?: string;
    receptionDatetime?: string;
}

export interface RMQaRma {
    productCode: string;
    rmaNumber: string;
    rmrrId: string;
    rmName: string;
    supplierName: string;
    truckPlateNum: string;
    rmaVanNumber: string;
    inputDateTime: string;
    rmaTimeOne: string;
    rmaTimeTwo: string;
    rmaTimeIn: string;
    rmaTimeOut: string;
    rmaCP: number;
    rmaCPNIR: number;
    rmaMC: number;
    rmaMCNIR: number;
    rmaCfat: number;
    rmaCFNIR: number;
    rmaCA: number;
    rmaCANIR: number;
    rmaCrudeFiber: number;
    rmaCrudeFiberNIR: number;
    rmaAV: number;
    rmaFFA: number;
    rmaStarch: number;
    rmaGluten: number;
    rmaPH: number;
    rmaTemp: number;
    rmaUrease: number;
    rmaUreaTest: number;
    rmaTVBN: number;
    rmaBrix: number;
    rmaImpurities: number;
    rmaAfla: number;
    rmaSalmonella: string;
    rmaWaterActivity: number;
    rmaDensity: number;
    rmaMeshTen: number;
    rmaMeshTwenty: number;
    rmaMeshThirty: number;
    rmaMeshForty: number;
    rmaMeshSixty: number;
    rmaClass: string;
    rmaSampler: string;
    rmaAnalyst: string;
    rmaStatus: string;
    rmaRemarks: string;
}

export interface RMAnalysisInfo {
    collectedBy?: string;
    collectionDatetime?: string;
    analyzedBy?: string;
    analysisDatetime?: string;
    analysisResult?: string;
    analysisResultDatetime?: string;
    remarks?: string;
    recommendation?: string;
    recommendedBy?: string;
    recommendationDetails?: string;
    rmAnalysisInfo?: RMQaRma;
    qaAttachment?: string;
}

export interface RMUnloadingInfo {
    warehouseId?: string;
    locationId?: string;
    unloadedBy?: string;
    unloadingDatetime?: Date;
    receivedBy?: string;
    receptionDatetime?: Date;
}

export interface RMTruckInspectionItem {
    item_id: string;
    inspection_result: string;
    remark: string;
    action_taken: string;
}

export interface RMTruckInspectionInfo {
    truckPlateNum?: string;
    vehicleType?: string;
    containerNum?: string;
    rmName?: string;
    inspectionDatetime?: Date;
    supplierName?: string;
    inspectedBy?: string;
    notedBy?: string;
    conformedBy?: string;
    inspectionDecision?: string;
    inspectionItems?: Array<RMTruckInspectionItem>;
}

export interface RMShipmentApproval {
    approval: string;
    approvedBy: string;
}

export interface LocationInfo {
    warehouseId: string;
    locationId: string;
    weightCapacity: number;
    rmAssignment: string;
    bagNum: number;
    netWeight: number;
}

export interface RawMaterial {
    rmCode: string;
    rmName: string;
}

export interface GetLetterPresignedUrlParams {
    rmrrId: string;
    fileName: string;
}

export interface GetRMQAPresignedUrlParams {
    rmrrId: string;
    fileName: string;
}

export interface RMShipmentInfo extends RMInfo {
    analysisInfo?: RMAnalysisInfo;
    truckInspectionInfo?: RMTruckInspectionInfo;
}

export interface RMShipmentInfoState {
    shipments: Array<RMShipmentInfo>;
    warehouseInfo: Array<LocationInfo>;
    rmList: Array<RawMaterial>;
}

export interface RMShipmentReducerType {
    rmshipment: RMShipmentInfoState;
}

export const GET_RM_SHIPMENTS = 'GET_RM_SHIPMENTS';
export const GET_SINGLE_RM_SHIPMENT = 'GET_SINGLE_RM_SHIPMENTS';
export const POST_NEW_RM_SHIPMENT = 'POST_NEW_RM_SHIPMENT';
export const PATCH_RM_SHIPMENT_ANALYSIS_INFO = 'PATCH_RM_SHIPMENT_ANALYSIS_INFO';
export const PATCH_RM_SHIPMENT_TRUCK_INSPECTION = 'PATCH_RM_SHIPMENT_TRUCK_INSPECTION';
export const PATCH_RM_SHIPMENT_UNLOADING_INFO = 'PATCH_RM_SHIPMENT_UNLOADING_INFO';
export const PATCH_RM_SHIPMENT_APPROVAL = 'PATCH_RM_SHIPMENT_APPROVAL';
export const POST_TRUCK_INSPECTION = 'POST_TRUCK_INSPECTION';

export const GET_WAREHOUSE_INFO = 'GET_WAREHOUSE_INFO';
export const GET_RM_INFO_LIST = 'GET_RM_INFO_LIST';
export const DELETE_RM_SHIPMENT_BY_RMRR_ID = 'DELETE_RM_SHIPMENT_BY_RMRR_ID';

interface GetRMShipmentsAction {
    type: typeof GET_RM_SHIPMENTS;
    payload: Array<RMShipmentInfo>;
}

interface GetWarehouseInfoAction {
    type: typeof GET_WAREHOUSE_INFO;
    payload: Array<LocationInfo>;
}

interface GetRmInfoListAction {
    type: typeof GET_RM_INFO_LIST;
    payload: Array<RawMaterial>;
}

export type RMShipmentActionTypes = GetRMShipmentsAction | GetWarehouseInfoAction | GetRmInfoListAction | LogoutAction;
