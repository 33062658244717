import { getCookie } from 'utils/cookies'
import { ILoggedUser } from './interface';
import {
    UserRoleInfo,
    RolePermissionsToRoleDepartmentMap,
    RoleDepartmentToFeatureToMap,
    RolePermissions,
    RoleDepartments,
    RoleFeatures
} from 'redux/reducers/users/types'

export const LoggedUser: ILoggedUser = {

    getfirstName: (): string => {
        return getCookie('firstName')
    },

    getLastName: (): string => {
        return getCookie('lastName')
    },

    getFullName: (): string => {
        return `${getCookie('firstName')} ${getCookie('lastName')}`
    },

    getRole: (): string => {
        return getCookie('role')
    },

    getEmail: (): string => {
        return getCookie('email')
    },

    getFeatureAccessPermission: (): RoleFeatures[] => {
        let userRole = getCookie('role') as RolePermissions;
        let roleDepartment = RolePermissionsToRoleDepartmentMap.get(userRole) as RoleDepartments;
        let roleFeatures = RoleDepartmentToFeatureToMap.get(roleDepartment) as RoleFeatures[];
        return roleFeatures
    },

    getPermission: (): UserRoleInfo => {
        let permissionList = localStorage.getItem('permission');
        let role = getCookie('role');
        let ret: UserRoleInfo = {
            roleName: '',
            rmAccess: 'NO_ACCESS',
            fgAccess: 'NO_ACCESS',
            rmChecklistAccess: 'NO_ACCESS',
            fgChecklistAccess: 'NO_ACCESS',
            qcRMAccess: 'NO_ACCESS',
            qcFGAccess: 'NO_ACCESS',
            prodAccess: 'NO_ACCESS',
            dashAccess: 'NO_ACCESS'
        };
        if (permissionList) {
            let list: Array<UserRoleInfo> = JSON.parse(permissionList);
            let found = list.find((value) => {
                return value.roleName === role
            })
            if (found) {
                ret = found;
            }
        }
        return ret;

    }
};
