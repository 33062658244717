import { Button, Grid, TextField } from "@material-ui/core";
import {
    ItemInfo,
    OrderedItemWithPriceInfo,
    PurchaseOrderInfoRequest,
    PurchaseRequestInfoList,
    SupplierInfoList,
} from "redux/reducers/purchasing/types";
import React, { Fragment, useEffect, useState } from "react";
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles";
import {
    addPurchaseOrderAction,
    getAllPurchaseRequestsAction,
    getAllSuppliersAction,
} from "redux/reducers/purchasing/actions";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import Alert from "@material-ui/lab/Alert";
import BooleanRepresentationRenderer from "components/common/renderers/BooleanRepresentationRenderer";
import CancelIcon from "@material-ui/icons/Cancel";
import NumericEditor from "components/common/editors/NumericEditor";
import { RowNode } from "ag-grid";
import SelectComponent from "components/common/Select";
import TableGrid from "components/common/TableGrid";
import { grey } from "@material-ui/core/colors";
import { purchasingTypedSelector } from "redux/reducers/purchasing/purchasingReducer";
import { useDispatch } from "react-redux";

export interface ContentProps extends WithStyles<typeof styles> {
    onFormClose: () => void;
    onAddFormSetData: () => void;
    onCancel: () => void;
}
interface OrderedItemWithSupplierCheck extends OrderedItemWithPriceInfo {
    isSupplied: boolean;
}

const PurchaseOrderAddView = (props: ContentProps) => {
    const { onFormClose, onAddFormSetData, onCancel } = props;
    const [newRequestId, setNewRequestId] = useState("");
    const [newRequestIdHelper, setNewRequestIdHelper] = useState("");
    const [newTerms, setNewTerms] = useState("");
    const [newTermsHelper, setNewTermsHelper] = useState("");
    const [newSupplier, setNewSupplier] = useState("");
    const [newSupplierHelper, setNewSupplierHelper] = useState("");
    const [newShipTo, setNewShipTo] = useState("");
    const [newShipToHelper, setNewShipToHelper] = useState("");
    const [newOrderItemList, setNewOrderItemList] = useState<Array<OrderedItemWithSupplierCheck>>([]);
    const [newReviewedBy, setNewReviewedBy] = useState("");
    const [newReviewedByHelper, setNewReviewedByHelper] = useState("");
    const [newTotal, setNewTotal] = useState(0);
    const [newPreparedBy, setNewPreparedBy] = useState("");
    const [newPreparedByHelper, setNewPreparedByHelper] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [supplierNamesList, setSupplierNamesList] = useState<Array<string>>([]);
    const [itemsBySupplierList, setItemsBySupplierList] = useState<Array<ItemInfo>>([]);
    const [isSuppliersInfoAcquired, setIsSuppliersInfoAcquired] = useState(false);
    const [isRequestInfoAcquired, setIsRequestInfoAcquired] = useState(false);
    const [requestIdList, setRequestIdList] = useState<Array<string>>([]);
    const dispatcher = useDispatch();

    const purchaseRequestInfoList = purchasingTypedSelector<PurchaseRequestInfoList>(
        (state) => state.purchasing.purchaseRequests
    );
    const suppliersInfoList = purchasingTypedSelector<SupplierInfoList>((state) => state.purchasing.suppliers);

    const isEditable = (param: any) => {
        return param.data.isSupplied;
    };

    let columnDefs = [
        {
            headerName: "Provided by Supplier?",
            field: "isSupplied",
            minWidth: 100,
            filter: "agTextColumnFilter",
            headerTooltip: "Indicates if item is supplied by the Supplier",
            cellRenderer: "booleanRepresentationRenderer",
        },
        {
            headerName: "Item Code",
            field: "itemCode",
            minWidth: 100,
            filter: "agTextColumnFilter",
            headerTooltip: "Item Code",
        },
        {
            headerName: "Description",
            field: "itemDescription",
            minWidth: 250,
            filter: "agTextColumnFilter",
            headerTooltip: "Item Description",
        },
        {
            headerName: "Unit",
            field: "itemUnit",
            minWidth: 50,
            filter: "agTextColumnFilter",
            headerTooltip: "Unit",
        },
        {
            headerName: "Quantity",
            field: "itemQuantity",
            minWidth: 50,
            filter: "agTextColumnFilter",
            headerTooltip: "Quantity",
            editable: isEditable,
            cellEditor: "numericEditor",
            valueGetter: (param: any) => {
                return param.data.itemQuantity;
            },
            valueSetter: (param: any) => {
                param.data.itemQuantity = Number(param.newValue);
                return true;
            },
        },
        {
            headerName: "Unit Price",
            minWidth: 50,
            filter: "agTextColumnFilter",
            headerTooltip: "Unit Price",
            editable: isEditable,
            cellEditor: "numericEditor",
            valueGetter: (param: any) => {
                return param.data.itemUnitPrice;
            },
            valueSetter: (param: any) => {
                param.data.itemUnitPrice = Number(param.newValue);
                param.data.itemTotalPrice = param.data.itemQuantity * param.newValue;
                return true;
            },
        },
        {
            headerName: "Total Price",
            field: "itemTotalPrice",
            minWidth: 50,
            filter: "agTextColumnFilter",
            headerTooltip: "Total Price",
        },
    ];

    useEffect(() => {
        if (suppliersInfoList.suppliersList.length > 0) {
            setSupplierNamesList(suppliersInfoList.suppliersList.map((info) => info.supplierName));
        } else if (!isSuppliersInfoAcquired) {
            setIsSuppliersInfoAcquired(true);
            dispatcher(getAllSuppliersAction());
        }
    }, [suppliersInfoList, isSuppliersInfoAcquired, dispatcher]);

    useEffect(() => {
        if (purchaseRequestInfoList.requestsList.length > 0) {
            setRequestIdList(
                purchaseRequestInfoList.requestsList
                    .filter((request) => request.approvedBy !== "" && request.approvedBy !== undefined)
                    .map((info) => info.requestId)
            );
        } else if (!isRequestInfoAcquired) {
            setIsRequestInfoAcquired(true);
            dispatcher(getAllPurchaseRequestsAction());
        }
    }, [purchaseRequestInfoList, isRequestInfoAcquired, dispatcher]);

    useEffect(() => {
        if (
            newRequestId !== "" ||
            newTerms !== "" ||
            newSupplier !== "" ||
            newShipTo !== "" ||
            newReviewedBy !== "" ||
            newPreparedBy !== ""
        ) {
            onAddFormSetData();
        }
    }, [newRequestId, newTerms, newSupplier, newShipTo, newReviewedBy, newPreparedBy, onAddFormSetData]);

    const onCellValueChanged = (event: any) => {
        let rowData: Array<OrderedItemWithSupplierCheck> = [];
        event.api.forEachNode((node: RowNode) => rowData.push(node.data));
        let sum = 0;
        rowData.forEach((element) => {
            if (element.isSupplied === true) {
                sum = sum + element.itemTotalPrice;
            }
        });
        setNewTotal(sum);
    };

    const resetHelpers = () => {
        setNewRequestIdHelper("");
        setNewTermsHelper("");
        setNewSupplierHelper("");
        setNewShipToHelper("");
        setNewReviewedByHelper("");
        setNewPreparedByHelper("");
        setErrorMessage("");
    };

    const checkInputValidity = () => {
        if (
            newRequestId === "" ||
            newTerms === "" ||
            newSupplier === "" ||
            newShipTo === "" ||
            newReviewedBy === "" ||
            newPreparedBy === "" ||
            newTotal <= 0
        ) {
            if (newRequestId === "") setNewRequestIdHelper("Please input value");
            if (newTerms === "") setNewTermsHelper("Please input value");
            if (newSupplier === "") setNewSupplierHelper("Please input value");
            if (newShipTo === "") setNewShipToHelper("Please input value");
            if (newReviewedBy === "") setNewReviewedByHelper("Please input value");
            if (newPreparedBy === "") setNewPreparedByHelper("Please input value");
            if (newTotal <= 0) setErrorMessage("Please input items to order. Current Total: " + newTotal);
            return false;
        }
        return true;
    };

    const addNewOrder = () => {
        if (checkInputValidity()) {
            let filteredItemsList: Array<OrderedItemWithPriceInfo> = [];
            newOrderItemList.forEach((element) => {
                if (element.isSupplied === true && element.itemTotalPrice > 0) {
                    let addItem = {
                        itemCode: element.itemCode,
                        itemDescription: element.itemDescription,
                        itemUnit: element.itemUnit,
                        itemQuantity: element.itemQuantity,
                        itemUnitPrice: element.itemUnitPrice,
                        itemTotalPrice: element.itemTotalPrice,
                    };
                    filteredItemsList = [...filteredItemsList, addItem];
                }
            });
            let addOrder: PurchaseOrderInfoRequest = {
                requestId: newRequestId,
                supplierName: newSupplier,
                shipTo: newShipTo,
                terms: newTerms,
                reviewedBy: newReviewedBy,
                preparedBy: newPreparedBy,
                total: newTotal,
                itemsList: filteredItemsList,
            };
            dispatcher(addPurchaseOrderAction(addOrder));
            resetHelpers();
            onFormClose();
        }
    };

    const handleSelectedRequestChange = (event: any) => {
        let requestId = event as string;
        if (requestId !== "") {
            let requestInstance = purchaseRequestInfoList.requestsList.find((info) => info.requestId === requestId);
            if (requestInstance) {
                let itemsList = requestInstance.itemsList;
                let itemWithPrice = itemsList.map((item) => ({
                    isSupplied: false,
                    itemCode: item.itemCode,
                    itemDescription: item.itemDescription,
                    itemUnit: item.itemUnit,
                    itemQuantity: item.itemQuantity,
                    itemUnitPrice: 0,
                    itemTotalPrice: 0,
                }));
                // Check if items of Purchase Request can be ordered from the supplier

                if (itemsBySupplierList.length > 0) {
                    itemWithPrice.forEach((element) => {
                        if (itemsBySupplierList.find((supplierItem) => supplierItem.itemCode === element.itemCode)) {
                            element.isSupplied = true;
                        }
                    });
                }
                setNewOrderItemList(itemWithPrice);
            }
        }
        setNewRequestId(requestId);
        setNewRequestIdHelper("");
        setNewTotal(0);
    };

    const handleSelectedSupplierChange = (event: any) => {
        let supplierName = event as string;
        if (supplierName !== "") {
            let supplierInstance = suppliersInfoList.suppliersList.find((info) => info.supplierName === supplierName);
            if (supplierInstance) {
                let requestInstance = purchaseRequestInfoList.requestsList.find(
                    (info) => info.requestId === newRequestId
                );
                if (requestInstance) {
                    let itemsList = requestInstance.itemsList;
                    let itemWithPrice = itemsList.map((item) => ({
                        isSupplied: false,
                        itemCode: item.itemCode,
                        itemDescription: item.itemDescription,
                        itemUnit: item.itemUnit,
                        itemQuantity: item.itemQuantity,
                        itemUnitPrice: 0,
                        itemTotalPrice: 0,
                    }));
                    // Check if items of Purchase Request can be ordered from the supplier

                    if (supplierInstance.itemsList.length > 0) {
                        itemWithPrice.forEach((element) => {
                            if (
                                supplierInstance!.itemsList.find(
                                    (supplierItem) => supplierItem.itemCode === element.itemCode
                                )
                            ) {
                                element.isSupplied = true;
                            }
                        });
                    }
                    setNewOrderItemList(itemWithPrice);
                }
                setItemsBySupplierList(supplierInstance.itemsList);
            }
        }
        setNewSupplier(supplierName);
        setNewSupplierHelper("");
        setNewTotal(0);
    };

    const rowStyleCallback = (param: any) => {
        if (!param.data.isSupplied) {
            return { background: grey[300] };
        }
        return {};
    };

    const renderPurchaseOrderDetails = () => {
        return (
            <Fragment>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <SelectComponent
                            title={"Request #"}
                            selectList={requestIdList}
                            setter={handleSelectedRequestChange}
                            currentValue={newRequestId}
                            error={newRequestIdHelper !== ""}
                            variant={"standard"}
                            codeHelperText={newRequestIdHelper}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label={"Terms"}
                            value={newTerms}
                            helperText={newTermsHelper}
                            error={newTermsHelper !== ""}
                            onChange={(event) => {
                                setNewTerms(event.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={3} />
                    <Grid item xs={6}>
                        <SelectComponent
                            title={"Supplier"}
                            selectList={supplierNamesList}
                            setter={handleSelectedSupplierChange}
                            currentValue={newSupplier}
                            error={newSupplierHelper !== ""}
                            variant={"standard"}
                            codeHelperText={newSupplierHelper}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label={"Ship To"}
                            value={newShipTo}
                            helperText={newShipToHelper}
                            error={newShipToHelper !== ""}
                            onChange={(event) => {
                                setNewShipTo(event.target.value);
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableGrid
                            rowData={newOrderItemList}
                            columnDefs={columnDefs}
                            height={300}
                            frameworkComponents={{
                                booleanRepresentationRenderer: BooleanRepresentationRenderer,
                                numericEditor: NumericEditor,
                            }}
                            getRowStyle={rowStyleCallback}
                            onCellValueChanged={onCellValueChanged}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label={"Reviewed By"}
                            value={newReviewedBy}
                            helperText={newReviewedByHelper}
                            error={newReviewedByHelper !== ""}
                            onChange={(event) => {
                                setNewReviewedBy(event.target.value);
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4} />
                    <Grid item xs={2}>
                        <TextField
                            label={"Total"}
                            variant={"outlined"}
                            value={newTotal}
                            inputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label={"Prepared By"}
                            value={newPreparedBy}
                            helperText={newPreparedByHelper}
                            error={newPreparedByHelper !== ""}
                            onChange={(event) => {
                                setNewPreparedBy(event.target.value);
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={6}>
                        <Button
                            onClick={() => {
                                resetHelpers();
                                addNewOrder();
                            }}
                            color="primary"
                            variant="contained"
                            fullWidth={true}
                            startIcon={<AddCircleIcon />}
                        >
                            Create
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            onClick={onCancel}
                            color="primary"
                            variant="contained"
                            startIcon={<CancelIcon />}
                            fullWidth={true}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        {errorMessage !== "" && <Alert severity="error">{errorMessage}</Alert>}
                    </Grid>
                </Grid>
            </Fragment>
        );
    };

    return <div style={{ padding: 10 }}>{renderPurchaseOrderDetails()}</div>;
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: "99%",
            margin: "auto",
            overflow: "hidden",
            alignContent: "center",
        },
        searchBar: {
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: "block",
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: "rgb(31,49,69)",
            alignSelf: "center",
        },
        contentWrapper: {
            margin: "0px 0px",
        },
    });

export default withStyles(styles)(PurchaseOrderAddView);
