import React, { Fragment, useEffect } from 'react';

import CheckIcon from '@material-ui/icons/Check';
import { Grid } from '@material-ui/core';
import TableGrid from 'components/common/TableGrid';
import { TransferTransactionInfo } from 'redux/reducers/finishedGoods/types';
import { finishedGoodsTypedSelector } from 'redux/reducers/finishedGoods/finishedGoodsReducer';
import { getRmStockTransferTransactionAction } from 'redux/reducers/finishedGoods/actions';
import moment from 'moment';
import { useDispatch } from 'react-redux';

export interface WarehouseReportBinTransfersProps {
    rmrrId: string;
}

const WarehouseReportBinTransfers = (props: WarehouseReportBinTransfersProps) => {
    const { rmrrId } = props;
    const dispatcher = useDispatch();

    const transactions = finishedGoodsTypedSelector<Array<TransferTransactionInfo>>(
        (state) => state.finishedGoods.transactions
    );

    useEffect(() => {
        dispatcher(getRmStockTransferTransactionAction(rmrrId));
    }, [rmrrId, dispatcher]);

    useEffect(() => {}, [transactions]);

    const dateRenderer = (date: any) => {
        if (date.value) {
            return moment(date.value).format('MM/DD/YYYY');
        }
        return '';
    };

    const firstShift = (shift: any) => {
        if (shift.value === '1') {
            return <CheckIcon />;
        }
        return null;
    };

    const secondShift = (shift: any) => {
        if (shift.value === '2') {
            return <CheckIcon />;
        }
        return null;
    };

    const thirdShift = (shift: any) => {
        if (shift.value === '3') {
            return <CheckIcon />;
        }
        return null;
    };

    const salesShift = (shift: any) => {
        if (shift.value === 'Sales') {
            return <CheckIcon />;
        }
        return null;
    };

    const columnDefs: Array<any> = [
        {
            headerName: 'Date',
            field: 'transferDatetime',
            filter: 'agDateColumnFilter',
            headerTooltip: 'Date',
            minWidth: 200,
            cellRenderer: dateRenderer,
            getQuickFilterText: dateRenderer,
        },
        {
            headerName: 'Balance',
            children: [
                {
                    headerName: 'Kgs',
                    field: 'balanceWeight',
                    filter: 'agNumberColumnFilter',
                    headerTooltip: 'Balance Kgs',
                    minWidth: 150,
                },
            ],
        },
        {
            headerName: 'Transfer',
            children: [
                {
                    headerName: 'kgs',
                    field: 'transferredWeight',
                    filter: 'agNumberColumnFilter',
                    headerTooltip: 'Transfer Kgs',
                    minWidth: 150,
                },
            ],
        },
        {
            headerName: 'Shift',
            children: [
                {
                    headerName: '1st',
                    field: 'shift',
                    headerTooltip: 'First Shift',
                    minWidth: 70,
                    cellRendererFramework: firstShift,
                },
                {
                    headerName: '2nd',
                    field: 'shift',
                    headerTooltip: '2nd Shift',
                    minWidth: 70,
                    cellRendererFramework: secondShift,
                },
                {
                    headerName: '3rd',
                    field: 'shift',
                    headerTooltip: '3rd Shift',
                    minWidth: 70,
                    cellRendererFramework: thirdShift,
                },
                {
                    headerName: 'Sales',
                    field: 'shift',
                    headerTooltip: 'Sales',
                    minWidth: 70,
                    cellRendererFramework: salesShift,
                },
            ],
        },
        {
            headerName: 'Autodosing',
            children: [
                {
                    headerName: '1',
                    field: 'autodosing_1',
                    headerTooltip: 'Autodosing 1',
                    sortable: false,
                },
                {
                    headerName: '2',
                    field: 'autodosing_2',
                    headerTooltip: 'Autodosing 2',
                    sortable: false,
                },
                {
                    headerName: '3',
                    field: 'autodosing_3',
                    headerTooltip: 'Autodosing 3',
                    sortable: false,
                },
                {
                    headerName: '4',
                    field: 'autodosing_4',
                    headerTooltip: 'Autodosing 4',
                    sortable: false,
                },
                {
                    headerName: '5',
                    field: 'autodosing_5',
                    headerTooltip: 'Autodosing 5',
                    sortable: false,
                },
                {
                    headerName: '6',
                    field: 'autodosing_6',
                    headerTooltip: 'Autodosing 6',
                    sortable: false,
                },
                {
                    headerName: '7',
                    field: 'autodosing_7',
                    headerTooltip: 'Autodosing 7',
                    sortable: false,
                },
                {
                    headerName: '8',
                    field: 'autodosing_8',
                    headerTooltip: 'Autodosing 8',
                    sortable: false,
                },
                {
                    headerName: '9',
                    field: 'autodosing_9',
                    headerTooltip: 'Autodosing 9',
                    sortable: false,
                },
            ],
        },
        {
            headerName: 'Newline',
            children: [
                {
                    headerName: '1',
                    field: 'newline_1',
                    headerTooltip: 'Newline 1',
                    sortable: false,
                },
                {
                    headerName: '2',
                    field: 'newline_2',
                    headerTooltip: 'Newline 2',
                    sortable: false,
                },
                {
                    headerName: '3',
                    field: 'newline_3',
                    headerTooltip: 'Newline 3',
                    sortable: false,
                },
                {
                    headerName: '4',
                    field: 'newline_4',
                    headerTooltip: 'Newline 4',
                    sortable: false,
                },
                {
                    headerName: '5',
                    field: 'newline_5',
                    headerTooltip: 'Newline 5',
                    sortable: false,
                },
                {
                    headerName: '6',
                    field: 'newline_6',
                    headerTooltip: 'Newline 6',
                    sortable: false,
                },
                {
                    headerName: '7',
                    field: 'newline_7',
                    headerTooltip: 'Newline 7',
                    sortable: false,
                },
                {
                    headerName: '8',
                    field: 'newline_8',
                    headerTooltip: 'Newline 8',
                    sortable: false,
                },
                {
                    headerName: '9',
                    field: 'newline_9',
                    headerTooltip: 'Newline 9',
                    sortable: false,
                },
            ],
        },
    ];

    return (
        <Fragment>
            <Grid container />

            <TableGrid rowData={transactions} columnDefs={columnDefs} height={400} />
        </Fragment>
    );
};

export default WarehouseReportBinTransfers;
