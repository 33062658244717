import AppController from "controller/application";
import { createContext } from "react";
import { AxiosError } from "axios";

interface ApplicationContextValues {
    app: typeof AppController;
    error?: Error | AxiosError
}

const AppContext = createContext<ApplicationContextValues>({
  app: AppController,
});

export default AppContext;