import React, { Fragment, useState } from 'react';

import { Button } from '@material-ui/core';
import { PurchaseOrderInfo } from 'redux/reducers/purchasing/types';
import TableGrid from 'components/common/TableGrid';
import { GridApi } from 'ag-grid';
import DeleteConfirmation from 'components/common/DeleteConfirmation';

export interface PurchaseOrderListTableProps {
  filterText: string;
  purchaseOrderList: Array<PurchaseOrderInfo>;
  onClickedPurchaseOrder: (orderId: string) => void;
  onDeletedPurchaseOrder?: (orderId: string) => boolean;
}

interface RowInfo {
  api?: GridApi;
  data?: PurchaseOrderInfo;
}

const PurchaseOrderListTable = (props: PurchaseOrderListTableProps) => {
  const [forDeleteRow, setForDeleteRow] = useState<RowInfo | any>({});
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);

  const {
    filterText,
    purchaseOrderList,
    onClickedPurchaseOrder,
    onDeletedPurchaseOrder,
  } = props;

  const orderIdRenderer = (params: { value: string }) => {
    return (
      <Button
        style={{ color: '#009fdc' }}
        onClick={() => {
          if (onClickedPurchaseOrder) {
            onClickedPurchaseOrder(params.value);
          }
        }}
      >
        {params.value}
      </Button>
    );
  };

  const itemsListConcatenatedRenderer = (data: any) => {
    let itemList = '';
    if (data.value.length > 0) {
      data.value.forEach((item: any, index: number) => {
        if (index > 0) {
          itemList += ', ';
        }
        let itemWithDescription = `(${item.itemCode}) ${item.itemDescription}`;
        itemList = itemList + itemWithDescription;
      });
    }
    return itemList;
  };

  const columnDefs = [
    {
      headerName: 'Order #',
      field: 'orderId',
      minWidth: 300,
      filter: 'agTextColumnFilter',
      cellRendererFramework: orderIdRenderer,
    },
    {
      headerName: 'Request #',
      field: 'requestId',
      minWidth: 300,
      filter: 'agTextColumnFilter',
      headerTooltip: 'Request #',
    },
    {
      headerName: 'Items',
      field: 'itemsList',
      minWidth: 500,
      maxWidth: 1000,
      filter: 'agTextColumnFilter',
      headerTooltip: 'Items',
      cellRenderer: itemsListConcatenatedRenderer,
    },
    {
      headerName: 'Supplier',
      field: 'supplierName',
      minWidth: 150,
      filter: 'agTextColumnFilter',
      headerTooltip: 'Supplier',
    },
    {
      headerName: 'Terms',
      field: 'terms',
      minWidth: 150,
      filter: 'agTextColumnFilter',
      headerTooltip: 'Terms',
    },
    {
      headerName: 'Ship To',
      field: 'shipTo',
      minWidth: 150,
      filter: 'agTextColumnFilter',
      headerTooltip: 'Ship To',
    },
    {
      headerName: 'Reviewed By',
      field: 'reviewedBy',
      minWidth: 200,
      filter: 'agTextColumnFilter',
      headerTooltip: 'Reviewed By',
    },
    {
      headerName: 'Prepared By',
      field: 'preparedBy',
      minWidth: 200,
      filter: 'agTextColumnFilter',
      headerTooltip: 'Prepared By',
    },
    {
      headerName: 'Approved By',
      field: 'approvedBy',
      minWidth: 200,
      filter: 'agTextColumnFilter',
      headerTooltip: 'Approved By',
    },
    {
      headerName: 'Status ',
      field: 'orderStatus',
      minWidth: 200,
      filter: 'agTextColumnFilter',
      headerTooltip: 'Status',
    },
  ];

  return (
    <Fragment>
      <TableGrid
        rowData={purchaseOrderList}
        columnDefs={columnDefs}
        filterText={filterText}
        height={600}
        deleteColumn={onDeletedPurchaseOrder ? true : false}
        onDeleteRow={(row) => {
          setForDeleteRow(row)
          setOpenDeleteConfirmation(true)
          return false
        }}
      />
      {openDeleteConfirmation && (
        <DeleteConfirmation     
          title='Delete Purchase Order'
          data={forDeleteRow}
          onDelete={(data: any) => {
            if (onDeletedPurchaseOrder ? onDeletedPurchaseOrder(forDeleteRow!.data!.orderId) : false) {
              let {data, api} = forDeleteRow

              api!.updateRowData({ remove: [data] })
              api!.refreshCells({ force: true });
            }

            setOpenDeleteConfirmation(false);
            setForDeleteRow({})
          }}
          onCancel={(data: any) => {
            setOpenDeleteConfirmation(false)
            setForDeleteRow({})
          }}
          message={forDeleteRow!.data!.orderId}
        />
      )}

    </Fragment>
  );
};

export default PurchaseOrderListTable;
