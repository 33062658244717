import React, { Fragment, useEffect, useState } from 'react';
import {
    disableSingleUserAction,
    enableSingleUserAction,
    getAllRegisteredUserAction,
    patchSingleUserInfoAction,
} from 'redux/reducers/users/actions';

import TableGrid from 'components/common/TableGrid';
import { useDispatch } from 'react-redux';
import { userRegistrationTypedSelector } from 'redux/reducers/users/usersReducer';

export interface UserListTableProps {
    filterText: string;
    exportCsv?: boolean;
    onExportCsv?: any;
    exportCsvFileName?: string;
}

const UserListTable = (props: UserListTableProps) => {
    const { filterText, exportCsv, onExportCsv, exportCsvFileName } = props;

    const dispatcher = useDispatch();
    const users = userRegistrationTypedSelector<Array<any>>((state) => state.users.users);
    // Note: Disable Role as editable. Not yet supported
    // const roles = userRegistrationTypedSelector<Array<UserRoleInfo>>(state => state.users.roles);

    const [usersList, setUsersList] = useState<Array<any>>([]);
    // Note: Disable Role as editable. Not yet supported
    // const [rolesList, setRolesList] = useState<Array<string>>([])

    useEffect(() => {
        if (users.length > 0) {
            setUsersList(users);
        } else if (users.length === 0) {
            dispatcher(getAllRegisteredUserAction());
        }
    }, [users]); // eslint-disable-line

    // Note: Disable Role as editable. Not yet supported
    // useEffect(() => {
    //     if (roles.length === 0) {
    //         dispatcher(getUserRolesInfoAction())
    //     } else {
    //         let list = roles.map(role => role.roleName)
    //         setRolesList(list)
    //     }
    // }, [roles, dispatcher])

    const onCellValueChanged = (event: any) => {
        if (event.newValue === event.data.userFirstName || event.newValue === event.data.userLastName) {
            dispatcher(patchSingleUserInfoAction(event.data));
        } else if (event.newValue === 'enabled') {
            dispatcher(enableSingleUserAction(event.data));
        } else if (event.newValue === 'disabled') {
            dispatcher(disableSingleUserAction(event.data));
        }
    };

    const columnDefs = [
        {
            headerName: 'First Name',
            field: 'userFirstName',
            minWidth: 100,
            editable: true,
        },
        {
            headerName: 'Last Name',
            field: 'userLastName',
            minWidth: 100,
            editable: true,
        },
        { headerName: 'E-mail Address', field: 'email', minWidth: 100 },
        {
            headerName: 'Role',
            field: 'userRole',
            minWidth: 100,
            // Note: Disable Role as editable. Not yet supported
            // editable: true,
            // cellEditor: 'agSelectCellEditor',
            // cellEditorParams: {
            //     values: rolesList
            // },
        },
        {
            headerName: 'Status',
            field: 'userStatus',
            minWidth: 50,
            maxWidth: 150,
            editable: true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['enabled', 'disabled'],
            },
        },
    ];

    return (
        <Fragment>
            <TableGrid
                rowData={usersList}
                columnDefs={columnDefs}
                filterText={filterText}
                exportCsv={exportCsv}
                onExportCsv={onExportCsv}
                exportCsvFileName={exportCsvFileName}
                onCellValueChanged={onCellValueChanged}
                height={600}
            />
        </Fragment>
    );
};

export default UserListTable;
