import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
} from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';

import { RMShipmentInfo } from 'redux/reducers/rmShipment/types';
import SelectComponent from 'components/common/Select';
import { TransferTransactionInfo } from 'redux/reducers/finishedGoods/types';
import { addRmStockTransferTransactionAction } from 'redux/reducers/finishedGoods/actions';
import { finishedGoodsTypedSelector } from 'redux/reducers/finishedGoods/finishedGoodsReducer';
import moment from 'moment';
import { rmShipmentTypedSelector } from 'redux/reducers/rmShipment/rmShipmentReducer';
import { useDispatch } from 'react-redux';

export interface WarehouseReportBinDetailsProps {
    rmrrId: string;
}

const WarehouseReportBinDetails = (props: WarehouseReportBinDetailsProps) => {
    const { rmrrId } = props;
    const dispatcher = useDispatch();

    const shipments = rmShipmentTypedSelector<Array<RMShipmentInfo>>((state) => state.rmshipment.shipments);
    const transactions = finishedGoodsTypedSelector<Array<TransferTransactionInfo>>(
        (state) => state.finishedGoods.transactions
    );

    const [shipment, setShipment] = useState<RMShipmentInfo>({
        itemNum: '',
        rmName: '',
        rmCode: '',
        inputDateTime: '',
        rmrrId: '',
        processingStatus: '',
        supplierName: '',
        purchaseOrderNum: '',
        truckPlateNum: '',
        quantity: 0,
        quantityUnit: 0,
        rmUnitPrice: 0,
        netWeight: 0,
        truckScaleNum: '',
    });

    useEffect(() => {
        let info = shipments.find((shipment) => shipment.rmrrId === rmrrId);
        if (info) {
            setShipment(info);
        }
    }, [shipments, rmrrId]);

    const [fields, setFields] = useState<Array<any>>([]);

    const getQuantity = (num: number) => {
        if (num === 1) return 'Bulk';
        if (num === 2) return 'Mega';
        if (num === 3) return 'Drums';
        return 'Bag';
    };

    const [openTransfer, setOpenTransfer] = useState(false);
    const [balanceWeight, setBalanceWeight] = useState(0);
    const [helperText, setHelperText] = useState('');
    const [transferWeightError, setTransferWeightError] = useState(false);
    const [transferWeight, setTransferWeight] = useState('');

    useEffect(() => {
        if (transactions.length > 0) {
            setBalanceWeight(transactions[transactions.length - 1].balanceWeight);
        } else {
            setBalanceWeight(shipment.netWeight);
        }
    }, [transactions, shipment]);

    const handleTransferWeightChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        let weight = parseInt(event.target.value as string);
        if (weight > balanceWeight || weight < 1) {
            setTransferWeightError(true);
            setHelperText(`Transfer weight should be from 1kg to ${balanceWeight}kgs`);
        } else {
            setTransferWeightError(false);
            setHelperText('');
            setTransferWeight(event.target.value as string);
        }
    };

    const [shift, setShift] = useState<any>(1);
    const executeTransfer = () => {
        let value: any = 1;
        if (shift === '2nd shift') value = 2;
        else if (shift === '3rd shift') value = 3;
        else if (shift === 'Sales') value = 'Sales';
        dispatcher(
            addRmStockTransferTransactionAction(
                shipment.warehouseId ? shipment.warehouseId : '',
                shipment.locationId ? shipment.locationId : '',
                {
                    stockSourceList: [rmrrId],
                    weight: parseInt(transferWeight),
                    shift: value.toString(),
                }
            )
        );
        setOpenTransfer(false);
    };

    const transferModal = () => {
        return (
            <div>
                <Dialog disableEscapeKeyDown open={openTransfer} onClose={() => setOpenTransfer(false)}>
                    <DialogTitle>Transfer Details</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            margin='dense'
                            style={{ minWidth: 300 }}
                            type={'number'}
                            label={'Weight to transfer (kg)'}
                            variant='outlined'
                            helperText={helperText}
                            error={transferWeightError}
                            onChange={handleTransferWeightChange}
                        />
                        <SelectComponent
                            title={'Shift'}
                            selectList={['1st shift', '2nd shift', '3rd shift', 'Sales']}
                            setter={setShift}
                            currentValue={shift}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={executeTransfer} color='primary' variant='contained'>
                            Execute Transfer
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    const transferButton = () => {
        return (
            <Button fullWidth variant='contained' color='primary' onClick={() => setOpenTransfer(true)}>
                Make A Transfer
            </Button>
        );
    };

    useEffect(() => {
        setFields([
            {
                entry: [
                    { label: 'Date', value: moment(shipment.inputDateTime).format('MM-DD-YYYY') },
                    { label: 'Raw Material Name', value: shipment.rmName },
                    { label: 'Name of Supplier', value: shipment.supplierName },
                    { label: 'Location', value: shipment.locationId },
                ],
            },
            {
                entry: [
                    { label: 'RMRR #', value: shipment.rmrrId },
                    { label: 'DR/SI Number', value: 'TODO' },
                    { label: 'Source Weight', value: 'TODO' },
                    { label: 'Truckscale Ticket #', value: shipment.truckScaleNum },
                ],
            },
            {
                entry: [
                    { label: 'Quantity', value: getQuantity(shipment.quantity) },
                    {
                        label: 'Container Van #',
                        value: shipment.truckInspectionInfo ? shipment.truckInspectionInfo.containerNum : '',
                    },
                    { label: 'Truck Scale Weight', value: 'TODO' },
                    { label: 'Plate #', value: shipment.truckPlateNum },
                ],
            },
            {
                entry: [
                    { label: 'Weight of Bags', value: 'TODO' },
                    { label: 'Net Weight', value: shipment.netWeight },
                    {},
                    { button: transferButton },
                ],
            },
        ]);
    }, [shipment]);

    const renderSection = (entries: Array<any>) => {
        return (
            <Container style={{ padding: 5, paddingBottom: 10 }}>
                {entries.map(({ entry }) => (
                    <Grid container alignItems='center' spacing={2}>
                        {entry.map(({ label, value, button }: any) => {
                            return (
                                <Grid item xs={6} sm={3}>
                                    {label && (
                                        <TextField
                                            fullWidth
                                            margin='dense'
                                            value={value}
                                            label={label}
                                            variant='outlined'
                                            disabled
                                        />
                                    )}
                                    {button && button()}
                                </Grid>
                            );
                        })}
                    </Grid>
                ))}
            </Container>
        );
    };

    return (
        <Fragment>
            {renderSection(fields)}
            {transferModal()}
        </Fragment>
    );
};

export default WarehouseReportBinDetails;
