import * as URL_CONST from 'utils/urls';

import { Avatar, Box, Button, Container, CssBaseline, Grid, Link, TextField, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';

import AppContext from 'components/app-context';
import CircularProgress from '@material-ui/core/CircularProgress';
import Copyright from 'components/common/Copyright';
import { INTERNAL_ERROR } from 'utils/strings';
import { RouterNavigation } from 'utils/navigation';
import { getError } from 'utils/validation/validation';
import { makeStyles } from '@material-ui/core/styles';

const LoginPage = () => {
    const classes = useStyles();
    const logo = require('static/images/logo270.png');

    const { app } = useContext(AppContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [displayError, setDisplayError] = useState('');

    useEffect(() => {
        if (isLoggingIn) {
            processLogin();
        }
    }, [isLoggingIn]); // eslint-disable-line

    const login = () => {
        setIsLoggingIn(true);
    };

    const processLogin = () => {
        // Login and redirect to Main Page
        app.login({
            email,
            password,
        })
            .then((data) => {
                if (data && data.ChallengeName === 'NEW_PASSWORD_REQUIRED') {
                    RouterNavigation.navigateToWithProps(URL_CONST.UPDATE_PASSWORD, { data });
                } else {
                    RouterNavigation.navigateTo(URL_CONST.MAIN);
                }
            })
            .catch((error) => {
                setIsLoggingIn(false);
                if (error.response && error.response.status) {
                    setDisplayError(getError(error.response.status, error.response.data.Message.Reason));
                } else {
                    setDisplayError(INTERNAL_ERROR);
                }
            });
    };

    const passwordRef = React.createRef<HTMLDivElement>();
    return (
        <Container component='main' maxWidth='xs'>
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar} src={logo} />
                <Typography component='h1' variant='h5'>
                    Hoc Po Inventory System
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        id='email'
                        label='Email Address'
                        name='email'
                        autoComplete='email'
                        autoFocus
                        onChange={(event) => {
                            setEmail(event.target.value);
                            setIsLoggingIn(false);
                            setDisplayError('');
                        }}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter' && passwordRef.current) {
                                passwordRef.current.focus();
                            }
                        }}
                    />
                    <TextField
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        name='password'
                        label='Password'
                        type='password'
                        id='password'
                        autoComplete='current-password'
                        inputRef={passwordRef}
                        onChange={(event) => {
                            setPassword(event.target.value);
                            setIsLoggingIn(false);
                            setDisplayError('');
                        }}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                login();
                            }
                        }}
                    />

                    {displayError !== '' && (
                        <Typography
                            style={{
                                fontSize: 12,
                                color: 'red',
                                fontFamily: 'arial',
                            }}>
                            {displayError}
                        </Typography>
                    )}

                    <Button
                        fullWidth
                        variant='contained'
                        color={isLoggingIn ? 'default' : 'primary'}
                        className={classes.submit}
                        onClick={login}>
                        {isLoggingIn ? <CircularProgress size='24px' /> : 'Sign In'}
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link
                                href=''
                                onMouseUp={() => {
                                    RouterNavigation.navigateTo(URL_CONST.FORGOT_PASSWORD);
                                }}
                                variant='body2'>
                                Forgot password?
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        height: 180,
        width: 180,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default LoginPage;
