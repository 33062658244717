import { applyMiddleware, createStore, Middleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers/rootReducer/rootReducer';
// import { createLogger } from 'redux-logger';

export default function configureStore() {
    let middleware: Array<Middleware> = [thunkMiddleware];

    // const loggerMiddleware = createLogger();
    // middleware = [...middleware, loggerMiddleware];

    let store = createStore(rootReducer, applyMiddleware(...middleware));

    return store;
}
