import { Button, Grid, TextField } from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { PremixInfo, PremixInfoList, PremixInfoRequest, PremixMaterialInfo } from "redux/reducers/premix/types";
import React, { Fragment, useEffect, useState } from "react";
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles";
import { addPremixAction, getAllPremixAction } from "redux/reducers/premix/actions";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import Alert from "@material-ui/lab/Alert";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteRowRenderer from "components/common/renderers/DeleteRowRenderer";
import MomentUtils from "@date-io/moment";
import NumericEditor from "components/common/editors/NumericEditor";
import { RowNode } from "ag-grid";
import TableGrid from "components/common/TableGrid";
import moment from "moment";
import { premixTypedSelector } from "redux/reducers/premix/premixReducer";
import { useDispatch } from "react-redux";

export interface ContentProps extends WithStyles<typeof styles> {
    onFormClose: () => void;
    onAddFormSetData: () => void;
    onCancel: () => void;
}

const PremixAddView = (props: ContentProps) => {
    const { onFormClose, onAddFormSetData, onCancel } = props;
    const [newPremixName, setNewPremixName] = useState("");
    const [newPremixNameHelper, setNewPremixNameHelper] = useState("");
    const [newFeedType, setNewFeedType] = useState("");
    const [newFeedTypeHelper, setNewFeedTypeHelper] = useState("");
    const [newRevisionNumber, setNewRevisionNumber] = useState(0);
    const [newLine, setNewLine] = useState("");
    const [newLineHelper, setNewLineHelper] = useState("");
    const [newDatePrepared, setNewDatePrepared] = useState<Date>(new Date());
    const [newTotalWeight, setNewTotalWeight] = useState(0);
    const [newPreparedBy, setNewPreparedBy] = useState("");
    const [newPreparedByHelper, setNewPreparedByHelper] = useState("");
    const [newCheckedBy, setNewCheckedBy] = useState("");
    const [newCheckedByHelper, setNewCheckedByHelper] = useState("");
    const [newNotedBy, setNewNotedBy] = useState("");
    const [newNotedByHelper, setNewNotedByHelper] = useState("");
    const [newMaterialList, setNewMaterialList] = useState<Array<PremixMaterialInfo>>([]);
    const [errorMessage, setErrorMessage] = useState("");
    const dispatcher = useDispatch();
    const [premixList, setPremixList] = useState<Array<PremixInfo> | []>([]);
    const [isAcquired, setIsAcquired] = useState(false);
    const premixListResponse = premixTypedSelector<PremixInfoList>((state) => state.premix.premixList);

    useEffect(() => {
        if (premixListResponse.premixes.length > 0) {
            setPremixList(premixListResponse.premixes);
        } else if (!isAcquired) {
            setIsAcquired(true);
            dispatcher(getAllPremixAction());
        }
    }, [premixListResponse, isAcquired, dispatcher]);

    let columnDefs = [
        {
            headerName: "Action",
            width: 100,
            cellRenderer: "deleteRowRenderer",
            suppressSizeToFit: true,
        },
        {
            headerName: "Material",
            field: "rmCode",
            minWidth: 150,
            filter: "agTextColumnFilter",
            headerTooltip: "Material",
            editable: true,
        },
        {
            headerName: "Used",
            field: "rmUsed",
            minWidth: 200,
            filter: "agTextColumnFilter",
            headerTooltip: "Used",
            editable: true,
        },
        {
            headerName: "Set Weight (kgs)",
            field: "rmWeight",
            minWidth: 50,
            filter: "agTextColumnFilter",
            headerTooltip: "Set Weight",
            editable: true,
            cellEditor: "numericEditor",
        },
    ];

    useEffect(() => {
        if (
            newPremixName !== "" ||
            newFeedType !== "" ||
            newLine !== "" ||
            newPreparedBy !== "" ||
            newCheckedBy !== "" ||
            newNotedBy !== "" ||
            newMaterialList.length > 0
        ) {
            onAddFormSetData();
        }
    }, [
        newPremixName,
        newFeedType,
        newLine,
        newPreparedBy,
        newCheckedBy,
        newNotedBy,
        newMaterialList,
        onAddFormSetData,
    ]);

    const onCellValueChanged = (event: any) => {
        let rowData: Array<PremixMaterialInfo> = [];
        event.api.forEachNode((node: RowNode) => rowData.push(node.data));
        setNewMaterialList(rowData);

        let sum = 0;
        rowData.forEach((element) => {
            if (element.rmWeight) {
                sum = sum + Number(element.rmWeight);
            }
        });
        setNewTotalWeight(sum);
    };

    const onRowDataUpdated = (event: any) => {
        let rowData: Array<PremixMaterialInfo> = [];
        event.api.forEachNode((node: RowNode) => rowData.push(node.data));
        setNewMaterialList(rowData);

        let sum = 0;
        rowData.forEach((element) => {
            if (element.rmWeight) {
                sum = sum + element.rmWeight;
            }
        });
        setNewTotalWeight(sum);
    };

    const isMaterialListValid = () => {
        let ret = true;

        // Copy the contents of the material list but remove empty rows
        let filteredMaterialList: Array<PremixMaterialInfo> = [];
        newMaterialList.forEach((element) => {
            // if at least one attribute is not undefined
            if (element.rmCode || element.rmUsed || element.rmWeight) {
                let addItem = {
                    rmCode: element.rmCode ? element.rmCode! : "",
                    rmUsed: element.rmUsed ? element.rmUsed! : "",
                    rmWeight: element.rmWeight ? element.rmWeight! : 0,
                };
                filteredMaterialList = [...filteredMaterialList, addItem];
            }
        });

        // Check if empty list
        if (filteredMaterialList.length === 0) {
            setErrorMessage("No materials added");
            return false;
        }

        // Check for duplicate rm codes
        let seen = new Set();
        var hasDuplicates = filteredMaterialList.some(function(currentItem) {
            return seen.size === seen.add(currentItem.rmCode).size;
        });

        if (!hasDuplicates) {
            // Check for empty value
            for (let i = 0; i < filteredMaterialList.length; i++) {
                let element = filteredMaterialList[i];
                let rowItemCode = element.rmCode;
                if (rowItemCode === "") {
                    setErrorMessage("Please set the code for : Material " + (i + 1));
                    ret = false;
                    break;
                }
                if (element.rmWeight <= 0) {
                    setErrorMessage("Please set the weight for " + rowItemCode);
                    ret = false;
                    break;
                }
            }
        } else {
            setErrorMessage("Duplicate materials detected");
            ret = false;
        }

        return ret;
    };

    const resetHelpers = () => {
        setNewPremixNameHelper("");
        setNewFeedTypeHelper("");
        setNewLineHelper("");
        setNewPreparedByHelper("");
        setNewCheckedByHelper("");
        setNewNotedByHelper("");
        setErrorMessage("");
    };

    const isPremixNameExisting = () => {
        let isExisting = premixList.find((premix) => premix.premixName === newPremixName);
        return isExisting ? true : false;
    };

    const checkInputValidity = () => {
        let isMaterialsValid = isMaterialListValid();
        let isDuplicatePremix = isPremixNameExisting();
        if (
            isDuplicatePremix === true ||
            newPremixName === "" ||
            newFeedType === "" ||
            newLine === "" ||
            newPreparedBy === "" ||
            newCheckedBy === "" ||
            newNotedBy === "" ||
            newTotalWeight <= 0 ||
            isMaterialsValid === false
        ) {
            if (isDuplicatePremix === true) setNewPremixNameHelper("Premix name already exists");
            if (newPremixName === "") setNewPremixNameHelper("Please set value");
            if (newFeedType === "") setNewFeedTypeHelper("Please set value");
            if (newLine === "") setNewLineHelper("Please set value");
            if (newPreparedBy === "") setNewPreparedByHelper("Please set value");
            if (newCheckedBy === "") setNewCheckedByHelper("Please set value");
            if (newNotedBy === "") setNewNotedByHelper("Please set value");
            if (newTotalWeight <= 0 && errorMessage === "")
                setErrorMessage("Please set weight for the materials. Current total: " + newTotalWeight);

            return false;
        }
        return true;
    };

    const addNewPremix = () => {
        if (checkInputValidity()) {
            let filteredMaterialList: Array<PremixMaterialInfo> = [];
            newMaterialList.forEach((element) => {
                // if at least one attribute is not undefined
                if (element.rmCode || element.rmUsed || element.rmWeight) {
                    let addItem = {
                        rmCode: element.rmCode ? element.rmCode! : "",
                        rmUsed: element.rmUsed ? element.rmUsed! : "",
                        rmWeight: element.rmWeight ? element.rmWeight! : 0,
                    };
                    filteredMaterialList = [...filteredMaterialList, addItem];
                }
            });
            let strDate = moment(newDatePrepared!).format("YYYY-MM-DD");
            let addPremixRequest: PremixInfoRequest = {
                premixName: newPremixName,
                feedType: newFeedType,
                line: newLine,
                datePrepared: strDate,
                rawMaterials: filteredMaterialList,
                preparedBy: newPreparedBy,
                checkedBy: newCheckedBy,
                notedBy: newNotedBy,
                rmTotalWeight: newTotalWeight,
                revisionNumber: newRevisionNumber,
            };
            dispatcher(addPremixAction(addPremixRequest));
            resetHelpers();
            onFormClose();
        }
    };

    const renderPremixDetails = () => {
        return (
            <Fragment>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={3}>
                            <TextField
                                label={"Name"}
                                value={newPremixName}
                                helperText={newPremixNameHelper}
                                error={newPremixNameHelper !== ""}
                                onChange={(event) => {
                                    setNewPremixName(event.target.value);
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                label={"Feed Type"}
                                value={newFeedType}
                                helperText={newFeedTypeHelper}
                                error={newFeedTypeHelper !== ""}
                                onChange={(event) => {
                                    setNewFeedType(event.target.value);
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                label={"Revision #"}
                                value={newRevisionNumber}
                                type={"number"}
                                onChange={(event: any) => {
                                    setNewRevisionNumber(event.target.value);
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                label={"Line"}
                                value={newLine}
                                helperText={newLineHelper}
                                error={newLineHelper !== ""}
                                onChange={(event) => {
                                    setNewLine(event.target.value);
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <DatePicker
                                disableFuture={false}
                                value={newDatePrepared}
                                onChange={(date) => {
                                    setNewDatePrepared(moment(date).toDate());
                                }}
                                format="MMM DD, YYYY"
                                inputVariant="standard"
                                onClose={() => {}}
                                label={"Date Prepared"}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TableGrid
                                rowData={newMaterialList}
                                columnDefs={columnDefs}
                                height={300}
                                showAddRowButton={true}
                                onCellValueChanged={onCellValueChanged}
                                onRowDataUpdated={onRowDataUpdated}
                                frameworkComponents={{
                                    deleteRowRenderer: DeleteRowRenderer,
                                    numericEditor: NumericEditor,
                                }}
                            />
                        </Grid>
                        <Grid item xs={8} />
                        <Grid item xs={2}>
                            <TextField
                                label={"Total Weight"}
                                variant={"outlined"}
                                value={newTotalWeight}
                                inputProps={{
                                    readOnly: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                label={"Prepared By"}
                                value={newPreparedBy}
                                helperText={newPreparedByHelper}
                                error={newPreparedByHelper !== ""}
                                onChange={(event) => {
                                    setNewPreparedBy(event.target.value);
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                label={"Checked By"}
                                value={newCheckedBy}
                                helperText={newCheckedByHelper}
                                error={newCheckedByHelper !== ""}
                                onChange={(event) => {
                                    setNewCheckedBy(event.target.value);
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextField
                                label={"Noted By"}
                                value={newNotedBy}
                                helperText={newNotedByHelper}
                                error={newNotedByHelper !== ""}
                                onChange={(event) => {
                                    setNewNotedBy(event.target.value);
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                onClick={() => {
                                    resetHelpers();
                                    addNewPremix();
                                }}
                                color="primary"
                                variant="contained"
                                fullWidth={true}
                                startIcon={<AddCircleIcon />}
                            >
                                Create
                            </Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button
                                onClick={onCancel}
                                color="primary"
                                variant="contained"
                                startIcon={<CancelIcon />}
                                fullWidth={true}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            {errorMessage !== "" && <Alert severity="error">{errorMessage}</Alert>}
                        </Grid>
                    </Grid>
                </MuiPickersUtilsProvider>
            </Fragment>
        );
    };

    return <div style={{ padding: 10 }}>{renderPremixDetails()}</div>;
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: "99%",
            margin: "auto",
            overflow: "hidden",
            alignContent: "center",
        },
        searchBar: {
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: "block",
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: "rgb(31,49,69)",
            alignSelf: "center",
        },
        contentWrapper: {
            margin: "0px 0px",
        },
    });

export default withStyles(styles)(PremixAddView);
