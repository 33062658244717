export const LOGIN_PAGE = "/";
export const MAIN = "/main";
export const UPDATE_PASSWORD = "/update-password";
export const FORGOT_PASSWORD = "/forgot-password";

const getDomain = (): string => {
    switch (String(process.env.REACT_APP_ENV)) {
        case "dev":
            return "dev.hocpo.link";
        case "prod":
            return "hocpo.link";
        default:
            return "localhost";
    }
};

const getAPIEndpointUrl = (): string => {
    switch (String(process.env.REACT_APP_ENV)) {
        case "dev":
            return "https://dev.api.hocpo.link";
        case "prod":
            return "https://api.hocpo.link";
        default:
            return "https://dev.api.hocpo.link";
    }
};

// Domain
export const SERVICE_DOMAIN = getDomain();
// API Endpoint URL
export const API_ENDPOINT_URL = getAPIEndpointUrl();
