import {
    AppBar,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Paper,
    TextField,
    Theme,
    Toolbar,
    Tooltip,
    Typography,
    WithStyles,
    createStyles,
    withStyles,
} from "@material-ui/core";
import { PremixInfo, PremixInfoList } from "redux/reducers/premix/types";
import React, { Fragment, useEffect, useState } from "react";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import PremixAddView from "./PremixAddView";
import PremixDetailsView from "./PremixDetailsView";
import PremixListTable from "./PremixListTable";
import PremixUpdateView from "./PremixUpdateView";
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from "@material-ui/icons/Search";
import { getAllPremixAction } from "redux/reducers/premix/actions";
import { premixTypedSelector } from "redux/reducers/premix/premixReducer";
import { useDispatch } from "react-redux";

export interface ContentProps extends WithStyles<typeof styles> {}

const viewState = {
    premixListView: "premixListView",
    premixShowDetailsView: "premixShowDetailsView",
    premixAddView: "premixAddView",
    premixUpdateView: "premixUpdateView",
};

let _gPremixList: Array<PremixInfo> | [] = [];
const PremixListView = (props: ContentProps) => {
    const { classes } = props;
    const [filterText, setfilterText] = useState("");
    const [clickedPremixName, setClickedPremixName] = useState("");
    const [clickedPremixDetails, setClickedPremixDetails] = useState<PremixInfo | undefined>(undefined);
    const [premixList, setPremixList] = useState<Array<PremixInfo> | []>([]);
    const [onReload, setOnReload] = useState(false);
    const [isAcquired, setIsAcquired] = useState(false);
    const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);
    const [view, setView] = useState(viewState.premixListView);
    const dispatcher = useDispatch();
    const [addFormHasData, setAddFormHasData] = useState(false);

    const premixListResponse = premixTypedSelector<PremixInfoList>((state) => state.premix.premixList);

    const isView = (checkView: string) => {
        return view === checkView;
    };

    useEffect(() => {
        if (premixListResponse.premixes.length > 0) {
            setPremixList(premixListResponse.premixes);
            _gPremixList = premixListResponse.premixes;
        } else if (!isAcquired) {
            setIsAcquired(true);
            dispatcher(getAllPremixAction());
        }
    }, [premixListResponse, isAcquired, dispatcher]);

    useEffect(() => {
        if (premixList.length > 0) {
            setTimeout(() => {
                setOnReload(false);
            }, 500);
        }
    }, [premixList]);

    const onClickedPremix = (premixName: string) => {
        setClickedPremixName(premixName);
        setClickedPremixDetails(_gPremixList.find((data) => data.premixName === premixName));
        setView(viewState.premixShowDetailsView);
    };

    const revertToListView = () => {
        setTimeout(() => {
            dispatcher(getAllPremixAction());
        }, 1000);

        setClickedPremixName("");
        setClickedPremixDetails(undefined);
        setAddFormHasData(false);
        setView(viewState.premixListView);
    };

    const onCloseConfirmCancelModal = () => {
        setShowConfirmCancelModal(false);
    };

    const confirmCancelInAddUpdate = () => {
        return (
            <div>
                <Dialog
                    disableEscapeKeyDown={true}
                    disableBackdropClick={true}
                    open={showConfirmCancelModal}
                    onClose={onCloseConfirmCancelModal}
                >
                    <DialogTitle>
                        <IconButton
                            className={classes.rightButton}
                            aria-label="close"
                            onClick={onCloseConfirmCancelModal}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="h6" component="h1" align="center">
                            {"This action will discard the current input information. Proceed?"}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                onCloseConfirmCancelModal();
                                if (isView(viewState.premixUpdateView)) {
                                    setView(viewState.premixShowDetailsView);
                                } else {
                                    revertToListView();
                                }
                            }}
                            color="primary"
                            variant="contained"
                            fullWidth={true}
                        >
                            Yes
                        </Button>
                        <Button
                            onClick={onCloseConfirmCancelModal}
                            color="primary"
                            variant="contained"
                            fullWidth={true}
                        >
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    const renderPremixListTableButton = () => {
        return (
            <Grid item>
                <Tooltip title="Create Premix Template">
                    <IconButton onClick={() => setView(viewState.premixAddView)}>
                        <NoteAddIcon className={classes.block} color="inherit" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Reload">
                    <IconButton
                        onClick={() => {
                            setPremixList([]);
                            _gPremixList = [];
                            setOnReload(true);
                            dispatcher(getAllPremixAction());
                        }}
                    >
                        {onReload ? (
                            <CircularProgress size="24px" />
                        ) : (
                            <RefreshIcon className={classes.block} color="inherit" />
                        )}
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    };

    const renderPremixListTableSearch = () => {
        return (
            <Fragment>
                <Grid item>
                    <SearchIcon className={classes.block} color="inherit" />
                </Grid>
                <Grid item xs>
                    <TextField
                        fullWidth
                        placeholder="Search"
                        InputProps={{
                            disableUnderline: true,
                            className: classes.searchInput,
                        }}
                        onChange={(event) => setfilterText(event.target.value)}
                    />
                </Grid>
            </Fragment>
        );
    };

    const renderPremixListTableContainer = () => {
        return (
            <Grid container spacing={2} alignItems="center">
                {renderPremixListTableSearch()}
                {renderPremixListTableButton()}
            </Grid>
        );
    };

    const renderPremixListTable = () => {
        return (
            <div className={classes.contentWrapper}>
                <PremixListTable onClickedPremix={onClickedPremix} premixList={premixList} filterText={filterText} />
            </div>
        );
    };

    const renderPremixDetailsContainer = () => {
        return (
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Tooltip title="Return / Cancel">
                        <IconButton onClick={revertToListView}>
                            <ArrowBackIosIcon className={classes.block} color="inherit" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    {clickedPremixName && (
                        <Typography color="primary" variant="h5" component="h1">
                            {`Premix: ${clickedPremixName} `}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        );
    };

    const renderPremixDetailsView = () => {
        return (
            <div className={classes.contentWrapper}>
                <PremixDetailsView
                    clickedPremix={clickedPremixDetails}
                    onClickedUpdateButton={() => {
                        setView(viewState.premixUpdateView);
                    }}
                    onClickedDeleteButton={() => {
                        revertToListView();
                    }}
                />
            </div>
        );
    };

    const renderPremixAddContainer = () => {
        return (
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Tooltip title="Cancel">
                        <IconButton
                            onClick={() => {
                                if (addFormHasData) {
                                    setShowConfirmCancelModal(true);
                                    confirmCancelInAddUpdate();
                                } else {
                                    revertToListView();
                                }
                            }}
                        >
                            <ArrowBackIosIcon className={classes.block} color="inherit" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Typography color="primary" variant="h5" component="h1">
                        {" Create Premix Template"}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    const onAddFormSetData = () => {
        setAddFormHasData(true);
    };

    const renderPremixAddView = () => {
        return (
            <div className={classes.contentWrapper}>
                <PremixAddView
                    onFormClose={revertToListView}
                    onAddFormSetData={onAddFormSetData}
                    onCancel={() => {
                        if (addFormHasData) {
                            setShowConfirmCancelModal(true);
                            confirmCancelInAddUpdate();
                        } else {
                            revertToListView();
                        }
                    }}
                />
            </div>
        );
    };

    const renderPremixUpdateContainer = () => {
        return (
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Tooltip title="Cancel">
                        <IconButton
                            onClick={() => {
                                setShowConfirmCancelModal(true);
                                confirmCancelInAddUpdate();
                            }}
                        >
                            <ArrowBackIosIcon className={classes.block} color="inherit" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Typography color="primary" variant="h5" component="h1">
                        {`Update Premix: ${clickedPremixName} `}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    const renderPremixUpdateView = () => {
        return (
            <div className={classes.contentWrapper}>
                <PremixUpdateView
                    onFormClose={revertToListView}
                    onCancel={() => {
                        setShowConfirmCancelModal(true);
                        confirmCancelInAddUpdate();
                    }}
                    clickedPremix={clickedPremixDetails}
                />
            </div>
        );
    };

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                <Toolbar>
                    {isView(viewState.premixListView) && renderPremixListTableContainer()}
                    {isView(viewState.premixShowDetailsView) && clickedPremixDetails && renderPremixDetailsContainer()}
                    {(isView(viewState.premixAddView) || isView(viewState.premixUpdateView)) &&
                        confirmCancelInAddUpdate()}
                    {isView(viewState.premixAddView) && renderPremixAddContainer()}
                    {isView(viewState.premixUpdateView) && renderPremixUpdateContainer()}
                </Toolbar>
            </AppBar>
            {isView(viewState.premixListView) && renderPremixListTable()}
            {isView(viewState.premixShowDetailsView) && clickedPremixDetails && renderPremixDetailsView()}
            {isView(viewState.premixAddView) && renderPremixAddView()}
            {isView(viewState.premixUpdateView) && clickedPremixDetails && renderPremixUpdateView()}
        </Paper>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: "99%",
            margin: "auto",
            overflow: "hidden",
            alignContent: "center",
        },
        searchBar: {
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: "block",
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: "rgb(31,49,69)",
            alignSelf: "center",
        },
        contentWrapper: {
            margin: "0px 0px",
        },
        rightButton: {
            float: "right",
        },
    });

export default withStyles(styles)(PremixListView);
