import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';

abstract class ApiController {
    protected _instance: AxiosInstance;

    constructor(config: AxiosRequestConfig) {
        this._instance = axios.create(config);
    }

    updateAuthorizationHeader(token: string) {
        this._instance.defaults.headers.common['Authorization'] = token;
    }
}

export default ApiController;