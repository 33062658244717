import * as URL_CONST from 'utils/urls';

import { Avatar, Box, Button, Container, CssBaseline, Grid, Link, TextField, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';

import Alert from '@material-ui/lab/Alert/Alert';
import AppContext from 'components/app-context';
import CircularProgress from '@material-ui/core/CircularProgress';
import Copyright from 'components/common/Copyright';
import { INTERNAL_ERROR } from 'utils/strings';
import { RouterNavigation } from 'utils/navigation';
import { getError } from 'utils/validation/validation';
import { makeStyles } from '@material-ui/core/styles';

const ForgotPasswordPage = () => {
    const classes = useStyles();
    const logo = require('static/images/logo270.png');

    const { app } = useContext(AppContext);
    const [email, setEmail] = useState('');
    const [isSendingToEmail, setIsSendingToEmail] = useState(false);
    const [displayError, setDisplayError] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(false);

    useEffect(() => {
        if (isSendingToEmail) {
            processSendToEmail();
        }
    }, [isSendingToEmail]); // eslint-disable-line

    useEffect(() => {
        if (email !== '') {
            setIsValidEmail(checkEmailFormat());
        }
    }, [email]); // eslint-disable-line

    useEffect(() => {
        isValidEmail ? setDisplayError('') : setDisplayError('Please enter a valid email');
    }, [isValidEmail]);

    const sendToEmail = () => {
        setIsSendingToEmail(true);
    };

    const processSendToEmail = () => {
        // Send password reset request to email and redirect to Login Page
        app.passwordReset(email)
            .then(() => {
                RouterNavigation.navigateTo(URL_CONST.LOGIN_PAGE);
            })
            .catch((error) => {
                setIsSendingToEmail(false);
                if (error.response && error.response.status) {
                    setDisplayError(getError(error.response.status, error.response.data.Message.Reason));
                } else {
                    setDisplayError(INTERNAL_ERROR);
                }
            });
    };

    const checkEmailFormat = () => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
            return true;
        }
        return false;
    };

    return (
        <Container component='main' maxWidth='xs'>
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar} src={logo} />
                <Typography component='h1' variant='h5'>
                    Reset Password
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        id='reset-email'
                        label='Email Address'
                        name='reset-email'
                        autoComplete='email'
                        autoFocus
                        onChange={(event) => {
                            setEmail(event.target.value);
                            setIsSendingToEmail(false);
                        }}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                sendToEmail();
                            }
                        }}
                    />
                    {displayError !== '' && (
                        <Typography
                            style={{
                                fontSize: 12,
                                color: 'red',
                                fontFamily: 'arial',
                            }}>
                            {displayError}
                        </Typography>
                    )}

                    <Button
                        fullWidth
                        variant='contained'
                        disabled={!isValidEmail}
                        color={isSendingToEmail ? 'default' : 'primary'}
                        className={classes.submit}
                        onClick={sendToEmail}>
                        {isSendingToEmail ? <CircularProgress size='24px' /> : 'Submit'}
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link
                                href=''
                                onMouseUp={() => {
                                    RouterNavigation.navigateTo(URL_CONST.LOGIN_PAGE);
                                }}
                                variant='body2'>
                                Back to Login
                            </Link>
                        </Grid>
                    </Grid>
                    <Alert severity='info'>
                        We will send information to the e-mail address for resetting your password
                    </Alert>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        height: 180,
        width: 180,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default ForgotPasswordPage;
