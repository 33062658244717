import {
    CREATE_NEW_CANVASS,
    Canvass,
    CanvassInfoState,
    GET_CANVASS_BY_ID,
    GET_MULTI_CANVASS,
    UPDATE_CANVASS_BY_ID,
    DELETE_CANVASS_SHEET
} from './types';

import { Action } from 'redux';
import AppController from 'controller/application';
import { SET_API_MESSAGE } from '../apiMessage/types';
import { ThunkAction } from 'redux-thunk';

async function processCreateNewCanvass(canvass: Canvass) {
    return AppController.createNewCanvass(canvass);
}

async function processGetMultipleCanvass(lastEvaluatedKey: string) {
    return AppController.getMultipleCanvass(lastEvaluatedKey);
}

async function processGetCanvassByID(id: string) {
    return AppController.getCanvassByID(id);
}

async function processUpdateCanvassByID(id: string, canvass: Canvass) {
    return AppController.updateCanvassByID(id, canvass);
}

async function processDeleteCanvassByIDAction(requestId: string) {
    return AppController.deleteCanvassByID(requestId);
}

export function createNewCanvassAction(canvass: Canvass): ThunkAction<void, CanvassInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processCreateNewCanvass(canvass);
        if (resp.message) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `${resp.message}`,
                    variant: 'error',
                },
            });
        } else {
            dispatch({
                type: CREATE_NEW_CANVASS,
                payload: resp,
            });
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `Canvass # ${canvass.requestId} created successfully`,
                    variant: 'success',
                },
            });
        }
    };
}

export function getMultipleCanvassAction(
    lastEvaluatedKey: string
): ThunkAction<void, CanvassInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processGetMultipleCanvass(lastEvaluatedKey);
        dispatch({
            type: GET_MULTI_CANVASS,
            payload: resp,
        });
    };
}

export function getCanvassByIDAction(id: string): ThunkAction<void, CanvassInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processGetCanvassByID(id);
        dispatch({
            type: GET_CANVASS_BY_ID,
            payload: resp,
        });
    };
}

export function updateCanvassByIDAction(
    id: string,
    canvass: Canvass
): ThunkAction<void, CanvassInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processUpdateCanvassByID(id, canvass);
        dispatch({
            type: UPDATE_CANVASS_BY_ID,
            payload: resp,
        });
        dispatch({
            type: SET_API_MESSAGE,
            payload: {
                message: `Canvass ${id} is updated`,
                variant: 'success',
            },
        });
    };
}


export function deleteCanvassByIDAction(
    requestId: string
): ThunkAction<void, CanvassInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processDeleteCanvassByIDAction(requestId);
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: resp.response.data.reason,
                    variant: "error",
                },
            });
        } else {
            dispatch({
                type: DELETE_CANVASS_SHEET,
                payload: {},
            });
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `Deleted successfully`,
                    variant: 'success',
                },
            });
        }
    };
}
