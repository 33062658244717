import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { LOGIN_PAGE } from 'utils/urls';
import { getCookie } from 'utils/cookies'

import { useDispatch } from 'react-redux'
import { logoutAction } from 'redux/reducers/rootReducer/actions';

interface PrivateRouteProps {
    component?: any;
    children?: any;
    path: string;
}

const PrivateRoute = (props: PrivateRouteProps) => {
    
    const { component: Component, children, ...rest } = props;
    
    const dispatcher = useDispatch();

    const isSessionAvailable = () => {
        let available = getCookie('accessToken')
        if(!available) {
            dispatcher(logoutAction())
            return false
        }
        return true
    }

    return (
        <Route
            {...rest}
            render={(routeProps: any) =>
                isSessionAvailable() ? (
                    Component ? (
                        <Component {...routeProps} />
                    ) : (
                            children
                        )
                ) : (
                        <Redirect
                            to={{
                                pathname: LOGIN_PAGE,
                                state: { from: routeProps.location },
                            }}
                        />
                    )
            }
        />
    );
};

export default PrivateRoute;