import {
    AppBar,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Paper,
    TextField,
    Theme,
    Toolbar,
    Tooltip,
    Typography,
    WithStyles,
    createStyles,
    withStyles,
} from "@material-ui/core";
import { ProductionOrderInfo, ProductionOrderInfoList } from "redux/reducers/premix/types";
import React, { Fragment, useEffect, useState } from "react";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import ProductionOrderAddView from "./ProductionOrderAddView";
import ProductionOrderDetailsView from "./ProductionOrderDetailsView";
import ProductionOrderListTable from "./ProductionOrderListTable";
import ProductionOrderUpdateView from "./ProductionOrderUpdateView";
import RefreshIcon from "@material-ui/icons/Refresh";
import SearchIcon from "@material-ui/icons/Search";
import { getAllProductionOrderAction } from "redux/reducers/premix/actions";
import { premixTypedSelector } from "redux/reducers/premix/premixReducer";
import { useDispatch } from "react-redux";

export interface ContentProps extends WithStyles<typeof styles> {}

const viewState = {
    productionOrderListView: "productionOrderListView",
    productionOrderShowDetailsView: "productionOrderShowDetailsView",
    productionOrderAddView: "productionOrderAddView",
    productionOrderUpdateView: "productionOrderUpdateView",
};

let _gProductionOrderList: Array<ProductionOrderInfo> | [] = [];
const ProductionOrderListView = (props: ContentProps) => {
    const { classes } = props;
    const [filterText, setfilterText] = useState("");
    const [clickedProductionOrderId, setClickedProductionOrderId] = useState("");
    const [clickedProductionOrderDetails, setClickedProductionOrderDetails] = useState<ProductionOrderInfo | undefined>(
        undefined
    );
    const [productionOrderList, setProductionOrderList] = useState<Array<ProductionOrderInfo> | []>([]);
    const [onReload, setOnReload] = useState(false);
    const [isAcquired, setIsAcquired] = useState(false);
    const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);
    const [view, setView] = useState(viewState.productionOrderListView);
    const dispatcher = useDispatch();
    const [addFormHasData, setAddFormHasData] = useState(false);

    const productionOrderListResponse = premixTypedSelector<ProductionOrderInfoList>(
        (state) => state.premix.productionOrderList
    );

    const isView = (checkView: string) => {
        return view === checkView;
    };

    useEffect(() => {
        if (productionOrderListResponse.prodOrders.length > 0) {
            setProductionOrderList(productionOrderListResponse.prodOrders);
            _gProductionOrderList = productionOrderListResponse.prodOrders;
        } else if (!isAcquired) {
            setIsAcquired(true);
            dispatcher(getAllProductionOrderAction());
        }
    }, [productionOrderListResponse, isAcquired, dispatcher]);

    useEffect(() => {
        if (productionOrderList.length > 0) {
            setTimeout(() => {
                setOnReload(false);
            }, 500);
        }
    }, [productionOrderList]);

    const onClickedProductionOrder = (productionOrderId: string) => {
        setClickedProductionOrderId(productionOrderId);
        setClickedProductionOrderDetails(_gProductionOrderList.find((data) => data.prodOrderId === productionOrderId));
        setView(viewState.productionOrderShowDetailsView);
    };

    const revertToListView = () => {
        setTimeout(() => {
            dispatcher(getAllProductionOrderAction());
        }, 1000);

        setClickedProductionOrderId("");
        setClickedProductionOrderDetails(undefined);
        setAddFormHasData(false);
        setView(viewState.productionOrderListView);
    };

    const onCloseConfirmCancelModal = () => {
        setShowConfirmCancelModal(false);
    };

    const confirmCancelInAddUpdate = () => {
        return (
            <div>
                <Dialog
                    disableEscapeKeyDown={true}
                    disableBackdropClick={true}
                    open={showConfirmCancelModal}
                    onClose={onCloseConfirmCancelModal}
                >
                    <DialogTitle>
                        <IconButton
                            className={classes.rightButton}
                            aria-label="close"
                            onClick={onCloseConfirmCancelModal}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="h6" component="h1" align="center">
                            {"This action will discard the current input information. Proceed?"}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                onCloseConfirmCancelModal();
                                if (isView(viewState.productionOrderUpdateView)) {
                                    setView(viewState.productionOrderShowDetailsView);
                                } else {
                                    revertToListView();
                                }
                            }}
                            color="primary"
                            variant="contained"
                            fullWidth={true}
                        >
                            Yes
                        </Button>
                        <Button
                            onClick={onCloseConfirmCancelModal}
                            color="primary"
                            variant="contained"
                            fullWidth={true}
                        >
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    const renderProductionOrderListTableButton = () => {
        return (
            <Grid item>
                <Tooltip title="Create Production Order">
                    <IconButton onClick={() => setView(viewState.productionOrderAddView)}>
                        <NoteAddIcon className={classes.block} color="inherit" />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Reload">
                    <IconButton
                        onClick={() => {
                            setProductionOrderList([]);
                            _gProductionOrderList = [];
                            setOnReload(true);
                            dispatcher(getAllProductionOrderAction());
                        }}
                    >
                        {onReload ? (
                            <CircularProgress size="24px" />
                        ) : (
                            <RefreshIcon className={classes.block} color="inherit" />
                        )}
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    };

    const renderProductionOrderListTableSearch = () => {
        return (
            <Fragment>
                <Grid item>
                    <SearchIcon className={classes.block} color="inherit" />
                </Grid>
                <Grid item xs>
                    <TextField
                        fullWidth
                        placeholder="Search"
                        InputProps={{
                            disableUnderline: true,
                            className: classes.searchInput,
                        }}
                        onChange={(event) => setfilterText(event.target.value)}
                    />
                </Grid>
            </Fragment>
        );
    };

    const renderProductionOrderListTableContainer = () => {
        return (
            <Grid container spacing={2} alignItems="center">
                {renderProductionOrderListTableSearch()}
                {renderProductionOrderListTableButton()}
            </Grid>
        );
    };

    const renderProductionOrderListTable = () => {
        return (
            <div className={classes.contentWrapper}>
                <ProductionOrderListTable
                    onClickedProductionOrder={onClickedProductionOrder}
                    productionOrderList={productionOrderList}
                    filterText={filterText}
                />
            </div>
        );
    };

    const renderProductionOrderDetailsContainer = () => {
        return (
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Tooltip title="Return / Cancel">
                        <IconButton onClick={revertToListView}>
                            <ArrowBackIosIcon className={classes.block} color="inherit" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    {clickedProductionOrderId && (
                        <Typography color="primary" variant="h5" component="h1">
                            {`Production Order #${clickedProductionOrderId} `}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        );
    };

    const renderProductionOrderDetailsView = () => {
        return (
            <div className={classes.contentWrapper}>
                <ProductionOrderDetailsView
                    clickedProductionOrder={clickedProductionOrderDetails}
                    onClickedUpdateButton={() => {
                        setView(viewState.productionOrderUpdateView);
                    }}
                    onClickedDeleteButton={() => {
                        revertToListView();
                    }}
                />
            </div>
        );
    };

    const renderProductionOrderAddContainer = () => {
        return (
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Tooltip title="Cancel">
                        <IconButton
                            onClick={() => {
                                if (addFormHasData) {
                                    setShowConfirmCancelModal(true);
                                    confirmCancelInAddUpdate();
                                } else {
                                    revertToListView();
                                }
                            }}
                        >
                            <ArrowBackIosIcon className={classes.block} color="inherit" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Typography color="primary" variant="h5" component="h1">
                        {" Create Production Order"}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    const onAddFormSetData = () => {
        setAddFormHasData(true);
    };

    const renderProductionOrderAddView = () => {
        return (
            <div className={classes.contentWrapper}>
                <ProductionOrderAddView
                    onFormClose={revertToListView}
                    onAddFormSetData={onAddFormSetData}
                    onCancel={() => {
                        if (addFormHasData) {
                            setShowConfirmCancelModal(true);
                            confirmCancelInAddUpdate();
                        } else {
                            revertToListView();
                        }
                    }}
                />
            </div>
        );
    };

    const renderProductionOrderUpdateContainer = () => {
        return (
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Tooltip title="Cancel">
                        <IconButton
                            onClick={() => {
                                setShowConfirmCancelModal(true);
                                confirmCancelInAddUpdate();
                            }}
                        >
                            <ArrowBackIosIcon className={classes.block} color="inherit" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Typography color="primary" variant="h5" component="h1">
                        {`Update Production Order #${clickedProductionOrderId} `}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    const renderProductionOrderUpdateView = () => {
        return (
            <div className={classes.contentWrapper}>
                <ProductionOrderUpdateView
                    onFormClose={revertToListView}
                    onCancel={() => {
                        setShowConfirmCancelModal(true);
                        confirmCancelInAddUpdate();
                    }}
                    clickedProductionOrder={clickedProductionOrderDetails}
                />
            </div>
        );
    };

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                <Toolbar>
                    {isView(viewState.productionOrderListView) && renderProductionOrderListTableContainer()}
                    {isView(viewState.productionOrderShowDetailsView) &&
                        clickedProductionOrderDetails &&
                        renderProductionOrderDetailsContainer()}
                    {(isView(viewState.productionOrderAddView) || isView(viewState.productionOrderUpdateView)) &&
                        confirmCancelInAddUpdate()}
                    {isView(viewState.productionOrderAddView) && renderProductionOrderAddContainer()}
                    {isView(viewState.productionOrderUpdateView) && renderProductionOrderUpdateContainer()}
                </Toolbar>
            </AppBar>
            {isView(viewState.productionOrderListView) && renderProductionOrderListTable()}
            {isView(viewState.productionOrderShowDetailsView) &&
                clickedProductionOrderDetails &&
                renderProductionOrderDetailsView()}
            {isView(viewState.productionOrderAddView) && renderProductionOrderAddView()}
            {isView(viewState.productionOrderUpdateView) &&
                clickedProductionOrderDetails &&
                renderProductionOrderUpdateView()}
        </Paper>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: "99%",
            margin: "auto",
            overflow: "hidden",
            alignContent: "center",
        },
        searchBar: {
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: "block",
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: "rgb(31,49,69)",
            alignSelf: "center",
        },
        contentWrapper: {
            margin: "0px 0px",
        },
        rightButton: {
            float: "right",
        },
    });

export default withStyles(styles)(ProductionOrderListView);
