import {
    RMInfo,
    RMAnalysisInfo,
    RMTruckInspectionInfo,
    RMShipmentApproval,
    RMUnloadingInfo,
    GET_RM_SHIPMENTS,
    POST_NEW_RM_SHIPMENT,
    PATCH_RM_SHIPMENT_ANALYSIS_INFO,
    PATCH_RM_SHIPMENT_TRUCK_INSPECTION,
    PATCH_RM_SHIPMENT_APPROVAL,
    PATCH_RM_SHIPMENT_UNLOADING_INFO,
    GET_WAREHOUSE_INFO,
    GET_RM_INFO_LIST,
    DELETE_RM_SHIPMENT_BY_RMRR_ID,
    RMShipmentInfoState
} from './types'

import {
    SET_API_MESSAGE
} from 'redux/reducers/apiMessage/types'

import AppController from "controller/application";

import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'

async function processGetRmShipments() {
    return AppController.getRMShipments()
}

async function processPostNewRmShipment(parameters: RMInfo) {
    return AppController.postNewRmShipment(parameters)
}

async function processRmShipmentAnalysisInfo(parameters: RMAnalysisInfo, rmrrId: string) {
    return AppController.patchRmShipmentAnalysisInfo(parameters, rmrrId)
}

async function processRmTruckInspectionInfo(parameters: RMTruckInspectionInfo, rmrrId: string) {
    return AppController.patchRmTruckInspectionInfo(parameters, rmrrId)
}

async function processRmShipmentApprovalInfo(parameters: RMShipmentApproval, rmrrId: string) {
    return AppController.patchRmShipmentApprovalInfo(parameters, rmrrId)
}

async function processRmShipmentUnloadingInfo(parameters: RMUnloadingInfo, rmrrId: string) {
    return AppController.patchRmShipmentUnloadingInfo(parameters, rmrrId)
}

async function processWarehouseInfo() {
    return AppController.getRMWarehouseLocationInfo()
}

async function processRMInfoList() {
    return AppController.getRMInfoList()
}

async function processDeleteShipmentByRmrrId(rmrrId: string) {
    return AppController.deleteShipmentByRmrrId(rmrrId)
}


export function getRmShipmentsAction():
    ThunkAction<void, RMShipmentInfoState, unknown, Action<string>> {

    return async dispatch => {
        let resp = await processGetRmShipments()
        dispatch({
            type: GET_RM_SHIPMENTS,
            payload: resp
        });
    };
}

export function postNewRmShipmentsAction(parameters: RMInfo):
    ThunkAction<void, RMShipmentInfoState, unknown, Action<string>> {

    return async dispatch => {
        await processPostNewRmShipment(parameters)
        dispatch({
            type: POST_NEW_RM_SHIPMENT,
            payload: {}
        });
    };
}

export function patchRmAnalysisInfoAction(parameters: RMAnalysisInfo, rmrrId: string):
    ThunkAction<void, RMShipmentInfoState, unknown, Action<string>> {

    return async dispatch => {
        let resp = await processRmShipmentAnalysisInfo(parameters, rmrrId)
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: resp.response.data.reason,
                    variant: 'error'
                }
            });
        } else {
            dispatch({
                type: PATCH_RM_SHIPMENT_ANALYSIS_INFO,
                payload: {}
            });
        }
    };
}

export function patchRmTruckInspectionAction(parameters: RMTruckInspectionInfo, rmrrId: string):
    ThunkAction<void, RMShipmentInfoState, unknown, Action<string>> {

    return async dispatch => {
        await processRmTruckInspectionInfo(parameters, rmrrId)
        dispatch({
            type: PATCH_RM_SHIPMENT_TRUCK_INSPECTION,
            payload: {}
        });
    };
}

export function patchRmShipmentApprovalAction(parameters: RMShipmentApproval, rmrrId: string):
    ThunkAction<void, RMShipmentInfoState, unknown, Action<string>> {

    return async dispatch => {
        let resp = await processRmShipmentApprovalInfo(parameters, rmrrId)
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: resp.response.data.reason,
                    variant: 'error'
                }
            });
        } else {
            dispatch({
                type: PATCH_RM_SHIPMENT_APPROVAL,
                payload: {}
            });
        }
    };
}

export function patchRmUnloadingInfoAction(parameters: RMUnloadingInfo, rmrrId: string):
    ThunkAction<void, RMShipmentInfoState, unknown, Action<string>> {

    return async dispatch => {
        let resp = await processRmShipmentUnloadingInfo(parameters, rmrrId)
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: resp.response.data.reason,
                    variant: 'error'
                }
            });
        } else {
            dispatch({
                type: PATCH_RM_SHIPMENT_UNLOADING_INFO,
                payload: {}
            });
        }
    };
}

export function getWarehouseInfoAction():
    ThunkAction<void, RMShipmentInfoState, unknown, Action<string>> {

    return async dispatch => {
        let resp = await processWarehouseInfo()
        dispatch({
            type: GET_WAREHOUSE_INFO,
            payload: resp
        });
    };
}

export function getRMInfoListAction():
    ThunkAction<void, RMShipmentInfoState, unknown, Action<string>> {

    return async dispatch => {
        let resp = await processRMInfoList()
        dispatch({
            type: GET_RM_INFO_LIST,
            payload: resp
        });
    };
}

export function deleteShipmentByRmrrId(
    rmrrId: string
): ThunkAction<void, RMShipmentInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processDeleteShipmentByRmrrId(rmrrId);
        
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: resp.response.data.reason,
                    variant: "error",
                },
            });
        } else {
            dispatch({
                type: DELETE_RM_SHIPMENT_BY_RMRR_ID,
                payload: {},
            });
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `Deleted successfully`,
                    variant: 'success',
                },
            });
        }
    };
}

