import React from 'react'

import {
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    Button
} from '@material-ui/core';

export interface DeleteModalProperties {
    title: string
    message: string
    onDelete: (data: any) => void
    onCancel: (data: any)=> void
    data?: any
}

const DeleteModal = (props: DeleteModalProperties) => {
  const {title, data, message, onDelete, onCancel} = props

    return (
        <div>
        <Dialog
          disableEscapeKeyDown
          open={true}
        >
          <DialogTitle>{title ? title : 'Delete Purchase Request'}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Typography>Are you sure you want to delete {message ? message : ''} ?</Typography>
              </Grid>
              <Grid item xs={6}>

                <Button
                  fullWidth
                  onClick={() => {
                      onDelete(data)
                  }}
                  color="primary"
                  variant="contained"
                >
                  Yes
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  onClick={() => {
                    onCancel(data)
                  }}
                  fullWidth
                  color="secondary"
                  variant="contained"
                >
                  No
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    );
}

export default DeleteModal