import React, { Fragment, useState } from "react";

import CalendarViewMain from "./CalendarView/CalendarViewMain";
import DeliveryScheduleHeader from "./DeliveryScheduleHeader";
import { Typography } from "@material-ui/core";

export interface DeliveryScheduleMainProps {
    onDrawerToggle: () => void;
    mainClass: any;
}

const DeliveryScheduleMain = (props: DeliveryScheduleMainProps) => {
    const TAB1_TITLE = "Delivery Schedules";
    const tabsTitle = [{ id: TAB1_TITLE }];

    const [currentTab, setCurrentTab] = useState(0);
    const onTabsChange = (id: number) => {
        setCurrentTab(id);
    };

    return (
        <Fragment>
            <DeliveryScheduleHeader
                onDrawerToggle={props.onDrawerToggle}
                onTabsChange={onTabsChange}
                tabsTitle={tabsTitle}
            />
            <main className={props.mainClass}>
                {tabsTitle[currentTab].id === TAB1_TITLE && (
                    <Fragment>
                        <Typography style={{ paddingBottom: 10 }} color="inherit" variant="h5" component="h1">
                            {TAB1_TITLE}
                        </Typography>
                        <CalendarViewMain />
                    </Fragment>
                )}
            </main>
        </Fragment>
    );
};

export default DeliveryScheduleMain;
