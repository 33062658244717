import React, { Fragment, useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

export interface BarGraphProps {
    labels?: Array<String>;
    data?: Array<Number>;
    selected?: String;
    legend: string;
    suggestedMax?: number;
}

const BarGraph = (props: BarGraphProps) => {

    const [backgroundColor, setBgColor] = useState<Array<String>>([])
    const [data, setData] = useState({})

    useEffect(() => {
        let bgColor: Array<String> = []
        if (props.labels && props.labels.length > 0) {
            props.labels.forEach((data) => {
                if (props.selected === data) {
                    bgColor.push('#3b8ad9')
                } else {
                    bgColor.push('#123057')
                }
            })
            setBgColor(bgColor);
        }
    }, [props.labels, props.selected])

    useEffect(() => {
        setData({
            labels: props.labels,
            datasets: [
                {
                    label: props.legend,
                    backgroundColor: backgroundColor,
                    borderColor: backgroundColor,
                    borderWidth: 1,
                    hoverBackgroundColor: '#133355',
                    hoverBorderColor: '#133355',
                    data: props.data
                }
            ]
        })
    }, [props.labels, props.legend, props.data, backgroundColor])

    return (
        <Fragment>
            {props.labels &&
                <Bar
                    data={data}
                    options={{
                        maintainAspectRatio: false,
                        legend: {
                            display: false
                        },
                        plugins: {
                            datalabels: {
                                color: 'white',
                            }
                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true,
                                    suggestedMax: props.suggestedMax
                                }
                            }]
                        }
                    }}
                    width={100}
                    height={window.innerHeight / 2}
                />
            }
        </Fragment>
    );
}

export default BarGraph;
