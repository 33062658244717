import React, { Fragment, useState, useEffect } from 'react';
import clsx from 'clsx';

// Material UI Components
import {
    Avatar, Divider, IconButton, List,
    ListItem, ListItemIcon, ListItemText
} from '@material-ui/core';
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';

// Material UI Style
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { Omit } from '@material-ui/types';

import { LoggedUser } from 'utils/loggedUser';
import { RoleFeatures } from 'redux/reducers/users/types';

export interface NavigatorProps extends Omit<DrawerProps, 'classes'>, WithStyles<typeof styles> {
    categories: Array<any>;
    onNavigate: (navId: string) => void;
}

const Navigator = (props: NavigatorProps) => {

    const { classes, categories, onNavigate, ...other } = props;
    const logo = require('static/images/logo270.png')

    const [activeNav, setActiveNav] = useState('')
    const role = LoggedUser.getRole();

    useEffect(() => {
        if (categories.length > 0) {
            setActiveNav(categories[0].children[0].childId)
        }
    }, [categories])

    const onNavClicked = (navId: string) => {
        setActiveNav(navId);
        onNavigate(navId);
    }

    const isAccessAllowed = (featureRoles: RoleFeatures[]) => {
        let userRoleFeatures = LoggedUser.getFeatureAccessPermission();
        let arrayIntersect = userRoleFeatures.filter(value => featureRoles.includes(value));
        if (userRoleFeatures.includes(RoleFeatures.ALL) || (arrayIntersect !== undefined && arrayIntersect.length > 0)) {
            return true;
        }
        return false;
    }

    return (
        <Drawer variant="permanent" {...other}>
            <List disablePadding>
                <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
                    <IconButton color="inherit" className={classes.iconButtonAvatar}
                        onClick={() => window.location.reload()}>
                        <Avatar src={logo} alt="Hoc Po Logo" />
                    </IconButton>
                    Hoc Po
                </ListItem>
                {categories.map(({ id, allowedUser, featureRoles, children }) => {
                    if (isAccessAllowed(featureRoles) && allowedUser && (allowedUser.includes(role) || allowedUser === 'All')) {
                        return (
                            <Fragment key={id}>
                                <ListItem className={classes.categoryHeader}>
                                    <ListItemText classes={{ primary: classes.categoryHeaderPrimary }} >
                                        {id}
                                    </ListItemText>
                                </ListItem>
                                {children.filter((item: { allowed: boolean; featureRoles: RoleFeatures[]}) => item.allowed === true && isAccessAllowed(item.featureRoles)).map(({ childId, navIcon, allowed, featureRoles }: any) => (
                                    <ListItem
                                        onClick={() => onNavClicked(childId)}
                                        key={childId}
                                        button
                                        className={clsx(classes.item, (activeNav === childId) && classes.itemActiveItem)} >
                                        <ListItemIcon className={classes.itemIcon}>{navIcon}</ListItemIcon>
                                        <ListItemText classes={{ primary: classes.itemPrimary }}>
                                            {childId}
                                        </ListItemText>
                                    </ListItem>
                                ))}
                                <Divider className={classes.divider} />
                            </Fragment>
                        )
                    } else {
                        return (<Fragment />)
                    }
                })}
            </List>
        </Drawer>
    )
}


const styles = (theme: Theme) => createStyles({
    categoryHeader: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    categoryHeaderPrimary: {
        color: theme.palette.common.white,
    },
    item: {
        paddingTop: 1,
        paddingBottom: 1,
        color: 'rgba(255, 255, 255, 0.7)',
        '&:hover,&:focus': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },
    },
    itemCategory: {
        // backgroundColor: '#232f3e',
        background: 'linear-gradient(0deg, rgba(19,51,85,1) 0%, rgba(31,49,69,1) 100%)',
        boxShadow: '0 -1px 0 #404854 inset',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    firebase: {
        fontSize: 24,
        color: theme.palette.common.white,
    },
    itemActiveItem: {
        color: '#4fc3f7',
    },
    itemPrimary: {
        fontSize: 'inherit',
    },
    itemIcon: {
        minWidth: 'auto',
        marginRight: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(2),
    },
    iconButtonAvatar: {
        padding: 4,
    },
});


export default withStyles(styles)(Navigator);