import {
    useSelector,
    TypedUseSelectorHook
} from 'react-redux'

import {
    TruckEntriesActionTypes,
    TruckEntriesReducerType,
    TruckEntriesInfoState,
    GET_RM_TRUCK_ENTRIES,
    GET_FG_TRUCK_ENTRIES,
} from './types'
import { LOGOUT } from 'redux/reducers/rootReducer/types';

const initialState: TruckEntriesInfoState = {
    rmTruckEntries: [],
    fgTruckEntries: []
}

export const truckEntriesTypedSelector: TypedUseSelectorHook<TruckEntriesReducerType> = useSelector;

export function truckEntriesReducer(
    state = initialState,
    action: TruckEntriesActionTypes
): TruckEntriesInfoState {

    switch (action.type) {

        case GET_RM_TRUCK_ENTRIES:
            return {
                rmTruckEntries: action.payload,
                fgTruckEntries: state.fgTruckEntries
            }

        case GET_FG_TRUCK_ENTRIES:
            return {
                rmTruckEntries: state.rmTruckEntries,
                fgTruckEntries: action.payload
            }

        case LOGOUT:

            return initialState

        default:
            return state
    }

}