import {
    AppBar,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Paper,
    TextField,
    Theme,
    Toolbar,
    Tooltip,
    Typography,
    WithStyles,
    createStyles,
    withStyles,
} from '@material-ui/core';
import { PurchaseRequestInfo, PurchaseRequestInfoList } from 'redux/reducers/purchasing/types';
import React, { Fragment, useEffect, useState } from 'react';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import PurchaseRequestAddView from './PurchaseRequestAddView';
import PurchaseRequestDetailsView from './PurchaseRequestDetailsView';
import PurchaseRequestUpdateView from './PurchaseRequestUpdateView';
import PurchaseRequestsListTable from './PurchaseRequestsListTable';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import { getAllPurchaseRequestsAction, deletePurchaseRequisitionAction } from 'redux/reducers/purchasing/actions';
import { purchasingTypedSelector } from 'redux/reducers/purchasing/purchasingReducer';
import { useDispatch } from 'react-redux';

export interface ContentProps extends WithStyles<typeof styles> {}

const viewState = {
    purchaseRequestListView: 'requestListView',
    purchaseRequestShowDetailsView: 'showDetailsView',
    purchaseRequestAddView: 'addPurchaseRequest',
    purchaseRequestUpdateView: 'updatePurchaseRequest',
};

let _gPurchaseRequestList: Array<PurchaseRequestInfo> | [] = [];
const PurchaseRequestListView = (props: ContentProps) => {
    const { classes } = props;
    const [filterText, setfilterText] = useState('');
    const [purchaseRequestsList, setPurchaseRequestsList] = useState<Array<PurchaseRequestInfo> | []>([]);
    const [clickedRequestId, setClickedRequestId] = useState('');
    const [clickedRequestDetails, setClickedRequestDetails] = useState<PurchaseRequestInfo | undefined>(undefined);
    const [onReload, setOnReload] = useState(false);
    const [showConfirmCancelModal, setShowConfirmCancelModal] = useState(false);
    const [isAcquired, setIsAcquired] = useState(false);
    const [view, setView] = useState(viewState.purchaseRequestListView);
    const dispatcher = useDispatch();

    const [addFormHasData, setAddFormHasData] = useState(false);

    const purchaseRequestListResponse = purchasingTypedSelector<PurchaseRequestInfoList>(
        (state) => state.purchasing.purchaseRequests
    );

    const isView = (checkView: string) => {
        return view === checkView;
    };

    useEffect(() => {
        if (purchaseRequestListResponse.requestsList.length > 0) {
            setPurchaseRequestsList(purchaseRequestListResponse.requestsList);
            _gPurchaseRequestList = purchaseRequestListResponse.requestsList;
        } else if (!isAcquired) {
            setIsAcquired(true);
            dispatcher(getAllPurchaseRequestsAction());
        }
    }, [purchaseRequestListResponse, isAcquired, dispatcher]);

    useEffect(() => {
        if (purchaseRequestsList.length > 0) {
            setTimeout(() => {
                setOnReload(false);
            }, 500);
        }
    }, [purchaseRequestsList]);

    const onClickedPurchaseRequest = (requestId: string) => {
        setClickedRequestId(requestId);
        setClickedRequestDetails(_gPurchaseRequestList.find((data) => data.requestId === requestId));
        setView(viewState.purchaseRequestShowDetailsView);
    };

    const revertToListView = () => {
        setTimeout(() => {
            dispatcher(getAllPurchaseRequestsAction());
        }, 1000);

        setClickedRequestId('');
        setClickedRequestDetails(undefined);
        setAddFormHasData(false);
        setView(viewState.purchaseRequestListView);
    };

    const fetchAndReloadList = () => {
        setPurchaseRequestsList([]);
        _gPurchaseRequestList = [];
        setOnReload(true);
        dispatcher(getAllPurchaseRequestsAction());
    }

    const renderPurchaseRequestsListTableButton = () => {
        return (
            <Grid item>
                <Tooltip title='Create Request'>
                    <IconButton onClick={() => setView(viewState.purchaseRequestAddView)}>
                        <NoteAddIcon className={classes.block} color='inherit' />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Reload'>
                    <IconButton
                        onClick={fetchAndReloadList}>
                        {onReload ? (
                            <CircularProgress size='24px' />
                        ) : (
                            <RefreshIcon className={classes.block} color='inherit' />
                        )}
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    };

    const renderPurchaseRequestsListTableSearch = () => {
        return (
            <Fragment>
                <Grid item>
                    <SearchIcon className={classes.block} color='inherit' />
                </Grid>
                <Grid item xs>
                    <TextField
                        fullWidth
                        placeholder='Search'
                        InputProps={{
                            disableUnderline: true,
                            className: classes.searchInput,
                        }}
                        onChange={(event) => setfilterText(event.target.value)}
                    />
                </Grid>
            </Fragment>
        );
    };

    const renderPurchaseRequestsListTableContainer = () => {
        return (
            <Grid container spacing={2} alignItems='center'>
                {renderPurchaseRequestsListTableSearch()}
                {renderPurchaseRequestsListTableButton()}
            </Grid>
        );
    };

    const onCloseConfirmCancelModal = () => {
        setShowConfirmCancelModal(false);
    };

    const confirmCancelInAddUpdate = () => {
        return (
            <div>
                <Dialog
                    disableEscapeKeyDown={true}
                    disableBackdropClick={true}
                    open={showConfirmCancelModal}
                    onClose={onCloseConfirmCancelModal}>
                    <DialogTitle>
                        <IconButton
                            className={classes.rightButton}
                            aria-label='close'
                            onClick={onCloseConfirmCancelModal}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant='h6' component='h1' align='center'>
                            {'This action will discard the current input information. Proceed?'}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                onCloseConfirmCancelModal();
                                if (isView(viewState.purchaseRequestUpdateView)) {
                                    setView(viewState.purchaseRequestShowDetailsView);
                                } else {
                                    revertToListView();
                                }
                            }}
                            color='primary'
                            variant='contained'
                            fullWidth={true}>
                            Yes
                        </Button>
                        <Button
                            onClick={onCloseConfirmCancelModal}
                            color='primary'
                            variant='contained'
                            fullWidth={true}>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    const renderPurchaseRequestDetailsContainer = () => {
        return (
            <Grid container spacing={2} alignItems='center'>
                <Grid item>
                    <Tooltip title='Return / Cancel'>
                        <IconButton onClick={revertToListView}>
                            <ArrowBackIosIcon className={classes.block} color='inherit' />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    {clickedRequestId && (
                        <Typography color='primary' variant='h5' component='h1'>
                            {`Request # ${clickedRequestId} `}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        );
    };

    const renderPurchaseRequestAddContainer = () => {
        return (
            <Grid container spacing={2} alignItems='center'>
                <Grid item>
                    <Tooltip title='Cancel'>
                        <IconButton
                            onClick={() => {
                                if (addFormHasData) {
                                    setShowConfirmCancelModal(true);
                                    confirmCancelInAddUpdate();
                                } else {
                                    revertToListView();
                                }
                            }}>
                            <ArrowBackIosIcon className={classes.block} color='inherit' />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Typography color='primary' variant='h5' component='h1'>
                        {' Create Purchase Request'}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    const onDeletePurchaseRequest = (requestId : string) => {
        dispatcher(deletePurchaseRequisitionAction(requestId));
        return true;
    }

    const renderPurchaseRequestsListTable = () => {
        return (
            <div className={classes.contentWrapper}>
                <PurchaseRequestsListTable
                    onClickedPurchaseRequest={onClickedPurchaseRequest}
                    onDeletePurchaseRequest= {onDeletePurchaseRequest}
                    purchaseRequestsList={purchaseRequestsList}
                    filterText={filterText}
                />
            </div>
        );
    };

    const renderPurchaseRequestsDetailsView = () => {
        return (
            <div className={classes.contentWrapper}>
                <PurchaseRequestDetailsView
                    clickedPurchaseRequest={clickedRequestDetails}
                    onClickedUpdateButton={() => {
                        setView(viewState.purchaseRequestUpdateView);
                    }}
                />
            </div>
        );
    };

    const onAddFormSetData = () => {
        setAddFormHasData(true);
    };

    const renderPurchaseRequestsAddView = () => {
        return (
            <div className={classes.contentWrapper}>
                <PurchaseRequestAddView
                    onFormClose={revertToListView}
                    onAddFormSetData={onAddFormSetData}
                    onCancel={() => {
                        if (addFormHasData) {
                            setShowConfirmCancelModal(true);
                            confirmCancelInAddUpdate();
                        } else {
                            revertToListView();
                        }
                    }}
                />
            </div>
        );
    };

    const renderPurchaseRequestUpdateContainer = () => {
        return (
            <Grid container spacing={2} alignItems='center'>
                <Grid item>
                    <Tooltip title='Cancel'>
                        <IconButton
                            onClick={() => {
                                setShowConfirmCancelModal(true);
                                confirmCancelInAddUpdate();
                            }}>
                            <ArrowBackIosIcon className={classes.block} color='inherit' />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Typography color='primary' variant='h5' component='h1'>
                        {`Update Request # ${clickedRequestId} `}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    const renderPurchaseRequestsUpdateView = () => {
        return (
            <div className={classes.contentWrapper}>
                <PurchaseRequestUpdateView
                    onFormClose={revertToListView}
                    onCancel={() => {
                        setShowConfirmCancelModal(true);
                        confirmCancelInAddUpdate();
                    }}
                    clickedPurchaseRequest={clickedRequestDetails}
                />
            </div>
        );
    };

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.searchBar} position='static' color='default' elevation={0}>
                <Toolbar>
                    {isView(viewState.purchaseRequestListView) && renderPurchaseRequestsListTableContainer()}
                    {isView(viewState.purchaseRequestShowDetailsView) &&
                        clickedRequestDetails &&
                        renderPurchaseRequestDetailsContainer()}
                    {(isView(viewState.purchaseRequestAddView) || isView(viewState.purchaseRequestUpdateView)) &&
                        confirmCancelInAddUpdate()}
                    {isView(viewState.purchaseRequestAddView) && renderPurchaseRequestAddContainer()}
                    {isView(viewState.purchaseRequestUpdateView) && renderPurchaseRequestUpdateContainer()}
                </Toolbar>
            </AppBar>
            {isView(viewState.purchaseRequestListView) && renderPurchaseRequestsListTable()}
            {isView(viewState.purchaseRequestShowDetailsView) &&
                clickedRequestDetails &&
                renderPurchaseRequestsDetailsView()}
            {isView(viewState.purchaseRequestAddView) && renderPurchaseRequestsAddView()}
            {isView(viewState.purchaseRequestUpdateView) && clickedRequestDetails && renderPurchaseRequestsUpdateView()}
        </Paper>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: '99%',
            margin: 'auto',
            overflow: 'hidden',
            alignContent: 'center',
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: 'rgb(31,49,69)',
            alignSelf: 'center',
        },
        contentWrapper: {
            margin: '0px 0px',
        },
        rightButton: {
            float: 'right',
        },
    });

export default withStyles(styles)(PurchaseRequestListView);
