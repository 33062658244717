import { Button, Typography } from '@material-ui/core';
import React, { Fragment, useState } from 'react';

import { Canvass } from 'redux/reducers/canvass/types';
import TableGrid from 'components/common/TableGrid';
import moment from 'moment';
import { GridApi } from 'ag-grid';
import DeleteConfirmation from 'components/common/DeleteConfirmation';

export interface CanvassSheetListTableProps {
  filterText: string;
  canvassList: Array<Canvass>;
  onClickedCanvass: (id: string) => void;
  onDeleteCanvass?: (id: string) => boolean;
}

interface RowInfo {
  api?: GridApi;
  data?: Canvass;
}

const CanvassSheetListTable = (props: CanvassSheetListTableProps) => {
  const { filterText, canvassList, onClickedCanvass, onDeleteCanvass } = props;
  const [forDeleteRow, setForDeleteRow] = useState<RowInfo | any>({});
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);

  const requestIdRenderer = (params: { value: string }) => {
    return (
      <Button
        style={{ color: '#009fdc' }}
        onClick={() => {
          if (onClickedCanvass) {
            onClickedCanvass(params.value);
          }
        }}
      >
        {params.value}
      </Button>
    );
  };

  const itemsListConcatenatedRenderer = (data: any) => {
    let itemList = '';
    if (data.value.length > 0) {
      data.value.forEach((item: any, index: number) => {
        if (index > 0) {
          itemList += ', ';
        }
        let itemWithDescription = `(${item.itemCode}) ${item.itemDescription}`;
        itemList = itemList + itemWithDescription;
      });
    }
    return itemList;
  };

  const dateRenderer = (date: any) => {
    return (
      <Typography variant="body2" style={{ paddingTop: 10 }}>
        {moment(date.value).format('MMM DD, YYYY HH:mm')}
      </Typography>
    );
  };

  const columnDefs = [
    {
      headerName: 'Canvass #',
      field: 'requestId',
      minWidth: 250,
      filter: 'agTextColumnFilter',
      cellRendererFramework: requestIdRenderer,
    },
    {
      headerName: 'Items List',
      field: 'itemsList',
      minWidth: 400,
      maxWidth: 1000,
      filter: 'agTextColumnFilter',
      headerTooltip: 'Items',
      cellRenderer: itemsListConcatenatedRenderer,
    },
    {
      headerName: 'Prepared By',
      field: 'preparedBy',
      minWidth: 150,
      filter: 'agTextColumnFilter',
      headerTooltip: 'Prepared By',
    },
    {
      headerName: 'Approved By',
      field: 'approvedBy',
      minWidth: 150,
      filter: 'agTextColumnFilter',
      headerTooltip: 'Approved By',
    },
    {
      headerName: 'Date of Creation',
      field: 'createDate',
      filter: 'agDateColumnFilter',
      headerTooltip: 'Purpose',
      minWidth: 100,
      cellRendererFramework: dateRenderer,
    },
    {
      headerName: 'Date Updated',
      field: 'updatedDate',
      filter: 'agDateColumnFilter',
      headerTooltip: 'Date Updated',
      minWidth: 100,
      cellRendererFramework: dateRenderer,
    },
  ];

  return (
    <Fragment>
      <TableGrid
        rowData={canvassList}
        columnDefs={columnDefs}
        filterText={filterText}
        height={600}
        deleteColumn={onDeleteCanvass ? true : false}
        onDeleteRow={(row) => {
          setForDeleteRow(row)
          setOpenDeleteConfirmation(true)

          return false;
        }}
      />
      {openDeleteConfirmation && (
        <DeleteConfirmation
          title='Delete Canvass Sheet'
          data={forDeleteRow}
          onDelete={(data: any) => {
            if (onDeleteCanvass ? onDeleteCanvass(forDeleteRow!.data!.requestId) : false) {
              let {data, api} = forDeleteRow

              api!.updateRowData({ remove: [data] })
              api!.refreshCells({ force: true });
            }

            setOpenDeleteConfirmation(false);
            setForDeleteRow({})
          }}
          onCancel={(data: any) => {
            setOpenDeleteConfirmation(false)
            setForDeleteRow({})
          }}
          message={forDeleteRow!.data!.requestId}
        />
      )}
    </Fragment>
  );
};

export default CanvassSheetListTable;
