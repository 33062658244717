import {
    Button,
    Grid,
    TextField,
    InputAdornment,
    IconButton
} from "@material-ui/core";
import {
    ItemsToRequisitionInfo,
    PurchaseRequestInfoRequest,
    PurchaseRequestStatus
} from "redux/reducers/purchasing/types";
import {
    RoleDepartments
} from "redux/reducers/users/types";
import React, { Fragment, useEffect, useState } from "react";
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles";
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

import Alert from "@material-ui/lab/Alert";
import SelectComponent from 'components/common/Select';
import NumericEditor from "components/common/editors/NumericEditor";
import { RowNode } from "ag-grid";
import TableGrid from "components/common/TableGrid";
import { addPurchaseRequestAction } from "redux/reducers/purchasing/actions";
import { useDispatch } from "react-redux";


// Icons
import AddCircleIcon from "@material-ui/icons/AddCircle";
import TodayIcon from '@material-ui/icons/Today';
import CancelIcon from "@material-ui/icons/Cancel";

export interface ContentProps extends WithStyles<typeof styles> {
    onFormClose: () => void;
    onAddFormSetData: () => void;
    onCancel: () => void;
}

const PurchaseRequestAddView = (props: ContentProps) => {
    const { onFormClose, onAddFormSetData, onCancel } = props;
    const [newOrigDept, setNewOrigDept] = useState('');
    const [newOrigDeptHelper, setNewOrigDeptHelper] = useState("");
    const [newPurpose, setNewPurpose] = useState("");
    const [newPurposeHelper, setNewPurposeHelper] = useState("");
    const [newRequisitionedBy, setNewRequisitionedBy] = useState("");
    const [newRequisitionedByHelper, setNewRequisitionedByHelper] = useState("");
    const [newDateNeeded, setNewDateNeeded] = useState<string | null>(null);
    const [newDateNeededHelper, setNewDateNeededHelper] = useState("");
    const [newRequestStatus, setNewRequestStatus] = useState(PurchaseRequestStatus.OPEN);
    const [newRequestStatusHelper, setNewRequestStatusHelper] = useState("");
    const [newItemsList, setNewItemsList] = useState<Array<ItemsToRequisitionInfo>>([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [newRemarks, setNewRemarks] = useState("");

    const dispatch = useDispatch();

    const requestDepartmentOptions: RoleDepartments[] = Object.values(RoleDepartments);
    const requestStatusOptions: PurchaseRequestStatus[] = Object.values(PurchaseRequestStatus);

    let columnDefs = [
        {
            headerName: "Item Code",
            field: "itemCode",
            minWidth: 100,
            filter: "agTextColumnFilter",
            headerTooltip: "Item Code",
            editable: true,
        },
        {
            headerName: "Description",
            field: "itemDescription",
            minWidth: 200,
            filter: "agTextColumnFilter",
            headerTooltip: "Item Description",
            editable: true,
        },
        {
            headerName: "Type",
            field: "itemType",
            minWidth: 100,
            filter: "agTextColumnFilter",
            headerTooltip: "Item Type",
            editable: true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['RM', 'SP', 'Others'],
            },
        },
        {
            headerName: "Unit",
            field: "itemUnit",
            minWidth: 50,
            filter: "agTextColumnFilter",
            headerTooltip: "Unit",
            editable: true,
        },
        {
            headerName: "Quantity",
            field: "itemQuantity",
            minWidth: 50,
            filter: "agTextColumnFilter",
            headerTooltip: "Quantity",
            editable: true,
            cellEditor: "numericEditor",
        },
    ];

    useEffect(() => {
        if (newRequisitionedBy !== "") {
            onAddFormSetData();
        }
    }, [newOrigDept, newRequisitionedBy, onAddFormSetData]);

    const onCellValueChanged = (event: any) => {
        let rowData: Array<ItemsToRequisitionInfo> = [];
        event.api.forEachNode((node: RowNode) => rowData.push(node.data));
        setNewItemsList(rowData);
    };

    const onRowDataUpdated = (event: any) => {
        let rowData: Array<ItemsToRequisitionInfo> = [];
        event.api.forEachNode((node: RowNode) => rowData.push(node.data));
        setNewItemsList(rowData);
    };

    const isItemsListValid = () => {
        let ret = true;

        // Copy the contents of the items list but remove empty rows
        let filteredItemsList: Array<ItemsToRequisitionInfo> = [];
        newItemsList.forEach((element) => {
            // if at least one attribute is not undefined
            if (element.itemCode || element.itemDescription || element.itemQuantity || element.itemUnit || element.itemType) {
                let addItem = {
                    itemCode: element.itemCode ? element.itemCode! : "",
                    itemDescription: element.itemDescription ? element.itemDescription! : "",
                    itemUnit: element.itemUnit ? element.itemUnit! : "",
                    itemQuantity: element.itemQuantity ? element.itemQuantity! : 0,
                    itemType: element.itemType ? element.itemType! : '',
                };
                filteredItemsList = [...filteredItemsList, addItem];
            }
        });

        // Check if empty list
        if (filteredItemsList.length === 0) {
            setErrorMessage("No items added");
            return false;
        }

        // Check for duplicate item codes
        let seen = new Set();
        var hasDuplicates = filteredItemsList.some(function (currentItem) {
            return seen.size === seen.add(currentItem.itemCode).size;
        });

        if (!hasDuplicates) {
            // Check for empty value
            for (let i = 0; i < filteredItemsList.length; i++) {
                let element = filteredItemsList[i];
                let rowItemCode = element.itemCode;
                if (rowItemCode === "") {
                    setErrorMessage("Please set the item code for : Item " + (i + 1));
                    ret = false;
                    break;
                }
                if (element.itemDescription === "") {
                    setErrorMessage("Please set the description for " + rowItemCode);
                    ret = false;
                    break;
                }
                if (element.itemUnit === "") {
                    setErrorMessage("Please set the unit for " + rowItemCode);
                    ret = false;
                    break;
                }
                if (element.itemQuantity <= 0) {
                    setErrorMessage("Please set the quantity for " + rowItemCode);
                    ret = false;
                    break;
                }
            }
        } else {
            setErrorMessage("Item Code duplicates detected");
            ret = false;
        }

        return ret;
    };

    const resetHelpers = () => {
        setNewOrigDeptHelper("");
        setNewPurposeHelper("");
        setNewRequisitionedByHelper("");
        setNewDateNeededHelper("");
        setNewRequestStatusHelper("");
        setErrorMessage("");
    };

    const checkInputValidity = () => {
        let isItemsValid = isItemsListValid();
        if ( newPurpose === "" || newRequisitionedBy === "" || newDateNeeded === undefined || isItemsValid === false ) {
            if (newPurpose === "") setNewPurposeHelper("Please set value");
            if (newRequisitionedBy === "") setNewRequisitionedByHelper("Please set value");
            if (newDateNeeded === undefined) setNewDateNeededHelper("Please set value");
            return false;
        }
        return true;
    };

    const addNewRequest = () => {
        if (checkInputValidity()) {
            let filteredItemsList: Array<ItemsToRequisitionInfo> = [];
            newItemsList.forEach((element) => {
                if (element.itemCode || element.itemDescription || element.itemQuantity || element.itemUnit || element.itemType) {
                    let addItem = {
                        itemCode: element.itemCode ? element.itemCode! : "",
                        itemDescription: element.itemDescription ? element.itemDescription! : "",
                        itemUnit: element.itemUnit ? element.itemUnit! : "",
                        itemQuantity: element.itemQuantity ? element.itemQuantity! : 0,
                        itemType: element.itemType ? element.itemType! : "",
                    };
                    filteredItemsList = [...filteredItemsList, addItem];
                }
            });
            let addPurchaseRequest: PurchaseRequestInfoRequest = {
                originDept: newOrigDept,
                requisitionedBy: newRequisitionedBy,
                purpose: newPurpose,
                needDate: newDateNeeded,
                requestStatus: newRequestStatus,
                itemsList: filteredItemsList,
                remarks: newRemarks,
            };
            dispatch(addPurchaseRequestAction(addPurchaseRequest));
            resetHelpers();
            onFormClose();
        }
    };

    const renderPurchaseRequestDetails = () => {
        return (
            <Fragment>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <SelectComponent
                            title={'Originating Department'}
                            selectList={requestDepartmentOptions}
                            setter={setNewOrigDept}
                            codeHelperText={newOrigDeptHelper}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectComponent
                            title={'Request Status'}
                            selectList={requestStatusOptions}
                            setter={setNewRequestStatus}
                            currentValue={PurchaseRequestStatus.OPEN}
                            codeHelperText={newRequestStatusHelper}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableGrid
                            rowData={newItemsList}
                            columnDefs={columnDefs}
                            height={300}
                            showAddRowButton={true}
                            onCellValueChanged={onCellValueChanged}
                            onRowDataUpdated={onRowDataUpdated}
                            deleteColumn={true}
                            frameworkComponents={{
                                numericEditor: NumericEditor,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={"Purpose"}
                            value={newPurpose}
                            helperText={newPurposeHelper}
                            error={newPurposeHelper !== ""}
                            onChange={(event) => {
                                setNewPurpose(event.target.value);
                            }}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DateTimePicker
                                ampm={false}
                                fullWidth
                                margin='dense'
                                value={ newDateNeeded === undefined ? null : newDateNeeded }
                                onChange={(event) => {
                                    setNewDateNeededHelper('');
                                    setNewDateNeeded(moment(event).toISOString());
                                }}
                                label={'Date Needed'}
                                format='MMM DD, YYYY HH:mm'
                                inputVariant='standard'
                                helperText={newDateNeededHelper}
                                error={newDateNeededHelper !== ''}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <IconButton>
                                                <TodayIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label={"Requisitioned By"}
                            value={newRequisitionedBy}
                            helperText={newRequisitionedByHelper}
                            error={newRequisitionedByHelper !== ""}
                            onChange={(event) => {
                                setNewRequisitionedBy(event.target.value);
                            }}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={"Remarks"}
                            value={newRemarks}
                            onChange={(event) => {
                                setNewRemarks(event.target.value);
                            }}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            onClick={() => {
                                resetHelpers();
                                addNewRequest();
                            }}
                            color="primary"
                            variant="contained"
                            fullWidth={true}
                            startIcon={<AddCircleIcon />}
                        >
                            Create
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            onClick={onCancel}
                            color="primary"
                            variant="contained"
                            startIcon={<CancelIcon />}
                            fullWidth={true}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        {errorMessage !== "" && <Alert severity="error">{errorMessage}</Alert>}
                    </Grid>
                </Grid>
            </Fragment>
        );
    };

    return <div style={{ padding: 10 }}>{renderPurchaseRequestDetails()}</div>;
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: "99%",
            margin: "auto",
            overflow: "hidden",
            alignContent: "center",
        },
        searchBar: {
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: "block",
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: "rgb(31,49,69)",
            alignSelf: "center",
        },
        contentWrapper: {
            margin: "0px 0px",
        },
    });

export default withStyles(styles)(PurchaseRequestAddView);
