import {
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { Fragment, useEffect, useState } from 'react';

import { FGTruckInspectionInfo } from 'redux/reducers/finishedGoods/types';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { patchTruckCleanlinessInspection } from 'redux/reducers/finishedGoods/actions';
import { useDispatch } from 'react-redux';

interface TruckInspectionProps {
    issueOrder: string;
    submit?: boolean;
    onSubmit?: () => void;
    truckInfo?: FGTruckInspectionInfo | undefined;
    customerName?: string;
}

const FinishedGoodsTruckInspectionForm = (props: TruckInspectionProps) => {
    const { issueOrder, submit, truckInfo, customerName, onSubmit } = props;

    const dispatcher = useDispatch();

    const [inspectionDatetime, setInspectionDatetime] = useState(moment(new Date()).toISOString());
    const [truckPlateNum, setTruckPlateNum] = useState('');
    const [vehicleType, setVehicleType] = useState('');

    const [q1status, setQ1Status] = useState('No');
    const [q2status, setQ2Status] = useState('No');
    const [q3status, setQ3Status] = useState('No');
    const [q4status, setQ4Status] = useState('No');
    const [q5status, setQ5Status] = useState('No');
    const [q6status, setQ6Status] = useState('No');
    const [q1remarks, setQ1Remarks] = useState('');
    const [q2remarks, setQ2Remarks] = useState('');
    const [q3remarks, setQ3Remarks] = useState('');
    const [q4remarks, setQ4Remarks] = useState('');
    const [q5remarks, setQ5Remarks] = useState('');
    const [q6remarks, setQ6Remarks] = useState('');
    const [q1action, setQ1Action] = useState('');
    const [q2action, setQ2Action] = useState('');
    const [q3action, setQ3Action] = useState('');
    const [q4action, setQ4Action] = useState('');
    const [q5action, setQ5Action] = useState('');
    const [q6action, setQ6Action] = useState('');

    const [inspectedBy, setInspectedBy] = useState('');
    const [approvedBy, setApprovedBy] = useState('');
    const [conformedBy, setConformedBy] = useState('');

    const camelize = (str: string) => {
        if (str === 'no' || str === 'No') {
            return 'No';
        }
        return 'Yes';
    };

    useEffect(() => {
        if (truckInfo) {
            if (truckInfo.inspectionDatetime) {
                setInspectionDatetime(truckInfo.inspectionDatetime);
            }
            setVehicleType(truckInfo.vehicleType);
            setInspectedBy(truckInfo.inspectedBy);
            setApprovedBy(truckInfo.approvedBy);
            setConformedBy(truckInfo.conformedBy);
            if (truckInfo.inspectionItems && truckInfo.inspectionItems.length > 0) {
                let inspection = truckInfo.inspectionItems;
                setQ1Status(camelize(inspection[0].inspection_result));
                setQ2Status(camelize(inspection[1].inspection_result));
                setQ3Status(camelize(inspection[2].inspection_result));
                setQ4Status(camelize(inspection[3].inspection_result));
                setQ5Status(camelize(inspection[4].inspection_result));
                setQ6Status(camelize(inspection[5].inspection_result));
                setQ1Remarks(inspection[0].remark);
                setQ2Remarks(inspection[1].remark);
                setQ3Remarks(inspection[2].remark);
                setQ4Remarks(inspection[3].remark);
                setQ5Remarks(inspection[4].remark);
                setQ6Remarks(inspection[5].remark);
                setQ1Action(inspection[0].action_taken);
                setQ2Action(inspection[1].action_taken);
                setQ3Action(inspection[2].action_taken);
                setQ4Action(inspection[3].action_taken);
                setQ5Action(inspection[4].action_taken);
                setQ6Action(inspection[5].action_taken);
            }
        }
    }, [truckInfo]);

    useEffect(() => {
        if (issueOrder && submit && submit === true) {
            let inspectionDecision = 'ACCEPT';
            if (
                q1status === 'No' ||
                q2status === 'No' ||
                q3status === 'No' ||
                q4status === 'No' ||
                q5status === 'No' ||
                q5status === 'No'
            ) {
                inspectionDecision = 'REJECT';
            }
            let truckChecklist: FGTruckInspectionInfo = {
                truckPlateNum: truckPlateNum,
                vehicleType: vehicleType,
                inspectionDatetime: inspectionDatetime,
                inspectionItems: [
                    {
                        item_id: '1',
                        inspection_result: q1status,
                        remark: q1remarks,
                        action_taken: q1action,
                    },
                    {
                        item_id: '2',
                        inspection_result: q2status,
                        remark: q2remarks,
                        action_taken: q2action,
                    },
                    {
                        item_id: '3',
                        inspection_result: q3status,
                        remark: q3remarks,
                        action_taken: q3action,
                    },
                    {
                        item_id: '4',
                        inspection_result: q4status,
                        remark: q4remarks,
                        action_taken: q4action,
                    },
                    {
                        item_id: '5',
                        inspection_result: q5status,
                        remark: q5remarks,
                        action_taken: q5action,
                    },
                    {
                        item_id: '6',
                        inspection_result: q6status,
                        remark: q6remarks,
                        action_taken: q6action,
                    },
                ],
                inspectedBy: inspectedBy,
                approvedBy: approvedBy,
                conformedBy: conformedBy,
                inspectionDecision: inspectionDecision,
                inspectionDecisionDatetime: inspectionDatetime, // TODO
            };
            dispatcher(patchTruckCleanlinessInspection(issueOrder, truckChecklist));
            if (onSubmit) {
                onSubmit();
            }
        }
    }, [submit, onSubmit]); // eslint-disable-line

    const handleRadio = (event: React.ChangeEvent<HTMLInputElement>, setter: any) => {
        setter(event.target.value);
    };

    const checklistHeader = () => {
        return (
            <Fragment>
                <Grid container alignItems='center' spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Typography color='inherit' variant='h6' component='h1'>
                            Item
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <Typography color='inherit' variant='h6'>
                            Yes
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <Typography color='inherit' variant='h6'>
                            No
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <Typography color='inherit' variant='h6' component='h1'>
                            Remarks
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <Typography color='inherit' variant='h6' component='h1'>
                            Action
                        </Typography>
                    </Grid>
                </Grid>
                <Divider light />
            </Fragment>
        );
    };

    const renderRmInfo = () => {
        return (
            <Fragment>
                <Grid container alignItems='center' spacing={1} style={{ paddingTop: 10 }}>
                    <Grid item xs={12} sm={6}>
                        <DateTimePicker
                            margin='dense'
                            fullWidth
                            label={'Date'}
                            value={moment(inspectionDatetime)}
                            onChange={(date) => setInspectionDatetime(date as any)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            margin='dense'
                            disabled
                            variant='filled'
                            label={'Customer Name'}
                            value={customerName}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems='center' spacing={1} style={{ paddingTop: 10 }}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            margin='dense'
                            label={'Vehicle Type'}
                            value={vehicleType}
                            onChange={(event) => setVehicleType(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            fullWidth
                            margin='dense'
                            label={'Plate #'}
                            value={truckPlateNum}
                            onChange={(event) => setTruckPlateNum(event.target.value)}
                        />
                    </Grid>
                </Grid>
            </Fragment>
        );
    };

    const renderChecklist = () => {
        const entries = [
            {
                q: 'Is the truck dry and clean?',
                status: q1status,
                statusSetter: setQ1Status,
                remarks: q1remarks,
                remarksSetter: setQ1Remarks,
                action: q1action,
                actionSetter: setQ1Action,
            },
            {
                q:
                    'Are there any damages on the wall, floor or ceiling of the truck that can cause water to enter when it rains?',
                status: q2status,
                statusSetter: setQ2Status,
                remarks: q2remarks,
                remarksSetter: setQ2Remarks,
                action: q2action,
                actionSetter: setQ2Action,
            },
            {
                q: 'Sweeping of truck before loading feeds',
                status: q3status,
                statusSetter: setQ3Status,
                remarks: q3remarks,
                remarksSetter: setQ3Remarks,
                action: q3action,
                actionSetter: setQ3Action,
            },
            {
                q: 'Is the turck infested (e.g. cockroaches, weevils, rats, etc.)',
                status: q4status,
                statusSetter: setQ4Status,
                remarks: q4remarks,
                remarksSetter: setQ4Remarks,
                action: q4action,
                actionSetter: setQ4Action,
            },
            {
                q: 'Does the truck have any unpleasant/foul odor?',
                status: q5status,
                statusSetter: setQ5Status,
                remarks: q5remarks,
                remarksSetter: setQ5Remarks,
                action: q5action,
                actionSetter: setQ5Action,
            },
            {
                q: 'Does the truck have sacoline/lona after loading of feeds for protection in the rain and sunlight?',
                status: q6status,
                statusSetter: setQ6Status,
                remarks: q6remarks,
                remarksSetter: setQ6Remarks,
                action: q6action,
                actionSetter: setQ6Action,
            },
        ];

        return (
            <Fragment>
                {entries.map(({ q, status, statusSetter, remarks, remarksSetter, action, actionSetter }, index) => (
                    <Grid container alignItems='center' spacing={1} style={{ paddingTop: 10 }}>
                        <Grid item xs={12} sm={6} style={{ paddingRight: 80 }}>
                            <Typography color='inherit' variant='body1' component='h1'>
                                {q}
                            </Typography>
                            <Divider light />
                        </Grid>
                        <Grid item xs={1} sm={1}>
                            <FormControl component='fieldset'>
                                <RadioGroup
                                    aria-label={'status' + index}
                                    name={'status' + index}
                                    value={status}
                                    onChange={(event) => handleRadio(event, statusSetter)}>
                                    <FormControlLabel value='Yes' control={<Radio color='primary' />} label='' />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={1} sm={1}>
                            <FormControl component='fieldset'>
                                <RadioGroup
                                    aria-label={'status' + index}
                                    name={'status' + index}
                                    value={status}
                                    onChange={(event) => handleRadio(event, statusSetter)}>
                                    <FormControlLabel value='No' control={<Radio color='primary' />} label='' />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField
                                fullWidth
                                margin='dense'
                                value={remarks}
                                multiline
                                onChange={(event) => remarksSetter(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={4} sm={2}>
                            <TextField
                                fullWidth
                                margin='dense'
                                value={action}
                                multiline
                                onChange={(event) => actionSetter(event.target.value)}
                            />
                        </Grid>
                    </Grid>
                ))}
            </Fragment>
        );
    };

    const approvers = [
        {
            value: inspectedBy,
            label: 'Inspected By',
            setter: setInspectedBy,
            title: 'Guard on Duty',
        },
        {
            value: approvedBy,
            label: 'Approved By',
            setter: setApprovedBy,
            title: 'FG Checker',
        },
        {
            value: conformedBy,
            label: 'Conformed By',
            setter: setConformedBy,
            title: 'Truck Driver',
        },
    ];

    const renderApproval = () => {
        return (
            <Fragment>
                <Grid container alignItems='center' spacing={1} style={{ paddingTop: 20 }}>
                    {approvers.map(({ value, label, setter }) => {
                        if (setter) {
                            return (
                                <Grid item xs={8} sm={4}>
                                    <TextField
                                        fullWidth
                                        margin='dense'
                                        label={label}
                                        value={value}
                                        onChange={(event) => setter(event.target.value)}
                                    />
                                </Grid>
                            );
                        }
                        return (
                            <Grid item xs={8} sm={4}>
                                <TextField fullWidth margin='dense' label={label} value={value} />
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid container alignItems='center' spacing={1}>
                    {approvers.map((approver) => (
                        <Grid item xs={8} sm={4}>
                            <Typography color='inherit' variant='subtitle2'>
                                {approver.title}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            </Fragment>
        );
    };

    return (
        <Fragment>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Container>
                    {renderRmInfo()}
                    <Container style={{ padding: 20 }}>
                        <Paper elevation={3} style={{ padding: 10 }}>
                            {checklistHeader()}
                            {renderChecklist()}
                        </Paper>
                    </Container>
                    {renderApproval()}
                </Container>
            </MuiPickersUtilsProvider>
        </Fragment>
    );
};

export default FinishedGoodsTruckInspectionForm;
