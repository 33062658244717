import React, { Fragment, useState } from "react";

import MixPremixHeader from "./MixerPremixHeader";
import PremixListView from "./Premix/PremixListView";
import ProductionOrderListView from "./ProductionOrder/ProductionOrderListView";
import { Typography } from "@material-ui/core";

export interface ContentProps {
    onDrawerToggle: () => void;
    mainClass: any;
}

const MixPremixMain = (props: ContentProps) => {
    const TAB1_TITLE = "Premix Template";
    const TAB2_TITLE = "Production Order";
    const tabsTitle = [{ id: TAB1_TITLE }, { id: TAB2_TITLE }];

    const [currentTab, setCurrentTab] = useState(0);
    const onTabsChange = (id: number) => {
        setCurrentTab(id);
    };

    return (
        <Fragment>
            <MixPremixHeader onDrawerToggle={props.onDrawerToggle} onTabsChange={onTabsChange} tabsTitle={tabsTitle} />
            <main className={props.mainClass}>
                {tabsTitle[currentTab].id === TAB1_TITLE && (
                    <Fragment>
                        <Typography style={{ paddingBottom: 10 }} color="inherit" variant="h5" component="h1">
                            {TAB1_TITLE}
                        </Typography>
                        <PremixListView />
                    </Fragment>
                )}
                {tabsTitle[currentTab].id === TAB2_TITLE && (
                    <Fragment>
                        <Typography style={{ paddingBottom: 10 }} color="inherit" variant="h5" component="h1">
                            {TAB2_TITLE}
                        </Typography>
                        <ProductionOrderListView />
                    </Fragment>
                )}
            </main>
        </Fragment>
    );
};

export default MixPremixMain;
