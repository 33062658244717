import ApiController from './api-controller';
import { AxiosPromise } from 'axios';

export interface PostSingleUserParams {
    email: string;
    userRole: string;
    userFirstName: string;
    userLastName: string;
}

export interface PatchSingleUserParams extends PostSingleUserParams {
    userStatus: string
}

class UserRegistrationController extends ApiController {

    getAllRegisteredUsers<Data = any>(): AxiosPromise<Data> {
        return this._instance.get(`/services/users`);
    }

    getSingleUser<Data = any>(email: string): AxiosPromise<Data> {
        return this._instance.get(`/services/users/${email}`);
    }

    patchSingleUserInfo<Data = any>(param: PostSingleUserParams): AxiosPromise<Data> {
        return this._instance.patch(`/services/users/${param.email}`, param);
    }

    getUserRoles<Data = any>(): AxiosPromise<Data> {
        return this._instance.get(`/services/roles`);
    }

}

export default UserRegistrationController;
