import { AppBar, CircularProgress, Grid, IconButton, Paper, TextField, Toolbar, Tooltip } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core/styles';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import UserListTable from './UserListTable';
import { getAllRegisteredUserAction } from 'redux/reducers/users/actions';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { userRegistrationTypedSelector } from 'redux/reducers/users/usersReducer';

export interface ContentProps extends WithStyles<typeof styles> {}

const UserListView = (props: ContentProps) => {
    const { classes } = props;

    const [filterText, setfilterText] = useState('');
    const [exportCsv, setExportCsv] = useState(false);
    const [onReload, setOnReload] = useState(false);
    const dispatcher = useDispatch();
    const users = userRegistrationTypedSelector<Array<any>>((state) => state.users.users);

    const onExportCsv = () => {
        setExportCsv(false);
    };

    useEffect(() => {
        setTimeout(() => {
            setOnReload(false);
        }, 1000);
    }, [users]);

    const renderUserListTableSearch = () => {
        return (
            <Fragment>
                <Grid item>
                    <SearchIcon className={classes.block} color='inherit' />
                </Grid>
                <Grid item xs>
                    <TextField
                        fullWidth
                        placeholder='Search'
                        InputProps={{
                            disableUnderline: true,
                            className: classes.searchInput,
                        }}
                        onChange={(event) => setfilterText(event.target.value)}
                    />
                </Grid>
            </Fragment>
        );
    };
    const [exportCsvFileName, setExportCsvFileName] = useState('');
    const renderUserListTableButton = () => {
        return (
            <Grid item>
                <Tooltip title='Export to CSV'>
                    <IconButton
                        onClick={() => {
                            setExportCsvFileName(`UserList-${moment(new Date()).format('MM-DD-YYYY:HH:mm:ss')}`);
                            setExportCsv(true);
                        }}>
                        <FileCopyIcon className={classes.block} color='inherit' />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Reload'>
                    <IconButton
                        onClick={() => {
                            setOnReload(true);
                            dispatcher(getAllRegisteredUserAction());
                        }}>
                        {onReload ? (
                            <CircularProgress size='24px' />
                        ) : (
                            <RefreshIcon className={classes.block} color='inherit' />
                        )}
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    };

    const renderUserListContainer = () => {
        return (
            <Grid container spacing={2} alignItems='center'>
                {renderUserListTableSearch()}
                {renderUserListTableButton()}
            </Grid>
        );
    };

    const renderContent = () => {
        return (
            <div className={classes.contentWrapper}>
                <UserListTable
                    filterText={filterText}
                    exportCsv={exportCsv}
                    onExportCsv={onExportCsv}
                    exportCsvFileName={exportCsvFileName}
                />
            </div>
        );
    };

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.searchBar} position='static' color='default' elevation={0}>
                <Toolbar>{renderUserListContainer()}</Toolbar>
            </AppBar>
            {renderContent()}
        </Paper>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: '99%',
            margin: 'auto',
            overflow: 'hidden',
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: 'rgb(31,49,69)',
        },
        contentWrapper: {
            margin: '0px 0px',
        },
    });

export default withStyles(styles)(UserListView);
