import {
    CLEAR_RELOCATE_WAREHOUSE,
    CLEAR_STOCKS,
    GET_RM_STOCKS,
    GET_WAREHOUSE_LOCATION_STOCKS,
    RELOCATE_RM_WAREHOUSE,
    StocksActionTypes,
    StocksInfoState,
    StocksReducerType,
} from './types';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { LOGOUT } from 'redux/reducers/rootReducer/types';

const initialState: StocksInfoState = {
    stocks: [],
    relocationStatus: { message: '', reason: '' },
};

export const stocksTypedSelector: TypedUseSelectorHook<StocksReducerType> = useSelector;

export function stocksReducer(state = initialState, action: StocksActionTypes): StocksInfoState {
    switch (action.type) {
        case GET_WAREHOUSE_LOCATION_STOCKS:
        case GET_RM_STOCKS:
            return { ...state, stocks: action.payload };

        case CLEAR_STOCKS:
            return { ...state, stocks: [] };

        case RELOCATE_RM_WAREHOUSE:
            return { ...state, relocationStatus: action.payload };

        case CLEAR_RELOCATE_WAREHOUSE:
            return { ...state, relocationStatus: { message: '', reason: '' } };

        case LOGOUT:
            return initialState;

        default:
            return state;
    }
}
