import "react-big-calendar/lib/css/react-big-calendar.css";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
    Theme,
    WithStyles,
    createStyles,
    withStyles,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import {
    DeliveryScheduleInfo,
    DeliveryStatus,
    ItemsToDeliverInfoBase,
    PurchaseOrderInfoList,
    PurchaseOrderStatus,
    SupplierInfoList,
} from "redux/reducers/purchasing/types";
import React, { Fragment, useEffect, useState } from "react";
import {
    deleteDeliveryScheduleAction,
    getAllPurchaseOrdersAction,
    getAllSuppliersAction,
    patchDeliveryScheduleInfoAction,
} from "redux/reducers/purchasing/actions";
import { getEndDateTimeFromDelivery, getStartDateTimeFromDelivery, transformToAmPm } from "./CalendarUtils";

import Alert from "@material-ui/lab/Alert";
import CancelIcon from "@material-ui/icons/Cancel";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import MomentUtils from "@date-io/moment";
import SaveIcon from "@material-ui/icons/Save";
import SelectComponent from "components/common/Select";
import TableGrid from "components/common/TableGrid";
import NumericEditor from "components/common/editors/NumericEditor";
import UpdateIcon from "@material-ui/icons/Update";
import moment from "moment";
import { purchasingTypedSelector } from "redux/reducers/purchasing/purchasingReducer";
import { useDispatch } from "react-redux";

export interface UpdateDeliveryFormProps extends WithStyles<typeof styles> {
    clickedDelivery?: DeliveryScheduleInfo;
    onFormClose: () => void;
    deliveries: DeliveryScheduleInfo[];
    onSyncList: () => void;
}

interface OrderInfoWithExpectedQuantity extends ItemsToDeliverInfoBase {
    deliveryQuantity: number;
}

const CalendarUpdateDelivery = (props: UpdateDeliveryFormProps) => {
    const { classes, clickedDelivery, onFormClose, deliveries, onSyncList } = props;

    const [showForm, setShowForm] = useState(false);
    const [newStartDeliveryDate, setNewStartDeliveryDate] = useState<Date>(new Date());
    const [newEndDeliveryDate, setNewEndDeliveryDate] = useState<Date>(new Date());
    const [newTruckNo, setNewTruckNo] = useState("");
    const [newTruckNoHelper, setNewTruckNoHelper] = useState("");
    const [newSupplier, setNewSupplier] = useState("");
    const [newSupplierHelper, setNewSupplierHelper] = useState("");
    const [newOrder, setNewOrder] = useState("");
    const [newOrderHelper, setNewOrderHelper] = useState("");
    const [newDeliveryStatus, setNewDeliveryStatus] = useState(DeliveryStatus.SCHEDULED);
    const [updateDeliveryErrorMessage, setUpdateDeliveryErrorMessage] = useState("");
    const [editMode, setEditMode] = useState(false);
    const [deleteMode, setDeleteMode] = useState(false);

    const suppliersInfoList = purchasingTypedSelector<SupplierInfoList>((state) => state.purchasing.suppliers);
    const purchaseOrderInfoList = purchasingTypedSelector<PurchaseOrderInfoList>(
        (state) => state.purchasing.purchaseOrders
    );
    const [supplierNamesList, setSupplierNamesList] = useState<Array<string>>([]);
    const [orderIdList, setOrderIdList] = useState<Array<string>>([]);
    const [orderItemsList, setOrderItemsList] = useState<Array<OrderInfoWithExpectedQuantity>>([]);
    const [isSuppliersInfoAcquired, setIsSuppliersInfoAcquired] = useState(false);
    const [isOrderInfoAcquired, setIsOrderInfoAcquired] = useState(false);

    const [newSender, setNewSender] = useState<string | undefined>("");
    const [newSenderHelper, setNewSenderHelper] = useState("");
    const [newRecipient, setNewRecipient] = useState<string | undefined>("");
    const [newRecipientHelper, setNewRecipientHelper] = useState("");
    const [newRequestor, setNewRequestor] = useState<string | undefined>("");
    const [newRequestorHelper, setNewRequestorHelper] = useState("");
    const [newProcurementRepresentative, setNewProcurementRepresentative] = useState<string | undefined>("");
    const [newProcurementRepresentativeHelper, setNewProcurementRepresentativeHelper] = useState("");

    const dispatcher = useDispatch();

    const statusOptions: DeliveryStatus[] = [
        DeliveryStatus.SCHEDULED,
        DeliveryStatus.DELIVERED,
        DeliveryStatus.DELAYED,
        DeliveryStatus.FAILED,
    ];

    useEffect(() => {
        if (clickedDelivery) {
            setNewStartDeliveryDate(getStartDateTimeFromDelivery(clickedDelivery));
            setNewEndDeliveryDate(getEndDateTimeFromDelivery(clickedDelivery));
            setNewOrder(clickedDelivery.orderId!);
            setNewTruckNo(clickedDelivery.truckNo);
            let orderInstance = purchaseOrderInfoList.ordersList.find(
                (info) => info.orderId === clickedDelivery.orderId!
            );
            let itemsList = clickedDelivery.itemsList;
            let itemWithExpecteQuantity = itemsList.map((item) => {
                let orderedItemQuantity = 0;
                if (orderInstance) {
                    let orderedItemList = orderInstance.itemsList;
                    let orderedItem = orderedItemList.find((ordered) => ordered.itemCode === item.itemCode);
                    if (orderedItem) {
                        orderedItemQuantity = orderedItem.itemQuantity;
                    }
                }
                let itemInfo: OrderInfoWithExpectedQuantity = {
                    itemCode: item.itemCode,
                    itemDescription: item.itemDescription,
                    itemUnit: item.itemUnit,
                    itemQuantity: orderedItemQuantity,
                    deliveryQuantity: item.itemQuantity,
                };
                return itemInfo;
            });
            setOrderItemsList(itemWithExpecteQuantity);
            setNewSupplier(clickedDelivery.supplierName);
            setNewSender(clickedDelivery.sender);
            setNewRecipient(clickedDelivery.recipient);
            setNewRequestor(clickedDelivery.requestor);
            setNewProcurementRepresentative(clickedDelivery.procurementRepresentative);
            setNewDeliveryStatus(clickedDelivery.scheduleStatus as DeliveryStatus);
            setShowForm(true);
        }
    }, [clickedDelivery, purchaseOrderInfoList]);

    useEffect(() => {
        if (suppliersInfoList.suppliersList.length > 0) {
            setSupplierNamesList(suppliersInfoList.suppliersList.map((info) => info.supplierName));
        } else if (!isSuppliersInfoAcquired) {
            setIsSuppliersInfoAcquired(true);
            dispatcher(getAllSuppliersAction());
        }
    }, [suppliersInfoList, isSuppliersInfoAcquired, dispatcher]);

    useEffect(() => {
        if (purchaseOrderInfoList.ordersList.length > 0) {
            setOrderIdList(purchaseOrderInfoList.ordersList.map((info) => info.orderId));
        } else if (!isOrderInfoAcquired) {
            setIsOrderInfoAcquired(true);
            dispatcher(getAllPurchaseOrdersAction());
        }
    }, [purchaseOrderInfoList, isOrderInfoAcquired, dispatcher]);

    const onModalClose = () => {
        resetFormErrorMessage();
        resetDeliveryInput();
        setShowForm(false);
        setEditMode(false);
        setDeleteMode(false);
        onFormClose();
    };

    const resetDeliveryInput = () => {
        setNewStartDeliveryDate(new Date());
        setNewEndDeliveryDate(new Date());
        setNewSupplier("");
        setNewOrder("");
        setNewTruckNo("");
        setNewSender("");
        setNewRecipient("");
        setNewRequestor("");
        setNewProcurementRepresentative("");
        setOrderItemsList([]);
        setNewDeliveryStatus(DeliveryStatus.SCHEDULED);
    };

    const addDeliveryToList = () => {
        let startDate = moment(newStartDeliveryDate!).format("YYYY-MM-DD");
        let startTime = moment(newStartDeliveryDate!).format("HH:mm");
        let itemsList = orderItemsList
            .filter((item) => item.deliveryQuantity > 0)
            .map((itemWithQuantity) => ({
                itemCode: itemWithQuantity.itemCode,
                itemDescription: itemWithQuantity.itemDescription,
                itemUnit: itemWithQuantity.itemUnit,
                itemQuantity: itemWithQuantity.deliveryQuantity,
            }));
        let updatedDelivery: any = {
            deliveryDate: startDate,
            timeSlot: startTime,
            scheduleStatus: newDeliveryStatus,
            sender: newSender,
            recipient: newRecipient,
            requestor: newRequestor,
            procurementRepresentative: newProcurementRepresentative,
            itemsList: itemsList,
        };

        dispatcher(patchDeliveryScheduleInfoAction(updatedDelivery, clickedDelivery!.scheduleId!));
    };

    const resetFormErrorMessage = () => {
        setNewTruckNoHelper("");
        setNewSupplierHelper("");
        setNewOrderHelper("");
        setNewSenderHelper("");
        setNewRecipientHelper("");
        setNewRequestorHelper("");
        setNewProcurementRepresentativeHelper("");
        setUpdateDeliveryErrorMessage("");
    };

    const hasAtLeastOneItemToDeliver = () => {
        return orderItemsList.find((item) => item.deliveryQuantity > 0) !== undefined;
    };

    const isFormComplete = () => {
        const hasDelivery = hasAtLeastOneItemToDeliver();
        if (
            newStartDeliveryDate === undefined ||
            newTruckNo === "" ||
            newSupplier === "" ||
            newOrder === "" ||
            newSender === "" ||
            newRecipient === "" ||
            newRequestor === "" ||
            newProcurementRepresentative === "" ||
            !hasDelivery
        ) {
            if (newTruckNo === "") setNewTruckNoHelper("Please input Truck #");
            if (newSupplier === "") setNewSupplierHelper("Please input Supplier");
            if (newOrder === "") setNewOrderHelper("Please input Order");
            if (newSender === "") setNewSenderHelper("Please input Sender");
            if (newRecipient === "") setNewRecipientHelper("Please input Recipient");
            if (newRequestor === "") setNewRequestorHelper("Please input Requestor");
            if (newProcurementRepresentative === "") setNewProcurementRepresentativeHelper("Please input Procurement Representative");
            if (!hasDelivery) setUpdateDeliveryErrorMessage("No items to be delivered");
            return false;
        }

        return true;
    };

    const checkIfSameDay = (delivery: DeliveryScheduleInfo) => {
        if (newStartDeliveryDate !== undefined) {
            // create a copy of dates but set the hours,mins,seconds to 0:0:0 to disregard time
            let storedDate = new Date(new Date(moment(delivery.deliveryDate).format("YYYY-MM-DD")).toDateString());
            let inputDate = new Date(newStartDeliveryDate.toDateString());
            // Compare dates (disregarding time) of date in the current loop and date input in form
            // if the dates are different, no need to compare time
            if (storedDate.getTime() !== inputDate.getTime()) {
                return false;
            }
            return true;
        }
        return false;
    };

    const getDeliveriesOnSameDay = () => {
        // remove first the delivery that is being updated
        let others = [...deliveries];
        if (clickedDelivery) {
            others.splice(others.findIndex((x) => x.scheduleId === clickedDelivery.scheduleId), 1);
        }
        return others.filter(checkIfSameDay);
    };

    const hasDuplicate = (): DeliveryScheduleInfo | null => {
        let duplicate = null;

        if (newStartDeliveryDate !== undefined && newEndDeliveryDate !== undefined) {
            let sameDayDeliveries = getDeliveriesOnSameDay();
            for (var i = 0; i < sameDayDeliveries.length; i++) {
                let startTime = getStartDateTimeFromDelivery(sameDayDeliveries[i]);
                let endTime = getEndDateTimeFromDelivery(sameDayDeliveries[i]);
                if (
                    startTime.getTime() === newStartDeliveryDate.getTime() ||
                    endTime.getTime() === newEndDeliveryDate.getTime() ||
                    (startTime < newStartDeliveryDate && newStartDeliveryDate < endTime) ||
                    (startTime < newEndDeliveryDate && newEndDeliveryDate < endTime)
                ) {
                    // if no duplicate found yet OR
                    // if there is a duplicate found, but another conflicting time slot is earlier than the current duplicate
                    // use the earlier time slot
                    if (duplicate === null || startTime < getStartDateTimeFromDelivery(duplicate)) {
                        duplicate = { ...sameDayDeliveries[i] };
                    }
                }
            }
        }
        return duplicate;
    };

    const updateDeliverySchedule = () => {
        onSyncList();
        resetFormErrorMessage();
        if (isFormComplete()) {
            let duplicate = hasDuplicate();
            if (duplicate) {
                setUpdateDeliveryErrorMessage(
                    "Time slot conflicts with delivery at " +
                        transformToAmPm(getStartDateTimeFromDelivery(duplicate)) +
                        "-" +
                        transformToAmPm(getEndDateTimeFromDelivery(duplicate))
                );
            } else {
                addDeliveryToList();
                resetDeliveryInput();
                onFormClose();
            }
        }
    };

    const deliveryDateRenderer = () => {
        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Grid container item xs={12} spacing={3}>
                    <Grid item xs={4}>
                        <DatePicker
                            disableFuture={false}
                            value={newStartDeliveryDate}
                            onChange={(date) => {
                                let newStartDate = moment(date);
                                let startDate = moment(newStartDeliveryDate);
                                newStartDate.set({ hour: startDate.hour(), minute: startDate.minute() });
                                setNewStartDeliveryDate(moment(newStartDate).toDate());
                                let newEndDate = moment(date);
                                let endDate = moment(newEndDeliveryDate);
                                newEndDate.set({ hour: endDate.hour(), minute: endDate.minute() });
                                setNewEndDeliveryDate(moment(newEndDate).toDate());
                            }}
                            format="MMM DD, YYYY"
                            inputVariant="standard"
                            onClose={() => {}}
                            label={"Date"}
                            readOnly={!editMode}
                            className={editMode ? classes.highlightedCell : ""}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TimePicker
                            value={newStartDeliveryDate}
                            ampm
                            onChange={(time) => {
                                let newStarDateAndTime = moment(time);
                                let currentStartDate = moment(newStartDeliveryDate);
                                currentStartDate.set({
                                    hour: newStarDateAndTime.hour(),
                                    minute: newStarDateAndTime.minute(),
                                });
                                setNewStartDeliveryDate(moment(currentStartDate).toDate());
                                let currentEndDate = moment(currentStartDate);
                                currentEndDate.add(2, "hours");
                                setNewEndDeliveryDate(moment(currentEndDate).toDate());
                            }}
                            inputVariant="standard"
                            onClose={() => {}}
                            label={"From"}
                            readOnly={!editMode}
                            className={editMode ? classes.highlightedCell : ""}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TimePicker
                            value={newEndDeliveryDate}
                            ampm
                            onChange={() => {}}
                            inputVariant="standard"
                            onClose={() => {}}
                            label={"To"}
                            disabled={true}
                            readOnly={true}
                            className={editMode ? classes.highlightedCell : ""}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectComponent
                            title={"Status"}
                            selectList={statusOptions}
                            setter={setNewDeliveryStatus}
                            currentValue={newDeliveryStatus}
                            readOnly={!editMode}
                            style={editMode ? { backgroundColor: "#DEEEFA" } : {}}
                        />
                    </Grid>
                </Grid>
            </MuiPickersUtilsProvider>
        );
    };

    const handleSelectedSupplierChange = (event: any) => {
        let supplier = event as string;
        setOrderIdList(
            purchaseOrderInfoList.ordersList
                .filter((info) => info.supplierName === supplier && info.orderStatus === PurchaseOrderStatus.APPROVED)
                .map((info) => info.orderId)
        );
        setNewSupplier(supplier);
        setNewOrder("");
        setOrderItemsList([]);
        setNewSupplierHelper("");
    };

    const handleSelectedOrderChange = (event: any) => {
        let orderId = event as string;
        if (orderId !== "") {
            let orderInstance = purchaseOrderInfoList.ordersList.find((info) => info.orderId === newOrder);
            if (orderInstance) {
                let itemsList = orderInstance.itemsList;
                let itemWithExpecteQuantity = itemsList.map(
                    (item) =>
                        ({
                            itemCode: item.itemCode,
                            itemDescription: item.itemDescription,
                            itemUnit: item.itemUnit,
                            itemQuantity: item.itemQuantity,
                            deliveryQuantity: 0,
                        } as OrderInfoWithExpectedQuantity)
                );
                setOrderItemsList(itemWithExpecteQuantity);
            }
        }
        setNewOrder(orderId);
        setNewOrderHelper("");
    };

    const columnDefs = [
        {
            headerName: "Item Code",
            field: "itemCode",
            minWidth: 100,
            filter: "agTextColumnFilter",
            suppressSizeToFit: true,
            headerTooltip: "Item Code",
        },
        {
            headerName: "Description",
            field: "itemDescription",
            minWidth: 350,
            filter: "agTextColumnFilter",
            suppressSizeToFit: true,
            headerTooltip: "Item Description",
        },
        {
            headerName: "Ordered Quantity",
            minWidth: 100,
            headerTooltip: "Ordered Quantity",
            suppressSizeToFit: true,
            valueGetter: (param: any) => {
                return param.data.itemQuantity + " " + param.data.itemUnit;
            },
        },
        {
            headerName: "Delivery Quantity",
            field: "deliveryQuantity",
            flex: 1,
            headerTooltip: "Delivery Quantity",
            editable: true,
            suppressSizeToFit: true,
            cellEditor: "numericEditor",
            valueSetter: (param: any) => {
                param.data.deliveryQuantity = Number(param.newValue);
                return true;
            },
        },
    ];

    const mainDeliveryFormRenderer = () => {
        return (
            <Fragment>
                <Grid container item xs={12} spacing={3}>
                    <Grid item xs={4}>
                        <SelectComponent
                            readOnly={true}
                            title={"Supplier"}
                            selectList={supplierNamesList}
                            setter={handleSelectedSupplierChange}
                            currentValue={newSupplier}
                            error={newSupplierHelper !== ""}
                            variant={"standard"}
                            codeHelperText={newSupplierHelper}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectComponent
                            readOnly={true}
                            title={"Order"}
                            disabled={newSupplier === ""}
                            selectList={orderIdList}
                            setter={handleSelectedOrderChange}
                            currentValue={newOrder}
                            error={newOrderHelper !== ""}
                            variant={"standard"}
                            codeHelperText={newOrderHelper}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            inputProps={{
                                readOnly: true,
                            }}
                            margin="dense"
                            type={"text"}
                            label={"Truck #"}
                            variant="outlined"
                            helperText={newTruckNoHelper}
                            value={newTruckNo}
                            error={newTruckNoHelper !== ""}
                            onChange={(event) => {
                                setNewTruckNo(event.target.value);
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3}>
                        <TextField
                            inputProps={{
                                readOnly: !editMode,
                            }}
                            label={"Sender"}
                            value={newSender}
                            helperText={newSenderHelper}
                            error={newSenderHelper !== ""}
                            onChange={(event) => {
                                setNewSender(event.target.value);
                            }}
                            fullWidth={true}
                            className={editMode ? classes.highlightedCell : ""}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            inputProps={{
                                readOnly: !editMode,
                            }}
                            label={"Recipient"}
                            value={newRecipient}
                            helperText={newRecipientHelper}
                            error={newRecipientHelper !== ""}
                            onChange={(event) => {
                                setNewRecipient(event.target.value);
                            }}
                            fullWidth={true}
                            className={editMode ? classes.highlightedCell : ""}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            inputProps={{
                                readOnly: !editMode,
                            }}
                            label={"Requester"}
                            value={newRequestor}
                            helperText={newRequestorHelper}
                            error={newRequestorHelper !== ""}
                            onChange={(event) => {
                                setNewRequestor(event.target.value);
                            }}
                            fullWidth={true}
                            className={editMode ? classes.highlightedCell : ""}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            inputProps={{
                                readOnly: !editMode,
                            }}
                            label={"Procurement Representative"}
                            value={newProcurementRepresentative}
                            helperText={newProcurementRepresentativeHelper}
                            error={newProcurementRepresentativeHelper !== ""}
                            onChange={(event) => {
                                setNewProcurementRepresentative(event.target.value);
                            }}
                            fullWidth={true}
                            className={editMode ? classes.highlightedCell : ""}
                        />
                    </Grid>
                </Grid>
                <Grid container item>
                    <Grid item xs={12}>
                        <TableGrid
                            rowData={orderItemsList}
                            columnDefs={columnDefs}
                            height={300}
                            frameworkComponents={{
                                numericEditor: NumericEditor,
                            }}
                        />
                    </Grid>
                </Grid>
            </Fragment>
        );
    };

    return (
        <div>
            <Dialog
                maxWidth={"md"}
                disableEscapeKeyDown={true}
                disableBackdropClick={true}
                open={showForm}
                onClose={onModalClose}
            >
                <DialogTitle className={deleteMode ? classes.confirmationText : ""}>
                    {editMode === false && deleteMode === false && <Fragment>Delivery Information</Fragment>}
                    {editMode === true && <Fragment>Update Delivery Information</Fragment>}
                    {deleteMode === true && <Fragment>Delete this delivery schedule?</Fragment>}
                    <IconButton className={classes.leftButton} aria-label="close" onClick={onModalClose}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container alignItems="center">
                        {deliveryDateRenderer()}
                        {mainDeliveryFormRenderer()}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {editMode === true && (
                        <Button
                            onClick={updateDeliverySchedule}
                            color="primary"
                            variant="contained"
                            fullWidth={true}
                            startIcon={<SaveIcon />}
                        >
                            Save
                        </Button>
                    )}
                    {editMode === false && deleteMode === false && (
                        <Button
                            onClick={() => {
                                setEditMode(true);
                            }}
                            color="primary"
                            variant="contained"
                            fullWidth={true}
                            startIcon={<UpdateIcon />}
                        >
                            Update
                        </Button>
                    )}
                    {editMode === false && deleteMode === false && (
                        <Button
                            className={classes.buttonRed}
                            onClick={() => {
                                setDeleteMode(true);
                            }}
                            variant="contained"
                            fullWidth={true}
                            startIcon={<DeleteIcon />}
                        >
                            Delete
                        </Button>
                    )}
                    {editMode === true && (
                        <Button
                            onClick={() => {
                                onModalClose();
                            }}
                            color="primary"
                            variant="contained"
                            fullWidth={true}
                            startIcon={<CancelIcon />}
                        >
                            Cancel
                        </Button>
                    )}
                    {deleteMode === true && (
                        <Fragment>
                            <Button
                                onClick={() => {
                                    dispatcher(deleteDeliveryScheduleAction(clickedDelivery!.scheduleId!));
                                    onFormClose();
                                    onModalClose();
                                }}
                                color="primary"
                                variant="contained"
                                fullWidth={true}
                                startIcon={<CancelIcon />}
                            >
                                Yes
                            </Button>
                            <Button
                                onClick={() => {
                                    onModalClose();
                                }}
                                color="primary"
                                variant="contained"
                                fullWidth={true}
                                startIcon={<CancelIcon />}
                            >
                                No
                            </Button>
                        </Fragment>
                    )}
                </DialogActions>
                {updateDeliveryErrorMessage !== "" && <Alert severity="error">{updateDeliveryErrorMessage}</Alert>}
            </Dialog>
        </div>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: "99%",
            margin: "auto",
            overflow: "hidden",
            alignContent: "center",
        },
        searchBar: {
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: "block",
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: "rgb(31,49,69)",
            alignSelf: "center",
        },
        contentWrapper: {
            margin: "0px 0px",
            height: "600px",
        },
        spacedGrid: {
            minWidth: "125px",
            marginLeft: "12px",
        },
        buttonRed: {
            backgroundColor: "#f25c5c",
            color: "white",
        },
        leftButton: {
            float: "right",
        },
        highlightedCell: {
            backgroundColor: "#DEEEFA",
        },
        confirmationText: {
            color: "#f72525",
            backgroundColor: "#fff1f0",
        },
    });

export default withStyles(styles)(CalendarUpdateDelivery);
