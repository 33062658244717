import {
    LOGOUT,
} from './types'
import AppController from "controller/application";
import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'

async function processLogout() {
    AppController.logout()
}

export function logoutAction():
    ThunkAction<void, {}, unknown, Action<string>> {

    return async dispatch => {
        processLogout();

        dispatch({
            type: LOGOUT
        });

    };
}
