import * as URL_CONST from 'utils/urls';

import { API_ENDPOINT_URL, SERVICE_DOMAIN } from 'utils/urls';
import {
    AddStockTransferTransactionParams,
    CreateFGBatchParam,
    FGBatchOrderInfo,
    FGDispatchApproval,
    FGQAAnalysisInfo,
    FGQAAnalysisResult,
    FGTruckInspectionInfo,
    FGUnloadDetails,
    GetFGQAPresignedUrlParams,
} from 'redux/reducers/finishedGoods/types';
import {
    DeletePurchaseRequestAtttachmentParams,
    DeliveryScheduleInfoRequest,
    GetPurchasingPresignedUrlParams,
    PurchaseOrderInfoRequest,
    PurchaseOrderInfoUpdateRequest,
    PurchaseRequestInfoRequest,
    SupplierInfoRequest,
} from 'redux/reducers/purchasing/types';
import { FGTruckEntries, RMTruckEntries } from 'redux/reducers/truckEntries/types';
import {
    GetLetterPresignedUrlParams,
    GetRMQAPresignedUrlParams,
    RMAnalysisInfo,
    RMInfo,
    RMShipmentApproval,
    RMTruckInspectionInfo,
    RMUnloadingInfo,
} from 'redux/reducers/rmShipment/types';
import { PremixInfoRequest, ProductionOrderInfoRequest } from 'redux/reducers/premix/types';
import SessionController, { LoginParams, NewPasswordRequiredParams } from './session-controller';
import UserController, { PatchSingleUserParams, PostSingleUserParams } from './userRegistration-controller';
import { deleteCookie, getCookie, setCookie } from 'utils/cookies';

import { Canvass } from 'redux/reducers/canvass/types';
import CanvassController from './canvass-controller';
import FinishedGoodsController from './finishedgoods-controller';
import PremixController from './premix-controller';
import PresignedController from './pre-signed-controller';
import PurchasingController from './purchasing-controller';
import RMShipmentController from './rmshipment-controller';
import { RouterNavigation } from 'utils/navigation';
import SparePartsController from './spareparts-controller';
import { SparePartsTransactionRequest } from 'redux/reducers/spareParts/types';
import { StockRelocationInfo } from 'redux/reducers/rmStocks/types';
import StocksController from './stocks-controller';
import TruckEntriesController from './truckentries-controller';
import { User } from './user-data';

class AppController {
    private _session: SessionController;
    private _user: UserController;
    private _rm_shipment: RMShipmentController;
    private _stocks: StocksController;
    private _fg: FinishedGoodsController;
    private _pre_signed: PresignedController;
    private _truck_entries: TruckEntriesController;
    private _spareparts: SparePartsController;
    private _purchasing: PurchasingController;
    private _premix: PremixController;
    private _canvass: CanvassController;

    private _user_data: User | null;
    private _domain: string;

    constructor() {
        const config = {
            baseURL: API_ENDPOINT_URL,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        };

        this._session = new SessionController(config);
        this._user = new UserController(config);
        this._rm_shipment = new RMShipmentController(config);
        this._stocks = new StocksController(config);
        this._fg = new FinishedGoodsController(config);
        this._pre_signed = new PresignedController(config);
        this._truck_entries = new TruckEntriesController(config);
        this._spareparts = new SparePartsController(config);
        this._purchasing = new PurchasingController(config);
        this._premix = new PremixController(config);
        this._canvass = new CanvassController(config);

        this._user_data = null;

        this._domain = window.location.hostname;
        if (this._domain !== 'localhost') {
            this._domain = '.' + SERVICE_DOMAIN;
        }
    }

    get isAuthenticated(): boolean {
        return Boolean(this._user_data);
    }

    get alternateUsername(): string {
        if (this._user_data) {
            return this._user_data.email;
        }
        return '';
    }

    saveUserData(UserData: any): User {
        var now: Date = new Date();
        var expiry: Date = new Date(now.getTime() + UserData.AuthenticationResult.ExpiresIn * 1000);
        return {
            email: UserData.email,
            userRole: UserData.userRole,
            userFirstName: UserData.userFirstName,
            userLastName: UserData.userLastName,
            tokenInfo: {
                accessToken: UserData.AuthenticationResult.AccessToken,
                idToken: UserData.AuthenticationResult.IdToken,
                refreshToken: UserData.AuthenticationResult.RefreshToken,
                expiry: expiry,
            },
        };
    }

    updateSessionTokens(UserData: any) {
        if (this._user_data) {
            const now: Date = new Date();
            const expiry: Date = new Date(now.getTime() + UserData.AuthenticationResult.ExpiresIn * 1000);
            this._user_data.tokenInfo.accessToken = UserData.AuthenticationResult.AccessToken;
            this._user_data.tokenInfo.idToken = UserData.AuthenticationResult.IdToken;
            this._user_data.tokenInfo.expiry = expiry;

            setCookie('idToken', this._user_data.tokenInfo.idToken, this._domain, this._user_data.tokenInfo.expiry);
            setCookie(
                'accessToken',
                this._user_data.tokenInfo.accessToken,
                this._domain,
                this._user_data.tokenInfo.expiry
            );
            this.updateAuthorizationHeader();
        }
    }

    saveUserInfo() {
        if (this._user_data === null) {
            return;
        }

        setCookie('idToken', this._user_data.tokenInfo.idToken, this._domain, this._user_data.tokenInfo.expiry);
        setCookie('accessToken', this._user_data.tokenInfo.accessToken, this._domain, this._user_data.tokenInfo.expiry);
        setCookie('refreshToken', this._user_data.tokenInfo.refreshToken, this._domain);
        setCookie('email', this._user_data.email, this._domain);
        setCookie('firstName', this._user_data.userFirstName, this._domain);
        setCookie('lastName', this._user_data.userLastName, this._domain);
        setCookie('role', this._user_data.userRole, this._domain);
    }

    async login(parameters: LoginParams) {
        var response = await this._session.login(parameters);

        if (response.status === 200) {
            if (
                response.data &&
                response.data.Message &&
                response.data.Message.ChallengeName === 'NEW_PASSWORD_REQUIRED'
            ) {
                return response.data.Message;
            }

            // if login is successful, clear the
            // localStorage before processing the response.
            localStorage.clear();
            this._user_data = this.saveUserData(response.data.Message);
            this.saveUserInfo();
            this.updateAuthorizationHeader();
        }
    }

    async newPassword(parameters: NewPasswordRequiredParams) {
        var response = await this._session.newPassword(parameters);

        if (response.status === 200) {
            // if set new password is successful, clear the
            // localStorage before processing the response.
            localStorage.clear();

            this._user_data = this.saveUserData(response.data.Message);
            this.saveUserInfo();
            this.updateAuthorizationHeader();
        }
    }

    // TO-DO: Implement sending password reset request to email
    async passwordReset(email: string) {
        console.log(email);
    }

    async refreshToken() {
        let idToken = getCookie('idToken');
        if (!idToken) {
            if (this._user_data) {
                const response = await this._session.refreshToken({
                    email: this._user_data.email,
                    refresh_token: this._user_data.tokenInfo.refreshToken,
                });
                if (response.status === 200) {
                    this.updateSessionTokens(response.data.Message);
                } else {
                    RouterNavigation.navigateTo(URL_CONST.LOGIN_PAGE);
                }
            }
        }
    }

    updateAuthorizationHeader() {
        let idToken = getCookie('idToken');
        if (idToken) {
            this._session.updateAuthorizationHeader(idToken);
        }
    }

    logout(): void {
        deleteCookie('idToken', this._domain);
        deleteCookie('accessToken', this._domain);
        deleteCookie('refreshToken', this._domain);
        deleteCookie('username', this._domain);

        localStorage.clear();
        this._user_data = null;

        RouterNavigation.navigateTo(URL_CONST.LOGIN_PAGE);
    }

    // Users
    async getAllRegisteredUsers() {
        await this.refreshToken();
        let response = await this._user.getAllRegisteredUsers();
        return { users: response.data };
    }

    async getSingleUser(email: string) {
        await this.refreshToken();
        let response = await this._user.getSingleUser(email);
        return response.data;
    }

    async postRegisterSingleUser(parameters: PostSingleUserParams) {
        await this.refreshToken();
        try {
            let response = await this._session.postRegisterSingleUser(parameters);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    async postEnableSingleUser(parameters: PostSingleUserParams) {
        await this.refreshToken();
        try {
            let response = await this._session.postEnableSingleUser(parameters);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    async postDisableSingleUser(parameters: PostSingleUserParams) {
        await this.refreshToken();
        try {
            let response = await this._session.postDisableSingleUser(parameters);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    async patchSingleUserInfo(parameters: PatchSingleUserParams) {
        await this.refreshToken();
        try {
            let response = await this._user.patchSingleUserInfo(parameters);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    async getUserRoles() {
        await this.refreshToken();
        let response = await this._user.getUserRoles();
        localStorage.setItem('permission', JSON.stringify(response.data));
        return response.data;
    }

    // RM Shipment
    async getRMShipments() {
        await this.refreshToken();
        let response = await this._rm_shipment.getRmShipments();
        return response.data;
    }

    async postNewRmShipment(parameters: RMInfo) {
        await this.refreshToken();
        let response = await this._rm_shipment.postNewRmShipments(parameters);
        return response.data;
    }

    async patchRmShipmentAnalysisInfo(parameters: RMAnalysisInfo, rmrrId: string) {
        await this.refreshToken();
        try {
            let response = await this._rm_shipment.patchRmShipmentAnalysisInfo(parameters, rmrrId);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    async patchRmTruckInspectionInfo(parameters: RMTruckInspectionInfo, rmrrId: string) {
        await this.refreshToken();
        let response = await this._rm_shipment.patchRmTruckInspectionInfo(parameters, rmrrId);
        return response.data;
    }

    async patchRmShipmentApprovalInfo(parameters: RMShipmentApproval, rmrrId: string) {
        await this.refreshToken();
        try {
            let response = await this._rm_shipment.patchRmShipmentApprovalInfo(parameters, rmrrId);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    async patchRmShipmentUnloadingInfo(parameters: RMUnloadingInfo, rmrrId: string) {
        await this.refreshToken();
        try {
            let response = await this._rm_shipment.patchRmShipmentUnloadingInfo(parameters, rmrrId);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    async getRMWarehouseLocationInfo() {
        await this.refreshToken();
        let response = await this._rm_shipment.getRMWarehouseLocationInfo();
        return response.data;
    }

    async getRMInfoList() {
        await this.refreshToken();
        let response = await this._rm_shipment.getRMInfoList();
        return response.data;
    }

    async deleteShipmentByRmrrId(rmrrId: string) {
        await this.refreshToken();
        try {
            let response = await this._rm_shipment.deleteShipmentByRmrrId(rmrrId);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    async uploadToPresignedUrl(url: string, fields: any, letter: any) {
        let uploadData = new FormData();
        let keys = Object.keys(fields);

        keys.forEach((key) => {
            uploadData.append(key, fields[key]);
        });

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        uploadData.append('file', letter);

        this._pre_signed.uploadPreSigned({
            url: url,
            data: uploadData,
            config: config,
        });
    }

    async postLetterOfGuaranteePresigned(parameters: GetLetterPresignedUrlParams, letter: any) {
        await this.refreshToken();
        let response = await this._rm_shipment.generateLetterOfGuaranteePresignedUrl(parameters);

        if (response.status === 200) {
            let fields = response.data.preSignedUrl.fields;
            let url = response.data.preSignedUrl.url;
            await this.uploadToPresignedUrl(url, fields, letter);
        }
    }

    async postQAFilePresigned(parameters: GetRMQAPresignedUrlParams, letter: any) {
        await this.refreshToken();
        let response = await this._rm_shipment.generateQAFilePresignedUrl(parameters);

        if (response.status === 200) {
            let fields = response.data.preSignedUrl.fields;
            let url = response.data.preSignedUrl.url;
            await this.uploadToPresignedUrl(url, fields, letter);
        }
    }

    // Stocks
    async getWarehouseLocationStocks(warehouse: string, location: string) {
        await this.refreshToken();
        let response = await this._stocks.getWarehouseLocationStocks(warehouse, location);
        return response.data;
    }

    async getRmStocks(rmName: string) {
        await this.refreshToken();
        let response = await this._stocks.getRmStocks(rmName);
        return response.data;
    }

    async relocateRMWarehouse(relocationInfo: StockRelocationInfo, rm: string) {
        await this.refreshToken();
        try {
            const response = await this._stocks.relocateRMWarehouse(relocationInfo, rm);
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    // Finished Goods
    async getRmStockTransferTransaction(rmrrId: string) {
        await this.refreshToken();
        try {
            let response = await this._fg.getRmStockTransferTransaction(rmrrId);
            return response.data;
        } catch (error) {
            return [];
        }
    }

    async addRmStockTransferTransaction(
        warehouseId: string,
        locationId: string,
        parameters: AddStockTransferTransactionParams
    ) {
        await this.refreshToken();
        try {
            let response = await this._fg.addRmStockTransferTransaction(warehouseId, locationId, parameters);
            return response.data;
        } catch (error) {
            return { response: 'error' };
        }
    }

    async getTtockTransactionsRawMaterialLocation(warehouseId: string, locationId: string, rmName: string) {
        await this.refreshToken();
        let response = await this._fg.getTtockTransactionsRawMaterialLocation(warehouseId, locationId, rmName);
        return response.data;
    }

    async getFGBatchPerLine(line: string) {
        await this.refreshToken();
        let response = await this._fg.getFGBatchPerLine(line);
        return response.data;
    }

    async createFgBatch(param: CreateFGBatchParam) {
        await this.refreshToken();
        let response = await this._fg.createFgBatch(param);
        return response.data;
    }

    async patchTruckCleanlinessInspection(id: string, param: FGTruckInspectionInfo) {
        await this.refreshToken();
        try {
            let response = await this._fg.patchTruckCleanlinessInspection(id, param);
            return response.data;
        } catch (error) {
            return { response: 'error' };
        }
    }

    async addFGQASampleAnalysis(id: string, param: FGQAAnalysisInfo) {
        await this.refreshToken();
        try {
            let response = await this._fg.addFGQASampleAnalysis(id, param);
            return response.data;
        } catch (error) {
            return { response: 'error' };
        }
    }

    async addFGBatchQAAnalysisResult(id: string, param: FGQAAnalysisResult) {
        await this.refreshToken();
        try {
            let response = await this._fg.addFGBatchQAAnalysisResult(id, param);
            return response.data;
        } catch (error) {
            return { response: 'error' };
        }
    }

    async getFGQASampleAnalysis(id: string) {
        await this.refreshToken();
        try {
            let response = await this._fg.getFGQASampleAnalysis(id);
            return response.data;
        } catch (error) {
            return { response: 'error' };
        }
    }

    async addFGOrderInfo(id: string, param: FGBatchOrderInfo) {
        await this.refreshToken();
        try {
            let response = await this._fg.addFGOrderInfo(id, param);
            return response.data;
        } catch (error) {
            return { response: 'error' };
        }
    }

    async getFGWarehouseLocation() {
        await this.refreshToken();
        let response = await this._fg.getFGWarehouseLocation();
        return response.data;
    }

    async getFGStockByWarehouseLocation(warehouse: string, location: string) {
        await this.refreshToken();
        let response = await this._fg.getFGStockByWarehouseLocation(warehouse, location);
        return response.data;
    }

    async addFGBatchUnloadingDetails(id: string, params: FGUnloadDetails) {
        await this.refreshToken();
        try {
            let response = await this._fg.addFGBatchUnloadingDetails(id, params);
            return response.data;
        } catch (error) {
            return {
                response: {
                    data: {
                        reason: `Finished goods batch ${id} is not yet approved for unloading`,
                    },
                },
            };
        }
    }

    /* 07_Approve FG order for dispatch */
    async approveFGOrderForDispatch(issueOrder: string, param: FGDispatchApproval) {
        await this.refreshToken();
        try {
            let response = await this._fg.approveFGOrderForDispatch(issueOrder, param);
            return response.data;
        } catch (error) {
            return { response: 'error' };
        }
    }

    /* 08_Dispatch FG order */
    async dispatchFGOrder(issueOrder: string, deliveryDate: string) {
        await this.refreshToken();
        try {
            let response = await this._fg.dispatchFGOrder(issueOrder, deliveryDate);
            return response.data;
        } catch (error) {
            return { response: 'error' };
        }
    }

    async getFGOrder(batchId: string) {
        await this.refreshToken();
        try {
            let response = await this._fg.getFGOrder(batchId);
            return response.data;
        } catch (error) {
            return { response: 'error' };
        }
    }

    async postFGQAPresigned(parameters: GetFGQAPresignedUrlParams, file: any) {
        await this.refreshToken();
        let response = await this._fg.generateFGQAPresignedUrl(parameters);

        if (response.status === 200) {
            let fields = response.data.preSignedUrl.fields;
            let url = response.data.preSignedUrl.url;
            await this.uploadToPresignedUrl(url, fields, file);
        }
    }

    async deleteFGBatchByID(batchId: string) {
        await this.refreshToken();
        let response = await this._fg.deleteFGBatchByID(batchId);
        return response.data;
    }
    // Truck Entries

    async getRMTruckEntries() {
        await this.refreshToken();
        let response = await this._truck_entries.getRMTruckEntries();
        return response.data;
    }

    async postRMTruckEntries(param: RMTruckEntries) {
        await this.refreshToken();
        let response = await this._truck_entries.postRMTruckEntries(param);
        return response.data;
    }

    async patchRMTruckEntries(inDateTime: string, outDateTime: string) {
        await this.refreshToken();
        let response = await this._truck_entries.patchRMTruckEntries(inDateTime, outDateTime);
        return response.data;
    }

    async deleteRMTruckEntry(inDateTime: string) {
        await this.refreshToken();
        let response = await this._truck_entries.deleteRMTruckEntry(inDateTime);
        return response.data;
    }

    async getFGTruckEntries() {
        await this.refreshToken();
        let response = await this._truck_entries.getFGTruckEntries();
        return response.data;
    }

    async postFGTruckEntries(param: FGTruckEntries) {
        await this.refreshToken();
        let response = await this._truck_entries.postFGTruckEntries(param);
        return response.data;
    }

    async patchFGTruckEntries(inDateTime: string, outDateTime: string) {
        await this.refreshToken();
        let response = await this._truck_entries.patchFGTruckEntries(inDateTime, outDateTime);
        return response.data;
    }

    async deleteFGTruckEntry(inDateTime: string) {
        await this.refreshToken();
        let response = await this._truck_entries.deleteFGTruckEntry(inDateTime);
        return response.data;
    }

    /* Spare Parts */
    async getAllSparePartsInfo() {
        await this.refreshToken();
        let response = await this._spareparts.getAllSparePartsInfo();
        return response.data;
    }

    async getAllSparePartsTranscation() {
        await this.refreshToken();
        let response = await this._spareparts.getAllSparePartsTranscation();
        return response.data;
    }

    async createSparePartsTransaction(data: SparePartsTransactionRequest) {
        await this.refreshToken();
        try {
            let response = await this._spareparts.createSparePartsTransaction(data);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    async updateSparePartsTransaction(transactionId: string, data: SparePartsTransactionRequest) {
        await this.refreshToken();
        try {
            let response = await this._spareparts.updateSparePartsTransaction(transactionId, data);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    async addNewSparePartsCode(code: string) {
        await this.refreshToken();
        let response = await this._spareparts.addNewSparePartsCode(code);
        return response.data;
    }

    async getAllSparePartsTranscationById(id: string) {
        await this.refreshToken();
        let response = await this._spareparts.getAllSparePartsTranscationById(id);
        return response.data;
    }

    async getAllSparePartsTranscationByCode(code: string) {
        await this.refreshToken();
        let response = await this._spareparts.getAllSparePartsTranscationByCode(code);
        return response.data;
    }

    /* Purchasing*/
    async getDeliverySchedule() {
        await this.refreshToken();
        let response = await this._purchasing.getDeliverySchedule();
        return response.data;
    }

    async getAllSuppliers() {
        await this.refreshToken();
        let response = await this._purchasing.getAllSuppliers();
        return response.data;
    }

    async getAllPurchaseRequests() {
        await this.refreshToken();
        let response = await this._purchasing.getAllPurchaseRequests();
        return response.data;
    }

    async getAllPurchaseOrders() {
        await this.refreshToken();
        let response = await this._purchasing.getAllPurchaseOrders();
        return response.data;
    }

    async postNewDeliverySchedule(parameters: DeliveryScheduleInfoRequest) {
        await this.refreshToken();
        let response = await this._purchasing.postNewDeliverySchedule(parameters);
        return response.data;
    }

    async postNewSupplier(parameters: SupplierInfoRequest) {
        await this.refreshToken();
        let response = await this._purchasing.postNewSupplier(parameters);
        return response.data;
    }

    async postNewPurchaseRequest(parameters: PurchaseRequestInfoRequest) {
        await this.refreshToken();
        let response = await this._purchasing.postNewPurchaseRequest(parameters);
        return response.data;
    }

    async postNewPurchaseOrder(parameters: PurchaseOrderInfoRequest) {
        await this.refreshToken();
        let response = await this._purchasing.postNewPurchaseOrder(parameters);
        return response.data;
    }

    async patchPurchaseRequestInfo(parameters: PurchaseRequestInfoRequest, requestId: string) {
        await this.refreshToken();
        try {
            let response = await this._purchasing.patchPurchaseRequestInfo(parameters, requestId);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    async patchPurchaseOrderInfo(parameters: PurchaseOrderInfoUpdateRequest, orderId: string) {
        await this.refreshToken();
        try {
            let response = await this._purchasing.patchPurchaseOrderInfo(parameters, orderId);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    async patchDeliveryScheduleInfo(parameters: DeliveryScheduleInfoRequest, scheduleId: string) {
        await this.refreshToken();
        try {
            let response = await this._purchasing.patchDeliveryScheduleInfo(parameters, scheduleId);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    async patchSupplierInfo(parameters: SupplierInfoRequest, supplierId: string) {
        await this.refreshToken();
        try {
            let response = await this._purchasing.patchSupplierInfo(parameters, supplierId);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    async deleteDeliverySchedule(scheduleId: string) {
        await this.refreshToken();
        try {
            let response = await this._purchasing.deleteDeliverySchedule(scheduleId);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    async deletePurchaseAttachment(parameters: DeletePurchaseRequestAtttachmentParams) {
        await this.refreshToken();
        try {
            let response = await this._purchasing.deletePurchaseAttachment(parameters);
            return response.data;
        } catch (error) {
            return error;
        }
    }
    async deletePurchaseRequisition(requestId: string) {
        await this.refreshToken();
        try {
            let response = await this._purchasing.deletePurchaseRequisition(requestId);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    async deletePurchaseOrder(orderId: string) {
        await this.refreshToken();
        try {
            let response = await this._purchasing.deletePurchaseOrder(orderId);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    async deleteSupplier(supplierId: string) {
        await this.refreshToken();
        try {
            let response = await this._purchasing.deleteSupplier(supplierId);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    async postPurchasePresigned(parameters: GetPurchasingPresignedUrlParams, files: Array<any>) {
        await this.refreshToken();

        let ctr = 0
        let response = await this._purchasing.generatePurchasingPresignedUrl(parameters);

        if (response.status === 200) {
            for (ctr = 0; ctr < files.length; ctr++) {
                await this.uploadToPresignedUrl(
                    response.data.preSignedUrls[ctr].url,
                    response.data.preSignedUrls[ctr].fields,
                    files[ctr]);
            }
        }
    }

    /* Premix */
    async getAllPremix() {
        await this.refreshToken();
        let response = await this._premix.getAllPremix();
        return response.data;
    }

    async getAllProductionOrder() {
        await this.refreshToken();
        let response = await this._premix.getAllProductionOrder();
        return response.data;
    }

    async postNewPremix(parameters: PremixInfoRequest) {
        await this.refreshToken();
        let response = await this._premix.postNewPremix(parameters);
        return response.data;
    }

    async postNewProductionOrder(parameters: ProductionOrderInfoRequest) {
        await this.refreshToken();
        let response = await this._premix.postNewProductionOrder(parameters);
        return response.data;
    }

    async patchPremixInfo(parameters: PremixInfoRequest, premixName: string) {
        await this.refreshToken();
        try {
            let response = await this._premix.patchPremixInfo(parameters, premixName);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    async patchProductionOrderInfo(parameters: ProductionOrderInfoRequest, productionOrderId: string) {
        await this.refreshToken();
        try {
            let response = await this._premix.patchProductionOrderInfo(parameters, productionOrderId);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    async deletePremix(premixName: string) {
        await this.refreshToken();
        try {
            let response = await this._premix.deletePremix(premixName);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    async deleteProductionOrder(productionOrderId: string) {
        await this.refreshToken();
        try {
            let response = await this._premix.deleteProductionOrder(productionOrderId);
            return response.data;
        } catch (error) {
            return error;
        }
    }

    // Canvass
    async createNewCanvass(canvass: Canvass) {
        await this.refreshToken();
        try {
            const response = await this._canvass.createNewCanvass(canvass);
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async getMultipleCanvass(lastEvaluatedKey: string) {
        await this.refreshToken();
        let response = await this._canvass.getMultipleCanvass(lastEvaluatedKey);
        return response.data;
    }

    async getCanvassByID(id: string) {
        await this.refreshToken();
        try {
            const response = await this._canvass.getCanvassByID(id);
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async updateCanvassByID(id: string, canvass: Canvass) {
        await this.refreshToken();
        try {
            const response = await this._canvass.updateCanvassByID(id, canvass);
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }

    async deleteCanvassByID(requestId: string) {
        await this.refreshToken();
        try {
            let response = await this._canvass.deleteCanvassByID(requestId);
            return response.data;
        } catch (error) {
            return error;
        }
    }

}

export default new AppController();
