import {
    AddStockTransferTransactionParams,
    CreateFGBatchParam,
    FGBatchOrderInfo,
    FGDispatchApproval,
    FGQAAnalysisInfo,
    FGQAAnalysisResult,
    FGTruckInspectionInfo,
    FGUnloadDetails,
    GetFGQAPresignedUrlParams,
} from 'redux/reducers/finishedGoods/types';

import ApiController from './api-controller';
import { AxiosPromise } from 'axios';

interface addStockTransferTransactionparam {
    stockSourceList: Array<string>;
    weight: number;
    shift: string;
}

class FinishedGoodsController extends ApiController {
    getRmStockTransferTransaction<Data = any>(rmrrId: string): AxiosPromise<Data> {
        return this._instance.get(`services/fg/stock/${rmrrId}/transactions`);
    }

    addRmStockTransferTransaction<Data = any>(
        warehouseId: string,
        locationId: string,
        parameters: AddStockTransferTransactionParams
    ): AxiosPromise<Data> {
        return this._instance.post(
            `services/fg/warehouse/${warehouseId}/location/${locationId}/stock/transaction`,
            parameters
        );
    }

    getTtockTransactionsRawMaterialLocation<Data = any>(
        warehouseId: string,
        locationId: string,
        rmName: string
    ): AxiosPromise<Data> {
        return this._instance.get(
            `services/fg/warehouse/${warehouseId}/location/${locationId}/stock/${rmName}/transactions`
        );
    }

    getFGBatchPerLine<Data = any>(line: string): AxiosPromise<Data> {
        return this._instance.get(`services/fg/line/${line}/batches`);
    }

    /* 01_Create new FG batch */
    createFgBatch<Data = any>(param: CreateFGBatchParam): AxiosPromise<Data> {
        return this._instance.post(`services/fg/batch`, param);
    }

    /* 02_Add QA sample analysis */
    addFGQASampleAnalysis<Data = any>(id: string, param: FGQAAnalysisInfo): AxiosPromise<Data> {
        return this._instance.post(`services/fg/batch/${id}/analysis`, param);
    }

    /* 03_Add QA analysis result of FG batch */
    addFGBatchQAAnalysisResult<Data = any>(id: string, params: FGQAAnalysisResult): AxiosPromise<Data> {
        return this._instance.patch(`services/fg/batch/${id}/qaResult`, params);
    }

    /* 04_Add FG Batch unloading details */
    addFGBatchUnloadingDetails<Data = any>(id: string, params: FGUnloadDetails): AxiosPromise<Data> {
        return this._instance.patch(`services/fg/batch/${id}/unloadingInfo`, params);
    }

    /* 05_Create FG order */
    addFGOrderInfo<Data = any>(id: string, param: FGBatchOrderInfo): AxiosPromise<Data> {
        return this._instance.patch(`services/fg/batch/${id}/stock/orderInfo`, param);
    }

    /* 06_Add truck loading inspection details to FG batch */
    patchTruckCleanlinessInspection<Data = any>(id: string, param: FGTruckInspectionInfo): AxiosPromise<Data> {
        return this._instance.patch(`services/fg/order/${id}/truckInspection`, param);
    }

    /* 07_Approve FG order for dispatch */
    approveFGOrderForDispatch<Data = any>(issueOrder: string, param: FGDispatchApproval): AxiosPromise<Data> {
        return this._instance.patch(`services/fg/order/${issueOrder}/dispatchApproval`, param);
    }

    /* 08_Dispatch FG order */
    dispatchFGOrder<Data = any>(issueOrder: string, deliveryDate: string): AxiosPromise<Data> {
        return this._instance.patch(`services/fg/order/${issueOrder}/dispatchInfo`, { deliveryDate });
    }

    getFGOrder<Data = any>(batchId: string): AxiosPromise<Data> {
        return this._instance.get(`services/fg/batch/${batchId}/stock/orders`);
    }

    deleteFGBatchByID<Data = any>(batchId: string): AxiosPromise<Data> {
        return this._instance.delete(`services/fg/batch/${batchId}`);
    }

    getFGQASampleAnalysis<Data = any>(id: string): AxiosPromise<Data> {
        return this._instance.get(`services/fg/batch/${id}/analysis`);
    }

    getFGWarehouseLocation<Data = any>(): AxiosPromise<Data> {
        return this._instance.get(`services/fg/warehouse/locations`);
    }

    getFGStockByWarehouseLocation<Data = any>(warehouse: string, location: string): AxiosPromise<Data> {
        return this._instance.get(`services/fg/warehouse/${warehouse}/location/${location}/stocks`);
    }

    generateFGQAPresignedUrl<Data = any>(parameters: GetFGQAPresignedUrlParams): AxiosPromise<Data> {
        return this._instance.post(`services/fg/batch/${parameters.batchId}/qaAttachment/`, {
            fileName: parameters.fileName,
        });
    }
}

export default FinishedGoodsController;
