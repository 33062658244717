import {
    GET_ALL_REGISTERED_USERS,
    GET_SINGLE_USER,
    REGISTER_SINGLE_USER,
    PATCH_SINGLE_USER_INFO,
    GET_ALL_ROLES_INFO,
    UserInfoState
} from './types'

import AppController from "controller/application";
import { ThunkAction } from 'redux-thunk'
import { Action } from 'redux'
import { PostSingleUserParams, PatchSingleUserParams } from 'controller/userRegistration-controller'

import {
    SET_API_MESSAGE
} from 'redux/reducers/apiMessage/types'

async function processGetAllRegisteredUsers() {
    return AppController.getAllRegisteredUsers()
}

async function processGetSingleUser(email: string) {
    return AppController.getSingleUser(email)
}

async function processRegisterSingleUser(param: PostSingleUserParams) {
    return AppController.postRegisterSingleUser(param)
}

async function processEnableSingleUser(param: PostSingleUserParams) {
    return AppController.postEnableSingleUser(param)
}

async function processDisableSingleUser(param: PostSingleUserParams) {
    return AppController.postDisableSingleUser(param)
}

async function processPatchSingleUser(param: PatchSingleUserParams) {
    return AppController.patchSingleUserInfo(param)
}

async function processGetUserRolesInfoAction() {
    return AppController.getUserRoles()
}

export function getAllRegisteredUserAction():
    ThunkAction<void, UserInfoState, unknown, Action<string>> {

    return async dispatch => {
        let resp = await processGetAllRegisteredUsers()
        dispatch({
            type: GET_ALL_REGISTERED_USERS,
            payload: resp
        });
    };
}

export function getSingleUserAction(email: string):
    ThunkAction<void, UserInfoState, unknown, Action<string>> {

    return async dispatch => {
        let resp = await processGetSingleUser(email)
        dispatch({
            type: GET_SINGLE_USER,
            payload: resp
        });
    };
}

export function registerSingleUserAction(registerSingleUserInfo: PostSingleUserParams):
    ThunkAction<void, UserInfoState, unknown, Action<string>> {

    return async dispatch => {
        let resp = await processRegisterSingleUser(registerSingleUserInfo)
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `${registerSingleUserInfo.email} : ${resp.response.data.Message.Reason}`,
                    variant: 'error'
                }
            });
        } else {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `${registerSingleUserInfo.email} is registered`,
                    variant: 'success'
                }
            });
            dispatch({
                type: REGISTER_SINGLE_USER,
                payload: {}
            });
        }
    };
}

export function enableSingleUserAction(parameter: PostSingleUserParams):
    ThunkAction<void, UserInfoState, unknown, Action<string>> {

    return async dispatch => {
        let resp = await processEnableSingleUser(parameter)
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `Enabling ${parameter.email} Failed : ${resp.response.data.Message.Reason}`,
                    variant: 'error'
                }
            });
        } else {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `${parameter.email} is enabled`,
                    variant: 'success'
                }
            });
            dispatch({
                type: PATCH_SINGLE_USER_INFO,
                payload: {}
            });
        }
    };
}

export function disableSingleUserAction(parameter: PostSingleUserParams):
    ThunkAction<void, UserInfoState, unknown, Action<string>> {

    return async dispatch => {
        let resp = await processDisableSingleUser(parameter)
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `Disabling ${parameter.email} Failed : ${resp.response.data.Message.Reason}`,
                    variant: 'error'
                }
            });
        } else {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `${parameter.email} is disabled`,
                    variant: 'success'
                }
            });
            dispatch({
                type: PATCH_SINGLE_USER_INFO,
                payload: {}
            });
        }
    };
}

export function patchSingleUserInfoAction(param: PatchSingleUserParams):
    ThunkAction<void, UserInfoState, unknown, Action<string>> {

    return async dispatch => {
        let resp = await processPatchSingleUser(param)
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `Patch ${param.email} : ${resp.response.data.Message.Reason}`,
                    variant: 'error'
                }
            });
        } else {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `${param.email} is updated`,
                    variant: 'success'
                }
            });
            dispatch({
                type: PATCH_SINGLE_USER_INFO,
                payload: {}
            });
        }
    };
}

export function getUserRolesInfoAction():
    ThunkAction<void, UserInfoState, unknown, Action<string>> {

    return async dispatch => {
        let resp = await processGetUserRolesInfoAction()
        dispatch({
            type: GET_ALL_ROLES_INFO,
            payload: { roles: resp }
        });
    };
}
