import React, { Fragment } from 'react';

import { Button } from '@material-ui/core';
import { CanvassItem } from 'redux/reducers/canvass/types';
import TableGrid from 'components/common/TableGrid';

export interface CanvassItemsListTableProps {
    filterText: string;
    itemsList: Array<CanvassItem>;
    deleteItem: (item: CanvassItem) => void;
    updateItem: (oldItem: CanvassItem, newStatus: string) => void;
    disableDelete?: boolean;
}

const CanvassItemsListTable = (props: CanvassItemsListTableProps) => {
    const { filterText, itemsList, deleteItem, updateItem, disableDelete } = props;

    const removeItem = (data: any) => {
        deleteItem(data.data);
    };

    const removeItemRenderer = (data: any) => {
        return (
            <Button
                onClick={() => removeItem(data)}
                color={'primary'}
                variant={'contained'}
                size={'small'}
                disabled={disableDelete}>
                Delete
            </Button>
        );
    };

    const onItemStatusChange = (event: any) => {
        const { data, newValue, oldValue } = event;
        const oldData = { ...data, itemCanvassApprovedStatus: oldValue };
        updateItem(oldData, newValue);
    };

    const columnDefs = [
        {
            headerName: 'Item Description',
            field: 'itemDescription',
            minWidth: 300,
            filter: 'agTextColumnFilter',
        },
        {
            headerName: 'Supplier Name',
            field: 'supplierName',
            minWidth: 300,
            filter: 'agTextColumnFilter',
        },
        {
            headerName: 'Unit Price',
            field: 'itemUnitPrice',
            minWidth: 100,
            filter: 'agTextColumnFilter',
        },
        {
            headerName: 'Order Quantity',
            field: 'itemQuantity',
            minWidth: 100,
            filter: 'agTextColumnFilter',
        },
        {
            headerName: 'Approval Status',
            field: 'itemCanvassApprovedStatus',
            minWidth: 100,
            filter: 'agTextColumnFilter',
            editable: true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['Approved', 'Not Approved'],
            },
        },
        {
            headerName: 'Remove Item',
            minWidth: 100,
            cellRendererFramework: removeItemRenderer,
        },
    ];

    return (
        <Fragment>
            <TableGrid
                rowData={itemsList}
                columnDefs={columnDefs}
                filterText={filterText}
                onCellValueChanged={onItemStatusChange}
                height={300}
            />
        </Fragment>
    );
};

export default CanvassItemsListTable;
