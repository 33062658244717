import React, { Fragment, useState } from 'react';

import SparePartsHeader from 'views/InventoryList/SpareParts/SparePartsHeader';
import SparePartsInventoryListView from 'views/InventoryList/SpareParts/SparePartsIssuanceList/SparePartsInventoryListView';
import { Typography } from '@material-ui/core';

export interface UserListMainProps {
    onDrawerToggle: () => void;
    mainClass: any;
}

const SparePartsMain = (props: UserListMainProps) => {
    const TAB1_TITLE = 'Material Inventory List';
    const tabsTitle = [{ id: TAB1_TITLE }];

    const [currentTab, setCurrentTab] = useState(0);
    const onTabsChange = (id: number) => {
        setCurrentTab(id);
    };

    return (
        <Fragment>
            <SparePartsHeader onDrawerToggle={props.onDrawerToggle} onTabsChange={onTabsChange} tabsTitle={tabsTitle} />
            <main className={props.mainClass}>
                {tabsTitle[currentTab].id === TAB1_TITLE && (
                    <Fragment>
                        <Typography style={{ paddingBottom: 10 }} color='inherit' variant='h5' component='h1'>
                            {TAB1_TITLE}
                        </Typography>
                        <SparePartsInventoryListView />
                    </Fragment>
                )}
            </main>
        </Fragment>
    );
};

export default SparePartsMain;
