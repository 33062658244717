import {
    DeliveryScheduleInfo,
    DeliveryScheduleInfoList,
    DeliveryStatus,
    OrderedItemWithPriceInfo,
    PurchaseOrderInfo,
} from "redux/reducers/purchasing/types";
import { Grid, TextField } from "@material-ui/core";
import React, { Fragment, useEffect, useState } from "react";
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles";

import TableGrid from "components/common/TableGrid";
import { getDeliveryScheduleAction } from "redux/reducers/purchasing/actions";
import moment from "moment";
import { purchasingTypedSelector } from "redux/reducers/purchasing/purchasingReducer";
import { useDispatch } from "react-redux";

export interface ContentProps extends WithStyles<typeof styles> {
    clickedOrderDetails?: PurchaseOrderInfo;
}

interface OrderDetailsWithDeliveryCount extends OrderedItemWithPriceInfo {
    deliveryCount?: Array<DeliveryScheduleInfo>;
}

interface ColumnDefinition {
    headerName: string;
    field?: string;
    minWidth: number;
    filter: string;
    headerTooltip: string;
    valueGetter?: () => string;
}

const PurchaseOrderDetails = (props: ContentProps) => {
    const { clickedOrderDetails } = props;

    const [schedulesList, setSchedulesList] = useState<Array<DeliveryScheduleInfo>>([]);
    const deliveriesInfoResponse = purchasingTypedSelector<DeliveryScheduleInfoList>(
        (state) => state.purchasing.deliverySchedule
    );
    const [orderWithDeliveryCount, setOrderWithDeliveryCount] = useState<Array<OrderDetailsWithDeliveryCount>>([]);
    const dispatcher = useDispatch();
    const [isAcquired, setIsAcquired] = useState(false);

    let columnDefs: Array<ColumnDefinition> = [
        {
            headerName: "Item Code",
            field: "itemCode",
            minWidth: 100,
            filter: "agTextColumnFilter",
            headerTooltip: "Item Code",
        },
        {
            headerName: "Description",
            field: "itemDescription",
            minWidth: 200,
            filter: "agTextColumnFilter",
            headerTooltip: "Item Description",
        },
        {
            headerName: "Unit",
            field: "itemUnit",
            minWidth: 50,
            filter: "agTextColumnFilter",
            headerTooltip: "Unit",
        },
        {
            headerName: "Quantity",
            field: "itemQuantity",
            minWidth: 50,
            filter: "agTextColumnFilter",
            headerTooltip: "Quantity",
        },
        {
            headerName: "Unit Price",
            field: "itemUnitPrice",
            minWidth: 50,
            filter: "agTextColumnFilter",
            headerTooltip: "Unit Price",
        },
        {
            headerName: "Total Price",
            field: "itemTotalPrice",
            minWidth: 50,
            filter: "agTextColumnFilter",
            headerTooltip: "Total Price",
        },
    ];

    const [origColumnDefs, setOrigColumnDefs] = useState(columnDefs);
    const [modifiedColumnDefs, setModifiedColumnDefs] = useState(columnDefs);

    useEffect(() => {
        if (deliveriesInfoResponse.schedulesList.length > 0) {
            setSchedulesList(deliveriesInfoResponse.schedulesList);
        } else if (!isAcquired) {
            setIsAcquired(true);
            setOrigColumnDefs(columnDefs);
            dispatcher(getDeliveryScheduleAction());
        }
    }, [deliveriesInfoResponse, isAcquired, dispatcher, columnDefs]);

    useEffect(() => {
        if (clickedOrderDetails) {
            let deliveriesOfOrder = schedulesList.filter(
                (schedule) => schedule.orderId === clickedOrderDetails.orderId
            );
            let orderWithCount = clickedOrderDetails.itemsList.map(
                (item) =>
                    ({
                        itemCode: item.itemCode,
                        itemDescription: item.itemDescription,
                        itemUnit: item.itemUnit,
                        itemQuantity: item.itemQuantity,
                        itemUnitPrice: item.itemUnitPrice,
                        itemTotalPrice: item.itemTotalPrice,
                        deliveryCount: deliveriesOfOrder,
                    } as OrderDetailsWithDeliveryCount)
            );

            let modColumnDefs: Array<ColumnDefinition> = [...origColumnDefs];
            deliveriesOfOrder = deliveriesOfOrder.sort((del1, del2) => {
                let del1Date: Date = moment(del1.deliveryDate).toDate();
                let del2Date: Date = moment(del2.deliveryDate).toDate();
                if (del1Date > del2Date) {
                    return 1;
                } else if (del1Date < del2Date) {
                    return -1;
                }
                return 0;
            });
            deliveriesOfOrder.forEach((element) => {
                let column = {
                    headerName: element.deliveryDate + "(" + element.scheduleStatus + ")",
                    minWidth: 50,
                    filter: "agTextColumnFilter",
                    headerTooltip: element.deliveryDate,
                    valueGetter: (param: any) => {
                        let itemElement = element.itemsList.find(
                            (toDeliver) => toDeliver.itemCode === param.data.itemCode
                        );
                        return itemElement ? itemElement.itemQuantity : "0";
                    },
                } as ColumnDefinition;
                modColumnDefs = [...modColumnDefs, column];
            });

            let column = {
                headerName: "Delivered Total",
                minWidth: 50,
                filter: "agTextColumnFilter",
                headerTooltip: "Delivered Total",
                valueGetter: (param: any) => {
                    let total = 0;
                    deliveriesOfOrder.forEach((delivery) => {
                        if (delivery.scheduleStatus === DeliveryStatus.DELIVERED) {
                            let itemElement = delivery.itemsList.find(
                                (toDeliver) => toDeliver.itemCode === param.data.itemCode
                            );
                            if (itemElement) {
                                total = total + itemElement.itemQuantity;
                            }
                        }
                    });
                    return total.toString() + "/" + param.data.itemQuantity.toString();
                },
            } as ColumnDefinition;
            modColumnDefs = [...modColumnDefs, column];

            setOrderWithDeliveryCount(orderWithCount);
            setModifiedColumnDefs(modColumnDefs);
        }
    }, [clickedOrderDetails, schedulesList, origColumnDefs]);

    const renderOrderDetails = () => {
        return (
            <Fragment>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={2}>
                        <TextField
                            label={"Supplier Name"}
                            value={clickedOrderDetails!.supplierName}
                            fullWidth={true}
                            inputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label={"Sender"}
                            value={clickedOrderDetails!.sender}
                            fullWidth={true}
                            inputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label={"Recipient"}
                            value={clickedOrderDetails!.recipient}
                            fullWidth={true}
                            inputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label={"Requestor"}
                            value={clickedOrderDetails!.requestor}
                            fullWidth={true}
                            inputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label={"Procurement Representative"}
                            value={clickedOrderDetails!.procurementRepresentative}
                            fullWidth={true}
                            inputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                {orderWithDeliveryCount.length > 0 && (
                    <Grid item xs={12}>
                        <TableGrid rowData={orderWithDeliveryCount} columnDefs={modifiedColumnDefs} height={400} />
                    </Grid>
                )}
                </Grid>

            </Fragment>
        );
    };

    return <div style={{ padding: 10 }}>{renderOrderDetails()}</div>;
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: "99%",
            margin: "auto",
            overflow: "hidden",
            alignContent: "center",
        },
        searchBar: {
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: "block",
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: "rgb(31,49,69)",
            alignSelf: "center",
        },
        contentWrapper: {
            margin: "0px 0px",
        },
    });

export default withStyles(styles)(PurchaseOrderDetails);
