import { Button, Grid } from '@material-ui/core';
import React, { Fragment, useState } from 'react';

import { FGBatchInfo } from 'redux/reducers/finishedGoods/types';
import TableGrid from 'components/common/TableGrid';
import moment from 'moment';
import DeleteConfirmation from 'components/common/DeleteConfirmation';
import { GridApi } from 'ag-grid';

export interface FinishedGoodsListTableProps {
  filterText: string;
  exportCsv?: boolean;
  onExportCsv?: any;
  exportCsvFileName?: string;
  fgList: FGBatchInfo[];
  onClickedBatchID: (id: string) => void;
  onDeleteFGBatch?: (batchId: string) => void;
}

interface RowInfo {
    api?: GridApi;
    data?: FGBatchInfo;
  }
const FinishedGoodsListTable = (props: FinishedGoodsListTableProps) => {
  const {
    filterText,
    exportCsv,
    onExportCsv,
    fgList,
    exportCsvFileName,
    onDeleteFGBatch,
  } = props;
  const [forDeleteRow, setForDeleteRow] = useState<RowInfo | any>({});
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(
    false
  );

  const dateRenderer = (date: any) => {
    if (date.value) {
      return moment(date.value).format('DD-MMM-YY');
    }
    return '';
  };

  const sortDate = (first: FGBatchInfo, second: FGBatchInfo) => {
    if (first.inputDateTime < second.inputDateTime) return 1;
    if (first.inputDateTime > second.inputDateTime) return -1;
    return 0;
  };

  const onClickHandler = (value: string) => {
    if (props.onClickedBatchID) {
      props.onClickedBatchID(value);
    }
  };

  const batchIDRenderer = (params: { value: string }) => {
    return (
      <Button
        style={{ color: '#009fdc' }}
        onClick={onClickHandler.bind({}, params.value)}
      >
        {params.value}
      </Button>
    );
  };

  const columnDefs = [
    {
      headerName: 'Mfg Date',
      field: 'mfgDate',
      cellRenderer: dateRenderer,
      getQuickFilterText: dateRenderer,
      headerTooltip: 'Mfg Date',
      minWidth: 120,
    },
    {
      headerName: 'Product Code',
      field: 'prodCode',
      filter: 'agTextColumnFilter',
      headerTooltip: 'Product Code',
      minWidth: 150,
    },
    {
      headerName: 'Line Num',
      field: 'lineNum',
      filter: 'agTextColumnFilter',
      headerTooltip: 'Line Num',
      minWidth: 80,
    },
    {
      headerName: 'Batch #',
      field: 'batchId',
      filter: 'agTextColumnFilter',
      cellRendererFramework: batchIDRenderer,
      headerTooltip: 'Batch #',
      minWidth: 350,
    },
    {
      headerName: '# of bags',
      field: 'bagNum',
      filter: 'agNumberColumnFilter',
      headerTooltip: 'Status',
      minWidth: 80,
    },
    {
      headerName: 'RESULT',
      field: 'qaAnalysisResult',
      filter: 'agTextColumnFilter',
      headerTooltip: 'RESULT',
      minWidth: 120,
    },
    {
      headerName: 'Input Date',
      field: 'inputDateTime',
      filter: 'agTextColumnFilter',
      cellRenderer: dateRenderer,
      getQuickFilterText: dateRenderer,
      headerTooltip: 'Date',
      minWidth: 120,
    },
  ];

  return (
    <Fragment>
      <Grid container>
        <Grid item />
      </Grid>

      <TableGrid
        rowData={fgList.sort(sortDate)}
        columnDefs={columnDefs}
        filterText={filterText}
        exportCsv={exportCsv}
        onExportCsv={onExportCsv}
        exportCsvFileName={exportCsvFileName}
        height={600}
        deleteColumn={onDeleteFGBatch ? true : false}
        onDeleteRow={(row) => {
            setForDeleteRow(row);
            setOpenDeleteConfirmation(true);
            return false;
        }}
      />
      {openDeleteConfirmation && (
        <DeleteConfirmation
          title="Delete Truck Entry"
          data={forDeleteRow}
          onDelete={(data: any) => {
            if (
                onDeleteFGBatch
                ? onDeleteFGBatch(forDeleteRow!.data!.batchId)
                : false
            ) {
              let { data, api } = forDeleteRow;

              api!.updateRowData({ remove: [data] });
              api!.refreshCells({ force: true });
            }

            setOpenDeleteConfirmation(false);
            setForDeleteRow({});
          }}
          onCancel={(data: any) => {
            setOpenDeleteConfirmation(false);
            setForDeleteRow({});
          }}
          message={forDeleteRow!.data!.batchId}
        />
      )}
    </Fragment>
  );
};

export default FinishedGoodsListTable;
