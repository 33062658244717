import {
    AppBar,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Paper,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
} from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core/styles';

import AppController from 'controller/application';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { LoggedUser } from 'utils/loggedUser';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import QAChecklistForm from './QAChecklistForm';
import RMRRForm from './RMRRForm';
import { RMShipmentInfo } from 'redux/reducers/rmShipment/types';
import RMShipmentListTable from './RMShipmentListTable';
import RMTruckInspectionForm from './RMTruckInspectionForm';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import TocIcon from '@material-ui/icons/Toc';
import { deleteShipmentByRmrrId, getRmShipmentsAction } from 'redux/reducers/rmShipment/actions';
import moment from 'moment';
import { rmShipmentTypedSelector } from 'redux/reducers/rmShipment/rmShipmentReducer';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

export interface ContentProps extends WithStyles<typeof styles> {
    pageReload: boolean;
    onPageReload: () => void;
}

const viewState = {
    materialList: 'materialList',
    newRMRR: 'newRMRR',
    qaChecklist: 'qaChecklist',
    truckChecklist: 'truckChecklist',
};

enum uploadType {
    LETTER_OF_GUARANTEE,
    QA_FILE,
}

const RMShipmentListView = (props: ContentProps) => {
    const { classes, pageReload, onPageReload } = props;
    const dispatcher = useDispatch();

    const [filterText, setfilterText] = useState('');
    const [exportCsv, setExportCsv] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [clickedRMRRID, setClickedRMRRID] = useState('');
    const [view, setView] = useState(viewState.materialList);

    const shipments = rmShipmentTypedSelector<Array<any>>((state) => state.rmshipment.shipments);
    const { enqueueSnackbar } = useSnackbar();

    const permission = LoggedUser.getPermission();
    const role = LoggedUser.getRole();

    useEffect(() => {
        if (pageReload) {
            onPageReload();
            revertToListView();
        }
    }, [onPageReload, pageReload]); // eslint-disable-line

    const onExportCsv = () => {
        setExportCsv(false);
    };

    const renderRMShipmentListTableSearch = () => {
        return (
            <Fragment>
                <Grid item>
                    <SearchIcon className={classes.block} color='inherit' />
                </Grid>
                <Grid item xs>
                    <TextField
                        fullWidth
                        placeholder='Search'
                        InputProps={{
                            disableUnderline: true,
                            className: classes.searchInput,
                        }}
                        onChange={(event) => setfilterText(event.target.value)}
                    />
                </Grid>
            </Fragment>
        );
    };
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => setOpen(true);

    const [onReload, setOnReload] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setOnReload(false);
        }, 1000);
    }, [shipments]);

    const [exportCsvFileName, setExportCsvFileName] = useState('');

    const fetchAndReloadList = () => {
        setOnReload(true);
        dispatcher(getRmShipmentsAction());
    }

    const renderRMShipmentListTableButton = () => {
        return (
            <Grid item>
                {(role === 'SMD Representative' || role === 'Administrator') && (
                    <Tooltip title='New Shipment'>
                        <IconButton onClick={() => setView(viewState.newRMRR)}>
                            <NoteAddIcon className={classes.block} color='inherit' />
                        </IconButton>
                    </Tooltip>
                )}
                <Tooltip title='Export to CSV'>
                    <IconButton
                        onClick={() => {
                            setExportCsvFileName(`RMRRList-${moment(new Date()).format('MM-DD-YYYY:HH:mm:ss')}`);
                            setExportCsv(true);
                        }}>
                        <FileCopyIcon className={classes.block} color='inherit' />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Show/Hide Columns'>
                    <IconButton onClick={handleClickOpen}>
                        <TocIcon className={classes.block} color='inherit' />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Reload'>
                    <IconButton
                        onClick={fetchAndReloadList}>
                        {onReload ? (
                            <CircularProgress size='24px' />
                        ) : (
                            <RefreshIcon className={classes.block} color='inherit' />
                        )}
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    };

    const renderRmShipmentListContainer = () => {
        return (
            <Grid container spacing={2} alignItems='center'>
                {renderRMShipmentListTableSearch()}
                {renderRMShipmentListTableButton()}
            </Grid>
        );
    };

    const onUploadFile = (type: uploadType) => {
        const fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        fileSelector.setAttribute('accept', 'image/*,application/pdf');
        fileSelector.onchange = (e: any) => {
            const { target } = e;
            const { files } = target;
            if (files && files[0]) {
                let reader = new FileReader();
                reader.readAsDataURL(files[0]);
                reader.onload = (event) => {
                    if (reader && reader.result) {
                        enqueueSnackbar(`${files[0].name} is being uploaded`, {
                            variant: 'info',
                        });
                        if (type === uploadType.LETTER_OF_GUARANTEE) {
                            AppController.postLetterOfGuaranteePresigned(
                                {
                                    rmrrId: clickedRMRRID,
                                    fileName: files[0].name,
                                },
                                files[0]
                            )
                                .then(() =>
                                    enqueueSnackbar(`${files[0].name} is uploaded successfully`, { variant: 'success' })
                                )
                                .catch((error) =>
                                    enqueueSnackbar(`failed uploading ${files[0].name}`, { variant: 'error' })
                                );
                        } else if (type === uploadType.QA_FILE) {
                            AppController.postQAFilePresigned(
                                {
                                    rmrrId: clickedRMRRID,
                                    fileName: files[0].name,
                                },
                                files[0]
                            )
                                .then(() =>
                                    enqueueSnackbar(`${files[0].name} is uploaded successfully`, { variant: 'success' })
                                )
                                .catch((error) =>
                                    enqueueSnackbar(`failed uploading ${files[0].name}`, { variant: 'error' })
                                );
                        }
                    }
                };
                reader.onerror = (error: any) => {
                    type === uploadType.LETTER_OF_GUARANTEE
                        ? enqueueSnackbar(`Error encountered in opening the letter of guarantee`, { variant: 'error' })
                        : enqueueSnackbar(`Error encountered in opening the QA File/Image`, { variant: 'error' });
                };
            }
        };
        fileSelector.click();
    };

    const [letter, setLetter] = useState('');
    const getShipment = (shipmentList: RMShipmentInfo[]) => {
        let selectedShipment: RMShipmentInfo = {
            itemNum: '',
            rmName: '',
            rmCode: '',
            inputDateTime: '',
            rmrrId: '',
            processingStatus: '',
            supplierName: '',
            purchaseOrderNum: '',
            truckPlateNum: '',
            quantity: 0,
            quantityUnit: 0,
            rmUnitPrice: 0,
            netWeight: 0,
            truckScaleNum: '',
        };
        if (clickedRMRRID && clickedRMRRID !== '' && shipmentList && shipmentList.length > 0) {
            const findShipment = shipmentList.find((shipment) => shipment.rmrrId === clickedRMRRID);
            selectedShipment = findShipment ? findShipment : selectedShipment;
        }
        return selectedShipment;
    };

    const [shipmentInfo, setShipmentInfo] = useState<RMShipmentInfo>(getShipment([]));
    useEffect(() => {
        setShipmentInfo(getShipment(shipments));
    }, [shipments, clickedRMRRID]); // eslint-disable-line
    const renderRMRRFormButton = () => {
        return (
            <Grid item>
                {letter === '' && clickedRMRRID !== '' && (
                    <Tooltip title='Upload Letter of Guarantee'>
                        <IconButton
                            onClick={() => onUploadFile(uploadType.LETTER_OF_GUARANTEE)}
                            className={classes.attention}>
                            <CloudUploadIcon className={classes.block} color='inherit' />
                        </IconButton>
                    </Tooltip>
                )}
                {letter !== '' && (
                    <Tooltip title='Download Letter of Guarantee'>
                        <IconButton href={letter}>
                            <CloudDownloadIcon className={classes.block} color='inherit' />
                        </IconButton>
                    </Tooltip>
                )}
                {clickedRMRRID !== '' && (
                    <Tooltip title='QA Checklist'>
                        <IconButton
                            className={
                                shipmentInfo.analysisInfo && shipmentInfo.analysisInfo.analysisDatetime
                                    ? ''
                                    : classes.attention
                            }
                            onClick={() => {
                                setShowSubmit(true);
                                setView(viewState.qaChecklist);
                            }}>
                            <AssignmentTurnedInIcon className={classes.block} color='inherit' />
                        </IconButton>
                    </Tooltip>
                )}
                {clickedRMRRID !== '' && (
                    <Tooltip title='Truck Inspection Checklist'>
                        <IconButton
                            className={
                                shipmentInfo.truckInspectionInfo && shipmentInfo.truckInspectionInfo.inspectionDatetime
                                    ? ''
                                    : classes.attention
                            }
                            onClick={() => {
                                setShowSubmit(true);
                                setView(viewState.truckChecklist);
                            }}>
                            <LocalShippingIcon className={classes.block} color='inherit' />
                        </IconButton>
                    </Tooltip>
                )}
            </Grid>
        );
    };

    const renderRMRRFormContainer = () => {
        return (
            <Grid container spacing={2} alignItems='center'>
                <Grid item>
                    <Tooltip title='Return / Cancel'>
                        <IconButton onClick={revertToListView}>
                            <ArrowBackIosIcon className={classes.block} color='inherit' />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Typography color='primary' variant='h5' component='h1'>
                        {clickedRMRRID === '' ? `[NEW] Raw Material Receiving Report` : `RMRR ID : ${clickedRMRRID}`}
                    </Typography>
                </Grid>
                <Grid item xs />
                {renderRMRRFormButton()}
            </Grid>
        );
    };

    const [qaFile, setQAFile] = useState('');
    const renderQAChecklistContainer = () => {
        return (
            <Grid container spacing={2} alignItems='center'>
                <Grid item>
                    <Tooltip title='Return / Cancel'>
                        <IconButton
                            onClick={() => {
                                dispatcher(getRmShipmentsAction());
                                setView(viewState.newRMRR);
                            }}>
                            <ArrowBackIosIcon className={classes.block} color='inherit' />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Typography color='primary' variant='h5' component='h1'>
                        RM QA Checklist
                    </Typography>
                </Grid>
                <Grid item xs />
                <Grid item>
                    {qaFile === '' && clickedRMRRID !== '' && (
                        <Tooltip title='Upload QA File/Image'>
                            <IconButton onClick={() => onUploadFile(uploadType.QA_FILE)} className={classes.attention}>
                                <CloudUploadIcon className={classes.block} color='inherit' />
                            </IconButton>
                        </Tooltip>
                    )}
                    {qaFile !== '' && (
                        <Tooltip title='Download QA File/Image'>
                            <IconButton href={qaFile}>
                                <CloudDownloadIcon className={classes.block} color='inherit' />
                            </IconButton>
                        </Tooltip>
                    )}
                </Grid>
            </Grid>
        );
    };

    const renderTruckChecklistContainer = () => {
        return (
            <Grid container spacing={2} alignItems='center'>
                <Grid item>
                    <Tooltip title='Return / Cancel'>
                        <IconButton
                            onClick={() => {
                                dispatcher(getRmShipmentsAction());
                                setView(viewState.newRMRR);
                            }}>
                            <ArrowBackIosIcon className={classes.block} color='inherit' />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Typography color='primary' variant='h5' component='h1'>
                        RM Unloading Truck Inspection
                    </Typography>
                </Grid>
                <Grid item xs />
            </Grid>
        );
    };

    const isView = (checkView: string) => {
        return view === checkView;
    };

    const onClickedRMRRID = (rmrrId: string) => {
        setClickedRMRRID(rmrrId);
        setView(viewState.newRMRR);
    };

    const revertToListView = () => {
        setClickedRMRRID('');
        setView(viewState.materialList);
        setLetter('');
        setQAFile('');
        setShowSubmit(true);
        setTimeout(() => {
            dispatcher(getRmShipmentsAction());
        }, 1000);
    };

    const onLetterAvailable = (letter: string) => {
        setLetter(letter);
    };

    const onQAFileAvailable = (qaFile: string) => {
        setQAFile(qaFile);
    };

    const [showSubmit, setShowSubmit] = useState(true);
    const onShowSubmit = (show: boolean) => {
        setShowSubmit(show);
    };

    const onDeleteRMRRID = ( rmrrID: string ) => {
        dispatcher(deleteShipmentByRmrrId(rmrrID));
        return true;
    }

    const renderContent = () => {
        return (
            <div className={classes.contentWrapper}>
                {isView(viewState.newRMRR) && (
                    <RMRRForm
                        submit={submit}
                        onSubmit={(isCompleted: boolean) => {
                            setSubmit(false);
                            if (isCompleted) {
                                revertToListView();
                            }
                        }}
                        onReturn={revertToListView}
                        onShowSubmit={onShowSubmit}
                        rmrrId={clickedRMRRID}
                        onLetterAvailable={onLetterAvailable}
                    />
                )}
                {isView(viewState.qaChecklist) && (
                    <QAChecklistForm
                        submit={submit}
                        rmrrId={clickedRMRRID}
                        onSubmit={() => {
                            setSubmit(false);
                            setView(viewState.newRMRR);
                        }}
                        onShowSubmit={onShowSubmit}
                        onQAFileAvailable={onQAFileAvailable}
                    />
                )}
                {isView(viewState.truckChecklist) && (
                    <RMTruckInspectionForm
                        submit={submit}
                        rmrrId={clickedRMRRID}
                        onSubmit={() => {
                            setSubmit(false);
                            setView(viewState.newRMRR);
                        }}
                        onShowSubmit={onShowSubmit}
                    />
                )}
                {isView(viewState.materialList) && (
                    <RMShipmentListTable
                        filterText={filterText}
                        exportCsv={exportCsv}
                        onExportCsv={onExportCsv}
                        exportCsvFileName={exportCsvFileName}
                        onClickedRMRRID={onClickedRMRRID}
                        onDeleteRMRRID={onDeleteRMRRID}
                        onCloseMenu={() => setOpen(false)}
                        showMenu={open}
                    />
                )}
            </div>
        );
    };

    const isSubmitterRole = () => {
        return permission.rmAccess === 'FULL' || role === 'QA Representative';
    };

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.searchBar} position='static' color='default' elevation={0}>
                <Toolbar>
                    {isView(viewState.materialList) && renderRmShipmentListContainer()}
                    {isView(viewState.newRMRR) && renderRMRRFormContainer()}
                    {isView(viewState.qaChecklist) && renderQAChecklistContainer()}
                    {isView(viewState.truckChecklist) && renderTruckChecklistContainer()}
                </Toolbar>
            </AppBar>
            {renderContent()}
            {!isView(viewState.materialList) && showSubmit && isSubmitterRole() && (
                <Paper style={{ padding: 10 }}>
                    <Button
                        fullWidth
                        variant='contained'
                        color='primary'
                        className={classes.contentButton}
                        onClick={() => {
                            setTimeout(() => {
                                dispatcher(getRmShipmentsAction());
                            }, 1000);
                            setSubmit(true);
                        }}>
                        Submit
                    </Button>
                </Paper>
            )}
        </Paper>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: '99%',
            margin: 'auto',
            overflow: 'hidden',
            alignContent: 'center',
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: 'rgb(31,49,69)',
            alignSelf: 'center',
        },
        contentWrapper: {
            margin: '0px 0px',
        },
        '@keyframes pulse': {
            '0%': { background: 'rgba(31,49,69, 0)' },
            '20%': { background: 'rgba(31,49,69, 0.5)' },
        },
        attention: {
            animation: `$pulse 1.5s infinite cubic-bezier(0.66, 0.33, 0, 1)`,
        },
    });

export default withStyles(styles)(RMShipmentListView);
