import ApiController from 'controller/api-controller';
import { AxiosPromise } from 'axios';

export interface PresignedUrlParams {
    url: any;
    data: any;
    config: any;
}

class PreSignedController extends ApiController {
    uploadPreSigned<Data = any>(parameters: PresignedUrlParams): AxiosPromise<Data> {
        return this._instance.post(parameters.url, parameters.data, parameters.config);
    }
}

export default PreSignedController;
