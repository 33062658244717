import Cookies from 'universal-cookie';

const cookie = new Cookies();

export const setCookie = (name: string, value: string, domain: string, expiry?: Date) => {
    cookie.set(name, value, {
        path: '/',
        domain: domain,
        expires: expiry
    });
};

export const getCookie = (name: string): string => {
    return cookie.get(name);
};

export const deleteCookie = (name: string, domain: string) => {
    cookie.remove(name, {
        path: '/',
        domain: domain
    });
};
