import {
    AppBar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    TextField,
    Toolbar,
    Tooltip,
} from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { Fragment, useEffect, useState } from 'react';
import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import {
    deleteRMTruckEntryAction,
    getRMTruckEntriesAction,
    patchRMTruckEntriesAction,
    postRMTruckEntriesAction,
} from 'redux/reducers/truckEntries/actions';

import CircularProgress from '@material-ui/core/CircularProgress';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MomentUtils from '@date-io/moment';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import { RMTruckEntries } from 'redux/reducers/truckEntries/types';
import RMTruckEntriesListTable from './RMTruckEntriesListTable';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import TodayIcon from '@material-ui/icons/Today';
import moment from 'moment';
import { truckEntriesTypedSelector } from 'redux/reducers/truckEntries/truckEntriesReducer';
import { useDispatch } from 'react-redux';

export interface ContentProps extends WithStyles<typeof styles> {}

const RMTruckEntriesMainView = (props: ContentProps) => {
    const { classes } = props;
    const dispatcher = useDispatch();

    const [filterText, setfilterText] = useState('');
    const [exportCsv, setExportCsv] = useState(false);
    const [openNew, setOpenNew] = useState(false);

    const rmTruckEntries = truckEntriesTypedSelector<Array<RMTruckEntries>>(
        (state) => state.truckEntries.rmTruckEntries
    );

    const [rmTruckEntriesList, setRmTruckEntriesList] = useState<Array<RMTruckEntries> | []>([]);

    const [isAcquired, setIsAcquired] = useState(false);

    const [onReload, setOnReload] = useState(false);

    useEffect(() => {
        if (rmTruckEntries.length === 0 && !isAcquired) {
            dispatcher(getRMTruckEntriesAction());
            setIsAcquired(true);
        } else {
            setRmTruckEntriesList(rmTruckEntries);
        }
    }, [rmTruckEntries, isAcquired, dispatcher]);

    useEffect(() => {
        setTimeout(() => setOnReload(false), 500);
    }, [rmTruckEntries]);

    const renderHeaderSearch = () => {
        return (
            <Fragment>
                <Grid item>
                    <SearchIcon className={classes.block} color='inherit' />
                </Grid>
                <Grid item xs>
                    <TextField
                        fullWidth
                        placeholder='Search'
                        InputProps={{
                            disableUnderline: true,
                            className: classes.searchInput,
                        }}
                        onChange={(event) => setfilterText(event.target.value)}
                    />
                </Grid>
            </Fragment>
        );
    };

    const exectureNewTruckIn = () => {
        if (newSupplier === '') {
            setSupplierHelperText('Please input supplier name');
        }

        if (newTruckPlateNum === '') {
            setPlateNumHelperText('Please input truck plate number');
        }

        if (newDateIn === null) {
            setNewDateInHelperText('Please input IN date and time');
        }

        if (newSupplier !== '' && newTruckPlateNum !== '' && newDateIn) {
            dispatcher(
                postRMTruckEntriesAction({
                    supplierName: newSupplier,
                    plateNumber: newTruckPlateNum,
                    truckTimeIn: newDateIn,
                })
            );
            setTimeout(() => dispatcher(getRMTruckEntriesAction()), 500);
            setNewSupplier('');
            setNewTruckPlateNum('');
            setNewDateIn(null);
            setOpenNew(false);
        }
    };

    const [newSupplier, setNewSupplier] = useState('');
    const [supplierHelperText, setSupplierHelperText] = useState('');
    const [newTruckPlateNum, setNewTruckPlateNum] = useState('');
    const [plateNumHelperText, setPlateNumHelperText] = useState('');
    const [newDateIn, setNewDateIn] = useState<string | null>(null);
    const [newDateInHelperText, setNewDateInHelperText] = useState('');

    const onModalClose = () => {
        setOpenNew(false);
        setSupplierHelperText('');
        setNewSupplier('');
        setPlateNumHelperText('');
        setNewTruckPlateNum('');
        setNewDateInHelperText('');
        setNewDateIn(null);
    };

    const newModal = () => {
        return (
            <div>
                <Dialog disableEscapeKeyDown open={openNew} onClose={onModalClose}>
                    <DialogTitle>Truck IN Details</DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            margin='dense'
                            style={{ minWidth: 200 }}
                            type={'text'}
                            label={'Supplier Name'}
                            variant='outlined'
                            helperText={supplierHelperText}
                            error={supplierHelperText !== ''}
                            onChange={(event) => {
                                setSupplierHelperText('');
                                setNewSupplier(event.target.value);
                            }}
                        />
                        <TextField
                            fullWidth
                            margin='dense'
                            style={{ minWidth: 200 }}
                            type={'text'}
                            label={'Truck Plate Number'}
                            variant='outlined'
                            helperText={plateNumHelperText}
                            error={plateNumHelperText !== ''}
                            onChange={(event) => {
                                setPlateNumHelperText('');
                                setNewTruckPlateNum(event.target.value);
                            }}
                        />
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DateTimePicker
                                ampm={false}
                                disableFuture
                                fullWidth
                                margin='dense'
                                value={newDateIn}
                                onChange={(event) => {
                                    setNewDateInHelperText('');
                                    setNewDateIn(moment(event).toISOString());
                                }}
                                label={'Date and Time IN'}
                                format='MMM DD, YYYY HH:mm'
                                inputVariant='outlined'
                                helperText={newDateInHelperText}
                                error={newDateInHelperText !== ''}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <IconButton>
                                                <TodayIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={exectureNewTruckIn} color='primary' variant='contained'>
                            Add New Truck IN
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    const onExportCsv = () => {
        setExportCsv(false);
    };
    const [exportCsvFileName, setExportCsvFileName] = useState('');

    const fetchAndReloadList = () => {
        setOnReload(true);
        setRmTruckEntriesList([]);
        dispatcher(getRMTruckEntriesAction());
    }

    const renderHeaderButton = () => {
        return (
            <Grid item>
                <Tooltip title='New Truck Entry'>
                    <IconButton onClick={() => setOpenNew(true)}>
                        <NoteAddIcon className={classes.block} color='inherit' />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Export to CSV'>
                    <IconButton
                        onClick={() => {
                            setExportCsvFileName(
                                `RMTruckEntriesList-${moment(new Date()).format('MM-DD-YYYY:HH:mm:ss')}`
                            );
                            setExportCsv(true);
                        }}>
                        <FileCopyIcon className={classes.block} color='inherit' />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Reload'>
                    <IconButton
                        onClick={fetchAndReloadList}>
                        {onReload ? (
                            <CircularProgress size='24px' />
                        ) : (
                            <RefreshIcon className={classes.block} color='inherit' />
                        )}
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    };

    const renderContainer = () => {
        return (
            <Grid container spacing={2} alignItems='center'>
                {renderHeaderSearch()}
                {renderHeaderButton()}
            </Grid>
        );
    };

    const onUpdate = (data: RMTruckEntries) => {
        if (data.truckTimeOut) {
            setOnReload(true);
            dispatcher(patchRMTruckEntriesAction(data.truckTimeIn, data.truckTimeOut));
        }
        setTimeout(() => dispatcher(getRMTruckEntriesAction()), 500);
    };

    const onDeleteTruckEntry = (truckEntry: RMTruckEntries) => {
        dispatcher(deleteRMTruckEntryAction(truckEntry.truckTimeIn));

        return true;
    }

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.searchBar} position='static' color='default' elevation={0}>
                <Toolbar>{renderContainer()}</Toolbar>
            </AppBar>
            <RMTruckEntriesListTable
                filterText={filterText}
                truckEntires={rmTruckEntriesList}
                exportCsv={exportCsv}
                onExportCsv={onExportCsv}
                exportCsvFileName={exportCsvFileName}
                onUpdate={onUpdate}
                onDeleteTruckEntry={onDeleteTruckEntry}
            />
            {newModal()}
        </Paper>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: '99%',
            margin: 'auto',
            overflow: 'hidden',
            alignContent: 'center',
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: 'rgb(31,49,69)',
            alignSelf: 'center',
        },
        contentWrapper: {
            margin: '0px 0px',
        },
    });

export default withStyles(styles)(RMTruckEntriesMainView);
