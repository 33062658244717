import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@material-ui/core';
import React from 'react';

export interface ItemMap {
    key: String;
    value: any;
}
export interface SelectProps {
    title: String;
    selectList?: Array<string | null>;
    selectMap?: Array<ItemMap>
    setter: (data: any) => void;
    disabled?: boolean;
    currentValue?: string | number;
    style?: React.CSSProperties;
    error?: boolean;
    readOnly?: boolean;
    variant?: 'filled' | 'outlined' | 'standard';
    codeHelperText?: string;
}

const SelectComponent = (props: SelectProps) => {
    const {
        title,
        selectList,
        selectMap,
        setter,
        disabled,
        currentValue,
        style,
        error,
        readOnly,
        variant,
        codeHelperText,
    } = props;

    return (
        <FormControl fullWidth={!!!style ? true : false} style={style} disabled={disabled} error={error}>
            <InputLabel id={`${title.replace(/ /g, '')}-select-label`}>{title}</InputLabel>
            <Select
                readOnly={readOnly}
                labelId={`${title.replace(/ /g, '')}-select-label`}
                id={`${title.replace(/ /g, '')}-select`}
                value={currentValue}
                variant={!!!variant ? 'standard' : variant}
                onChange={(event) => {
                    selectMap? setter(event.target.value) : 
                        setter(event.target.value as string)
                    }
                }>
                {selectList && (selectList.map((info) =>
                    info === null ? null : (
                        <MenuItem key={`${info.replace(/ /g, '')}-menuitem`} value={info}>
                            {info}
                        </MenuItem>
                    )
                ))}
                {selectMap && (selectMap.map((info) =>
                    info === null ? null : (
                        <MenuItem key={`${info.key.replace(/ /g, '')}-menuitem`} value={info.value}>
                            {info.key}
                        </MenuItem>
                    )
                ))}
            </Select>
            <FormHelperText>{codeHelperText}</FormHelperText>
        </FormControl>
    );
};

export default SelectComponent;
