import "react-big-calendar/lib/css/react-big-calendar.css";

import {
    AppBar,
    IconButton,
    Paper,
    Theme,
    Toolbar,
    Tooltip,
    WithStyles,
    createStyles,
    withStyles,
} from "@material-ui/core";
import { Calendar, DateLocalizer, momentLocalizer, stringOrDate } from "react-big-calendar";
import { DeliveryScheduleInfo, DeliveryScheduleInfoList } from "redux/reducers/purchasing/types";
import React, { useEffect, useState } from "react";
import { getEndDateTimeFromDelivery, getStartDateTimeFromDelivery, transformToAmPm } from "./CalendarUtils";

import { AddCircle } from "@material-ui/icons";
import CalendarAddDelivery from "./CalendarAddDelivery";
import CalendarUpdateDelivery from "./CalendarUpdateDelivery";
import { getDeliveryScheduleAction } from "redux/reducers/purchasing/actions";
import moment from "moment";
import { purchasingTypedSelector } from "redux/reducers/purchasing/purchasingReducer";
import { useDispatch } from "react-redux";

export interface ContentProps extends WithStyles<typeof styles> {}

export interface DisplayDelivery {
    scheduleId?: string;
    title: string;
    start: Date;
    end: Date;
}

const CalendarViewMain = (props: ContentProps) => {
    const { classes } = props;

    const [startDateFromClick, setStartDateFromClick] = useState<Date | undefined>(undefined);
    const [endDateFromClick, setEndDateFromClick] = useState<Date | undefined>(undefined);
    const [clickedDelivery, setClickedDelivery] = useState<DeliveryScheduleInfo | undefined>(undefined);
    const [currentCalendarView, setCurrentCalendarView] = useState("month");
    const [openAddDeliveryForm, setOpenAddDeliveryForm] = useState(false);
    const [isBlankTime, setIsBlankTime] = useState(false);
    const [displayDeliveries, setDisplayDeliveries] = useState<Array<DisplayDelivery>>([]);

    const deliveries = purchasingTypedSelector<DeliveryScheduleInfoList>((state) => state.purchasing.deliverySchedule);

    const dispatcher = useDispatch();

    useEffect(() => {
        if (startDateFromClick !== undefined && endDateFromClick !== undefined) {
            setOpenAddDeliveryForm(true);
        }
    }, [startDateFromClick, endDateFromClick]);

    useEffect(() => {
        if (deliveries.schedulesList.length === 0) {
            dispatcher(getDeliveryScheduleAction());
        } else {
            let converted = deliveries.schedulesList.map((x) => ({
                scheduleId: x.scheduleId,
                title: x.supplierName,
                start: getStartDateTimeFromDelivery(x),
                end: getEndDateTimeFromDelivery(x),
            }));
            setDisplayDeliveries(converted);
        }
    }, [deliveries, dispatcher]);

    const onAddFormClose = () => {
        setStartDateFromClick(undefined);
        setEndDateFromClick(undefined);
        setOpenAddDeliveryForm(false);
        dispatcher(getDeliveryScheduleAction());
    };

    const onUpdateFormClose = () => {
        setClickedDelivery(undefined);
        dispatcher(getDeliveryScheduleAction());
    };

    const renderAddDeliveryForm = () => {
        return (
            <CalendarAddDelivery
                showForm={openAddDeliveryForm}
                isBlankTime={isBlankTime}
                startDate={startDateFromClick!}
                endDate={endDateFromClick!}
                onFormClose={onAddFormClose}
                deliveries={deliveries.schedulesList}
                onSyncList={() => {
                    dispatcher(getDeliveryScheduleAction());
                }}
            />
        );
    };

    const renderUpdateDeliveryForm = () => {
        return (
            <CalendarUpdateDelivery
                clickedDelivery={clickedDelivery!}
                onFormClose={onUpdateFormClose}
                deliveries={deliveries.schedulesList}
                onSyncList={() => {
                    dispatcher(getDeliveryScheduleAction());
                }}
            />
        );
    };

    const handleSelect = ({ start, end, action }: { start: stringOrDate; end: stringOrDate; action: string }) => {
        if (action === "doubleClick") {
            var startDateAndTime = new Date(start.toLocaleString());
            var endDateAndTime = new Date(end.toLocaleString());
            // if start and end is not the same, then the user clicks in week or day view
            if (startDateAndTime.getTime() !== endDateAndTime.getTime()) {
                // if on week or day view, use the start time and add 2 hours as default interval for the end time
                if (endDateAndTime !== null) endDateAndTime.setHours(startDateAndTime.getHours() + 2);
                setIsBlankTime(false);
            } else {
                setIsBlankTime(true);
            }
            setStartDateFromClick(startDateAndTime);
            setEndDateFromClick(endDateAndTime);
        }
    };

    const handleEventClick = (event: DisplayDelivery) => {
        let clickedEvent = deliveries.schedulesList.find((x) => x.scheduleId === event.scheduleId);
        setClickedDelivery(clickedEvent);
    };

    const getTitleToShow = (event: DisplayDelivery) => {
        if (currentCalendarView === "month") {
            let start = event.start;
            let end = event.end;
            return "[" + transformToAmPm(start) + "-" + transformToAmPm(end) + "] " + event.title;
        }
        return event.title;
    };

    const renderCalendarContent = () => {
        const localizer: DateLocalizer = momentLocalizer(moment); // or globalizeLocalizer
        var todayMidnight = new Date();
        todayMidnight.setHours(23, 59);

        return (
            <div className={classes.contentWrapper}>
                <Calendar
                    selectable={"ignoreEvents"}
                    events={displayDeliveries}
                    defaultView={"month"}
                    views={{
                        month: true,
                        week: true,
                        day: true,
                    }}
                    titleAccessor={(event) => getTitleToShow(event)}
                    step={60}
                    showMultiDayTimes
                    max={todayMidnight}
                    defaultDate={new Date()}
                    onView={(view) => {
                        setCurrentCalendarView(view);
                    }}
                    localizer={localizer}
                    onSelectSlot={handleSelect}
                    onSelectEvent={handleEventClick}
                />
            </div>
        );
    };

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                <Toolbar>
                    <Tooltip title="Add Delivery Schedule">
                        <IconButton
                            onClick={() => {
                                var defaultTime = new Date();
                                if (defaultTime !== null) {
                                    defaultTime.setHours(0);
                                    defaultTime.setMinutes(0);
                                }
                                handleSelect({ start: defaultTime, end: defaultTime, action: "doubleClick" });
                            }}
                        >
                            <AddCircle className={classes.block} color="inherit" />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
            </AppBar>
            {renderCalendarContent()}
            {renderAddDeliveryForm()}
            {clickedDelivery !== undefined && renderUpdateDeliveryForm()}
        </Paper>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: "99%",
            margin: "auto",
            overflow: "hidden",
            alignContent: "center",
        },
        searchBar: {
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: "block",
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: "rgb(31,49,69)",
            alignSelf: "center",
        },
        contentWrapper: {
            margin: "0px 0px",
            height: "600px",
        },
        spacedGrid: {
            minWidth: "125px",
            marginLeft: "12px",
        },
    });

export default withStyles(styles)(CalendarViewMain);
