import {
    AppBar,
    CircularProgress,
    Grid,
    IconButton,
    Paper,
    TextField,
    Toolbar,
    Tooltip,
    Typography,
} from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { SparePartsTransaction, SparePartsTransactionResponse } from 'redux/reducers/spareParts/types';
import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core/styles';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import SparePartsIssuanceListTable from './SparePartsIssuanceListTable';
import SparePartsTransactionEntry from './SparePartsTransactionEntry';
import { getAllSparePartsTransaction } from 'redux/reducers/spareParts/actions';
import { sparePartsTypedSelector } from 'redux/reducers/spareParts/sparePartsReducer';
import { useDispatch } from 'react-redux';

export interface ContentProps extends WithStyles<typeof styles> {}

const viewState = {
    sparePartsIssuanceListView: 'materialIssuanceRecord',
    sparePartsTransactionView: 'transactionView',
};

let _gSparePartsTransactionList: Array<SparePartsTransaction> | [] = [];
const SparePartsInventoryListView = (props: ContentProps) => {
    const { classes } = props;

    const [filterText, setfilterText] = useState('');
    const [exportCsv, setExportCsv] = useState(false);

    const [view, setView] = useState(viewState.sparePartsIssuanceListView);

    const dispatcher = useDispatch();
    const [sparePartsTransactionList, setSparePartsTransactionList] = useState<Array<SparePartsTransaction> | []>([]);
    const sparePartsTransactionsResponse = sparePartsTypedSelector<SparePartsTransactionResponse>(
        (state) => state.spareParts.sparePartsTransaction
    );

    const [clickedMisNo, setClickedMisNo] = useState('');
    const [clickedTransaction, setClickedTransaction] = useState<SparePartsTransaction | undefined>(undefined);

    const onExportCsv = () => {
        setExportCsv(false);
    };

    const isView = (checkView: string) => {
        return view === checkView;
    };

    const [open, setOpen] = useState(false);
    const [onReload, setOnReload] = useState(false);
    const [isAcquired, setIsAcquired] = useState(false);

    useEffect(() => {
        if (sparePartsTransactionsResponse.transactions.length > 0) {
            setSparePartsTransactionList(sparePartsTransactionsResponse.transactions);
            _gSparePartsTransactionList = sparePartsTransactionsResponse.transactions;
        } else if (!isAcquired) {
            setIsAcquired(true);
            dispatcher(getAllSparePartsTransaction());
        }
    }, [sparePartsTransactionsResponse, isAcquired, dispatcher]);

    useEffect(() => {
        if (sparePartsTransactionList.length > 0) {
            setTimeout(() => {
                setOnReload(false);
            }, 500);
        }
    }, [sparePartsTransactionList]);

    const renderSparePartsIssuanceListTableButton = () => {
        return (
            <Grid item>
                <Tooltip title='Add Transaction'>
                    <IconButton onClick={() => setView(viewState.sparePartsTransactionView)}>
                        <NoteAddIcon className={classes.block} color='inherit' />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Reload'>
                    <IconButton
                        onClick={() => {
                            setSparePartsTransactionList([]);
                            _gSparePartsTransactionList = [];
                            setOnReload(true);
                            dispatcher(getAllSparePartsTransaction());
                        }}>
                        {onReload ? (
                            <CircularProgress size='24px' />
                        ) : (
                            <RefreshIcon className={classes.block} color='inherit' />
                        )}
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    };

    const renderSparePartsIssuanceListTableSearch = () => {
        return (
            <Fragment>
                <Grid item>
                    <SearchIcon className={classes.block} color='inherit' />
                </Grid>
                <Grid item xs>
                    <TextField
                        fullWidth
                        placeholder='Search'
                        InputProps={{
                            disableUnderline: true,
                            className: classes.searchInput,
                        }}
                        onChange={(event) => setfilterText(event.target.value)}
                    />
                </Grid>
            </Fragment>
        );
    };

    const renderSparePartsIssuanceListContainer = () => {
        return (
            <Grid container spacing={2} alignItems='center'>
                {renderSparePartsIssuanceListTableSearch()}
                {renderSparePartsIssuanceListTableButton()}
            </Grid>
        );
    };

    const onClickedMisNo = (misNo: string) => {
        setClickedMisNo(misNo);
        setClickedTransaction(_gSparePartsTransactionList.filter((data) => data.transactionId === misNo)[0]);
        setView(viewState.sparePartsTransactionView);
    };

    // TO-DO: call dispatch here to get the list of spare parts issuance
    const revertToListView = () => {
        setTimeout(() => {
            dispatcher(getAllSparePartsTransaction());
        }, 1000);

        setClickedMisNo('');
        setClickedTransaction(undefined);
        setView(viewState.sparePartsIssuanceListView);
    };

    const renderSparePartsTransactionEntryContainer = () => {
        return (
            <Grid container spacing={2} alignItems='center'>
                <Grid item>
                    <Tooltip title='Return / Cancel'>
                        <IconButton onClick={revertToListView}>
                            <ArrowBackIosIcon className={classes.block} color='inherit' />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    {clickedMisNo && (
                        <Typography color='primary' variant='h5' component='h1'>
                            {`Spare Parts (${
                                clickedTransaction && clickedTransaction.transactionType
                                    ? clickedTransaction.transactionType.toUpperCase()
                                    : ''
                            }) Transaction ${clickedMisNo} `}
                        </Typography>
                    )}
                    {!clickedMisNo && (
                        <Typography color='primary' variant='h5' component='h1'>
                            {'Spare Parts Transaction Entry'}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        );
    };

    const renderContent = () => {
        return (
            <div className={classes.contentWrapper}>
                {isView(viewState.sparePartsIssuanceListView) && (
                    <SparePartsIssuanceListTable
                        sparePartsTransactionList={sparePartsTransactionList}
                        filterText={filterText}
                        exportCsv={exportCsv}
                        onExportCsv={onExportCsv}
                        onClickedMisNo={onClickedMisNo}
                        onCloseMenu={() => setOpen(false)}
                        showMenu={open}
                    />
                )}
                {isView(viewState.sparePartsTransactionView) && (
                    <SparePartsTransactionEntry
                        onSubmit={revertToListView}
                        onRevert={revertToListView}
                        selectedMisNo={clickedMisNo}
                        selectedTransaction={clickedTransaction}
                    />
                )}
            </div>
        );
    };

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.searchBar} position='static' color='default' elevation={0}>
                <Toolbar>
                    {isView(viewState.sparePartsIssuanceListView) && renderSparePartsIssuanceListContainer()}
                    {isView(viewState.sparePartsTransactionView) && renderSparePartsTransactionEntryContainer()}
                </Toolbar>
            </AppBar>
            {renderContent()}
        </Paper>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: '99%',
            margin: 'auto',
            overflow: 'hidden',
            alignContent: 'center',
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: 'rgb(31,49,69)',
            alignSelf: 'center',
        },
        contentWrapper: {
            margin: '0px 0px',
        },
    });

export default withStyles(styles)(SparePartsInventoryListView);
