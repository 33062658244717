import 'static/styles/dropzone.css';

import { AppBar, Grid, Paper, Toolbar, Typography } from '@material-ui/core';
import Dropzone, { IFileWithMeta, StatusValue } from 'react-dropzone-uploader';
import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core/styles';

import React from 'react';
import { registerSingleUserAction } from 'redux/reducers/users/actions';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

export interface ContentProps extends WithStyles<typeof styles> {}

interface registrationInfo {
    email: string;
    userRole: string;
    userFirstName: string;
    userLastName: string;
}

const RegisterMultipleUsersView = (props: ContentProps) => {
    const { classes } = props;
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const dispatcher = useDispatch();

    const renderAddUserContainer = () => {
        return (
            <Grid container spacing={2} alignItems='center'>
                <Grid item>
                    <Typography color='primary' variant='h5' component='h1'>
                        Register Multiples Users
                    </Typography>
                </Grid>
                <Grid item xs />
            </Grid>
        );
    };

    // called every time a file's `status` changes
    const handleChangeStatus = (file: IFileWithMeta, status: StatusValue, allFiles: IFileWithMeta[]) => {
        if (status === 'rejected_file_type') {
            enqueueSnackbar('Please upload JSON file only', { variant: 'warning' });
        }
    };

    const checkFileEntry = ({ email, userRole, userFirstName, userLastName }: any) => {
        let errorMsg = '';
        const addErrorMsg = (msg: string) => {
            if (errorMsg !== '') {
                errorMsg += ', ';
            }
            errorMsg += msg;
        };
        if (!email) {
            addErrorMsg('Missing Email Address entry');
        }
        if (!userRole) {
            addErrorMsg('Missing User Role entry');
        }
        if (!userFirstName) {
            addErrorMsg('Missing First Name entry');
        }
        if (!userLastName) {
            addErrorMsg('Missing Last Name entry');
        }
        return errorMsg;
    };

    // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = (successFiles: IFileWithMeta[], allFiles: IFileWithMeta[]) => {
        successFiles.forEach((file) => {
            let reader = new FileReader();
            reader.readAsText(file.file);
            reader.onload = () => {
                if (reader && reader.result) {
                    const userList: Array<registrationInfo> = JSON.parse(reader.result as string);
                    userList.forEach(({ email, userRole, userFirstName, userLastName }, index) => {
                        let errorMsg = checkFileEntry({ email, userRole, userFirstName, userLastName });
                        if (errorMsg === '') {
                            setTimeout(function() {
                                dispatcher(registerSingleUserAction({ email, userRole, userFirstName, userLastName }));
                            }, 500 * index);
                        } else {
                            setTimeout(function() {
                                const key = enqueueSnackbar(`Entry #${index + 1}: ${errorMsg}`, {
                                    variant: 'error',
                                    persist: true,
                                    onClick: () => {
                                        closeSnackbar(key);
                                    },
                                });
                            }, 500 * index);
                        }
                    });
                }
            };
            file.remove();
        });
    };

    const renderContent = () => {
        return (
            <div className={classes.contentWrapper}>
                <Dropzone
                    onChangeStatus={handleChangeStatus}
                    onSubmit={handleSubmit}
                    multiple={false}
                    inputContent={'Drag Files or Click to Browse'}
                    accept='.json'
                />
            </div>
        );
    };

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.searchBar} position='static' color='default' elevation={0}>
                <Toolbar>{renderAddUserContainer()}</Toolbar>
            </AppBar>
            {renderContent()}
        </Paper>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: '50%',
            margin: 'auto',
            overflow: 'hidden',
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        contentButton: {
            marginRight: theme.spacing(1),
        },
        contentWrapper: {
            // margin: '40px 16px',
            overflow: 'hidden',
        },
    });

export default withStyles(styles)(RegisterMultipleUsersView);
