import {
    Container,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { RMShipmentInfo, RMTruckInspectionInfo } from 'redux/reducers/rmShipment/types';
import React, { Fragment, useEffect, useState } from 'react';

import { LoggedUser } from 'utils/loggedUser';
import MomentUtils from '@date-io/moment';
import SelectComponent from 'components/common/Select';
import TodayIcon from '@material-ui/icons/Today';
import { patchRmTruckInspectionAction } from 'redux/reducers/rmShipment/actions';
import { rmShipmentTypedSelector } from 'redux/reducers/rmShipment/rmShipmentReducer';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

interface TruckInspectionProps {
    rmrrId: string;
    submit?: boolean;
    onSubmit?: () => void;
    onShowSubmit: (show: boolean) => void;
}

const RMTruckInspectionForm = (props: TruckInspectionProps) => {
    const { rmrrId, submit, onSubmit, onShowSubmit } = props;
    const { enqueueSnackbar } = useSnackbar();

    const dispatcher = useDispatch();
    const shipments = rmShipmentTypedSelector<Array<RMShipmentInfo>>((state) => state.rmshipment.shipments);

    const [truckInspection, setTruckInpection] = useState<RMTruckInspectionInfo>({});
    const [supplierName, setSupplierName] = useState('');
    const [rmName, setRmName] = useState('');
    const [truckPlateNum, setTruckPlateNum] = useState('');
    const [q1status, setQ1Status] = useState('No');
    const [q2status, setQ2Status] = useState('No');
    const [q3status, setQ3Status] = useState('No');
    const [q4status, setQ4Status] = useState('No');
    const [q5status, setQ5Status] = useState('No');
    const [q6status, setQ6Status] = useState('No');
    const [q7status, setQ7Status] = useState('No');
    const [q8status, setQ8Status] = useState('No');
    const [q1remarks, setQ1Remarks] = useState('');
    const [q2remarks, setQ2Remarks] = useState('');
    const [q3remarks, setQ3Remarks] = useState('');
    const [q4remarks, setQ4Remarks] = useState('');
    const [q5remarks, setQ5Remarks] = useState('');
    const [q6remarks, setQ6Remarks] = useState('');
    const [q7remarks, setQ7Remarks] = useState('');
    const [q8remarks, setQ8Remarks] = useState('');
    const [q1action, setQ1Action] = useState('');
    const [q2action, setQ2Action] = useState('');
    const [q3action, setQ3Action] = useState('');
    const [q4action, setQ4Action] = useState('');
    const [q5action, setQ5Action] = useState('');
    const [q6action, setQ6Action] = useState('');
    const [q7action, setQ7Action] = useState('');
    const [q8action, setQ8Action] = useState('');
    const [vehicleType, setVehicleType] = useState('');
    const [containerNum, setContainerNum] = useState('');
    const [inspectionDatetime, setInspectionDatetime] = useState(new Date());
    const [inspectedBy, setInspectedBy] = useState('');
    const [notedBy, setNotedBy] = useState('');
    const [conformedBy, setConformedBy] = useState('');

    useEffect(() => {
        if (rmrrId && rmrrId !== '' && shipments && shipments.length > 0) {
            for (let idx = 0; idx < shipments.length; idx++) {
                let shipment = shipments[idx];
                if (shipment.rmrrId === rmrrId) {
                    setSupplierName(shipment.supplierName);
                    setRmName(shipment.rmName);
                    setTruckPlateNum(shipment.truckPlateNum);
                    if (shipment.truckInspectionInfo) {
                        setTruckInpection(shipment.truckInspectionInfo);
                    }
                    break;
                }
            }
        }
    }, [shipments, rmrrId]);

    const camelize = (str: string) => {
        if (str === 'no' || str === 'No') {
            return 'No';
        }
        return 'Yes';
    };

    useEffect(() => {
        if (truckInspection && truckInspection.inspectionItems && truckInspection.inspectionItems.length === 8) {
            setQ1Status(camelize(truckInspection.inspectionItems[0].inspection_result));
            setQ2Status(camelize(truckInspection.inspectionItems[1].inspection_result));
            setQ3Status(camelize(truckInspection.inspectionItems[2].inspection_result));
            setQ4Status(camelize(truckInspection.inspectionItems[3].inspection_result));
            setQ5Status(camelize(truckInspection.inspectionItems[4].inspection_result));
            setQ6Status(camelize(truckInspection.inspectionItems[5].inspection_result));
            setQ7Status(camelize(truckInspection.inspectionItems[6].inspection_result));
            setQ8Status(camelize(truckInspection.inspectionItems[7].inspection_result));
            setQ1Remarks(truckInspection.inspectionItems[0].remark);
            setQ2Remarks(truckInspection.inspectionItems[1].remark);
            setQ3Remarks(truckInspection.inspectionItems[2].remark);
            setQ4Remarks(truckInspection.inspectionItems[3].remark);
            setQ5Remarks(truckInspection.inspectionItems[4].remark);
            setQ6Remarks(truckInspection.inspectionItems[5].remark);
            setQ7Remarks(truckInspection.inspectionItems[6].remark);
            setQ8Remarks(truckInspection.inspectionItems[7].remark);
            setQ1Action(truckInspection.inspectionItems[0].action_taken);
            setQ2Action(truckInspection.inspectionItems[1].action_taken);
            setQ3Action(truckInspection.inspectionItems[2].action_taken);
            setQ4Action(truckInspection.inspectionItems[3].action_taken);
            setQ5Action(truckInspection.inspectionItems[4].action_taken);
            setQ6Action(truckInspection.inspectionItems[5].action_taken);
            setQ7Action(truckInspection.inspectionItems[6].action_taken);
            setQ8Action(truckInspection.inspectionItems[7].action_taken);
        }
        if (truckInspection) {
            setVehicleType(truckInspection.vehicleType ? truckInspection.vehicleType : '');
            setContainerNum(truckInspection.containerNum ? truckInspection.containerNum : '');
            if (truckInspection.inspectionDatetime) {
                setInspectionDatetime(truckInspection.inspectionDatetime);
            }
            setInspectedBy(truckInspection.inspectedBy ? truckInspection.inspectedBy : '');
            setNotedBy(truckInspection.notedBy ? truckInspection.notedBy : '');
            setConformedBy(truckInspection.conformedBy ? truckInspection.conformedBy : '');
        }
    }, [truckInspection]);

    const setTruckUnloadingInfo = () => {
        let unloadingInfo = {
            truckPlateNum,
            vehicleType,
            containerNum,
            rmName,
            inspectionDatetime,
            supplierName,
            inspectionItems: [
                {
                    item_id: '1',
                    inspection_result: q1status,
                    remark: q1remarks,
                    action_taken: q1action,
                },
                {
                    item_id: '2',
                    inspection_result: q2status,
                    remark: q2remarks,
                    action_taken: q2action,
                },
                {
                    item_id: '3',
                    inspection_result: q3status,
                    remark: q3remarks,
                    action_taken: q3action,
                },
                {
                    item_id: '4',
                    inspection_result: q4status,
                    remark: q4remarks,
                    action_taken: q4action,
                },
                {
                    item_id: '5',
                    inspection_result: q5status,
                    remark: q5remarks,
                    action_taken: q5action,
                },
                {
                    item_id: '6',
                    inspection_result: q6status,
                    remark: q6remarks,
                    action_taken: q6action,
                },
                {
                    item_id: '7',
                    inspection_result: q7status,
                    remark: q7remarks,
                    action_taken: q7action,
                },
                {
                    item_id: '8',
                    inspection_result: q8status,
                    remark: q8remarks,
                    action_taken: q8action,
                },
            ],
            inspectedBy,
            notedBy,
            conformedBy,
            inspectionDecision: 'ACCEPT', // TODO: Need to update how to set inspection Decision
        };
        return unloadingInfo;
    };

    useEffect(() => {
        if (submit && submit === true) {
            dispatcher(patchRmTruckInspectionAction(setTruckUnloadingInfo(), rmrrId));
            if (onSubmit) {
                onSubmit();
            }
            enqueueSnackbar(`Truck Inspection Checklist is submitted!`, { variant: 'info' });
        }
    }, [submit, onSubmit]); // eslint-disable-line

    const handleRadio = (event: React.ChangeEvent<HTMLInputElement>, setter: any) => {
        setter(event.target.value);
    };

    const isSubmitted = () => {
        let isSubmitted = false;
        if (truckInspection) {
            isSubmitted =
                truckInspection.inspectedBy && truckInspection.notedBy && truckInspection.conformedBy ? true : false;
        }
        onShowSubmit(!isSubmitted);
        return isSubmitted;
    };

    const checklistHeader = () => {
        return (
            <Fragment>
                <Grid container alignItems='center' spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Typography color='inherit' variant='h6' component='h1'>
                            Item
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <Typography color='inherit' variant='h6'>
                            Yes
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sm={1}>
                        <Typography color='inherit' variant='h6'>
                            No
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <Typography color='inherit' variant='h6' component='h1'>
                            Remarks
                        </Typography>
                    </Grid>
                    <Grid item xs={4} sm={2}>
                        <Typography color='inherit' variant='h6' component='h1'>
                            Action
                        </Typography>
                    </Grid>
                </Grid>
                <Divider light />
            </Fragment>
        );
    };

    const renderRmInfo = () => {
        return (
            <Fragment>
                <Grid container alignItems='center' spacing={1} style={{ paddingTop: 10 }}>
                    <Grid item xs={8} sm={4}>
                        <DateTimePicker
                            margin='dense'
                            fullWidth
                            label={'Date'}
                            value={inspectionDatetime}
                            disabled={isSubmitted()}
                            onChange={(date) => setInspectionDatetime(date as any)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <IconButton>
                                            <TodayIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={8} sm={4}>
                        <TextField
                            fullWidth
                            margin='dense'
                            disabled
                            variant='filled'
                            label={'Supplier Name'}
                            value={supplierName}
                        />
                    </Grid>
                    <Grid item xs={8} sm={4}>
                        <TextField
                            fullWidth
                            margin='dense'
                            disabled
                            variant='filled'
                            label={'Raw Material Name'}
                            value={rmName}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems='center' spacing={1} style={{ paddingTop: 10 }}>
                    <Grid item xs={8} sm={4}>
                        <TextField
                            fullWidth
                            margin='dense'
                            label={'Vehicle Type'}
                            disabled={isSubmitted()}
                            value={vehicleType}
                            onChange={(event) => setVehicleType(event.target.value)}
                        />
                    </Grid>
                    <Grid item xs={8} sm={4} />
                    <Grid item xs={8} sm={4}>
                        <TextField
                            fullWidth
                            margin='dense'
                            disabled
                            variant='filled'
                            label={'Plate #'}
                            value={truckPlateNum}
                        />
                    </Grid>
                </Grid>
                <Grid container alignItems='center' spacing={1} style={{ paddingTop: 10 }}>
                    <Grid item sm={8} />
                    <Grid item xs={8} sm={4}>
                        <TextField fullWidth margin='dense' label={'DR #'} disabled={isSubmitted()} />
                    </Grid>
                </Grid>
                <Grid container alignItems='center' spacing={1} style={{ paddingTop: 10 }}>
                    <Grid item xs={8} sm={4}>
                        <TextField
                            fullWidth
                            margin='dense'
                            label={'CONT Van #'}
                            disabled={isSubmitted()}
                            value={containerNum}
                            onChange={(event) => setContainerNum(event.target.value)}
                        />
                    </Grid>
                </Grid>
            </Fragment>
        );
    };

    const renderChecklist = () => {
        const entries = [
            {
                q: 'Is the truck dry and clean?',
                status: q1status,
                statusSetter: setQ1Status,
                remarks: q1remarks,
                remarksSetter: setQ1Remarks,
                action: q1action,
                actionSetter: setQ1Action,
                disabled: isSubmitted(),
            },
            {
                q:
                    'Is the raw material well covered upon delivery? Is it protected from rain, sunlight and other foreign materials?',
                status: q2status,
                statusSetter: setQ2Status,
                remarks: q2remarks,
                remarksSetter: setQ2Remarks,
                action: q2action,
                actionSetter: setQ2Action,
                disabled: isSubmitted(),
            },
            {
                q: 'Are there any damages on the wall, floor or ceiling of the truck?',
                status: q3status,
                statusSetter: setQ3Status,
                remarks: q3remarks,
                remarksSetter: setQ3Remarks,
                action: q3action,
                actionSetter: setQ3Action,
                disabled: isSubmitted(),
            },
            {
                q: 'Are there any presence of rust on the wall, floor or ceiling or the truck?',
                status: q4status,
                statusSetter: setQ4Status,
                remarks: q4remarks,
                remarksSetter: setQ4Remarks,
                action: q4action,
                actionSetter: setQ4Action,
                disabled: isSubmitted(),
            },
            {
                q:
                    'Are there any presence of cockroaches, weevils, rats, etc. during unloading of raw materials from the truck?',
                status: q5status,
                statusSetter: setQ5Status,
                remarks: q5remarks,
                remarksSetter: setQ5Remarks,
                action: q5action,
                actionSetter: setQ5Action,
                disabled: isSubmitted(),
            },
            {
                q: 'Does the truck have any unpleasant/foul odor?',
                status: q6status,
                statusSetter: setQ6Status,
                remarks: q6remarks,
                remarksSetter: setQ6Remarks,
                action: q6action,
                actionSetter: setQ6Action,
                disabled: isSubmitted(),
            },
            {
                q: 'Are there any evidence of other chemicals on the delivery?',
                status: q7status,
                statusSetter: setQ7Status,
                remarks: q7remarks,
                remarksSetter: setQ7Remarks,
                action: q7action,
                actionSetter: setQ7Action,
                disabled: isSubmitted(),
            },
            {
                q:
                    'Are there any presence of wood, plastic, stone, paper and other foreign matter during unloading. (Please include the quantity of findings)',
                status: q8status,
                statusSetter: setQ8Status,
                remarks: q8remarks,
                remarksSetter: setQ8Remarks,
                action: q8action,
                actionSetter: setQ8Action,
                disabled: isSubmitted(),
            },
        ];
        return (
            <Fragment>
                {entries.map(
                    ({ q, status, statusSetter, remarks, remarksSetter, action, actionSetter, disabled }, index) => (
                        <Grid container alignItems='center' spacing={1} style={{ paddingTop: 10 }}>
                            <Grid item xs={12} sm={6} style={{ paddingRight: 80 }}>
                                <Typography color='inherit' variant='body1' component='h1'>
                                    {q}
                                </Typography>
                                <Divider light />
                            </Grid>
                            <Grid item xs={1} sm={1}>
                                <FormControl component='fieldset'>
                                    <RadioGroup
                                        aria-label={'status' + index}
                                        name={'status' + index}
                                        value={status}
                                        onChange={(event) => handleRadio(event, statusSetter)}>
                                        <FormControlLabel
                                            value='Yes'
                                            control={<Radio color='primary' disabled={disabled} />}
                                            label=''
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={1} sm={1}>
                                <FormControl component='fieldset'>
                                    <RadioGroup
                                        aria-label={'status' + index}
                                        name={'status' + index}
                                        value={status}
                                        onChange={(event) => handleRadio(event, statusSetter)}>
                                        <FormControlLabel
                                            value='No'
                                            control={<Radio color='primary' disabled={disabled} />}
                                            label=''
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <TextField
                                    fullWidth
                                    margin='dense'
                                    value={remarks}
                                    multiline
                                    disabled={disabled}
                                    onChange={(event) => remarksSetter(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={4} sm={2}>
                                <TextField
                                    fullWidth
                                    margin='dense'
                                    value={action}
                                    multiline
                                    disabled={disabled}
                                    onChange={(event) => actionSetter(event.target.value)}
                                />
                            </Grid>
                        </Grid>
                    )
                )}
            </Fragment>
        );
    };

    const role = LoggedUser.getRole();
    const fullName = LoggedUser.getFullName();

    const inspectedBySelect = () => {
        let inspectedByList: Array<string> = [];
        if (role === 'SMD Representative' || role === 'Administrator') {
            inspectedByList.push(fullName);
        }
        if (inspectedBy !== '') {
            inspectedByList.push(inspectedBy);
        }

        return (
            <Grid item xs={8} sm={4}>
                <TextField
                    label='Inspected By'
                    fullWidth
                    margin='dense'
                    disabled={isSubmitted()}
                    value={inspectedBy}
                    onChange={(event) => {
                        setInspectedBy(event.target.value);
                    }}
                />
            </Grid>
        );
    };

    const notedBySelect = () => {
        let notedByList: Array<string> = [];
        if (role === 'QA Representative' || role === 'Administrator') {
            notedByList.push(fullName);
        }
        if (notedBy !== '') {
            notedByList.push(notedBy);
        }

        return (
            <Grid item xs={8} sm={4}>
                <SelectComponent
                    title={'Noted By'}
                    selectList={notedByList}
                    setter={setNotedBy}
                    disabled={(role !== 'QA Representative' && role !== 'Administrator') || isSubmitted()}
                    currentValue={notedBy}
                />
            </Grid>
        );
    };

    const approvers = [
        {
            select: inspectedBySelect,
            title: 'SMD Checker / Guard on Duty',
        },
        {
            select: notedBySelect,
            title: 'Laboratory Sample',
        },
        {
            value: conformedBy,
            label: 'Conformed By',
            setter: setConformedBy,
            title: 'Truck Driver',
            disabled: isSubmitted(),
        },
    ];

    const renderApproval = () => {
        return (
            <Fragment>
                <Grid container alignItems='center' spacing={1} style={{ paddingTop: 20 }}>
                    {approvers.map(({ value, label, setter, select, disabled }) => {
                        if (select) {
                            return select();
                        }

                        if (setter) {
                            return (
                                <Grid item xs={8} sm={4}>
                                    <TextField
                                        fullWidth
                                        margin='dense'
                                        label={label}
                                        value={value}
                                        disabled={disabled}
                                        onChange={(event) => setter(event.target.value)}
                                    />
                                </Grid>
                            );
                        }
                        return (
                            <Grid item xs={8} sm={4}>
                                <TextField fullWidth margin='dense' label={label} value={value} />
                            </Grid>
                        );
                    })}
                </Grid>
                <Grid container alignItems='center' spacing={1}>
                    {approvers.map((approver) => (
                        <Grid item xs={8} sm={4}>
                            <Typography color='inherit' variant='subtitle2'>
                                {approver.title}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            </Fragment>
        );
    };

    return (
        <Fragment>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Container>
                    {renderRmInfo()}
                    <Container style={{ padding: 20 }}>
                        <Paper elevation={3} style={{ padding: 10 }}>
                            {checklistHeader()}
                            {renderChecklist()}
                        </Paper>
                    </Container>
                    {renderApproval()}
                </Container>
            </MuiPickersUtilsProvider>
        </Fragment>
    );
};

export default RMTruckInspectionForm;
