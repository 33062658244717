import React, { Fragment } from 'react';

import { LoggedUser } from 'utils/loggedUser';
import TabsAppBar from 'components/appbar/TabsAppBar';
import TitleAppBar from 'components/appbar/TitleAppBar';
import UserAppBar from 'components/appbar/UserAppBar';
import { RoleFeatures } from 'redux/reducers/users/types';

interface RawMaterialsHeaderProps {
    onDrawerToggle: () => void;
    onTabsChange: (id: number) => void;
    tabsTitle?: Array<{ id: string, featureRole: RoleFeatures}>;
    selectedTab?: number;
}

const isAccessAllowed = (featureRole: RoleFeatures) => {
    let userRoleFeatures = LoggedUser.getFeatureAccessPermission();
    if (userRoleFeatures.includes(RoleFeatures.ALL) || userRoleFeatures.includes(featureRole)) {
        return true;
    }
    return false;
}

const RawMaterialsHeader = (props: RawMaterialsHeaderProps) => {
    const { onDrawerToggle, onTabsChange, tabsTitle, selectedTab } = props;
    const userFullName = LoggedUser.getFullName();
    const role = LoggedUser.getRole();

    return (
        <Fragment>
            <UserAppBar username={`${userFullName}`} role={role} onDrawerToggle={onDrawerToggle} />
            {tabsTitle && <TitleAppBar title='Raw Materials' subTitle='Stock Management Department' />}
            {tabsTitle && <TabsAppBar tabs={tabsTitle} onTabsChange={onTabsChange} selectedTab={selectedTab} />}
        </Fragment>
    );
};

export default RawMaterialsHeader;
