import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";

import { TextField } from "@material-ui/core";

export default forwardRef((props: any, ref: any) => {
    const inputRef = useRef<HTMLInputElement | undefined>();
    const [value, setValue] = useState("");

    function inputHandler(e: any) {
        setValue(e.target.value);
    }

    useImperativeHandle(ref, () => {
        return {
            getValue: () => {
                return value;
            },
            afterGuiAttached: () => {
                setValue(props.value);
                if (inputRef.current !== undefined) {
                    inputRef.current.focus();
                    inputRef.current.select();
                }
            },
        };
    });

    return <TextField type={"number"} inputRef={inputRef} onChange={inputHandler} value={value} />;
});
