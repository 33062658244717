import {
    ADD_DELIVERY_SCHEDULE,
    ADD_PURCHASE_ORDER,
    ADD_PURCHASE_REQUEST,
    ADD_SUPPLIER,
    DELETE_DELIVERY_SCHEDULE,
    DELETE_PURCHASE_ORDER,
    DELETE_PURCHASE_REQUISITION,
    DeliveryScheduleInfoRequest,
    GET_All_PURCHASE_ORDERS,
    GET_All_PURCHASE_REQUESTS,
    GET_All_SUPPLIERS,
    GET_DELIVERY_SCHEDULE,
    PATCH_DELIVERY_SCHEDULE_INFO,
    PATCH_PURCHASE_ORDER_INFO,
    PATCH_PURCHASE_REQUEST_INFO,
    PATCH_SUPPLIER_INFO,
    PurchaseOrderInfoRequest,
    PurchaseOrderInfoUpdateRequest,
    PurchaseRequestInfoRequest,
    PurchasingInfoState,
    SupplierInfoRequest,
} from "./types";

import { Action } from "redux";
import AppController from "controller/application";
import { SET_API_MESSAGE } from "../apiMessage/types";
import { ThunkAction } from "redux-thunk";

async function processGetDeliverySchedule() {
    return AppController.getDeliverySchedule();
}

async function processGetAllSuppliers() {
    return AppController.getAllSuppliers();
}

async function processGetAllPurchaseRequests() {
    return AppController.getAllPurchaseRequests();
}

async function processGetAllPurchaseOrders() {
    return AppController.getAllPurchaseOrders();
}

async function processPostNewDeliverySchedule(parameters: DeliveryScheduleInfoRequest) {
    return AppController.postNewDeliverySchedule(parameters);
}

async function processPostNewSupplier(parameters: SupplierInfoRequest) {
    return AppController.postNewSupplier(parameters);
}

async function processPostNewPurchaseRequest(parameters: PurchaseRequestInfoRequest) {
    return AppController.postNewPurchaseRequest(parameters);
}

async function processPostNewPurchaseOrder(parameters: PurchaseOrderInfoRequest) {
    return AppController.postNewPurchaseOrder(parameters);
}

async function processPatchPurchaseRequestInfo(parameters: PurchaseRequestInfoRequest, requestId: string) {
    return AppController.patchPurchaseRequestInfo(parameters, requestId);
}

async function processPatchSupplierInfo(parameters: SupplierInfoRequest, supplierId: string) {
    return AppController.patchSupplierInfo(parameters, supplierId);
}

async function processPatchPurchaseOrderInfo(parameters: PurchaseOrderInfoUpdateRequest, orderId: string) {
    return AppController.patchPurchaseOrderInfo(parameters, orderId);
}

async function processPatchDeliveryScheduleInfo(parameters: DeliveryScheduleInfoRequest, scheduleId: string) {
    return AppController.patchDeliveryScheduleInfo(parameters, scheduleId);
}

async function processDeleteDeliverySchedule(scheduleId: string) {
    return AppController.deleteDeliverySchedule(scheduleId);
}

async function processDeletePurchaseRequisition(requestId: string) {
    return AppController.deletePurchaseRequisition(requestId);
}

async function processDeletePurchaseOrder(orderId: string) {
    return AppController.deletePurchaseOrder(orderId);
}

async function processDeleteSupplier(supplierId: string) {
    return AppController.deleteSupplier(supplierId);
}

export function getDeliveryScheduleAction(): ThunkAction<void, PurchasingInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processGetDeliverySchedule();
        dispatch({
            type: GET_DELIVERY_SCHEDULE,
            payload: resp,
        });
    };
}

export function addDeliveryScheduleAction(
    parameters: DeliveryScheduleInfoRequest
): ThunkAction<void, PurchasingInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        await processPostNewDeliverySchedule(parameters);
        dispatch({
            type: ADD_DELIVERY_SCHEDULE,
            payload: {},
        });
    };
}

export function getAllSuppliersAction(): ThunkAction<void, PurchasingInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processGetAllSuppliers();
        dispatch({
            type: GET_All_SUPPLIERS,
            payload: resp,
        });
    };
}

export function addSupplierAction(
    parameters: SupplierInfoRequest
): ThunkAction<void, PurchasingInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        await processPostNewSupplier(parameters);
        dispatch({
            type: ADD_SUPPLIER,
            payload: {},
        });
    };
}

export function getAllPurchaseRequestsAction(): ThunkAction<void, PurchasingInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processGetAllPurchaseRequests();
        dispatch({
            type: GET_All_PURCHASE_REQUESTS,
            payload: resp,
        });
    };
}

export function addPurchaseRequestAction(
    parameters: PurchaseRequestInfoRequest
): ThunkAction<void, PurchasingInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        await processPostNewPurchaseRequest(parameters);
        dispatch({
            type: ADD_PURCHASE_REQUEST,
            payload: {},
        });
    };
}

export function patchPurchaseRequestInfoAction(
    parameters: PurchaseRequestInfoRequest,
    requestId: string
): ThunkAction<void, PurchasingInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processPatchPurchaseRequestInfo(parameters, requestId);
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: resp.response.data.reason,
                    variant: "error",
                },
            });
        } else {
            dispatch({
                type: PATCH_PURCHASE_REQUEST_INFO,
                payload: {},
            });
        }
    };
}

export function getAllPurchaseOrdersAction(): ThunkAction<void, PurchasingInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processGetAllPurchaseOrders();
        dispatch({
            type: GET_All_PURCHASE_ORDERS,
            payload: resp,
        });
    };
}

export function addPurchaseOrderAction(
    parameters: PurchaseOrderInfoRequest
): ThunkAction<void, PurchasingInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        await processPostNewPurchaseOrder(parameters);
        dispatch({
            type: ADD_PURCHASE_ORDER,
            payload: {},
        });
    };
}

export function patchPurchaseOrderInfoAction(
    parameters: PurchaseOrderInfoUpdateRequest,
    orderId: string
): ThunkAction<void, PurchasingInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processPatchPurchaseOrderInfo(parameters, orderId);
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: resp.response.data.reason,
                    variant: "error",
                },
            });
        } else {
            dispatch({
                type: PATCH_PURCHASE_ORDER_INFO,
                payload: {},
            });
        }
    };
}

export function patchDeliveryScheduleInfoAction(
    parameters: DeliveryScheduleInfoRequest,
    scheduleId: string
): ThunkAction<void, PurchasingInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processPatchDeliveryScheduleInfo(parameters, scheduleId);
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: resp.response.data.reason,
                    variant: "error",
                },
            });
        } else {
            dispatch({
                type: PATCH_DELIVERY_SCHEDULE_INFO,
                payload: {},
            });
        }
    };
}

export function patchSupplierInfoAction(
    parameters: SupplierInfoRequest,
    supplierId: string
): ThunkAction<void, PurchasingInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processPatchSupplierInfo(parameters, supplierId);
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: resp.response.data.reason,
                    variant: "error",
                },
            });
        } else {
            dispatch({
                type: PATCH_SUPPLIER_INFO,
                payload: {},
            });
        }
    };
}

export function deleteDeliveryScheduleAction(
    scheduleId: string
): ThunkAction<void, PurchasingInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processDeleteDeliverySchedule(scheduleId);
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: resp.response.data.reason,
                    variant: "error",
                },
            });
        } else {
            dispatch({
                type: DELETE_DELIVERY_SCHEDULE,
                payload: {},
            });
        }
    };
}

export function deletePurchaseRequisitionAction(
    requestId: string
): ThunkAction<void, PurchasingInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processDeletePurchaseRequisition(requestId);

        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: resp.response.data.reason,
                    variant: "error",
                },
            });
        } else {
            dispatch({
                type: DELETE_PURCHASE_REQUISITION,
                payload: {},
            });
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `Deleted successfully`,
                    variant: 'success',
                },
            });
        }
    };
}

export function deletePurchaseOrderAction(
    orderId: string
): ThunkAction<void, PurchasingInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processDeletePurchaseOrder(orderId);
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: resp.response.data.reason,
                    variant: "error",
                },
            });
        } else {
            dispatch({
                type: DELETE_PURCHASE_ORDER,
                payload: {},
            });
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `Deleted successfully`,
                    variant: 'success',
                },
            });
        }
    };
}

export function deleteSupplierAction(
    supplierId: string
): ThunkAction<void, PurchasingInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processDeleteSupplier(supplierId);
        if (resp.response) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: resp.response.data.reason,
                    variant: "error",
                },
            });
        } else {
            dispatch({
                type: DELETE_DELIVERY_SCHEDULE,
                payload: {},
            });
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `Deleted successfully`,
                    variant: 'success',
                },
            });
        }
    };
}

