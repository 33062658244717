import { DeletePurchaseRequestAtttachmentParams } from './../redux/reducers/purchasing/types';
import {
    DeliveryScheduleInfoRequest,
    GetPurchasingPresignedUrlParams,
    PurchaseOrderInfoRequest,
    PurchaseOrderInfoUpdateRequest,
    PurchaseRequestInfoRequest,
    SupplierInfoRequest,
} from 'redux/reducers/purchasing/types';

import ApiController from './api-controller';
import { AxiosPromise } from 'axios';

class PurchasingController extends ApiController {
    // Get
    getDeliverySchedule<Data = any>(): AxiosPromise<Data> {
        return this._instance.get(`/services/purchase/schedule`);
    }

    getAllSuppliers<Data = any>(): AxiosPromise<Data> {
        return this._instance.get(`/services/purchase/supplier`);
    }

    getAllPurchaseRequests<Data = any>(): AxiosPromise<Data> {
        return this._instance.get(`/services/purchase/request`);
    }

    getAllPurchaseOrders<Data = any>(): AxiosPromise<Data> {
        return this._instance.get(`/services/purchase/order`);
    }
    // Post
    postNewDeliverySchedule<Data = any>(parameters: DeliveryScheduleInfoRequest): AxiosPromise<Data> {
        return this._instance.post(`/services/purchase/schedule`, parameters);
    }

    postNewSupplier<Data = any>(parameters: SupplierInfoRequest): AxiosPromise<Data> {
        return this._instance.post(`/services/purchase/supplier`, parameters);
    }

    postNewPurchaseRequest<Data = any>(parameters: PurchaseRequestInfoRequest): AxiosPromise<Data> {
        return this._instance.post(`/services/purchase/request`, parameters);
    }

    postNewPurchaseOrder<Data = any>(parameters: PurchaseOrderInfoRequest): AxiosPromise<Data> {
        return this._instance.post(`/services/purchase/order`, parameters);
    }
    // Patch
    patchDeliveryScheduleInfo<Data = any>(
        parameters: DeliveryScheduleInfoRequest,
        scheduleId: string
    ): AxiosPromise<Data> {
        return this._instance.patch(`/services/purchase/schedule/${scheduleId}`, parameters);
    }

    patchSupplierInfo<Data = any>(parameters: SupplierInfoRequest, supplierId: string): AxiosPromise<Data> {
        return this._instance.patch(`/services/purchase/supplier/${supplierId}`, parameters);
    }

    patchPurchaseRequestInfo<Data = any>(
        parameters: PurchaseRequestInfoRequest,
        requestId: string
    ): AxiosPromise<Data> {
        return this._instance.patch(`/services/purchase/request/${requestId}`, parameters);
    }

    patchPurchaseOrderInfo<Data = any>(
        parameters: PurchaseOrderInfoUpdateRequest,
        orderId: string
    ): AxiosPromise<Data> {
        return this._instance.patch(`/services/purchase/order/${orderId}`, parameters);
    }

    //Delete
    deleteDeliverySchedule<Data = any>(scheduleId: string): AxiosPromise<Data> {
        return this._instance.delete(`/services/purchase/schedule/${scheduleId}`);
    }

    deletePurchaseAttachment<Data = any>(parameters: DeletePurchaseRequestAtttachmentParams): AxiosPromise<Data> {
        return this._instance.delete(`/services/purchase/request/${parameters.requestId}/attachment/${parameters.fileName}`);
    }
    
    deletePurchaseRequisition<Data = any>(requestId: string): AxiosPromise<Data> {
        return this._instance.delete(`/services/purchase/request/${requestId}`);
    }

    deletePurchaseOrder<Data = any>(orderId: string): AxiosPromise<Data> {
        return this._instance.delete(`/services/purchase/order/${orderId}`);
    }

    deleteSupplier<Data = any>(supplierId: string): AxiosPromise<Data> {
        return this._instance.delete(`/services/purchase/supplier/${supplierId}`);
    }

    generatePurchasingPresignedUrl<Data = any>(parameters: GetPurchasingPresignedUrlParams): AxiosPromise<Data> {
        return this._instance.post(`services/purchase/request/${parameters.requestId}/attachment/`, {
            fileNames: parameters.fileNames,
        });
    }
}

export default PurchasingController;
