import ApiController from './api-controller';
import { AxiosPromise } from 'axios';
import { SparePartsTransactionRequest } from 'redux/reducers/spareParts/types';

class SparePartsController extends ApiController {
    getAllSparePartsInfo<Data = any>(): AxiosPromise<Data> {
        return this._instance.get(`services/spareparts/info`);
    }

    getAllSparePartsTranscation<Data = any>(): AxiosPromise<Data> {
        return this._instance.get(`services/spareparts/transact`);
    }

    createSparePartsTransaction<Data = any>(
        param: SparePartsTransactionRequest
    ): AxiosPromise<Data> {
        return this._instance.post(`services/spareparts/transact`, param);
    }

    updateSparePartsTransaction<Data = any>(
        transactionId: string,
        param: SparePartsTransactionRequest
    ): AxiosPromise<Data> {
        return this._instance.patch(
            `services/spareparts/transact/${transactionId}`,
            param
        );
    }

    addNewSparePartsCode<Data = any>(code: string): AxiosPromise<Data> {
        return this._instance.post(`services/spareparts/info`, { code });
    }

    getAllSparePartsTranscationById<Data = any>(
        id: string
    ): AxiosPromise<Data> {
        return this._instance.get(`services/spareparts/transact/${id}`);
    }

    getAllSparePartsTranscationByCode<Data = any>(
        code: string
    ): AxiosPromise<Data> {
        return this._instance.get(`services/spareparts/transact?code=${code}`);
    }
}

export default SparePartsController;
