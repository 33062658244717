import { Container, Grid, TextField } from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { Fragment, useEffect, useState } from 'react';
import { addFGQASampleAnalysis, getFGQASampleAnalysisAction } from 'redux/reducers/finishedGoods/actions';

import { FGQAAnalysisInfo } from 'redux/reducers/finishedGoods/types';
import MomentUtils from '@date-io/moment';
import { finishedGoodsTypedSelector } from 'redux/reducers/finishedGoods/finishedGoodsReducer';
import moment from 'moment';
import { useDispatch } from 'react-redux';

interface QAChecklistProps {
    batchId: string | null;
    submit?: boolean;
    onSubmit?: () => void;
}

const FinishedGoodsQAChecklistForm = (props: QAChecklistProps) => {
    const { batchId, submit, onSubmit } = props;
    const dispatcher = useDispatch();

    const [analysisTime, setAnalysisTime] = useState('');
    const [physical, setPhysical] = useState('');

    const [sizeUniformity1, setSizeUniformity1] = useState(0.0);
    const [sizeUniformity2, setSizeUniformity2] = useState(0.0);
    const [sizeUniformity3, setSizeUniformity3] = useState(0.0);

    const [moisture, setMoisture] = useState(0.0);
    const [powder, setPowder] = useState(0.0);
    const [bulkDensity, setBulkDensity] = useState(0.0);
    const [pdiPelletHardness, setPdiPelletHardness] = useState(0.0);
    const [stabilityFloatability, setStabilityFloatability] = useState(0);
    const [temperature, setTemperature] = useState(0);

    const [analyzedBy, setAnalyzedBy] = useState('');
    const [remarks, setRemarks] = useState('');

    const qaAnalyisInfo = finishedGoodsTypedSelector<FGQAAnalysisInfo>((state) => state.finishedGoods.qaAnalysis);
    const [isSubmitted, setIsSubmitted] = useState(false);
    useEffect(() => {
        if (batchId) {
            dispatcher(getFGQASampleAnalysisAction(batchId));
        }
    }, [batchId, dispatcher]);

    useEffect(() => {
        if (qaAnalyisInfo.analysisTime) {
            setAnalysisTime(moment(qaAnalyisInfo.analysisTime).toISOString());
            setIsSubmitted(true);
        }
        if (qaAnalyisInfo.analyzedBy) {
            setAnalyzedBy(qaAnalyisInfo.analyzedBy);
        }

        setRemarks(qaAnalyisInfo.remarks);
        if (qaAnalyisInfo.checkItems) {
            qaAnalyisInfo.checkItems.forEach((checkItem) => {
                if (checkItem.description === 'physical') {
                    setPhysical(checkItem.remark);
                } else if (checkItem.description === 'sizeUniformity1') {
                    setSizeUniformity1(parseInt(checkItem.remark));
                } else if (checkItem.description === 'sizeUniformity2') {
                    setSizeUniformity2(parseInt(checkItem.remark));
                } else if (checkItem.description === 'sizeUniformity3') {
                    setSizeUniformity3(parseInt(checkItem.remark));
                } else if (checkItem.description === 'moisture') {
                    setMoisture(parseInt(checkItem.remark));
                } else if (checkItem.description === 'powder') {
                    setPowder(parseInt(checkItem.remark));
                } else if (checkItem.description === 'bulkDensity') {
                    setBulkDensity(parseInt(checkItem.remark));
                } else if (checkItem.description === 'pdiPelletHardness') {
                    setPdiPelletHardness(parseInt(checkItem.remark));
                } else if (checkItem.description === 'stabilityFloatability') {
                    setStabilityFloatability(parseInt(checkItem.remark));
                } else if (checkItem.description === 'temperature') {
                    setTemperature(parseInt(checkItem.remark));
                }
            });
        }
    }, [qaAnalyisInfo]);

    useEffect(() => {
        if (submit && onSubmit && batchId) {
            dispatcher(
                addFGQASampleAnalysis(batchId, {
                    analyzedBy: analyzedBy,
                    analysisTime: moment(analysisTime).toISOString(),
                    remarks: remarks,
                    checkItems: [
                        { description: 'physical', remark: physical },
                        { description: 'sizeUniformity1', remark: sizeUniformity1.toString() },
                        { description: 'sizeUniformity2', remark: sizeUniformity2.toString() },
                        { description: 'sizeUniformity3', remark: sizeUniformity3.toString() },
                        { description: 'moisture', remark: moisture.toString() },
                        { description: 'powder', remark: powder.toString() },
                        { description: 'bulkDensity', remark: bulkDensity.toString() },
                        { description: 'pdiPelletHardness', remark: pdiPelletHardness.toString() },
                        { description: 'stabilityFloatability', remark: stabilityFloatability.toString() },
                        { description: 'temperature', remark: temperature.toString() },
                    ],
                })
            );
            onSubmit();
        }
    }, [submit, onSubmit, batchId]); //eslint-disable-line

    const firstField = [
        {
            entry: [
                { label: 'Batch No.', type: 'text', value: batchId, disabled: true },
                {
                    label: 'Time',
                    type: 'date',
                    setter: setAnalysisTime,
                    value: analysisTime ? analysisTime : null,
                    disabled: isSubmitted,
                },
                { label: 'Physical', type: 'text', setter: setPhysical, value: physical, disabled: isSubmitted },
            ],
        },
    ];

    const secondField = [
        {
            entry: [
                { label: 'Moisture (%)', type: 'number', setter: setMoisture, value: moisture, disabled: isSubmitted },
                { label: 'Powder (%)', type: 'number', setter: setPowder, value: powder, disabled: isSubmitted },
                {
                    label: 'Bulk Density',
                    type: 'number',
                    setter: setBulkDensity,
                    value: bulkDensity,
                    disabled: isSubmitted,
                },
            ],
        },
        {
            entry: [
                {
                    label: 'PDI / Pellet Hardness',
                    type: 'number',
                    setter: setPdiPelletHardness,
                    value: pdiPelletHardness,
                    disabled: isSubmitted,
                },
                {
                    label: 'Stability / Floatability',
                    type: 'number',
                    setter: setStabilityFloatability,
                    value: stabilityFloatability,
                    disabled: isSubmitted,
                },
                {
                    label: 'Temperature (Celsius)',
                    type: 'number',
                    setter: setTemperature,
                    value: temperature,
                    disabled: isSubmitted,
                },
            ],
        },
    ];

    const lastField = [
        {
            entry: [
                { label: 'Analyzed By', type: 'text', setter: setAnalyzedBy, value: analyzedBy, disabled: isSubmitted },
                { label: 'Remarks', type: 'text', setter: setRemarks, value: remarks, disabled: isSubmitted },
            ],
        },
    ];

    const renderSizeUniformity = () => {
        return (
            <Container style={{ padding: 5 }}>
                <Grid container alignItems='center' spacing={1}>
                    <Grid item xs={6} sm={4}>
                        <TextField
                            fullWidth
                            margin='dense'
                            value={sizeUniformity1}
                            type='number'
                            variant='outlined'
                            disabled={isSubmitted}
                            onChange={(event) => setSizeUniformity1(parseInt(event.target.value))}
                            label={'SIZE UNIFORMITY Diameter x Length (mm)'}
                        />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <TextField
                            fullWidth
                            margin='dense'
                            value={sizeUniformity2}
                            type='number'
                            variant='outlined'
                            disabled={isSubmitted}
                            onChange={(event) => setSizeUniformity2(parseInt(event.target.value))}
                            label={'SIZE UNIFORMITY Diameter x Length (mm)'}
                        />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                        <TextField
                            fullWidth
                            margin='dense'
                            value={sizeUniformity3}
                            type='number'
                            variant='outlined'
                            disabled={isSubmitted}
                            onChange={(event) => setSizeUniformity3(parseInt(event.target.value))}
                            label={'SIZE UNIFORMITY Diameter x Length (mm)'}
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    };

    const renderSection = (entries: Array<any>, xs: 12 | 6 | 3, sm: 6 | 4 | 3 | 2) => {
        return (
            <Container style={{ padding: 5 }}>
                {entries.map(({ entry }) => (
                    <Grid container alignItems='center' spacing={1}>
                        {entry.map(({ label, setter, value, type, disabled }: any) => {
                            return (
                                <Grid item xs={xs} sm={sm}>
                                    {label && label.includes('Time') && (
                                        <DateTimePicker
                                            margin='dense'
                                            fullWidth
                                            label={label}
                                            value={value}
                                            inputVariant='outlined'
                                            disabled={disabled}
                                            onChange={(date) => setter(date)}
                                        />
                                    )}
                                    {label && !label.includes('Time') && (
                                        <TextField
                                            fullWidth
                                            margin='dense'
                                            value={value}
                                            label={label}
                                            type={type}
                                            variant='outlined'
                                            onChange={(event) => setter(event.target.value)}
                                            disabled={disabled}
                                        />
                                    )}
                                </Grid>
                            );
                        })}
                    </Grid>
                ))}
            </Container>
        );
    };

    return (
        <Fragment>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {renderSection(firstField, 6, 4)}
                {renderSizeUniformity()}
                {renderSection(secondField, 6, 4)}
                {renderSection(lastField, 12, 6)}
            </MuiPickersUtilsProvider>
        </Fragment>
    );
};

export default FinishedGoodsQAChecklistForm;
