import { DeliveryScheduleInfo } from "redux/reducers/purchasing/types";
import moment from "moment";

export const getStartDateTimeFromDelivery = (delivery: DeliveryScheduleInfo) => {
    return moment(delivery.deliveryDate + " " + delivery.timeSlot).toDate();
};

export const getEndDateTimeFromDelivery = (delivery: DeliveryScheduleInfo) => {
    let startDatetime = moment(delivery.deliveryDate + " " + delivery.timeSlot);
    let endDatetime = startDatetime.add(2, "hours").toDate();
    return endDatetime;
};

export const transformToAmPm = (date: Date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let format = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;

    hours = hours === 0 ? 12 : hours;
    let strMinutes = minutes < 10 ? "0" + minutes.toString() : minutes.toString();

    return hours + ":" + strMinutes + format;
};
