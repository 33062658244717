import * as URL_CONST from './utils/urls';

import { Route, Router, Switch } from 'react-router-dom';

import ForgotPasswordPage from './views/Login/ForgotPasswordPage';
import LoginPage from './views/Login/LoginPage';
import Paperbase from './views/Main';
import PrivateRoute from './utils/privateRoute';
import { Provider } from 'react-redux';
import React from 'react';
import { SnackbarProvider } from 'notistack';
import UpdatePasswordPage from './views/Login/UpdatePasswordPage';
import configureStore from './redux/configureStore';
import { reactRouterHistory } from './utils/navigation';

const App = (props: any) => {
    const store = configureStore();

    return (
        <Provider store={store}>
            <SnackbarProvider maxSnack={5} anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
                <Router history={reactRouterHistory}>
                    <Switch>
                        <Route path={URL_CONST.LOGIN_PAGE} exact component={LoginPage} />
                        <Route path={URL_CONST.UPDATE_PASSWORD} exact component={UpdatePasswordPage} />
                        <Route path={URL_CONST.FORGOT_PASSWORD} exact component={ForgotPasswordPage} />
                        <PrivateRoute path={URL_CONST.MAIN} component={Paperbase} />
                        {/* Add other routes for page redirections from HomePage
                                        - Use PrivateRoute for pages with authentication */}
                    </Switch>
                </Router>
            </SnackbarProvider>
        </Provider>
    );
};

export default App;
