import {
    Button,
    Container,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { LocationInfo, RMShipmentInfo, RawMaterial } from "redux/reducers/rmShipment/types";
import React, { Fragment, useEffect, useState } from "react";
import {
    getRMInfoListAction,
    getWarehouseInfoAction,
    patchRmAnalysisInfoAction,
    patchRmShipmentApprovalAction,
    patchRmUnloadingInfoAction,
    postNewRmShipmentsAction,
} from "redux/reducers/rmShipment/actions";

import { LoggedUser } from "utils/loggedUser";
import MomentUtils from "@date-io/moment";
import { RMTruckEntries } from "redux/reducers/truckEntries/types";
import SelectComponent from "components/common/Select";
import ThumbDownIcon from "@material-ui/icons/ThumbDown";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import TodayIcon from "@material-ui/icons/Today";
import { getRMTruckEntriesAction } from "redux/reducers/truckEntries/actions";
import moment from "moment";
import { rmShipmentTypedSelector } from "redux/reducers/rmShipment/rmShipmentReducer";
import { truckEntriesTypedSelector } from "redux/reducers/truckEntries/truckEntriesReducer";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";

export interface RMRRFormProps {
    submit?: boolean;
    onSubmit: (isComplete: boolean) => void;
    rmrrId?: string;
    onReturn: () => void;
    onLetterAvailable: (letter: string) => void;
    onShowSubmit: (show: boolean) => void;
}

const RMRRForm = (props: RMRRFormProps) => {
    const { submit, onSubmit, rmrrId, onReturn, onLetterAvailable, onShowSubmit } = props;
    const shipments = rmShipmentTypedSelector<Array<RMShipmentInfo>>((state) => state.rmshipment.shipments);
    const warehouseInfo = rmShipmentTypedSelector<Array<LocationInfo>>((state) => state.rmshipment.warehouseInfo);
    const rmList = rmShipmentTypedSelector<Array<RawMaterial>>((state) => state.rmshipment.rmList);
    const permission = LoggedUser.getPermission();
    const role = LoggedUser.getRole();
    const fullName = LoggedUser.getFullName();
    const dispatcher = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const rmTruckEntries = truckEntriesTypedSelector<Array<RMTruckEntries>>(
        (state) => state.truckEntries.rmTruckEntries
    );
    const [isAcquired, setIsAcquired] = useState(false);
    useEffect(() => {
        if (rmTruckEntries.length === 0 && !isAcquired) {
            dispatcher(getRMTruckEntriesAction());
            setIsAcquired(true);
        }
    }, [rmTruckEntries, isAcquired, dispatcher]);

    const getShipment = (shipmentList: RMShipmentInfo[]) => {
        let selectedShipment: RMShipmentInfo = {
            itemNum: "",
            rmName: "",
            rmCode: "",
            inputDateTime: "",
            rmrrId: "",
            processingStatus: "",
            supplierName: "",
            purchaseOrderNum: "",
            truckPlateNum: "",
            quantity: 0,
            quantityUnit: 0,
            rmUnitPrice: 0,
            netWeight: 0,
            truckScaleNum: "",
        };
        if (rmrrId && rmrrId !== "" && shipmentList && shipmentList.length > 0) {
            for (let idx = 0; idx < shipmentList.length; idx++) {
                let shipment = shipmentList[idx];
                if (shipment.rmrrId === rmrrId) {
                    selectedShipment = shipment;
                    break;
                }
            }
        }
        return selectedShipment;
    };

    useEffect(() => {
        const shipment = getShipment(shipments);
        if (shipment) {
            setRMRRData(shipment);
        }
    }, [shipments, rmrrId]); // eslint-disable-line

    useEffect(() => {
        if (warehouseInfo.length === 0) {
            dispatcher(getWarehouseInfoAction());
        }
    }, [warehouseInfo, dispatcher]);

    useEffect(() => {
        if (rmList.length === 0) {
            dispatcher(getRMInfoListAction());
        }
    }, [rmList, dispatcher]);

    const [letter, setLetter] = useState("");
    useEffect(() => {
        if (letter !== "") {
            onLetterAvailable(letter);
        }
    }, [letter, onLetterAvailable]);

    const setRMRRData = (shipment: RMShipmentInfo) => {
        setRawMaterialName(shipment.rmName);
        setSupplierName(shipment.supplierName);
        setTruckPlateNum(shipment.truckPlateNum);
        setQuantity(shipment.quantity);
        setRmUnitPrice(shipment.rmUnitPrice);
        setItemNum(shipment.itemNum);
        setPurchaseOrderNum(shipment.purchaseOrderNum);
        setQuantityUnit(shipment.quantityUnit);
        if (shipment.analysisInfo) {
            let analysis = shipment.analysisInfo;
            setCollectedBy(analysis.collectedBy ? analysis.collectedBy : "");
            setCollectedByTime(
                analysis.collectionDatetime ? moment(analysis.collectionDatetime).format("MMM DD, YYYY HH:mm:ss") : ""
            );
            setAnalyzeBy(analysis.analyzedBy ? analysis.analyzedBy : "");
            setAnalyzeByTime(
                analysis.analysisDatetime ? moment(analysis.analysisDatetime).format("MMM DD, YYYY HH:mm:ss") : ""
            );
            setResult(analysis.analysisResult ? analysis.analysisResult : "");
            setResultTime(
                analysis.analysisResultDatetime
                    ? moment(analysis.analysisResultDatetime).format("MMM DD, YYYY HH:mm:ss")
                    : ""
            );
            setRecommendedBy(analysis.recommendedBy ? analysis.recommendedBy : "");
            setRecommendationDetails(analysis.recommendationDetails ? analysis.recommendationDetails : "");
            if (analysis.recommendation) {
                if (analysis.recommendation.toLowerCase() === "accept") {
                    setRecommendation("Accept");
                } else {
                    setRecommendation("Reject");
                }
            }
            setRemarks(analysis.remarks ? analysis.remarks : "");
        }
        setDepartmentHead(shipment.approvedBy ? shipment.approvedBy : "");
        setNetWeight(shipment.netWeight);
        setTruckScaleNum(shipment.truckScaleNum);
        setWarehouseId(shipment.warehouseId ? shipment.warehouseId : "");
        setLocationId(shipment.locationId ? shipment.locationId : "");
        setUnloadedBy(shipment.unloadedBy ? shipment.unloadedBy : "");
        setUnloadedBySet(shipment.unloadedBy ? true : false);
        setUnloadedTime(shipment.unloadingDatetime ? shipment.unloadingDatetime : "");
        setUnloadedTimeSet(shipment.unloadingDatetime ? true : false);
        setReceivedBy(shipment.receivedBy ? shipment.receivedBy : "");
        setReceivedBySet(shipment.receivedBy ? true : false);
        setReceivedTime(shipment.receptionDatetime ? shipment.receptionDatetime : "");
        setReceivedTimeSet(shipment.receptionDatetime ? true : false);

        setLetter(shipment.letterOfGuarantee ? shipment.letterOfGuarantee : "");
    };

    const checkMissingInputs = () => {
        let missingInput = "";
        if (purchaseOrderNum === "") {
            missingInput += "Purchase Order #, ";
        }
        if (rmName === "") {
            missingInput += "Raw Material Name, ";
        }
        if (supplierName === "") {
            missingInput += "Supplier, ";
        }
        if (truckPlateNum === "") {
            missingInput += "Truck Plate #, ";
        }
        return missingInput;
    };

    const getMandatoryRMInfo = () => {
        return {
            purchaseOrderNum: purchaseOrderNum,
            rmName: rmName,
            itemNum: itemNum,
            supplierName: supplierName,
            truckPlateNum: truckPlateNum,
            quantity: quantity,
            rmUnitPrice: rmUnitPrice,
            quantityUnit: quantityUnit,
            netWeight: netWeight,
            truckScaleNum: truckScaleNum,
        };
    };

    const checkAnalysisInfo = () => {
        let analysis: any = {};
        if (collectedBy !== "") {
            analysis.collectedBy = collectedBy;
            analysis.collectionDatetime = moment.utc(collectedByTime).toISOString();
        }
        if (analyzedBy !== "") {
            analysis.analyzedBy = analyzedBy;
            analysis.analysisDatetime = moment.utc(analyzedByTime).toISOString();
        }
        if (result !== "") {
            analysis.analysisResult = result;
            analysis.analysisResultDatetime = moment.utc(resultTime).toISOString();
        }
        if (remarks !== "") {
            analysis.remarks = remarks;
        }

        return analysis;
    };

    const checkUnloadingInfo = () => {
        let unloading: any = {};
        if (warehouseId !== "") {
            unloading.warehouseId = warehouseId;
        }
        if (locationId !== "") {
            unloading.locationId = locationId;
        }

        if (unloadedBy !== "") {
            unloading.unloadedBy = unloadedBy;
            unloading.unloadingDatetime = moment.utc(unloadTime).toISOString();
        }
        if (receivedBy !== "") {
            unloading.receivedBy = receivedBy;
            unloading.receptionDatetime = moment.utc(receivedTime).toISOString();
        }
        return unloading;
    };

    useEffect(() => {
        if (submit && submit === true) {
            let missingInputs = checkMissingInputs();
            if (missingInputs === "") {
                if (!rmrrId) {
                    let newRmShipment: any = getMandatoryRMInfo();
                    dispatcher(postNewRmShipmentsAction(newRmShipment));

                    if (onSubmit) {
                        onSubmit(true);
                    }
                } else {
                    setTimeout(() => {
                        if (departmentHead !== "") {
                            let unloadingInfo = checkUnloadingInfo();
                            if (Object.getOwnPropertyNames(unloadingInfo).length > 0 && rmrrId) {
                                dispatcher(patchRmUnloadingInfoAction(unloadingInfo, rmrrId));
                            }
                        }
                        if (onSubmit) {
                            onSubmit(true);
                        }
                    }, 1000);
                }
            } else {
                enqueueSnackbar(`Missing inputs: ${missingInputs}`, { variant: "error" });
                if (onSubmit) {
                    onSubmit(false);
                }
            }
        }
    }, [submit, onSubmit]); // eslint-disable-line

    // Confirmation Summary
    const [rmName, setRawMaterialName] = useState("");
    const [supplierName, setSupplierName] = useState("");
    const [truckPlateNum, setTruckPlateNum] = useState("");
    const [quantity, setQuantity] = useState(0);
    const [rmUnitPrice, setRmUnitPrice] = useState(0);
    const [itemNum, setItemNum] = useState("");
    const [purchaseOrderNum, setPurchaseOrderNum] = useState("");
    const [quantityUnit, setQuantityUnit] = useState(0);
    const [netWeight, setNetWeight] = useState(0);
    const [truckScaleNum, setTruckScaleNum] = useState("");
    // const [processingStatus, setProcessingStatus] = useState('');

    const handleQuantityRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuantityUnit(parseInt(event.target.value));
    };

    const renderQuantityRadio = () => {
        return (
            <FormControl component="fieldset" disabled={rmrrId ? true : !isRMAccess()}>
                <RadioGroup
                    style={{ flexDirection: "row" }}
                    aria-label="quantity"
                    name="quantity"
                    value={quantityUnit}
                    onChange={handleQuantityRadio}
                >
                    <FormControlLabel style={{ flex: 1 }} value={0} control={<Radio color="primary" />} label="Bag" />
                    <FormControlLabel style={{ flex: 1 }} value={1} control={<Radio color="primary" />} label="Bulk" />
                    <FormControlLabel style={{ flex: 1 }} value={2} control={<Radio color="primary" />} label="Mega" />
                    <FormControlLabel style={{ flex: 1 }} value={3} control={<Radio color="primary" />} label="Drums" />
                </RadioGroup>
            </FormControl>
        );
    };

    const isRMAccess = () => {
        return permission.rmAccess === "FULL";
    };

    const rawMaterialNameSelect = () => {
        if (!!!rmrrId) {
            return (
                <SelectComponent
                    title={"Raw Material Name"}
                    selectList={rmList.map((info) => info.rmName)}
                    setter={setRawMaterialName}
                    disabled={!isRMAccess()}
                    currentValue={rmName}
                />
            );
        }
        return <TextField fullWidth margin="dense" value={rmName} label={"Raw Material Name"} type={"text"} disabled />;
    };

    const setPlateSupplier = (event: any) => {
        const availableSupplierTruck = rmTruckEntries.filter((truck) => (!!!truck.truckTimeOut ? true : false));
        availableSupplierTruck.forEach((info) => {
            if (info.plateNumber === event || event === info.supplierName) {
                setTruckPlateNum(info.plateNumber);
                setSupplierName(info.supplierName);
            }
        });
    };

    const supplierNameSelect = () => {
        if (!!!rmrrId) {
            return (
                <SelectComponent
                    title={"Supplier Name"}
                    selectList={rmTruckEntries
                        .filter((truck) => (!!!truck.truckTimeOut ? true : false))
                        .map((truck) => truck.supplierName)}
                    setter={setPlateSupplier}
                    currentValue={supplierName}
                />
            );
        }
        return (
            <TextField fullWidth margin="dense" value={supplierName} label={"Supplier Name"} type={"text"} disabled />
        );
    };

    const truckPlateNumSelect = () => {
        if (!!!rmrrId) {
            return (
                <SelectComponent
                    title={"Truck Plate #"}
                    selectList={rmTruckEntries
                        .filter((truck) => (!!!truck.truckTimeOut ? true : false))
                        .map((truck) => truck.plateNumber)}
                    setter={setPlateSupplier}
                    currentValue={truckPlateNum}
                />
            );
        }
        return (
            <TextField fullWidth margin="dense" value={truckPlateNum} label={"Truck Plate #"} type={"text"} disabled />
        );
    };

    const confirmationSummaryFields = [
        {
            entry: [
                { select: rawMaterialNameSelect },
                {
                    label: "Item #",
                    type: "text",
                    setter: setItemNum,
                    value: itemNum,
                    disabled: rmrrId ? true : !isRMAccess(),
                },
            ],
        },
        {
            entry: [
                { select: supplierNameSelect },
                {
                    label: "Purchase Order #",
                    type: "text",
                    setter: setPurchaseOrderNum,
                    value: purchaseOrderNum,
                    disabled: rmrrId ? true : !isRMAccess(),
                },
            ],
        },
        {
            entry: [
                {
                    select: truckPlateNumSelect,
                },
            ],
        },
        {
            entry: [
                {
                    label: "Quantity",
                    type: "number",
                    setter: setQuantity,
                    value: quantity,
                    disabled: rmrrId ? true : !isRMAccess(),
                },
                { radio: renderQuantityRadio },
            ],
        },
        {
            entry: [
                {
                    label: "Unit Price",
                    type: "number",
                    setter: setRmUnitPrice,
                    value: rmUnitPrice,
                    disabled: rmrrId ? true : !isRMAccess(),
                },
            ],
        },
        {
            entry: [
                {
                    label: "Net Wt.",
                    type: "number",
                    setter: setNetWeight,
                    value: netWeight,
                    disabled: rmrrId ? true : !isRMAccess(),
                },
                {
                    label: "Truck Scale #",
                    type: "text",
                    setter: setTruckScaleNum,
                    value: truckScaleNum,
                    disabled: rmrrId ? true : !isRMAccess(),
                },
            ],
        },
    ];

    // Sample Analysis Summary (QA/Lab)
    const [collectedBy, setCollectedBy] = useState("");
    const [collectedByTime, setCollectedByTime] = useState("");
    const [analyzedBy, setAnalyzeBy] = useState("");
    const [analyzedByTime, setAnalyzeByTime] = useState("");
    const [result, setResult] = useState("");
    const [resultTime, setResultTime] = useState("");
    const [remarks, setRemarks] = useState("");

    const sampleAnalysisSummaryFields = [
        {
            entry: [
                { label: "Collected By", value: collectedBy, disabled: true },
                { label: "Collection Date", setter: setCollectedByTime, value: collectedByTime, disabled: true },
            ],
        },
        {
            entry: [
                { label: "Analyzed By", value: analyzedBy, disabled: true },
                { label: "Analysis Date", setter: setAnalyzeByTime, value: analyzedByTime, disabled: true },
            ],
        },
        {
            entry: [
                { label: "Result", type: "text", setter: setResult, value: result, disabled: true },
                { label: "Result Date", setter: setResultTime, value: resultTime, disabled: true },
            ],
        },
        { entry: [{ label: "Remarks", type: "text", setter: setRemarks, value: remarks, disabled: true }] },
    ];

    // Recommending For
    const [recommendedBy, setRecommendedBy] = useState("");
    const [recommendationDetails, setRecommendationDetails] = useState("");
    const [recommendation, setRecommendation] = useState("Reject");
    const renderRecommendationRadio = () => {
        return (
            <TextField
                fullWidth
                margin="dense"
                label={"Status"}
                value={recommendation === "Accept" ? "ACCEPTED" : "REJECTED"}
                type={"text"}
                disabled={true}
            />
        );
    };

    const sectionHeadSection = () => {
        return (
            <TextField
                fullWidth
                margin="dense"
                label={"Recommended By (Section Head)"}
                value={recommendedBy}
                type={"text"}
                disabled={true}
            />
        );
    };

    const sampleRecommendingForFields = [
        {
            entry: [
                { select: sectionHeadSection },
                { radio: renderRecommendationRadio },
                {
                    label: "Recommendation Details",
                    type: "text",
                    setter: setRecommendationDetails,
                    value: recommendationDetails,
                    disabled: true,
                },
            ],
        },
    ];

    // Approved By
    const [departmentHead, setDepartmentHead] = useState("");
    const deparmentHeadSelect = () => {
        return (
            <TextField
                fullWidth
                margin="dense"
                label={"Department Head"}
                value={departmentHead}
                type={"text"}
                disabled={true}
            />
        );
    };
    const approvedByFields = [{ entry: [{ select: deparmentHeadSelect }] }];

    // Unloading Summary
    const [warehouseId, setWarehouseId] = useState("");
    const [locationId, setLocationId] = useState("");
    const [unloadedBy, setUnloadedBy] = useState("");
    const [unloadedBySet, setUnloadedBySet] = useState(false);
    const [unloadTime, setUnloadedTime] = useState("");
    const [unloadTimeSet, setUnloadedTimeSet] = useState(false);
    const [receivedBy, setReceivedBy] = useState("");
    const [receivedBySet, setReceivedBySet] = useState(false);
    const [receivedTime, setReceivedTime] = useState("");
    const [receivedTimeSet, setReceivedTimeSet] = useState(false);

    const warehouseSelect = () => {
        let warehouseList: Array<string> = [];
        warehouseInfo.forEach((info) => {
            if (!warehouseList.some((warehouseId) => warehouseId === info.warehouseId)) {
                warehouseList.push(info.warehouseId);
            }
        });

        const shipment = getShipment(shipments);

        return (
            <SelectComponent
                title={"Warehouse"}
                selectList={warehouseList}
                setter={setWarehouseId}
                currentValue={warehouseId}
                disabled={shipment && shipment.warehouseId ? true : !isRMAccess()}
            />
        );
    };

    const locationSelect = () => {
        let locationList = warehouseInfo.filter((info) => {
            return info.warehouseId === warehouseId;
        });

        const shipment = getShipment(shipments);

        return (
            <SelectComponent
                title={"Location"}
                selectList={locationList.map((info) => info.locationId)}
                setter={setLocationId}
                disabled={shipment && shipment.locationId ? true : !isRMAccess()}
                currentValue={locationId}
            />
        );
    };

    const unloadingSummaryFields = [
        {
            entry: [{ select: warehouseSelect }, { select: locationSelect }],
        },
        {
            entry: [
                {
                    label: "Unloaded By",
                    type: "text",
                    setter: setUnloadedBy,
                    value: unloadedBy,
                    disabled: !isRMAccess() || unloadedBySet,
                },
                {
                    label: "Unloading Time",
                    setter: setUnloadedTime,
                    value: unloadTime,
                    disabled: !isRMAccess() || unloadTimeSet,
                },
            ],
        },
        {
            entry: [
                {
                    label: "Received By",
                    type: "text",
                    setter: setReceivedBy,
                    value: receivedBy,
                    disabled: !isRMAccess() || receivedBySet,
                },
                {
                    label: "Reception Time",
                    setter: setReceivedTime,
                    value: receivedTime,
                    disabled: !isRMAccess() || receivedTimeSet,
                },
            ],
        },
    ];

    const renderSection = (title: string, entries: Array<any>, align?: "center") => {
        if (title === "Recommending For:" && (warehouseId === "" || locationId === "")) {
            onShowSubmit(true);
        }

        return (
            <Container style={{ padding: 10 }}>
                <Typography align={align ? align : "inherit"} color="inherit" variant="h5" component="h6">
                    {title}
                </Typography>
                {entries.map(({ entry }, index) => (
                    <Grid container alignItems="center" spacing={1} key={`${index}-parentGrid`}>
                        {entry.map(
                            ({ label, setter, value, radio, type, menu, select, disabled }: any, index: number) => {
                                return (
                                    <Grid item xs={12} sm={6} key={`${index}-grid`}>
                                        {label && label.includes("Time") && value && (
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <DateTimePicker
                                                    margin="dense"
                                                    fullWidth
                                                    label={label}
                                                    value={value}
                                                    disabled={disabled}
                                                    onChange={(date) => setter(date)}
                                                />
                                            </MuiPickersUtilsProvider>
                                        )}
                                        {label && label.includes("Time") && !!!value && (
                                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                                <DateTimePicker
                                                    margin="dense"
                                                    label={label}
                                                    value={null}
                                                    onChange={(date) => setter(date)}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <IconButton>
                                                                    <TodayIcon />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                        )}
                                        {label && !label.includes("Time") && (
                                            <TextField
                                                fullWidth
                                                margin="dense"
                                                value={value}
                                                label={label}
                                                type={type}
                                                onChange={(event) => setter(event.target.value)}
                                                disabled={disabled}
                                            />
                                        )}
                                        {radio && radio()}
                                        {menu && menu()}
                                        {select && select()}
                                    </Grid>
                                );
                            }
                        )}
                    </Grid>
                ))}
            </Container>
        );
    };

    const renderSoloCenterSection = (title: string, entries: Array<any>) => {
        return (
            <Container style={{ padding: 10 }}>
                <Typography align={"center"} color="inherit" variant="h5" component="h6">
                    {title}
                </Typography>
                {entries.map(({ entry }, index) => (
                    <Grid container alignItems="center" spacing={1} key={`${index}-parentCenterGrid`}>
                        {entry.map(({ label, setter, value, type, select }: any, index: number) => (
                            <Fragment key={`${index}-centerFragment`}>
                                <Grid item xs={6} sm={3} />
                                <Grid item xs={12} sm={6}>
                                    {label && (
                                        <TextField
                                            fullWidth
                                            margin="dense"
                                            value={value}
                                            label={label}
                                            type={type}
                                            onChange={(event) => setter(event.target.value)}
                                        />
                                    )}
                                    {select && select()}
                                </Grid>
                                <Grid item xs={6} sm={3} />
                            </Fragment>
                        ))}
                    </Grid>
                ))}
            </Container>
        );
    };

    const RMShipmentRecommendation = (recommendation: string) => {
        let analysisInfo = checkAnalysisInfo();
        analysisInfo.recommendedBy = fullName;
        analysisInfo.recommendationDetails = recommendationDetails;
        analysisInfo.recommendation = recommendation;
        if (rmrrId) {
            dispatcher(patchRmAnalysisInfoAction(analysisInfo, rmrrId));
            enqueueSnackbar(`${fullName} sets recommendation to ${recommendation}`, { variant: "info" });
        }
    };

    const renderRecommendationButton = () => {
        onShowSubmit(false);
        const shipment = getShipment(shipments);
        if (shipment.analysisInfo && shipment.analysisInfo.analysisResult) {
            return (
                <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            fullWidth
                            margin="dense"
                            label={"Recommendation Details"}
                            value={recommendationDetails}
                            type={"text"}
                            onChange={(event) => {
                                setRecommendationDetails(event.target.value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{ background: "#4ec624" }}
                            onClick={() => {
                                RMShipmentRecommendation("ACCEPT");
                                onReturn();
                            }}
                        >
                            <ThumbUpIcon />
                            Approve
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{ background: "#ff0000" }}
                            onClick={() => {
                                RMShipmentRecommendation("REJECT");
                                onReturn();
                            }}
                        >
                            <ThumbDownIcon />
                            Reject
                        </Button>
                    </Grid>
                </Grid>
            );
        }
        return null;
    };

    const RMShipmentApproval = (approval: string) => {
        if (rmrrId) {
            dispatcher(
                patchRmShipmentApprovalAction(
                    {
                        approval: approval,
                        approvedBy: fullName,
                    },
                    rmrrId
                )
            );
            if (approval === "ACCEPT") {
                enqueueSnackbar(`${fullName} approves shipment ${rmrrId}`, { variant: "success" });
            } else {
                enqueueSnackbar(`${fullName} rejects shipment ${rmrrId}`, { variant: "error" });
            }
        }
    };

    const renderApprovalButton = () => {
        onShowSubmit(false);
        const shipment = getShipment(shipments);
        if (shipment.analysisInfo && shipment.analysisInfo.recommendedBy) {
            return (
                <Grid container alignItems="center" spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{ background: "#4ec624" }}
                            onClick={() => {
                                RMShipmentApproval("ACCEPT");
                                onReturn();
                            }}
                            startIcon={<ThumbUpIcon />}
                        >
                            Approve
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{ background: "#ff0000" }}
                            onClick={() => {
                                RMShipmentApproval("REJECT");
                                onReturn();
                            }}
                            startIcon={<ThumbDownIcon />}
                        >
                            Reject
                        </Button>
                    </Grid>
                </Grid>
            );
        }
        return null;
    };

    const isSectionHead = () => {
        return role === "QA Section Head" || role === "Administrator";
    };

    const isDepartmentHead = () => {
        return role === "QA Department Head" || role === "Administrator";
    };

    return (
        <Container>
            {renderSection("Confirmation Summary", confirmationSummaryFields)}
            {rmrrId && renderSection("Sample Analysis Summary (QA / LAB)", sampleAnalysisSummaryFields)}
            {rmrrId && recommendedBy && renderSection("Recommending For:", sampleRecommendingForFields, "center")}
            {rmrrId && recommendedBy === "" && isSectionHead() && renderRecommendationButton()}
            {rmrrId && recommendedBy && departmentHead && renderSoloCenterSection("Approved By:", approvedByFields)}
            {rmrrId && recommendedBy && departmentHead === "" && isDepartmentHead() && renderApprovalButton()}
            {rmrrId && recommendedBy && departmentHead && renderSection("Unloading Summary", unloadingSummaryFields)}
            <div style={{ height: 10 }} />
        </Container>
    );
};

export default RMRRForm;
