import {
    GetLetterPresignedUrlParams,
    GetRMQAPresignedUrlParams,
    RMAnalysisInfo,
    RMInfo,
    RMShipmentApproval,
    RMTruckInspectionInfo,
    RMUnloadingInfo,
} from 'redux/reducers/rmShipment/types';

import ApiController from './api-controller';
import { AxiosPromise } from 'axios';

class RMShipmentController extends ApiController {
    // Posts
    getRmShipments<Data = any>(): AxiosPromise<Data> {
        return this._instance.get(`/services/rm/shipment`);
    }

    postNewRmShipments<Data = any>(parameters: RMInfo): AxiosPromise<Data> {
        return this._instance.post(`/services/rm/shipment`, parameters);
    }

    patchRmShipmentAnalysisInfo<Data = any>(parameters: RMAnalysisInfo, rmrrId: string): AxiosPromise<Data> {
        return this._instance.patch(`/services/rm/shipment/${rmrrId}/analysis`, parameters);
    }

    patchRmTruckInspectionInfo<Data = any>(parameters: RMTruckInspectionInfo, rmrrId: string): AxiosPromise<Data> {
        return this._instance.patch(`/services/rm/shipment/${rmrrId}/truckinspection`, parameters);
    }

    patchRmShipmentApprovalInfo<Data = any>(parameters: RMShipmentApproval, rmrrId: string): AxiosPromise<Data> {
        return this._instance.patch(`/services/rm/shipment/${rmrrId}/approval`, parameters);
    }

    patchRmShipmentUnloadingInfo<Data = any>(parameters: RMUnloadingInfo, rmrrId: string): AxiosPromise<Data> {
        return this._instance.patch(`/services/rm/shipment/${rmrrId}/unloadingInfo`, parameters);
    }

    getRMWarehouseLocationInfo<Data = any>(): AxiosPromise<Data> {
        return this._instance.get(`/services/rm/warehouse/locations`);
    }

    getRMInfoList<Data = any>(): AxiosPromise<Data> {
        return this._instance.get(`/services/rm/info`);
    }

    generateLetterOfGuaranteePresignedUrl<Data = any>(parameters: GetLetterPresignedUrlParams): AxiosPromise<Data> {
        return this._instance.post(`services/rm/shipment/${parameters.rmrrId}/attachment/`, {
            fileName: parameters.fileName,
        });
    }

    generateQAFilePresignedUrl<Data = any>(parameters: GetRMQAPresignedUrlParams): AxiosPromise<Data> {
        return this._instance.post(`services/rm/shipment/${parameters.rmrrId}/qaAttachment/`, {
            fileName: parameters.fileName,
        });
    }

    deleteShipmentByRmrrId<Data = any>(rmrrId: string): AxiosPromise<Data> {
        return this._instance.delete(`services/rm/shipment/${rmrrId}`);
    }
}

export default RMShipmentController;
