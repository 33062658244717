import React, { Fragment, useState } from 'react';
import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core/styles';

import DashboardHeader from 'views/Dashboard/DashboardHeader';
import FGWarehouseDashboard from './FGWarehouse/FGWarehouseDashboard';
import RMDashboard from './RMStocks/RMDashboard';
import RMWarehouseDashboard from './RMWarehouse/RMWarehouseDashboard';
import SparePartsDashboard from './SpareParts/SparePartsDashboard';

export interface DashboardMainProps extends WithStyles<typeof styles> {
    onDrawerToggle: () => void;
    mainClass: any;
}

const DashboardMain = (props: DashboardMainProps) => {
    const tabsTitle = [{ id: 'RM Warehouse' }, { id: 'RM Stocks' }, { id: 'FG Warehouse' }, { id: 'Spare Parts' }];

    const [currentTab, setCurrentTab] = useState(0);
    const onTabsChange = (id: number) => {
        setCurrentTab(id);
    };

    return (
        <Fragment>
            <Fragment>
                <DashboardHeader
                    onDrawerToggle={props.onDrawerToggle}
                    onTabsChange={onTabsChange}
                    tabsTitle={tabsTitle}
                    selectedTab={currentTab}
                />
                <main className={props.mainClass}>
                    {tabsTitle[currentTab].id === 'RM Warehouse' && <RMWarehouseDashboard />}
                    {tabsTitle[currentTab].id === 'RM Stocks' && <RMDashboard />}
                    {tabsTitle[currentTab].id === 'FG Warehouse' && <FGWarehouseDashboard />}
                    {tabsTitle[currentTab].id === 'Spare Parts' && <SparePartsDashboard />}
                </main>
            </Fragment>
        </Fragment>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            margin: 'auto',
            padding: 50,
            alignContent: 'center',
            alignItems: 'center',
        },
    });

export default withStyles(styles)(DashboardMain);
