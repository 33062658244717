import React, { Fragment } from 'react';

import UserListHeader from 'views/AccountManagement/UsersList/UserListHeader';
import UsertListView from 'views/AccountManagement/UsersList/UserListView';

export interface UserListMainProps {
    onDrawerToggle: () => void;
    mainClass: any;
}

const UserListMain = (props: UserListMainProps) => {
    const onTabsChange = (id: number) => {};

    return (
        <Fragment>
            <UserListHeader onDrawerToggle={props.onDrawerToggle} onTabsChange={onTabsChange} />
            <main className={props.mainClass}>
                <UsertListView />
            </main>
        </Fragment>
    );
};

export default UserListMain;
