import {
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
    SparePartsBase,
    SparePartsInfoList,
    SparePartsTransaction,
    SparePartsTransactionRequest,
} from 'redux/reducers/spareParts/types';
import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import {
    createSparePartsTransaction,
    getAllSparePartsInfo,
    updateSparePartsTransaction,
} from 'redux/reducers/spareParts/actions';

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import SelectComponent from 'components/common/Select';
import TableGrid from 'components/common/TableGrid';
import { sparePartsTypedSelector } from 'redux/reducers/spareParts/sparePartsReducer';
import { useDispatch } from 'react-redux';

export interface ContentProps extends WithStyles<typeof styles> {
    onSubmit: () => void;
    onRevert: () => void;
    selectedMisNo?: string;
    selectedTransaction?: SparePartsTransaction;
}

const SparePartsTransactionEntry = (props: ContentProps) => {
    const { classes, onSubmit, selectedMisNo, selectedTransaction } = props;
    const dispatcher = useDispatch();

    const [materialEntryTableContent, setEntryTableContent] = useState<Array<SparePartsBase>>([]);
    const sparePartsInfoList = sparePartsTypedSelector<SparePartsInfoList>((state) => state.spareParts.sparePartsInfo);

    const [transactionType, setTransactionType] = useState<'in' | 'out'>('in');
    const [openCodeEntryModal, setOpenCodeEntryModal] = useState(false);
    const [codeHelperText, setCodeHelperText] = useState('');
    const [newCode, setCode] = useState('');
    const [descriptionHelperText, setDescriptionHelperText] = useState('');
    const [newDescription, setDescription] = useState('');
    const [quantityHelperText, setQuantityHelperText] = useState('');
    const [newQuantity, setQuantity] = useState(0);
    const [unitHelperText, setUnitHelperText] = useState('');
    const [newUnit, setUnit] = useState('');
    const [unitPriceHelperText, setUnitPriceHelperText] = useState('');
    const [newUnitPrice, setUnitPrice] = useState(0);
    const [lineHelperText, setLineHelperText] = useState('');
    const [newLine, setLine] = useState(0);
    const [remarksHelperText, setRemarksHelperText] = useState('');
    const [newRemarks, setRemarks] = useState('');
    const [newPurpose, setPurpose] = useState('');
    const [materialType, setMaterialType] = useState('Spare Parts');
    const [receivedBy, setReceivedBy] = useState('');
    const [requestedBy, setRequestedBy] = useState('');
    const [notedBy, setNotedBy] = useState('');
    const [approvedBy, setApprovedBy] = useState('');
    const [issuedBy, setIssuedBy] = useState('');

    const [sparePartsCodeList, setSparePartsCodeList] = useState<Array<string>>([]);
    const [isAcquired, setIsAcquired] = useState(false);

    useEffect(() => {
        if (sparePartsInfoList.infoList.length > 0) {
            setSparePartsCodeList(sparePartsInfoList.infoList.map((info) => info.code));
        } else if (!isAcquired) {
            setIsAcquired(true);
            dispatcher(getAllSparePartsInfo());
        }
    }, [sparePartsInfoList, isAcquired, dispatcher]);

    useEffect(() => {
        if (!!!selectedMisNo || !!!selectedTransaction) {
            return;
        }
        setEntryTableContent(selectedTransaction.transactionItems);
        setTransactionType(selectedTransaction.transactionType);
        setPurpose(selectedTransaction.purpose);
        setMaterialType(!!!selectedTransaction.materialType ? 'Spare Parts' : selectedTransaction.materialType);
        setReceivedBy(!!!selectedTransaction.receivedBy ? '' : selectedTransaction.receivedBy);
        setRequestedBy(!!!selectedTransaction.requestedBy ? '' : selectedTransaction.requestedBy);
        setNotedBy(!!!selectedTransaction.notedBy ? '' : selectedTransaction.notedBy);
        setApprovedBy(!!!selectedTransaction.approvedBy ? '' : selectedTransaction.approvedBy);
        setIssuedBy(!!!selectedTransaction.issuedBy ? '' : selectedTransaction.issuedBy);
    }, [selectedMisNo, selectedTransaction]);

    const onTransactionSelect = () => {
        transactionType === 'in' ? setTransactionType('out') : setTransactionType('in');
    };

    const renderTransactionSelect = () => {
        return (
            <Grid container alignItems='center' spacing={2}>
                <Grid>
                    <Typography color='primary' variant='h6' component='h3' style={{ paddingLeft: 20 }}>
                        {'Transaction Type'}​​​​​
                    </Typography>
                </Grid>
                <Grid item xs>
                    <RadioGroup
                        row
                        aria-label='Transaction Type'
                        name='Transaction Type'
                        value={transactionType}
                        onChange={onTransactionSelect}>
                        <FormControlLabel value='in' control={<Radio />} label='IN' />
                        <FormControlLabel value='out' control={<Radio />} label='OUT' />
                    </RadioGroup>
                </Grid>
                <Grid item>
                    <Tooltip title='Add Spare Parts Transaction'>
                        <IconButton onClick={() => setOpenCodeEntryModal(true)}>
                            <PlaylistAddIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        );
    };

    const columnDefs = [
        {
            headerName: 'Code',
            field: 'code',
            minWidth: 300,
            filter: 'agTextColumnFilter',
            headerTooltip: 'Code',
        },
        {
            headerName: 'Description',
            field: 'description',
            minWidth: 200,
            filter: 'agTextColumnFilter',
            headerTooltip: 'Description',
        },
        {
            headerName: 'Quantity',
            field: 'quantity',
            minWidth: 200,
            filter: 'agTextColumnFilter',
            headerTooltip: 'Quantity',
        },
        {
            headerName: 'Unit',
            field: 'unit',
            minWidth: 200,
            filter: 'agTextColumnFilter',
            headerTooltip: 'unit',
        },
        {
            headerName: 'Unit Price',
            field: 'unitPrice',
            minWidth: 200,
            filter: 'agTextColumnFilter',
            hide: transactionType === 'out',
            headerTooltip: 'unitPrice',
        },
        {
            headerName: 'Line',
            field: 'line',
            minWidth: 200,
            filter: 'agTextColumnFilter',
            hide: transactionType === 'in',
            headerTooltip: 'Line',
        },
        {
            headerName: 'Remarks',
            field: 'remarks',
            minWidth: 200,
            filter: 'agTextColumnFilter',
            hide: transactionType === 'in',
            headerTooltip: 'Remarks',
        },
    ];

    // TO-DO: Check which input are required (e.g. can submit without remark)
    const isFormComplete = () => {
        if (transactionType === 'in') {
            return newCode !== '' && newDescription !== '' && newQuantity !== 0 && newUnit !== '';
        } else {
            return newCode !== '' && newDescription !== '' && newQuantity !== 0 && newUnit !== '' && newRemarks !== '';
        }
    };

    const checkIndividualInput = () => {
        if (newCode === '') setCodeHelperText('Spare parts code is required');
        if (newDescription === '') setDescriptionHelperText('Description is required');
        if (newQuantity <= 0) setQuantityHelperText('Quantity should be greater than 0');
        if (newUnit === '') setUnitHelperText('Unit is required');
        if (newUnitPrice <= 0) setUnitPriceHelperText('Unit Price should be greater than 0');
        //if(newTotal === '') setTotalHelperText('Please set total')
        //if(newLine === '') setLineHelperText('Please input line')
        if (newRemarks === '') setRemarks('Please input remarks');
    };

    const resetDialogInput = () => {
        setCode('');
        setDescription('');
        setQuantity(0);
        setUnit('');
        setUnitPrice(0);
        setLine(0);
        setRemarks('');
    };

    const addEntryToInputTable = () => {
        const newEntry: SparePartsBase = {
            code: newCode,
            description: newDescription,
            quantity: newQuantity,
            unit: newUnit,
            unitPrice: newUnitPrice,
            line: newLine,
            remarks: newRemarks,
        };
        const currentEntries = [...materialEntryTableContent, newEntry];
        setEntryTableContent(currentEntries);
    };

    const addNewCodeEntry = () => {
        checkIndividualInput();

        if (isFormComplete()) {
            addEntryToInputTable();
            resetDialogInput();
            setOpenCodeEntryModal(false);
        }
    };

    const onModalClose = () => {
        setOpenCodeEntryModal(false);
        setCodeHelperText('');
        setDescriptionHelperText('');
        setQuantityHelperText('');
        setUnitHelperText('');
        setUnitPriceHelperText('');
        setLineHelperText('');
        setRemarksHelperText('');
    };

    const dialogInput = [
        {
            label: 'Description',
            type: 'text',
            helper: descriptionHelperText,
            helperSetter: setDescriptionHelperText,
            valueSetter: setDescription,
            showIf: 'always',
        },
        {
            label: 'Quantity',
            type: 'number',
            helper: quantityHelperText,
            helperSetter: setQuantityHelperText,
            valueSetter: setQuantity,
            showIf: 'always',
        },
        {
            label: 'Unit',
            type: 'text',
            helper: unitHelperText,
            helperSetter: setUnitHelperText,
            valueSetter: setUnit,
            showIf: 'always',
        },
        {
            label: 'Unit Price',
            type: 'number',
            helper: unitPriceHelperText,
            helperSetter: setUnitPriceHelperText,
            valueSetter: setUnitPrice,
            showIf: 'in',
        },
        {
            label: 'Line',
            type: 'number',
            helper: lineHelperText,
            helperSetter: setLineHelperText,
            valueSetter: setLine,
            showIf: 'out',
        },
        {
            label: 'Remarks',
            type: 'text',
            helper: remarksHelperText,
            helperSetter: setRemarksHelperText,
            valueSetter: setRemarks,
            showIf: 'out',
        },
    ];

    const handleSelectedCodeChange = (event: any) => {
        setCode(event as string);
        setCodeHelperText('');
    };
    const codeEntryModal = () => {
        return (
            <div>
                <Dialog disableEscapeKeyDown open={openCodeEntryModal} onClose={onModalClose}>
                    <DialogTitle>Material Details</DialogTitle>
                    <DialogContent>
                        <SelectComponent
                            title={'Spare Parts Code'}
                            selectList={sparePartsCodeList}
                            setter={handleSelectedCodeChange}
                            currentValue={newCode}
                            error={codeHelperText !== ''}
                            variant={'outlined'}
                            codeHelperText={codeHelperText}
                        />
                    </DialogContent>
                    <DialogContent>
                        {dialogInput.map(({ label, type, helper, helperSetter, valueSetter, showIf }: any) => {
                            return (
                                (showIf === 'always' || showIf === transactionType) && (
                                    <TextField
                                        fullWidth
                                        margin='dense'
                                        style={{ minWidth: 200 }}
                                        type={type}
                                        label={label}
                                        variant='outlined'
                                        helperText={helper}
                                        error={helper !== ''}
                                        onChange={(event) => {
                                            helperSetter('');
                                            valueSetter(event.target.value);
                                        }}
                                    />
                                )
                            );
                        })}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={addNewCodeEntry} color='primary' variant='contained'>
                            Add Material
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    const renderSparePartsInputTable = () => {
        return (
            <div style={{ padding: 10 }}>
                <TableGrid rowData={materialEntryTableContent} columnDefs={columnDefs} height={450} />
            </div>
        );
    };

    const materialTypeList = ['Spare Parts', 'Operating Supplies', 'Office Supplies'];
    const renderPurposeInput = () => {
        return (
            <Grid container alignItems='center' spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        margin='dense'
                        style={{ minWidth: 200, marginLeft: 10 }}
                        type='text'
                        label='Purpose'
                        value={newPurpose}
                        variant='outlined'
                        onChange={(event) => {
                            setPurpose(event.target.value);
                        }}
                    />
                </Grid>
                {transactionType === 'out' && (
                    <Grid item xs={12} sm={6}>
                        <SelectComponent
                            title={'Material Type'}
                            selectList={materialTypeList}
                            setter={setMaterialType}
                            currentValue={materialType}
                        />
                    </Grid>
                )}
            </Grid>
        );
    };

    const personEntryFields = [
        {
            entry: [
                {
                    label: 'Received By',
                    type: 'text',
                    value: receivedBy,
                    show: transactionType === 'in',
                    setter: setReceivedBy,
                },
                {
                    label: 'Requested By',
                    type: 'text',
                    value: requestedBy,
                    show: true,
                    setter: setRequestedBy,
                },
                {
                    label: 'Noted By',
                    type: 'text',
                    value: notedBy,
                    show: transactionType === 'out',
                    setter: setNotedBy,
                },
            ],
        },
        {
            entry: [
                {
                    label: 'Approved By',
                    type: 'text',
                    value: approvedBy,
                    show: transactionType === 'out',
                    setter: setApprovedBy,
                },
                {
                    label: 'Issued By',
                    type: 'text',
                    value: issuedBy,
                    show: transactionType === 'out',
                    setter: setIssuedBy,
                },
            ],
        },
    ];

    const renderPersonSection = (entries: Array<any>, align?: 'center') => {
        return (
            <Container style={{ padding: 10 }}>
                {entries.map(({ entry }) => (
                    <Grid container alignItems='center' spacing={1}>
                        {entry.map(({ label, setter, value, type, show }: any) => {
                            return (
                                label &&
                                show && (
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            margin='dense'
                                            value={value}
                                            label={label}
                                            type={type}
                                            onChange={(event) => setter(event.target.value)}
                                        />
                                    </Grid>
                                )
                            );
                        })}
                    </Grid>
                ))}
            </Container>
        );
    };

    const sendCreateTransaction = () => {
        const transactionItems = materialEntryTableContent.map((data) => {
            return {
                ...data,
                quantity: Number(data.quantity),
                line: Number(data.line),
                unitPrice: Number(data.unitPrice),
            };
        });
        let param: SparePartsTransactionRequest = {
            transactionType: transactionType,
            transactionItems: [...transactionItems],
            purpose: newPurpose,
            requestedBy: requestedBy,
        };
        if (transactionType === 'out') {
            param.materialType = materialType;
            param.notedBy = notedBy;
            param.approvedBy = approvedBy;
            param.issuedBy = issuedBy;
        }
        if (transactionType === 'in') {
            param.receivedBy = receivedBy;
        }
        if (!!!selectedMisNo) {
            dispatcher(createSparePartsTransaction(param));
        } else {
            dispatcher(updateSparePartsTransaction(selectedMisNo, param));
        }
    };

    return (
        <div style={{ padding: 10 }}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                {!selectedMisNo && renderTransactionSelect()}
                {renderSparePartsInputTable()}
                {renderPurposeInput()}
                {renderPersonSection(personEntryFields)}
                {codeEntryModal()}
            </MuiPickersUtilsProvider>
            <Button
                fullWidth
                variant='contained'
                color='primary'
                className={classes.contentButton}
                onClick={() => {
                    sendCreateTransaction();
                    onSubmit();
                }}>
                {!!!selectedMisNo ? 'Create' : 'Update'}
            </Button>
        </div>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: '99%',
            margin: 'auto',
            overflow: 'hidden',
            alignContent: 'center',
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: 'rgb(31,49,69)',
            alignSelf: 'center',
        },
        contentWrapper: {
            margin: '0px 0px',
        },
    });

export default withStyles(styles)(SparePartsTransactionEntry);
