import React from 'react';
import { AppBar, Avatar, Grid, Hidden, IconButton, Typography, Toolbar } from '@material-ui/core';
import { createStyles, Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { logoutAction } from 'redux/reducers/rootReducer/actions';
import { useDispatch } from 'react-redux'
import * as URL_CONST from 'utils/urls';
import { RouterNavigation } from 'utils/navigation';

interface UserAppBarProps extends WithStyles<typeof styles> {
    onDrawerToggle: () => void;
    username: string;
    role: string;
}

const UserAppBar = (props: UserAppBarProps) => {

    const { classes, username, onDrawerToggle } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const dispatcher = useDispatch();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        dispatcher(logoutAction())
        RouterNavigation.navigateTo(URL_CONST.LOGIN_PAGE);
    }

    return (
        <AppBar color="primary" position="sticky" elevation={0}>
            <Toolbar>
                <Grid container spacing={1} alignItems="center">
                    <Hidden smUp>
                        <Grid item>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={onDrawerToggle}
                                className={classes.menuButton}>
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                    </Hidden>
                    <Grid item xs />
                    <Grid item>
                        <Typography className={classes.link} variant="body2" onClick={handleClick}>
                            {username}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <IconButton color="inherit" className={classes.iconButtonAvatar} onClick={handleClick}>
                            <Avatar style={{ backgroundColor: 'white' }}>
                                <AccountCircleIcon color='primary' />
                            </Avatar>
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem disabled>{`Role : ${props.role}`}</MenuItem>
                            <MenuItem onClick={handleLogout}><ExitToAppIcon /> Log out</MenuItem>
                        </Menu>
                    </Grid>
                </Grid>
            </Toolbar>
        </AppBar>
    )
}

const lightColor = 'rgba(255, 255, 255, 0.7)';
const styles = (theme: Theme) => createStyles({
    menuButton: {
        marginLeft: -theme.spacing(1),
    },
    iconButtonAvatar: {
        padding: 4,
    },
    link: {
        textDecoration: 'none',
        color: lightColor,
        '&:hover': {
            color: theme.palette.common.white,
        },
    },
    button: {
        borderColor: lightColor,
    },
});

export default withStyles(styles)(UserAppBar);