import {
    INCORRECT_CREDENTIALS,
    INTERNAL_ERROR
} from 'utils/strings';

export const isEmail = (input: string) => {
    if (input.search("@") > -1) {
        return true;
    }

    return false;
};

export const isUsernameValid = (input: string) => {

    if (input !== null && input !== undefined && input !== '') {
        return true;
    }

    return false;
};

export const isPasswordValid = (input: string) => {

    const passwordFormat = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$');

    if (passwordFormat.test(input)) {
        return true;
    }

    return false;
};

export const isPasswordMatched = (password: string, confirmPassword: string) => {
    if (password === confirmPassword) {
        return true;
    }

    return false;
};

export const isEmailValid = (input: string) => {
    const emailFormat = new RegExp('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$');

    if (emailFormat.test(input) === true) {
        return true;
    }

    return false;
};

export const isMobileValid = (input: string) => {
    const mobileFormat = new RegExp('^\\+[0-9]*$');

    if (input.length <= 16 &&
        mobileFormat.test(input) === true) {
        return true;
    }

    return false;
};

export const getError = (status: number, response: string) => {

    if (response !== '') {
        return response
    }

    switch (status) {
        case 401:
            return INCORRECT_CREDENTIALS;
        case 400:
            return response
    }

    return INTERNAL_ERROR;
};