import React, { Fragment } from "react";

import { Button } from "@material-ui/core";
import { ProductionOrderInfo } from "redux/reducers/premix/types";
import TableGrid from "components/common/TableGrid";

export interface ContentProps {
    filterText: string;
    productionOrderList: Array<ProductionOrderInfo>;
    onClickedProductionOrder: (productionOrderId: string) => void;
}

const ProductionOrderListTable = (props: ContentProps) => {
    const { filterText, productionOrderList, onClickedProductionOrder } = props;

    const productionOrderIdRenderer = (params: { value: string }) => {
        return (
            <Button
                style={{ color: "#009fdc" }}
                onClick={() => {
                    if (onClickedProductionOrder) {
                        onClickedProductionOrder(params.value);
                    }
                }}
            >
                {params.value}
            </Button>
        );
    };

    const premixListConcatenatedRenderer = (data: any) => {
        let itemList = "";
        if (data.value.length > 0) {
            data.value.forEach((item: any, index: number) => {
                if (index > 0) {
                    itemList += ", ";
                }
                let itemWithDescription = `${item.premixName}`;
                itemList = itemList + itemWithDescription;
            });
        }
        return itemList;
    };

    const formulationInputRenderer = (data: any) => {
        let formulationList = "";
        if (data.value.length > 0) {
            data.value.forEach((item: any, index: number) => {
                if (index > 0) {
                    formulationList += ", ";
                }
                let itemWithDescription = `${item.name} (${item.value})`;
                formulationList = formulationList + itemWithDescription;
            });
        }
        return formulationList;
    };

    const columnDefs = [
        {
            headerName: "Production Order #",
            field: "prodOrderId",
            minWidth: 300,
            filter: "agTextColumnFilter",
            cellRendererFramework: productionOrderIdRenderer,
        },
        {
            headerName: "Update Date",
            field: "updateDate",
            hide: true,
            minWidth: 150,
            filter: "agTextColumnFilter",
            headerTooltip: "Update Date",
            sort: "asc",
        },
        {
            headerName: "Feed Type",
            field: "feedType",
            minWidth: 150,
            filter: "agTextColumnFilter",
            headerTooltip: "Feed Type",
        },
        {
            headerName: "Line",
            field: "line",
            minWidth: 150,
            filter: "agTextColumnFilter",
            headerTooltip: "Line",
        },
        {
            headerName: "Date",
            field: "date",
            minWidth: 150,
            filter: "agTextColumnFilter",
            headerTooltip: "Date",
        },
        {
            headerName: "Premix Checker",
            field: "premixChecker",
            minWidth: 150,
            filter: "agTextColumnFilter",
            headerTooltip: "Premix Checker",
        },
        {
            headerName: "Prepared By",
            field: "preparedBy",
            minWidth: 150,
            filter: "agTextColumnFilter",
            headerTooltip: "Prepared By",
        },
        {
            headerName: "Date Prepared",
            field: "datePrepared",
            minWidth: 150,
            filter: "agTextColumnFilter",
            headerTooltip: "Date Prepared",
        },
        {
            headerName: "Formulation Input",
            field: "formulationInput",
            minWidth: 500,
            maxWidth: 1000,
            filter: "agTextColumnFilter",
            headerTooltip: "Formulation Input",
            cellRenderer: formulationInputRenderer,
        },
        {
            headerName: "Premixes",
            field: "premixes",
            minWidth: 500,
            maxWidth: 1000,
            filter: "agTextColumnFilter",
            headerTooltip: "Premixes",
            cellRenderer: premixListConcatenatedRenderer,
        },
        {
            headerName: "Total Weight",
            minWidth: 150,
            filter: "agTextColumnFilter",
            headerTooltip: "Total Weight",
            valueGetter: (param: any) => {
                return param.data.rmTotalWeight + " kgs";
            },
        },
        {
            headerName: "Revision #",
            field: "revisionNumber",
            minWidth: 150,
            filter: "agTextColumnFilter",
            headerTooltip: "Revision #",
        },
    ];

    return (
        <Fragment>
            <TableGrid rowData={productionOrderList} columnDefs={columnDefs} filterText={filterText} height={600} />
        </Fragment>
    );
};

export default ProductionOrderListTable;
