import { Button, Grid, TextField } from "@material-ui/core";
import React, { Fragment } from "react";
import { SupplierInfo, SupplierLocationOption } from "redux/reducers/purchasing/types";
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles";

import SelectComponent from "components/common/Select";
import TableGrid from "components/common/TableGrid";

export interface ContentProps extends WithStyles<typeof styles> {
    clickedSupplierDetails?: SupplierInfo;
    onClickedUpdateButton: () => void;
}

const SupplierDetailsView = (props: ContentProps) => {
    const { clickedSupplierDetails, onClickedUpdateButton } = props;

    let columnDefs = [
        {
            headerName: "Item Code",
            field: "itemCode",
            minWidth: 100,
            filter: "agTextColumnFilter",
            headerTooltip: "Item Code",
        },
        {
            headerName: "Description",
            field: "itemDescription",
            minWidth: 200,
            filter: "agTextColumnFilter",
            headerTooltip: "Item Description",
        },
    ];

    const renderPurchaseRequestDetails = () => {
        return (
            <Fragment>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <TextField
                            label={"Name"}
                            value={clickedSupplierDetails!.supplierName}
                            inputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectComponent
                            title={"Location"}
                            selectList={[SupplierLocationOption.LOCAL, SupplierLocationOption.INTERNATIONAL]}
                            currentValue={clickedSupplierDetails!.supplierLocation}
                            setter={(event) => {}}
                            readOnly={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableGrid rowData={clickedSupplierDetails!.itemsList} columnDefs={columnDefs} height={300} />
                    </Grid>

                    <Grid item xs={12}>
                        <Button onClick={onClickedUpdateButton} color="primary" variant="contained" fullWidth={true}>
                            Update
                        </Button>
                    </Grid>
                </Grid>
            </Fragment>
        );
    };

    return <div style={{ padding: 10 }}>{renderPurchaseRequestDetails()}</div>;
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: "99%",
            margin: "auto",
            overflow: "hidden",
            alignContent: "center",
        },
        searchBar: {
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: "block",
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: "rgb(31,49,69)",
            alignSelf: "center",
        },
        contentWrapper: {
            margin: "0px 0px",
        },
    });

export default withStyles(styles)(SupplierDetailsView);
