import React, { Fragment, useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

export interface LineGraphProps {
    labels?: Array<String>;
    data?: Array<Number>;
    title?: string;
}

const LineGraph = (props: LineGraphProps) => {

    const [data, setData] = useState<any>({})

    useEffect(() => { }, [data])

    useEffect(() => {
        setData({
            labels: props.labels,
            datasets: [
                {
                    label: props.title,
                    fill: false,
                    lineTension: 0.1,
                    backgroundColor: 'rgb(0,159,220,0.4)',
                    borderColor: '#009fdc',
                    borderCapStyle: 'butt',
                    borderDash: [],
                    borderDashOffset: 0.0,
                    borderJoinStyle: 'miter',
                    pointBorderColor: '#009fdc',
                    pointBackgroundColor: '#fff',
                    pointBorderWidth: 1,
                    pointHoverRadius: 5,
                    pointHoverBackgroundColor: '#009fdc',
                    pointHoverBorderColor: 'rgba(220,220,220,1)',
                    pointHoverBorderWidth: 2,
                    pointRadius: 1,
                    pointHitRadius: 10,
                    data: props.data
                }
            ]
        })
    }, [props])

    return (
        <Fragment>
            <Line
                data={data}
                options={{
                    maintainAspectRatio: false,
                    legend: {
                        display: true
                    },
                    plugins: {
                        datalabels: {
                            color: '#1f3145',
                        }
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                            }
                        }]
                    }
                }}
                width={100}
                height={window.innerHeight / 4} />
        </Fragment>
    );
}

export default LineGraph;
