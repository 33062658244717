import {
    useSelector,
    TypedUseSelectorHook
} from 'react-redux'

import {
    UserRegistrationActionTypes,
    UserReducerType,
    UserInfoState,
    GET_ALL_REGISTERED_USERS,
    GET_SINGLE_USER,
    REGISTER_SINGLE_USER,
    GET_ALL_ROLES_INFO
} from './types'
import { LOGOUT } from 'redux/reducers/rootReducer/types';

const initialState: UserInfoState = {
    user: {},
    users: [],
    roles: []
}

export const userRegistrationTypedSelector: TypedUseSelectorHook<UserReducerType> = useSelector;

export function usersReducer(
    state = initialState,
    action: UserRegistrationActionTypes
): UserInfoState {

    switch (action.type) {

        case GET_ALL_REGISTERED_USERS:
            return {
                user: state.user,
                users: action.payload.users,
                roles: state.roles
            }

        case GET_SINGLE_USER:
            return {
                user: action.payload,
                users: state.users,
                roles: state.roles
            }
            
        case REGISTER_SINGLE_USER:
            return state

        case GET_ALL_ROLES_INFO:
            return {
                user: state.user,
                users: state.users,
                roles: action.payload.roles
            }

        case LOGOUT:

            return initialState

        default:
            return state
    }

}