import { LogoutAction } from 'redux/reducers/rootReducer/types';

export interface TransferTransactionInfo {
    rmrrId: string;
    balanceWeight: number;
    transferredWeight: number;
    transferDatetime: string;
    shift: number | string | null;
}

export interface AddStockTransferTransactionParams {
    stockSourceList: string[];
    weight: number;
    shift: string;
}

export interface FGTruckInspectionItemInfo {
    item_id: string;
    inspection_result: string;
    remark: string;
    action_taken: string;
}

export interface FGTruckInspectionInfo {
    truckPlateNum: string;
    vehicleType: string;
    inspectionDatetime: string;
    inspectionItems: FGTruckInspectionItemInfo[];
    inspectedBy: string;
    approvedBy: string;
    conformedBy: string;
    inspectionDecision: string;
    inspectionDecisionDatetime: string;
}

export interface FGDispatchApproval {
    dispatchApproval: string;
    dispatchApprovedBy: string;
}

export interface FGQACheckItems {
    description: string;
    remark: string;
}

export interface FGQAAnalysisInfo {
    batchId?: string;
    analyzedBy: string;
    analysisTime: string;
    remarks: string;
    checkItems: FGQACheckItems[];
}

export interface CreateFGBatchParam {
    mfgDate: string;
    prodCode: string;
    lineNum: string;
    bagNum: string;
}

export interface FGQAAnalysisResult {
    qaCheckedBy?: string;
    qaNotedBy?: string;
    qaAnalysisResult?: string;
    qaAttachment?: string;
}

export interface FGBatchOrderInfo {
    customerName: string;
    bagNum: number;
}

export interface GetFGQAPresignedUrlParams {
    batchId: string;
    fileName: string;
}

export interface FGBatchInfo extends CreateFGBatchParam, FGQAAnalysisResult, FGUnloadDetails {
    batchId: string;
    inputDateTime: string;
    drNum: string;
    containerNum: string;
    qaCheckedBy: string;
    qaNotedBy: string;
    qaAnalysisResult: string;
    qaAnalysisResultTime: string;
    truckInspectionInfo?: FGTruckInspectionInfo;
    customerName: string;
}

export interface FGStockOrder {
    batchId: string;
    prodCode: string;
    issueOrder: string;
    customerName: string;
    weight: number;
    bagNum: number;
    inputDateTime: string;
    truckInspectionInfo: FGTruckInspectionInfo;
    dispatchApprovalInfo: {
        dispatchApprovedBy: string;
        dispatchApprovalDatetime: string;
        dispatchApproval: string;
    };
    dispatchInfo: {
        deliveryDate: string;
        drNum: string;
    };
}

export interface FGUnloadDetails {
    warehouseId: string;
    locationId: string;
    unloadedBy: string;
    unloadingDatetime: string;
}

export interface FGWarehouseLocationInfo {
    warehouseId: string;
    locationId: string;
    netWeight: number;
    fgAssignment: string;
    weightCapacity: number;
}

export interface FGWarehouseLocationStock {
    batchId: string;
    prodCode: string;
    netWeight: number;
    bagNum: number;
    warehouseId: string;
    warehouseLocId: string;
    inputDateTime: string;
}

export interface FinishedGoodsInfoState {
    transactions: Array<TransferTransactionInfo>;
    fgBatch: Array<FGBatchInfo>;
    qaAnalysis: FGQAAnalysisInfo;
    wahouseLocation: Array<FGWarehouseLocationInfo>;
    stockOrder: Array<FGStockOrder>;
    warehouseLocationStock: Array<FGWarehouseLocationStock>;
}

export interface FinishedGoodsReducerType {
    finishedGoods: FinishedGoodsInfoState;
}

/* 01_Create new FG batch */
export const CREATE_FG_BATCH = 'CREATE_FG_BATCH';
/* 02_Add QA sample analysis */
export const ADD_FG_QA_SAMPLE_ANALYSIS = 'ADD_FG_QA_SAMPLE_ANALYSIS';
/* 03_Add QA analysis result of FG batch */
export const ADD_FG_QA_ANALYSIS_RESULT = 'ADD_FG_QA_ANALYSIS_RESULT';
/* 04_Add FG Batch unloading details */
export const ADD_FG_UNLOADING_DETAILS = 'ADD_FG_UNLOADING_DETAILS';
/* 05_Create FG order */
export const ADD_ORDER_INFO = 'ADD_ORDER_INFO';
/* 06_Add truck loading inspection details to FG batch */
export const PATCH_TRUCK_CLEANLINESS_INSPECTION = 'PATCH_TRUCK_CLEANLINESS_INSPECTION';
/* 07_Approve FG order for dispatch */
export const APPROVE_FG_ORDER_FOR_DISPATCH = 'APPROVE_FG_ORDER_FOR_DISPATCH';
/* 08_Dispatch FG order */
export const DISPATCH_FG_ORDER = 'DISPATCH_FG_ORDER';
export const GET_FG_ORDER = 'GET_FG_ORDER';
export const GET_RM_STOCK_TRANSFER_TRANSACTION = 'GET_RM_STOCK_TRANSFER_TRANSACTION';
export const CLEAR_STOCK_TRANSFER_TRANSACTION = 'CLEAR_STOCK_TRANSFER_TRANSACTION';
export const GET_FG_BATCH_PER_LINE = 'GET_FG_BATCH_PER_LINE';
export const GET_FG_QA_ANALYSIS = 'GET_FG_QA_ANALYSIS';
export const GET_FG_WAREHOUSE_LOCATION = 'GET_FG_WAREHOUSE_LOCATION';
export const GET_FG_STOCK_BY_WAREHOUSE_LOCATION = 'GET_FG_STOCK_BY_WAREHOUSE_LOCATION';
export const DELETE_FG_BATCH_BY_ID = 'DELETE_FG_BATCH_BY_ID';


interface GetRmStockTransferTransactionAction {
    type: typeof GET_RM_STOCK_TRANSFER_TRANSACTION;
    payload: Array<TransferTransactionInfo>;
}

interface ClearStockTransferTransactionAction {
    type: typeof CLEAR_STOCK_TRANSFER_TRANSACTION;
    payload: [];
}

interface GetFgBatchPerLine {
    type: typeof GET_FG_BATCH_PER_LINE;
    payload: Array<FGBatchInfo>;
}

interface PatchTruckCleanlinessInspection {
    type: typeof PATCH_TRUCK_CLEANLINESS_INSPECTION;
    payload: [];
}

interface GetFGQAAnalysisAction {
    type: typeof GET_FG_QA_ANALYSIS;
    payload: FGQAAnalysisInfo;
}

interface AddOrderInfoAction {
    type: typeof ADD_ORDER_INFO;
    payload: null;
}

interface GetWarehouseLocationAction {
    type: typeof GET_FG_WAREHOUSE_LOCATION;
    payload: Array<FGWarehouseLocationInfo>;
}

interface GetFGStockByWarehouseLocationAction {
    type: typeof GET_FG_STOCK_BY_WAREHOUSE_LOCATION;
    payload: Array<FGWarehouseLocationStock>;
}

interface GetFGStockOrderAction {
    type: typeof GET_FG_ORDER;
    payload: Array<FGStockOrder>;
}

export type FinishedGoodsActionTypes =
    | GetRmStockTransferTransactionAction
    | ClearStockTransferTransactionAction
    | GetFgBatchPerLine
    | PatchTruckCleanlinessInspection
    | GetFGQAAnalysisAction
    | AddOrderInfoAction
    | GetWarehouseLocationAction
    | GetFGStockByWarehouseLocationAction
    | GetFGStockOrderAction
    | LogoutAction;
