import * as URL_CONST from 'utils/urls';

import { Box, Button, Container, CssBaseline, TextField, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';

import AppContext from 'components/app-context';
import CircularProgress from '@material-ui/core/CircularProgress';
import Copyright from 'components/common/Copyright';
import { INTERNAL_ERROR } from 'utils/strings';
import { RouterNavigation } from 'utils/navigation';
import { getError } from 'utils/validation/validation';
import { makeStyles } from '@material-ui/core/styles';

const UpdatePasswordPage = (props: any) => {
    const classes = useStyles();
    const { app } = useContext(AppContext);

    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [displayError, setDisplayError] = useState('');

    useEffect(() => {
        if (isLoggingIn) {
            processLogin();
        }
    }, [isLoggingIn]); // eslint-disable-line

    const login = () => {
        if (confirmPassword !== newPassword) {
            setDisplayError('Password mismatch');
        } else {
            setIsLoggingIn(true);
        }
    };

    const processLogin = () => {
        const { data } = props.location.state;
        app.newPassword({
            ...data,
            NewPassword: newPassword,
        })
            .then(() => {
                RouterNavigation.navigateTo(URL_CONST.MAIN);
            })
            .catch((error) => {
                setIsLoggingIn(false);
                if (error.response && error.response.status) {
                    setDisplayError(getError(error.response.status, error.response.data.Message.Reason));
                } else {
                    setDisplayError(INTERNAL_ERROR);
                }
            });
    };

    const passwordRef = React.createRef<HTMLDivElement>();

    return (
        <Container component='main' maxWidth='xs'>
            <CssBaseline />
            <div className={classes.paper}>
                <Typography component='h1' variant='h5'>
                    Update your password
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        name='new password'
                        label='New Password'
                        type='password'
                        id='password'
                        autoComplete='current-password'
                        onChange={(event) => {
                            setNewPassword(event.target.value);
                        }}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter' && passwordRef.current) {
                                passwordRef.current.focus();
                            }
                        }}
                    />
                    <TextField
                        variant='outlined'
                        margin='normal'
                        required
                        fullWidth
                        name='password'
                        label='Confirm Password'
                        type='password'
                        id='password'
                        autoComplete='current-password'
                        onChange={(event) => {
                            setConfirmPassword(event.target.value);
                        }}
                        onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                                login();
                            }
                        }}
                    />
                    {displayError !== '' && (
                        <Typography style={{ fontSize: 12, color: 'red', fontFamily: 'arial' }}>
                            {displayError}
                        </Typography>
                    )}
                    <Typography style={{ fontSize: 12, color: 'gray', fontFamily: 'arial' }}>
                        Password must be at least 8 characters long, contains at least one uppercase, lowercase, number
                        and special character
                    </Typography>
                    <Button
                        fullWidth
                        variant='contained'
                        color={isLoggingIn ? 'default' : 'primary'}
                        className={classes.submit}
                        onClick={login}>
                        {isLoggingIn ? <CircularProgress size='24px' /> : 'Update Password'}
                    </Button>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
};

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        height: 180,
        width: 180,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default UpdatePasswordPage;
