import "react-big-calendar/lib/css/react-big-calendar.css";

import {
    AppBar,
    Grid,
    IconButton,
    Paper,
    TextField,
    Theme,
    Toolbar,
    Tooltip,
    Typography,
    WithStyles,
    createStyles,
    withStyles,
} from "@material-ui/core";
import { PurchaseOrderInfo, PurchaseOrderInfoList } from "redux/reducers/purchasing/types";
import React, { Fragment, useEffect, useState } from "react";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import PurchaseOrderDetails from "./PurchaseOrderDetails";
import PurchaseOrderStatusTable from "./PurchaseOrderStatusTable";
import SearchIcon from "@material-ui/icons/Search";
import { getAllPurchaseOrdersAction } from "redux/reducers/purchasing/actions";
import { purchasingTypedSelector } from "redux/reducers/purchasing/purchasingReducer";
import { useDispatch } from "react-redux";

export interface ContentProps extends WithStyles<typeof styles> {}

export interface DisplayDelivery {
    scheduleId?: string;
    title: string;
    start: Date;
    end: Date;
}

const viewState = {
    purchaseOrderListView: "orderListView",
    purchaseOrderDetailsView: "detailsView",
};

let _gPurchaseOrderList: Array<PurchaseOrderInfo> | [] = [];

const PurchaseOrderStatusMain = (props: ContentProps) => {
    const { classes } = props;
    const [filterText, setfilterText] = useState("");
    const [view, setView] = useState(viewState.purchaseOrderListView);
    const [clickedOrderId, setClickedOrderId] = useState("");
    const [clickedOrderDetails, setClickedOrderDetails] = useState<PurchaseOrderInfo | undefined>(undefined);
    const dispatcher = useDispatch();
    const [isAcquired, setIsAcquired] = useState(false);
    const [purchaseOrderList, setPurchaseOrderList] = useState<Array<PurchaseOrderInfo> | []>([]);
    const purchaseOrderInfoResponse = purchasingTypedSelector<PurchaseOrderInfoList>(
        (state) => state.purchasing.purchaseOrders
    );

    const isView = (checkView: string) => {
        return view === checkView;
    };

    useEffect(() => {
        if (purchaseOrderInfoResponse.ordersList.length > 0) {
            setPurchaseOrderList(purchaseOrderInfoResponse.ordersList);
            _gPurchaseOrderList = purchaseOrderInfoResponse.ordersList;
        } else if (!isAcquired) {
            setIsAcquired(true);
            dispatcher(getAllPurchaseOrdersAction());
        }
    }, [purchaseOrderInfoResponse, isAcquired, dispatcher]);

    const onClickedOrderId = (orderId: string) => {
        setClickedOrderId(orderId);
        setClickedOrderDetails(_gPurchaseOrderList.find((data) => data.orderId === orderId));
        setView(viewState.purchaseOrderDetailsView);
    };

    const revertToListView = () => {
        setTimeout(() => {
            dispatcher(getAllPurchaseOrdersAction());
        }, 1000);

        setClickedOrderId("");
        setClickedOrderDetails(undefined);
        setView(viewState.purchaseOrderListView);
    };

    const renderPurchaseOrderTableSearch = () => {
        return (
            <Fragment>
                <Grid item>
                    <SearchIcon className={classes.block} color="inherit" />
                </Grid>
                <Grid item xs>
                    <TextField
                        fullWidth
                        placeholder="Search"
                        InputProps={{
                            disableUnderline: true,
                            className: classes.searchInput,
                        }}
                        onChange={(event) => setfilterText(event.target.value)}
                    />
                </Grid>
            </Fragment>
        );
    };

    const renderPurchaseOrderTable = () => {
        return (
            <div className={classes.contentWrapper}>
                <PurchaseOrderStatusTable
                    filterText={filterText}
                    onClickedOrderNo={onClickedOrderId}
                    purchaseOrderList={purchaseOrderList}
                />
                ;
            </div>
        );
    };

    const renderPurchaseOrderDetailsContainer = () => {
        return (
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Tooltip title="Return / Cancel">
                        <IconButton onClick={revertToListView}>
                            <ArrowBackIosIcon className={classes.block} color="inherit" />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    {clickedOrderId && (
                        <Typography color="primary" variant="h5" component="h1">
                            {`Order # ${clickedOrderId} `}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        );
    };

    const renderPurchaseOrderDetails = () => {
        return (
            <div className={classes.contentWrapper}>
                <PurchaseOrderDetails clickedOrderDetails={clickedOrderDetails} />
            </div>
        );
    };

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>
                <Toolbar>
                    {isView(viewState.purchaseOrderListView) && renderPurchaseOrderTableSearch()}
                    {isView(viewState.purchaseOrderDetailsView) && renderPurchaseOrderDetailsContainer()}
                </Toolbar>
            </AppBar>
            {isView(viewState.purchaseOrderListView) && renderPurchaseOrderTable()}
            {isView(viewState.purchaseOrderDetailsView) && clickedOrderDetails && renderPurchaseOrderDetails()}
        </Paper>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: "99%",
            margin: "auto",
            overflow: "hidden",
            alignContent: "center",
        },
        searchBar: {
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: "block",
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: "rgb(31,49,69)",
            alignSelf: "center",
        },
        contentWrapper: {
            margin: "0px 0px",
            height: "600px",
        },
        spacedGrid: {
            minWidth: "125px",
            marginLeft: "12px",
        },
    });

export default withStyles(styles)(PurchaseOrderStatusMain);
