import React, { Fragment } from "react";

import { LoggedUser } from "utils/loggedUser";
import TabsAppBar from "components/appbar/TabsAppBar";
import TitleAppBar from "components/appbar/TitleAppBar";
import UserAppBar from "components/appbar/UserAppBar";

interface DashboardHeaderProps {
    onDrawerToggle: () => void;
    onTabsChange: (id: number) => void;
    tabsTitle?: Array<{ id: string }>;
    selectedTab?: number;
}

const DashboardHeader = (props: DashboardHeaderProps) => {
    const { onDrawerToggle, onTabsChange, tabsTitle, selectedTab } = props;
    const userFullName = LoggedUser.getFullName();
    const role = LoggedUser.getRole();

    return (
        <Fragment>
            <UserAppBar username={`${userFullName}`} role={role} onDrawerToggle={onDrawerToggle} />
            {tabsTitle && <TitleAppBar title="Dashboard" />}
            {tabsTitle && <TabsAppBar tabs={tabsTitle} onTabsChange={onTabsChange} selectedTab={selectedTab} />}
        </Fragment>
    );
};

export default DashboardHeader;
