import React, { Fragment, useState } from 'react';

import CanvassSheetListView from './CanvassSheet/CanvassSheetListView';
import PurchaseOrderListView from './PurchaseOrder/PurchaseOrderListView';
import PurchaseRequestListView from './PurchaseRequest/PurchaseRequestListView';
import PurchasingHeader from './PurchasingHeader';
import SupplierListView from './Suppliers/SupplierListView';
import { Typography } from '@material-ui/core';
import { RoleFeatures } from 'redux/reducers/users/types';
import { LoggedUser } from 'utils/loggedUser';


export interface UserListMainProps {
    onDrawerToggle: () => void;
    mainClass: any;
}

const PurchasingMain = (props: UserListMainProps) => {
    const TAB1_TITLE = 'Purchase Requisition';
    const TAB2_TITLE = 'Canvass Sheet';
    const TAB3_TITLE = 'Purchase Order';
    const TAB4_TITLE = 'Suppliers';
    const tabsTitle = [
        { id: TAB1_TITLE, featureRole: RoleFeatures.PROC_PR },
        { id: TAB2_TITLE, featureRole: RoleFeatures.PROC_CANVASS_SHEET },
        { id: TAB3_TITLE, featureRole: RoleFeatures.PROC_PURCHASE_ORDER },
        { id: TAB4_TITLE, featureRole: RoleFeatures.PROC_SUPPLIER_LIST }
    ];

    const [currentTab, setCurrentTab] = useState(0);
    const onTabsChange = (id: number) => {
        setCurrentTab(id);
    };

    const isAccessAllowed = (featureRole: RoleFeatures) => {
        let userRoleFeatures = LoggedUser.getFeatureAccessPermission();
        if (userRoleFeatures.includes(RoleFeatures.ALL) || userRoleFeatures.includes(featureRole)) {
            return true;
        }
        return false;
    }

    return (
        <Fragment>
            <PurchasingHeader onDrawerToggle={props.onDrawerToggle} onTabsChange={onTabsChange} tabsTitle={tabsTitle} />
            <main className={props.mainClass}>
                {tabsTitle[currentTab].id === TAB1_TITLE && isAccessAllowed(tabsTitle[currentTab].featureRole) && (
                    <Fragment>
                        <Typography style={{ paddingBottom: 10 }} color='inherit' variant='h5' component='h1'>
                            {TAB1_TITLE}
                        </Typography>
                        <PurchaseRequestListView />
                    </Fragment>
                )}
                {tabsTitle[currentTab].id === TAB2_TITLE && isAccessAllowed(tabsTitle[currentTab].featureRole) && (
                    <Fragment>
                        <Typography style={{ paddingBottom: 10 }} color='inherit' variant='h5' component='h1'>
                            {TAB2_TITLE}
                        </Typography>
                        <CanvassSheetListView />
                    </Fragment>
                )}
                {tabsTitle[currentTab].id === TAB3_TITLE && isAccessAllowed(tabsTitle[currentTab].featureRole) && (
                    <Fragment>
                        <Typography style={{ paddingBottom: 10 }} color='inherit' variant='h5' component='h1'>
                            {TAB3_TITLE}
                        </Typography>
                        <PurchaseOrderListView />
                    </Fragment>
                )}

                {tabsTitle[currentTab].id === TAB4_TITLE && isAccessAllowed(tabsTitle[currentTab].featureRole) && (
                    <Fragment>
                        <Typography style={{ paddingBottom: 10 }} color='inherit' variant='h5' component='h1'>
                            {TAB4_TITLE}
                        </Typography>
                        <SupplierListView />
                    </Fragment>
                )}
            </main>
        </Fragment>
    );
};

export default PurchasingMain;
