import {
    AppBar,
    CircularProgress,
    Grid,
    IconButton,
    Paper,
    TextField,
    Theme,
    Toolbar,
    Tooltip,
    Typography,
    WithStyles,
    createStyles,
    withStyles,
} from '@material-ui/core';
import { Canvass, CanvassItem } from 'redux/reducers/canvass/types';
import React, { Fragment, useEffect, useState } from 'react';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CanvassSheetDetailsView from './CanvassSheetDetailsView';
import CanvassSheetListTable from './CanvassSheetListTable';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import { canvassTypedSelector } from 'redux/reducers/canvass/canvassReducer';
import { getMultipleCanvassAction, deleteCanvassByIDAction } from 'redux/reducers/canvass/actions';

import { useDispatch } from 'react-redux';

export interface ContentProps extends WithStyles<typeof styles> {}

const viewState = {
    canvassListView: 'canvassListView',
    canvassDetailsView: 'canvassDetailsView',
};

const CanvassSheetListView = (props: ContentProps) => {
    const { classes } = props;
    const [filterText, setfilterText] = useState('');
    const [canvassList, setCanvassList] = useState<Array<Canvass> | []>([]);

    const [clickedCanvassId, setClickedCanvassId] = useState('');
    const [onReload, setOnReload] = useState(false);
    const [isAcquired, setIsAcquired] = useState(false);
    const [view, setView] = useState(viewState.canvassListView);

    const [inputCanvassListItems, setCanvassListItems] = useState<Array<CanvassItem>>([]);
    const [inputPreparedBy, setinputPreparedBy] = useState('');
    const [inputApprovedBy, setinputApprovedBy] = useState('');
    const dispatcher = useDispatch();

    const canvassSheetsList = canvassTypedSelector<Array<Canvass>>((state) => state.canvass.canvassSheetsList);

    const isView = (checkView: string) => {
        return view === checkView;
    };

    useEffect(() => {
        if (canvassSheetsList.length > 0) {
            setCanvassList(canvassSheetsList);
        } else if (!isAcquired) {
            setIsAcquired(true);
            dispatcher(getMultipleCanvassAction(''));
        }
    }, [canvassSheetsList, isAcquired, dispatcher]);

    useEffect(() => {
        if (!!!clickedCanvassId) {
            setCanvassListItems([]);
            setinputPreparedBy('');
            setinputApprovedBy('');
            return;
        }

        const list = canvassSheetsList.filter((c) => c.requestId === clickedCanvassId);
        if (list.length > 0) {
            const itemList = list[0].itemsList.map((i) => ({
                ...i,
                itemCanvassApprovedStatus: i.itemCanvassApproved ? 'Approved' : 'Not Approved',
            }));
            setCanvassListItems(itemList);
            setinputPreparedBy(list[0].preparedBy);
            setinputApprovedBy(list[0].approvedBy ? list[0].approvedBy : '');
        }
    }, [clickedCanvassId, canvassSheetsList]);

    useEffect(() => {
        if (canvassList.length > 0) {
            setTimeout(() => {
                setOnReload(false);
            }, 500);
        }
    }, [canvassList]);

    const onClickedCanvass = (id: string) => {
        setClickedCanvassId(id);
        setView(viewState.canvassDetailsView);
    };

    const revertToListView = () => {
        setTimeout(() => {
            dispatcher(getMultipleCanvassAction(''));
        }, 1000);
        setClickedCanvassId('');
        setView(viewState.canvassListView);
    };

    const fetchAndReloadList = () => {
        setCanvassList([]);
        setOnReload(true);
        dispatcher(getMultipleCanvassAction(''));
    }

    const renderCanvassListTableButton = () => {
        return (
            <Grid item>
                <Tooltip title='Create Canvass'>
                    <IconButton onClick={() => setView(viewState.canvassDetailsView)}>
                        <NoteAddIcon className={classes.block} color='inherit' />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Reload'>
                    <IconButton
                        onClick={fetchAndReloadList}>
                        {onReload ? (
                            <CircularProgress size='24px' />
                        ) : (
                            <RefreshIcon className={classes.block} color='inherit' />
                        )}
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    };

    const renderCanvassListTableSearch = () => {
        return (
            <Fragment>
                <Grid item>
                    <SearchIcon className={classes.block} color='inherit' />
                </Grid>
                <Grid item xs>
                    <TextField
                        fullWidth
                        placeholder='Search'
                        InputProps={{
                            disableUnderline: true,
                            className: classes.searchInput,
                        }}
                        onChange={(event) => setfilterText(event.target.value)}
                    />
                </Grid>
            </Fragment>
        );
    };

    const renderCanvassListTableContainer = () => {
        return (
            <Grid container spacing={2} alignItems='center'>
                {renderCanvassListTableSearch()}
                {renderCanvassListTableButton()}
            </Grid>
        );
    };

    const onDeleteCanvass = (id: string) => {
        dispatcher(deleteCanvassByIDAction(id))
        return true;
    }

    const renderCanvassListTable = () => {
        return (
            <div className={classes.contentWrapper}>
                <CanvassSheetListTable
                    onClickedCanvass={onClickedCanvass}
                    onDeleteCanvass={onDeleteCanvass}
                    canvassList={canvassList}
                    filterText={filterText}
                />
            </div>
        );
    };

    const renderCanvassDetail = () => {
        return (
            <div className={classes.contentWrapper}>
                <CanvassSheetDetailsView
                    clickedCanvassID={clickedCanvassId}
                    onCancel={revertToListView}
                    inputCanvassListItems={inputCanvassListItems}
                    inputPreparedBy={inputPreparedBy}
                    inputApprovedBy={inputApprovedBy}
                />
            </div>
        );
    };

    const renderCanvassDetailsContainer = () => {
        return (
            <Grid container spacing={2} alignItems='center'>
                <Grid item>
                    <Tooltip title='Return / Cancel'>
                        <IconButton onClick={revertToListView}>
                            <ArrowBackIosIcon className={classes.block} color='inherit' />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Typography color='primary' variant='h5' component='h1'>
                        {!!!clickedCanvassId ? `Create Canvass` : `Canvass # ${clickedCanvassId} `}
                    </Typography>
                </Grid>
            </Grid>
        );
    };

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.searchBar} position='static' color='default' elevation={0}>
                <Toolbar>
                    {isView(viewState.canvassListView) && renderCanvassListTableContainer()}
                    {isView(viewState.canvassDetailsView) && renderCanvassDetailsContainer()}
                </Toolbar>
            </AppBar>
            {isView(viewState.canvassListView) && renderCanvassListTable()}
            {isView(viewState.canvassDetailsView) && renderCanvassDetail()}
        </Paper>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: '99%',
            margin: 'auto',
            overflow: 'hidden',
            alignContent: 'center',
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: 'rgb(31,49,69)',
            alignSelf: 'center',
        },
        contentWrapper: {
            margin: '0px 0px',
        },
        rightButton: {
            float: 'right',
        },
    });

export default withStyles(styles)(CanvassSheetListView);
