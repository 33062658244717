import { LogoutAction } from "redux/reducers/rootReducer/types";

export interface PremixMaterialInfo {
    rmCode: string;
    rmWeight: number;
    rmUsed: string;
}

export interface FormulaValuePair {
    name: string;
    value: number;
}

export interface RawMaterialInProductionOrderInfo {
    rmCode: string;
    rmWeight: number;
    rmTotPrep?: string;
    rmDatesChecked?: Array<string>;
}

export interface PremixInProductionOrderInfo {
    premixName: string;
    rawMaterials: Array<RawMaterialInProductionOrderInfo>;
    rmSubtotal: number;
}

export interface DateTimePair {
    date: string;
    value: string;
}

export interface DateBatchNumberPair {
    date: string;
    value: number;
}

export interface ProductionOrderInfo {
    prodOrderId: string;
    feedType: string;
    line: string;
    die?: string;
    date: string;
    premixChecker: string;
    shift?: string;
    preparedBy: string;
    datePrepared: string;
    receivedBy?: string;
    dateReceived?: string;
    formulationInput: Array<FormulaValuePair>;
    premixes: Array<PremixInProductionOrderInfo>;
    rmDateTime?: Array<DateTimePair>;
    batchNumber?: Array<DateBatchNumberPair>;
    createDate: string;
    updateDate: string;
    rmTotalWeight: number;
    revisionNumber: number;
}

export interface ProductionOrderInfoRequest {
    feedType?: string;
    line?: string;
    die?: string;
    date: string;
    premixChecker: string;
    shift?: string;
    preparedBy?: string;
    datePrepared?: string;
    receivedBy?: string;
    dateReceived?: string;
    formulationInput?: Array<FormulaValuePair>;
    premixes?: Array<PremixInProductionOrderInfo>;
    rmDateTime?: Array<DateTimePair>;
    batchNumber?: Array<DateBatchNumberPair>;
    rmTotalWeight?: number;
    revisionNumber?: number;
}

export interface PremixInfo {
    premixName: string;
    feedType: string;
    line: string;
    datePrepared: string;
    rawMaterials: Array<PremixMaterialInfo>;
    preparedBy: string;
    checkedBy: string;
    notedBy: string;
    createDate: string;
    updateDate: string;
    rmTotalWeight: number;
    revisionNumber: number;
}

export interface PremixInfoRequest {
    premixName?: string;
    feedType?: string;
    line?: string;
    datePrepared?: string;
    rawMaterials?: Array<PremixMaterialInfo>;
    preparedBy?: string;
    checkedBy?: string;
    notedBy?: string;
    rmTotalWeight?: number;
    revisionNumber?: number;
}

export interface PremixInfoList {
    premixes: Array<PremixInfo>;
    LastEvaluatedKey: null;
}

export interface ProductionOrderInfoList {
    prodOrders: Array<ProductionOrderInfo>;
    LastEvaluatedKey: null;
}

export interface PremixInfoState {
    premixList: PremixInfoList;
    productionOrderList: ProductionOrderInfoList;
}

export interface PremixReducerType {
    premix: PremixInfoState;
}

export const GET_All_PREMIX = "GET_All_PREMIX";
export const GET_All_PRODUCTION_ORDER = "GET_All_PRODUCTION_ORDER";
export const ADD_PREMIX_TEMPLATE = "ADD_PREMIX_TEMPLATE";
export const ADD_PRODUCTION_ORDER = "ADD_PRODUCTION_ORDER";
export const PATCH_PREMIX_INFO = "PATCH_PREMIX_INFO";
export const PATCH_PRODUCTION_ORDER_INFO = "PATCH_PRODUCTION_ORDER_INFO";
export const DELETE_PREMIX_TEMPLATE = "DELETE_PREMIX_TEMPLATE";
export const DELETE_PRODUCTION_ORDER = "DELETE_PRODUCTION_ORDER";

interface GetAllPremix {
    type: typeof GET_All_PREMIX;
    payload: PremixInfoList;
}

interface GetAllProductionOrder {
    type: typeof GET_All_PRODUCTION_ORDER;
    payload: ProductionOrderInfoList;
}

export type PremixActionTypes = GetAllPremix | GetAllProductionOrder | LogoutAction;
