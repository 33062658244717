import React, { Fragment, useState } from "react";

import DeliveryTrackerHeader from "./DeliveryTrackerHeader";
import PurchaseOrderStatusMain from "./PurchaseOrderStatus/PurchaseOrderStatusMain";
import { Typography } from "@material-ui/core";

export interface DeliveryTrackerMainProps {
    onDrawerToggle: () => void;
    mainClass: any;
}

const DeliveryTrackerMain = (props: DeliveryTrackerMainProps) => {
    const TAB1_TITLE = "Scheduled Deliveries";
    const tabsTitle = [{ id: TAB1_TITLE }];

    const [currentTab, setCurrentTab] = useState(0);
    const onTabsChange = (id: number) => {
        setCurrentTab(id);
    };

    return (
        <Fragment>
            <DeliveryTrackerHeader
                onDrawerToggle={props.onDrawerToggle}
                onTabsChange={onTabsChange}
                tabsTitle={tabsTitle}
            />
            <main className={props.mainClass}>
                {tabsTitle[currentTab].id === TAB1_TITLE && (
                    <Fragment>
                        <Typography style={{ paddingBottom: 10 }} color="inherit" variant="h5" component="h1">
                            {TAB1_TITLE}
                        </Typography>
                        <PurchaseOrderStatusMain />
                    </Fragment>
                )}
            </main>
        </Fragment>
    );
};

export default DeliveryTrackerMain;
