import { Button, Container, Grid, TextField, Toolbar } from '@material-ui/core';
import { LocationInfo, RawMaterial } from 'redux/reducers/rmShipment/types';
import React, { Fragment, useEffect, useState } from 'react';
import { StockRelocationInfo, StockRelocationResponse } from 'redux/reducers/rmStocks/types';
import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import {
    clearRelocateAction,
    getWarehouseLocationStocksAction,
    relocateRMWarehouseAction,
} from 'redux/reducers/rmStocks/actions';

import SelectComponent from 'components/common/Select';
import { rmShipmentTypedSelector } from 'redux/reducers/rmShipment/rmShipmentReducer';
import { stocksTypedSelector } from 'redux/reducers/rmStocks/stocksReducer';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

export interface ContentProps extends WithStyles<typeof styles> {
    pageReload: boolean;
    onPageReload: () => void;
}

const WarehouseRelocationView = (props: ContentProps) => {
    const { classes } = props;
    const dispatcher = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const warehouseInfo = rmShipmentTypedSelector<Array<LocationInfo>>((state) => state.rmshipment.warehouseInfo);
    const rmList = rmShipmentTypedSelector<Array<RawMaterial>>((state) => state.rmshipment.rmList);
    const relocationStatus = stocksTypedSelector<StockRelocationResponse>((state) => state.stocks.relocationStatus);

    const [srcWarehouse, setSrcWarehouse] = useState('');
    const [srcLocation, setSrcLocation] = useState('');
    const [dstWarehouse, setDstWarehouse] = useState('');
    const [dstLocation, setDstLocation] = useState('');
    const [relocatedWeight, setRelocatedWeight] = useState(0);
    const [rmName, setRmName] = useState('');

    const [srcWarehouseErr, setSrcWarehouseErr] = useState(false);
    const [srcLocationErr, setSrcLocationErr] = useState(false);
    const [dstWarehouseErr, setDstWarehouseErr] = useState(false);
    const [dstLocationErr, setDstLocationErr] = useState(false);
    const [relocatedWeightErr, setRelocatedWeightErr] = useState(false);
    const [rmNameErr, setRmNameErr] = useState(false);

    const itemList: { [id: string]: string } = {
        srcWarehouse: 'From (Warehouse)',
        srcLocation: 'From (Location)',
        dstWarehouse: 'To (Warehouse)',
        dstLocation: 'To (Location)',
        weight: 'Weight',
        rmName: 'Raw Material',
    };

    useEffect(() => {
        if (relocationStatus.rmCode) {
            enqueueSnackbar(
                `Successfully relocated ${relocationStatus.relocatedWeight}Kg of ${relocationStatus.rmCode} to ${
                    relocationStatus.dstWarehouse
                }-${relocationStatus.dstLocation}`,
                { variant: 'success' }
            );
        }
    }, [relocationStatus, enqueueSnackbar]);

    const getWarehouseList = () => {
        return [...new Set(warehouseInfo.map((warehouse) => warehouse.warehouseId))];
    };

    const getLocationList = (warehouse: string) => {
        return warehouseInfo.filter((info) => info.warehouseId === warehouse).map((info) => info.locationId);
    };

    const fromWarehouseNameSelect = () => {
        const warehouseList: Array<string> = getWarehouseList();
        return (
            <Fragment>
                <Grid item xs>
                    <SelectComponent
                        title={itemList.srcWarehouse}
                        selectList={warehouseList}
                        setter={(obj) => {
                            setSrcWarehouse(obj);
                            setInvalidity(itemList.srcWarehouse, false);
                        }}
                        currentValue={srcWarehouse}
                        error={srcWarehouseErr}
                    />
                </Grid>
            </Fragment>
        );
    };

    const handleFromLocationChange = (event: any) => {
        setSrcLocation(event as string);
        setTimeout(() => {
            dispatcher(getWarehouseLocationStocksAction(srcWarehouse, event as string));
        }, 100);
    };

    const fromLocationSelect = () => {
        return (
            <Fragment>
                <Grid item xs>
                    <SelectComponent
                        title={itemList.srcLocation}
                        selectList={getLocationList(srcWarehouse)}
                        setter={(obj) => {
                            handleFromLocationChange(obj);
                            setInvalidity(itemList.srcLocation, false);
                        }}
                        currentValue={srcLocation}
                        error={srcLocationErr}
                    />
                </Grid>
            </Fragment>
        );
    };

    const toWarehouseNameSelect = () => {
        const warehouseList: Array<string> = getWarehouseList();
        return (
            <Fragment>
                <Grid item xs>
                    <SelectComponent
                        title={itemList.dstWarehouse}
                        selectList={warehouseList}
                        setter={(obj) => {
                            setDstWarehouse(obj);
                            setInvalidity(itemList.dstWarehouse, false);
                        }}
                        currentValue={dstWarehouse}
                        error={dstWarehouseErr}
                    />
                </Grid>
            </Fragment>
        );
    };

    const toLocationSelect = () => {
        return (
            <Fragment>
                <Grid item xs>
                    <SelectComponent
                        title={itemList.dstLocation}
                        selectList={getLocationList(dstWarehouse)}
                        setter={(obj) => {
                            setDstLocation(obj);
                            setInvalidity(itemList.dstLocation, false);
                        }}
                        currentValue={dstLocation}
                        error={dstLocationErr}
                    />
                </Grid>
            </Fragment>
        );
    };

    const rawMaterialNameSelect = () => {
        return (
            <Fragment>
                <Grid item xs>
                    <SelectComponent
                        title={itemList.rmName}
                        selectList={rmList.map((info) => info.rmCode)}
                        setter={(obj) => {
                            setRmName(obj);
                            setInvalidity(itemList.rmName, false);
                        }}
                        currentValue={rmName}
                        error={rmNameErr}
                    />
                </Grid>
            </Fragment>
        );
    };

    const weightInput = () => {
        return (
            <Fragment>
                <Grid item xs>
                    <TextField
                        fullWidth
                        margin='dense'
                        type={'number'}
                        label={itemList.weight}
                        value={relocatedWeight}
                        onChange={(event) => {
                            setRelocatedWeight(parseInt(event.target.value as any));
                            setInvalidity(itemList.weight, false);
                        }}
                        error={relocatedWeightErr}
                    />
                </Grid>
            </Fragment>
        );
    };

    const renderFrom = () => {
        return (
            <Grid container spacing={2} alignItems='center'>
                {fromWarehouseNameSelect()}
                {fromLocationSelect()}
            </Grid>
        );
    };

    const renderTo = () => {
        return (
            <Grid container spacing={2} alignItems='center'>
                {toWarehouseNameSelect()}
                {toLocationSelect()}
            </Grid>
        );
    };

    const renderRMDetails = () => {
        return (
            <Grid container spacing={2} alignItems='center'>
                {rawMaterialNameSelect()}
                {weightInput()}
            </Grid>
        );
    };

    const setInvalidity = (item: string, isInvalid: boolean): boolean => {
        const mapper: { [id: string]: React.Dispatch<React.SetStateAction<boolean>> } = {
            'From (Warehouse)': setSrcWarehouseErr,
            'From (Location)': setSrcLocationErr,
            'To (Warehouse)': setDstWarehouseErr,
            'To (Location)': setDstLocationErr,
            Weight: setRelocatedWeightErr,
            'Raw Material': setRmNameErr,
        };
        mapper[item](isInvalid);
        return isInvalid;
    };

    const isInputInvalid = () => {
        let invalid = false;
        invalid = setInvalidity(itemList.srcWarehouse, !!!srcWarehouse) || invalid;
        invalid = setInvalidity(itemList.srcLocation, !!!srcLocation) || invalid;
        invalid = setInvalidity(itemList.dstWarehouse, !!!dstWarehouse) || invalid;
        invalid = setInvalidity(itemList.dstLocation, !!!dstLocation) || invalid;
        invalid = setInvalidity(itemList.rmName, !!!rmName) || invalid;
        invalid = setInvalidity(itemList.weight, relocatedWeight <= 0) || invalid;

        if (invalid) {
            enqueueSnackbar(`Request not sent. Please update invalid inputs`, { variant: 'error' });
        } else if (srcWarehouse === dstWarehouse && srcLocation === dstLocation) {
            invalid = true;
            setSrcLocationErr(true);
            setDstLocationErr(true);
            enqueueSnackbar(`From and To Location is same`, { variant: 'error' });
        }
        return invalid;
    };

    const clearInputs = () => {
        setSrcWarehouse('');
        setSrcLocation('');
        setDstWarehouse('');
        setDstLocation('');
        setRelocatedWeight(0);
        setRmName('');
    };

    const relocate = () => {
        dispatcher(clearRelocateAction());
        if (isInputInvalid()) {
            return;
        }

        const relocationInfo: StockRelocationInfo = {
            srcWarehouse,
            srcLocation,
            dstWarehouse,
            dstLocation,
            relocatedWeight,
        };
        enqueueSnackbar(`Relocating ${relocatedWeight}Kg of ${rmName}`, { variant: 'info' });
        dispatcher(relocateRMWarehouseAction(relocationInfo, rmName));
        clearInputs();
    };

    return (
        <Container style={{ flex: 1, flexDirection: 'column', justifyContent: 'space-around' }}>
            <Toolbar>{renderFrom()}</Toolbar>
            <Toolbar>{renderTo()}</Toolbar>
            <Toolbar>{renderRMDetails()}</Toolbar>
            <div className={classes.padding}>
                <Button
                    fullWidth
                    variant='contained'
                    color='primary'
                    className={classes.contentButton}
                    onClick={relocate}>
                    Relocate Raw Material
                </Button>
            </div>
        </Container>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: '99%',
            margin: 'auto',
            overflow: 'hidden',
            alignContent: 'center',
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: 'rgb(31,49,69)',
            alignSelf: 'center',
        },
        padding: {
            padding: '30px',
        },
        contentWrapper: {
            margin: '0px 16px',
        },
    });

export default withStyles(styles)(WarehouseRelocationView);
