import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@material-ui/core";
import React, { Fragment, useState } from "react";
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles";

import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import { PremixInfo } from "redux/reducers/premix/types";
import TableGrid from "components/common/TableGrid";
import UpdateIcon from "@material-ui/icons/Update";
import { deletePremixAction } from "redux/reducers/premix/actions";
import { useDispatch } from "react-redux";

export interface ContentProps extends WithStyles<typeof styles> {
    clickedPremix?: PremixInfo;
    onClickedUpdateButton: () => void;
    onClickedDeleteButton: () => void;
}

const PremixDetailsView = (props: ContentProps) => {
    const { classes, clickedPremix, onClickedUpdateButton, onClickedDeleteButton } = props;
    const [deleteMode, setDeleteMode] = useState(false);
    const dispatcher = useDispatch();

    let columnDefs = [
        {
            headerName: "Material",
            field: "rmCode",
            minWidth: 300,
            filter: "agTextColumnFilter",
            headerTooltip: "Material",
        },
        {
            headerName: "Used",
            field: "rmUsed",
            minWidth: 150,
            filter: "agTextColumnFilter",
            headerTooltip: "Used",
        },
        {
            headerName: "Set Weight",
            minWidth: 250,
            filter: "agTextColumnFilter",
            headerTooltip: "Item Description",
            valueGetter: (param: any) => {
                return param.data.rmWeight + " kgs";
            },
        },
    ];

    const onCloseDeleteDialog = () => {
        setDeleteMode(false);
    };

    const renderDeleteDialog = () => {
        return (
            <div>
                <Dialog
                    disableEscapeKeyDown={true}
                    disableBackdropClick={true}
                    open={deleteMode}
                    onClose={onCloseDeleteDialog}
                >
                    <DialogTitle>
                        <IconButton className={classes.rightButton} aria-label="close" onClick={onCloseDeleteDialog}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <Typography variant="h6" component="h1" align="center">
                            {"Are you sure you want to delete this information?"}
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                dispatcher(deletePremixAction(clickedPremix!.premixName));
                                onCloseDeleteDialog();
                                onClickedDeleteButton();
                            }}
                            color="primary"
                            variant="contained"
                            fullWidth={true}
                        >
                            Yes
                        </Button>
                        <Button onClick={onCloseDeleteDialog} color="primary" variant="contained" fullWidth={true}>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    const renderPremixDetails = () => {
        return (
            <Fragment>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3}>
                        <TextField
                            label={"Premix Name"}
                            value={clickedPremix!.premixName}
                            inputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label={"Feed Type"}
                            value={clickedPremix!.feedType}
                            inputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label={"Revision #"}
                            value={clickedPremix!.revisionNumber}
                            inputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label={"Line"}
                            value={clickedPremix!.line}
                            inputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label={"Date Prepared"}
                            value={clickedPremix!.datePrepared}
                            inputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableGrid rowData={clickedPremix!.rawMaterials} columnDefs={columnDefs} height={300} />
                    </Grid>
                    <Grid item xs={8} />
                    <Grid item xs={2}>
                        <TextField
                            label={"Total Weight"}
                            variant={"outlined"}
                            value={clickedPremix!.rmTotalWeight}
                            inputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label={"Prepared By"}
                            value={clickedPremix!.preparedBy}
                            inputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label={"Checked By"}
                            value={clickedPremix!.checkedBy}
                            inputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label={"Noted By"}
                            value={clickedPremix!.notedBy}
                            inputProps={{
                                readOnly: true,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            onClick={onClickedUpdateButton}
                            color="primary"
                            variant="contained"
                            fullWidth={true}
                            startIcon={<UpdateIcon />}
                        >
                            Update
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            onClick={() => {
                                setDeleteMode(true);
                            }}
                            color="primary"
                            variant="contained"
                            fullWidth={true}
                            startIcon={<DeleteIcon />}
                        >
                            Delete
                        </Button>
                    </Grid>
                </Grid>
            </Fragment>
        );
    };

    return (
        <div style={{ padding: 10 }}>
            {renderPremixDetails()}
            {renderDeleteDialog()}
        </div>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: "99%",
            margin: "auto",
            overflow: "hidden",
            alignContent: "center",
        },
        searchBar: {
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: "block",
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: "rgb(31,49,69)",
            alignSelf: "center",
        },
        contentWrapper: {
            margin: "0px 0px",
        },
        rightButton: {
            float: "right",
        },
    });

export default withStyles(styles)(PremixDetailsView);
