import { PremixInfoRequest, ProductionOrderInfoRequest } from "redux/reducers/premix/types";

import ApiController from "./api-controller";
import { AxiosPromise } from "axios";

class PremixController extends ApiController {
    // Get
    getAllPremix<Data = any>(): AxiosPromise<Data> {
        return this._instance.get(`/services/mixer/premix`);
    }

    getAllProductionOrder<Data = any>(): AxiosPromise<Data> {
        return this._instance.get(`/services/mixer/prodOrder`);
    }
    // Post
    postNewPremix<Data = any>(parameters: PremixInfoRequest): AxiosPromise<Data> {
        return this._instance.post(`/services/mixer/premix`, parameters);
    }
    postNewProductionOrder<Data = any>(parameters: ProductionOrderInfoRequest): AxiosPromise<Data> {
        return this._instance.post(`/services/mixer/prodOrder`, parameters);
    }

    // Patch
    patchPremixInfo<Data = any>(parameters: PremixInfoRequest, premixName: string): AxiosPromise<Data> {
        return this._instance.patch(`/services/mixer/premix/${premixName}`, parameters);
    }
    patchProductionOrderInfo<Data = any>(
        parameters: ProductionOrderInfoRequest,
        productionOrderId: string
    ): AxiosPromise<Data> {
        return this._instance.patch(`/services/mixer/prodOrder/${productionOrderId}`, parameters);
    }

    //Delete
    deletePremix<Data = any>(premixName: string): AxiosPromise<Data> {
        return this._instance.delete(`/services/mixer/premix/${premixName}`);
    }

    deleteProductionOrder<Data = any>(productionOrderId: string): AxiosPromise<Data> {
        return this._instance.delete(`/services/mixer/prodOrder/${productionOrderId}`);
    }
}

export default PremixController;
