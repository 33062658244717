import React, { Fragment } from 'react';

import { LoggedUser } from 'utils/loggedUser';
import TabsAppBar from 'components/appbar/TabsAppBar';
import TitleAppBar from 'components/appbar/TitleAppBar';
import UserAppBar from 'components/appbar/UserAppBar';

interface UserListHeaderProps {
    onDrawerToggle: () => void;
    onTabsChange: (id: number) => void;
}

const UserListHeader = (props: UserListHeaderProps) => {
    const tabs_title = [{ id: 'User List' }];

    const { onDrawerToggle, onTabsChange } = props;
    const userFullName = LoggedUser.getFullName();
    const role = LoggedUser.getRole();

    return (
        <Fragment>
            <UserAppBar username={`${userFullName}`} role={role} onDrawerToggle={onDrawerToggle} />
            <TitleAppBar title='Account Management' />
            <TabsAppBar tabs={tabs_title} onTabsChange={onTabsChange} />
        </Fragment>
    );
};

export default UserListHeader;
