import {
    useSelector,
    TypedUseSelectorHook
} from 'react-redux'

import {
    SparePartsActionTypes,
    SparePartsReducerType,
    SparePartsInfoState,
    GET_ALL_SPAREPARTS_TRANSACTION,
    GET_ALL_SPAREPARTS_INFO
} from './types'
import { LOGOUT } from 'redux/reducers/rootReducer/types';

const initialState: SparePartsInfoState = {
    sparePartsTransaction: {
        transactions: [],
        LastEvaluatedKey: null
    },
    sparePartsInfo: {
        infoList: [],
        LastEvaluatedKey: null
    }
}

export const sparePartsTypedSelector: TypedUseSelectorHook<SparePartsReducerType> = useSelector;

export function sparePartsReducer(
    state = initialState,
    action: SparePartsActionTypes
): SparePartsInfoState {

    switch (action.type) {

        case GET_ALL_SPAREPARTS_INFO:
            return {
                sparePartsTransaction: state.sparePartsTransaction,
                sparePartsInfo: action.payload
            }

        case GET_ALL_SPAREPARTS_TRANSACTION: 
            return {
                sparePartsTransaction: action.payload,
                sparePartsInfo: state.sparePartsInfo
            }

        case LOGOUT:

            return initialState

        default:
            return state
    }

}