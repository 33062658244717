import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";

import { AgGridEvent, GridSizeChangedEvent } from "ag-grid";
import React, { useEffect, useState } from "react";

import { AgGridReact } from "ag-grid-react";
import { Button } from "@material-ui/core";
import { GridReadyEvent } from "ag-grid-community";

import DeleteRowRenderer from "components/common/renderers/DeleteRowRenderer";

export interface GripProps {
    rowData: Array<any>;
    columnDefs: Array<any>;
    filterText?: string;
    exportCsv?: boolean;
    onExportCsv?: any;
    exportCsvFileName?: string;
    showColumn?: Array<string>;
    hideColumn?: Array<string>;
    height: number;
    onCellValueChanged?: (event: any) => void;
    headerHeight?: number;
    showAddRowButton?: boolean;
    frameworkComponents?: any;
    getRowStyle?: (param: any) => void;
    onRowDataUpdated?: (event: any) => void;
    gridOptions?: any;
    suppressHorizontalScroll?: boolean;
    deleteColumn?: boolean;
    onDeleteRow?: (param: any) => void;
}

const TableGrid = (props: GripProps) => {
    const {
        rowData,
        columnDefs,
        filterText,
        exportCsv,
        onExportCsv,
        exportCsvFileName,
        showColumn,
        hideColumn,
        onCellValueChanged,
        headerHeight,
        showAddRowButton,
        frameworkComponents,
        getRowStyle,
        onRowDataUpdated,
        gridOptions,
        suppressHorizontalScroll,
        deleteColumn,
        onDeleteRow,
    } = props;
    const [gridApi, setGridApi] = useState<any>({});
    const [columnApi, setColumnApi] = useState<any>({});

    const getColumnDefs = () => {
        if (deleteColumn) {
            columnDefs.unshift({
                headerName: "Action",
                width: 100,
                cellRendererParams: {onDelete: onDeleteRow},
                cellRendererFramework: DeleteRowRenderer,
                suppressSizeToFit: true,
            });
        }

        return columnDefs;
    }

    useEffect(() => {
        if (columnApi && columnApi.resetColumnState) {
            columnApi.resetColumnState();
        }
    }, [rowData, columnApi]);

    useEffect(() => {
        if (gridApi && gridApi.setQuickFilter) {
            gridApi.setQuickFilter(filterText);
        }
    }, [filterText, gridApi]);

    useEffect(() => {
        if (columnApi && columnApi.setColumnVisible) {
            if (hideColumn && hideColumn.length > 0) {
                hideColumn.forEach((col) => columnApi.setColumnVisible(col, false));
            }
            if (showColumn && showColumn.length > 0) {
                showColumn.forEach((col) => columnApi.setColumnVisible(col, true));
            }
        }

        if (gridApi && gridApi.autoSizeColumns) {
            gridApi.autoSizeColumns();
        }
        if (gridApi && gridApi.sizeColumnsToFit) {
            gridApi.sizeColumnsToFit();
        }
    }, [showColumn, hideColumn, columnApi, gridApi]);

    useEffect(() => {
        if (gridApi && gridApi.exportDataAsCsv && exportCsv && exportCsv === true) {
            gridApi.exportDataAsCsv({
                fileName: exportCsvFileName,
                allColumns: true,
            });
            if (onExportCsv) {
                onExportCsv();
            }
        }
    }, [exportCsv, gridApi, onExportCsv, exportCsvFileName]);

    const setGridWidth = (params: AgGridEvent) => {
        let parentDiv = document.getElementById("agGridDiv");
        params.columnApi.sizeColumnsToFit(parentDiv ? parentDiv.offsetWidth - 20 : 276);
    };

    const onGridSizeChanged = (params: GridSizeChangedEvent) => {
        setGridWidth(params);
    };

    const onGridReady = (event: GridReadyEvent) => {
        setGridApi(event.api);
        setColumnApi(event.columnApi);
    };

    let [editing, setEditing] = useState(false);

    useEffect(() => {
        if (Object.keys(gridApi).length !== 0) {
            gridApi.addEventListener("rowEditingStarted", onRowEditingStarted);
            gridApi.addEventListener("rowEditingStopped", onRowEditingStopped);

            return () => {
                gridApi.removeEventListener("rowEditingStarted", onRowEditingStarted);
                gridApi.removeEventListener("rowEditingStopped", onRowEditingStopped);
            };
        }
        return () => {};
    }, [gridApi]);

    function onRowEditingStarted() {
        setEditing(true);
    }

    function onRowEditingStopped() {
        setEditing(false);
    }

    function addRow() {
        let emptyRow = {};
        let added = gridApi.applyTransaction({ add: [emptyRow] });
        let node = gridApi.getRowNode(added.add[0].id);
        gridApi.ensureIndexVisible(node.rowIndex);

        setTimeout(() => {
            gridApi.startEditingCell({
                rowIndex: node.rowIndex,
                colKey: columnApi.getAllColumns()[0].getColId(),
            });
        }, 300);
    }

    return (
        <div id="agGridDiv" className="ag-theme-alpine" style={{ height: props.height }}>
            <AgGridReact
                columnDefs={getColumnDefs()}
                rowData={rowData}
                suppressHorizontalScroll={suppressHorizontalScroll ? suppressHorizontalScroll : false}
                enableFilter={false}
                enableSorting={true}
                rowSelection={"multiple"}
                suppressRowClickSelection={true}
                suppressTabbing={true}
                suppressCellSelection={false}
                suppressPaginationPanel={true}
                suppressMovableColumns={true}
                ensureDomOrder={true}
                suppressNoRowsOverlay={true}
                onGridSizeChanged={onGridSizeChanged}
                alwaysShowVerticalScroll={false}
                onGridReady={onGridReady}
                animateRows={true}
                defaultColDef={{ sortable: true, resizable: true }}
                singleClickEdit={true}
                onCellValueChanged={onCellValueChanged}
                headerHeight={headerHeight}
                frameworkComponents={frameworkComponents}
                stopEditingWhenGridLosesFocus={true}
                enableRangeSelection={true}
                getRowStyle={getRowStyle}
                onRowDataUpdated={onRowDataUpdated}
                gridOptions={gridOptions}
            />
            {showAddRowButton && (
                <Button
                    variant={editing ? "outlined" : "contained"}
                    color="primary"
                    onClick={addRow}
                    disabled={editing}
                    style={{ marginTop: 2, float: "right" }}
                >
                    Add Row
                </Button>
            )}
        </div>
    );
};

export default TableGrid;
