import {
    AppBar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    InputLabel,
    TextField,
    Theme,
    Toolbar,
    Tooltip,
    WithStyles,
    createStyles,
    withStyles,
} from '@material-ui/core';
import { FGStockOrder, FGTruckInspectionInfo } from 'redux/reducers/finishedGoods/types';
import React, { Fragment, useEffect, useState } from 'react';
import { approveFGOrderForDispatch, dispatchFGOrder, getFGOrder } from 'redux/reducers/finishedGoods/actions';

import FinishedGoodsTruckInspectionForm from './FinishedGoodsTruckInspectionForm';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SearchIcon from '@material-ui/icons/Search';
import SelectComponent from 'components/common/Select';
import TableGrid from 'components/common/TableGrid';
import { finishedGoodsTypedSelector } from 'redux/reducers/finishedGoods/finishedGoodsReducer';
import moment from 'moment';
import { useDispatch } from 'react-redux';

export interface FinishedGoodsTransactionDetailsProps extends WithStyles<typeof styles> {
    batchId: string;
}

const FinishedGoodsTransactionDetails = (props: FinishedGoodsTransactionDetailsProps) => {
    const { classes, batchId } = props;
    const dispatcher = useDispatch();

    const stockOrderList = finishedGoodsTypedSelector<Array<FGStockOrder>>((state) => state.finishedGoods.stockOrder);

    const [clickedIssueOrder, setClickedIssueOrder] = useState('');
    const [customerName, setCustomerName] = useState('');
    const [truckModal, setTruckModal] = useState(false);
    const [truckInfo, setTruckInfo] = useState<FGTruckInspectionInfo | undefined>(undefined);

    useEffect(() => {
        dispatcher(getFGOrder(batchId));
    }, [batchId, dispatcher]);

    const truckInspectionRenderer = (data: any) => {
        if (!data.data.truckInspectionInfo.inspectionDecision)
            return (
                <Tooltip title='Truck Inspection Checklist'>
                    <IconButton
                        onClick={() => {
                            setClickedIssueOrder(data.data.issueOrder);
                            setTruckInfo(data.data.truckInspectionInfo);
                            setCustomerName(data.data.customerName);
                            setTruckModal(true);
                        }}>
                        <LocalShippingIcon color='primary' />
                    </IconButton>
                </Tooltip>
            );

        return (
            <Button
                style={{ color: '#009fdc' }}
                onClick={() => {
                    setClickedIssueOrder(data.data.issueOrder);
                    setTruckInfo(data.data.truckInspectionInfo);
                    setCustomerName(data.data.customerName);
                    setTruckModal(true);
                }}>
                {data.data.truckInspectionInfo.inspectionDecision}
            </Button>
        );
    };

    const [submitTruck, setSubmitTruck] = useState(false);

    const truckInspectionModal = () => {
        return (
            <Dialog
                maxWidth='xl'
                disableEscapeKeyDown
                open={truckModal}
                onClose={() => {
                    setTruckModal(false);
                }}>
                <DialogTitle>Truck Inspection Info</DialogTitle>
                <DialogContent>
                    <FinishedGoodsTruckInspectionForm
                        issueOrder={clickedIssueOrder}
                        customerName={customerName}
                        submit={submitTruck}
                        onSubmit={() => {
                            setSubmitTruck(false);
                            setTruckModal(false);
                        }}
                        truckInfo={truckInfo}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setSubmitTruck(true)} color='primary' variant='contained' fullWidth>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    const dateRenderer = (date: any) => {
        if (date.value) {
            return moment(date.value).format('DD-MMM-YY');
        }
        return '';
    };

    const [openDispatchApproval, setOpenDispatchApproval] = useState(false);
    const [dispatchApproval, setDispatchApproval] = useState('');
    const [dispatchApprovedBy, setDispatchApprovedBy] = useState('');

    const [selectedIssueOrder, setSelectedIssueOrder] = useState('');

    const dispatchApprovalRenderer = (data: any) => {
        if (!data.data.dispatchApprovalInfo.dispatchApprovedBy) {
            return (
                <Button
                    style={{ color: '#009fdc' }}
                    onClick={() => {
                        setSelectedIssueOrder(data.data.issueOrder);
                        setOpenDispatchApproval(true);
                    }}>
                    {'Set Dispatch Approval'}
                </Button>
            );
        }
        return <div style={{ paddingLeft: 10 }}>{data.data.dispatchApprovalInfo.dispatchApprovedBy}</div>;
    };

    const dispatchRenderer = (data: any) => {
        if (!data.data.dispatchInfo.drNum) {
            return (
                <Button
                    style={{ color: '#009fdc' }}
                    onClick={() => {
                        setSelectedIssueOrder(data.data.issueOrder);
                        setOpenConfirmDispatch(true);
                    }}>
                    {'Dispatch'}
                </Button>
            );
        }
        return <div style={{ paddingLeft: 10 }}>{data.data.dispatchInfo.drNum}</div>;
    };

    const executeSetDispatchApproval = () => {
        if (dispatchApproval !== '' && dispatchApprovedBy !== '') {
            dispatcher(
                approveFGOrderForDispatch(selectedIssueOrder, {
                    dispatchApproval,
                    dispatchApprovedBy,
                })
            );
            setTimeout(() => dispatcher(getFGOrder(batchId)), 500);
            setDispatchApproval('');
            setDispatchApprovedBy('');
            setOpenDispatchApproval(false);
        }
    };

    const dispatchApprovalModal = () => {
        let dispatchApprovalList: any[] = ['ACCEPT', 'REJECT'];
        return (
            <div>
                <Dialog
                    disableEscapeKeyDown
                    open={openDispatchApproval}
                    onClose={() => {
                        setDispatchApproval('');
                        setDispatchApprovedBy('');
                        setOpenDispatchApproval(false);
                    }}>
                    <DialogTitle>Dispatch Approval</DialogTitle>
                    <DialogContent>
                        <SelectComponent
                            title={'Status'}
                            selectList={dispatchApprovalList}
                            setter={setDispatchApproval}
                        />
                        <TextField
                            fullWidth
                            margin='dense'
                            style={{ minWidth: 300 }}
                            type={'text'}
                            label={'Approved By'}
                            variant='outlined'
                            onChange={(event) => {
                                setDispatchApprovedBy(event.target.value);
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={executeSetDispatchApproval} color='primary' variant='contained'>
                            Set Dispatch Approval
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    const [openConfirmDispatch, setOpenConfirmDispatch] = useState(false);

    const confirmDispatchModal = () => {
        return (
            <div>
                <Dialog
                    disableEscapeKeyDown
                    open={openConfirmDispatch}
                    onClose={() => {
                        setOpenConfirmDispatch(false);
                    }}>
                    <DialogTitle>Dispatch Confirmation</DialogTitle>
                    <DialogContent>
                        <InputLabel id='dispatchApproval-select-label'>Proceed with dispatch?</InputLabel>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                dispatcher(dispatchFGOrder(selectedIssueOrder, moment(new Date()).toISOString()));
                                setOpenConfirmDispatch(false);
                                setTimeout(() => dispatcher(getFGOrder(batchId)), 1000);
                            }}
                            color='primary'
                            variant='contained'>
                            Yes
                        </Button>
                        <Button onClick={() => setOpenConfirmDispatch(false)} color='primary' variant='contained'>
                            No
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    };

    const columnDefs = [
        {
            headerName: 'Order Informartion',
            children: [
                {
                    headerName: 'Issue Order',
                    field: 'issueOrder',
                    filter: 'agTextColumnFilter',
                    headerTooltip: 'Issue Order',
                    minWidth: 300,
                },
                {
                    headerName: 'Customer',
                    field: 'customerName',
                    filter: 'agTextColumnFilter',
                    headerTooltip: 'Customer',
                    minWidth: 120,
                },
                {
                    headerName: '# of bags',
                    field: 'bagNum',
                    filter: 'agNumberColumnFilter',
                    headerTooltip: '# of bags',
                    minWidth: 120,
                },
                {
                    headerName: 'Truck Inspection',
                    field: 'truckInspectionInfo',
                    cellRendererFramework: truckInspectionRenderer,
                    headerTooltip: 'Truck Inspection',
                    minWidth: 150,
                },
            ],
        },
        {
            headerName: 'Dispatch Approval',
            children: [
                {
                    headerName: 'Approved By',
                    field: 'dispatchApprovalInfo.dispatchApprovedBy',
                    headerTooltip: 'Approved By',
                    minWidth: 200,
                    cellRendererFramework: dispatchApprovalRenderer,
                },
                {
                    headerName: 'Approval',
                    field: 'dispatchApprovalInfo.dispatchApproval',
                    headerTooltip: 'Dispatch Approval',
                    minWidth: 120,
                },
                {
                    headerName: 'Approval Date',
                    field: 'dispatchApprovalInfo.dispatchApprovalDatetime',
                    headerTooltip: 'Dispatch Approval Date',
                    minWidth: 150,
                    cellRenderer: dateRenderer,
                    getQuickFilterText: dateRenderer,
                },
            ],
        },
        {
            headerName: 'Dispatch Informartion',
            children: [
                {
                    headerName: 'DR Number',
                    field: 'dispatchInfo.drNum',
                    headerTooltip: 'DR Number',
                    minWidth: 300,
                    cellRendererFramework: dispatchRenderer,
                },
                {
                    headerName: 'Delivery Date',
                    field: 'dispatchInfo.deliveryDate',
                    headerTooltip: 'Dispatch Delivery Date',
                    minWidth: 150,
                    cellRenderer: dateRenderer,
                    getQuickFilterText: dateRenderer,
                },
            ],
        },
    ];
    const [filterText, setfilterText] = useState('');
    const renderListTableSearch = () => {
        return (
            <Fragment>
                <Grid item>
                    <SearchIcon className={classes.block} color='inherit' />
                </Grid>
                <Grid item xs>
                    <TextField
                        fullWidth
                        placeholder='Search'
                        InputProps={{
                            disableUnderline: true,
                            className: classes.searchInput,
                        }}
                        onChange={(event) => setfilterText(event.target.value)}
                    />
                </Grid>
            </Fragment>
        );
    };

    const renderTableHeader = () => {
        return (
            <Grid container spacing={2} alignItems='center'>
                {renderListTableSearch()}
            </Grid>
        );
    };

    return (
        <Fragment>
            <Grid container>
                <Grid item />
            </Grid>

            <Fragment>
                <AppBar className={classes.searchBar} position='static' color='default' elevation={0}>
                    <Toolbar>{renderTableHeader()}</Toolbar>
                </AppBar>

                <TableGrid rowData={stockOrderList} columnDefs={columnDefs} height={300} filterText={filterText} />
            </Fragment>

            {truckInspectionModal()}
            {dispatchApprovalModal()}
            {confirmDispatchModal()}
        </Fragment>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: '99%',
            margin: 'auto',
            overflow: 'hidden',
            alignContent: 'center',
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: 'rgb(31,49,69)',
            alignSelf: 'center',
        },
        contentWrapper: {
            margin: '0px 0px',
        },
    });

export default withStyles(styles)(FinishedGoodsTransactionDetails);
