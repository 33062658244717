import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  PurchaseRequestInfo,
  PurchaseRequestStatus,
} from 'redux/reducers/purchasing/types';
import React, { Fragment, useState } from 'react';
import {
  Theme,
  WithStyles,
  createStyles,
  withStyles,
} from '@material-ui/core/styles';

import AppController from 'controller/application';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import MomentUtils from '@date-io/moment';
import TableGrid from 'components/common/TableGrid';
import SelectComponent from 'components/common/Select';
import { useSnackbar } from 'notistack';
import { GridApi } from 'ag-grid';

export interface ContentProps extends WithStyles<typeof styles> {
  clickedPurchaseRequest?: PurchaseRequestInfo;
  onClickedUpdateButton: () => void;
}

export interface AttachmentInfo {
  name: string;
  index: number;
  api?: GridApi;
  data?: any;
}

const PurchaseRequestDetailsView = (props: ContentProps) => {
  const { classes, clickedPurchaseRequest, onClickedUpdateButton } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [selectedAttachment, setSelectedAttachment] = useState<AttachmentInfo | any>({});
  const [openSelectAttachment, setOpenSelectAttachment] = useState(false);

  const fileNameIndex = 6;

  const onDeleteFile = (attachment: AttachmentInfo) => {
    try {
      AppController.deletePurchaseAttachment({
        requestId: `${clickedPurchaseRequest!.requestId}_${attachment.data.itemCode}`,
        fileName: attachment.name,
      });
    } catch (e) {
      enqueueSnackbar(`Failed deleting ${attachment.name}`, {
        variant: 'error',
      });
      return false;
    }
    enqueueSnackbar(`Files deleted successfully`, {
      variant: 'success',
    });
    return true;
  };

  const onUploadFile = (requestFile: string) => {
    const fileSelector = document.createElement('input');
    fileSelector.setAttribute('type', 'file');
    fileSelector.setAttribute('multiple', 'multiple');
    fileSelector.setAttribute('accept', 'image/*,application/pdf');
    fileSelector.onchange = (e: any) => {
      const { target } = e;
      const { files } = target;
      if (files && files[0]) {
        let fileNames: string[] = [];
        let promises: Promise<any>[] = [];
        let ctr = 0;

        for (ctr = 0; ctr < files.length; ctr++) {
          let file = files[ctr]
          fileNames.push(file.name);
          promises.push(
            new Promise((resolve) => {
              let reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
            })
          );
        }

        Promise.all(promises).then((loadResults) => {
          let success: boolean = true;

          loadResults.forEach((loadResult, index) => {
            if (!loadResult) {
              enqueueSnackbar(`failed uploading ${files[index].name}`, {
                variant: 'error',
              });
              success = false;
            }
          });

          if (success) {
            enqueueSnackbar(`Files are being uploaded`, {
              variant: 'info',
            });
            AppController.postPurchasePresigned(
              {
                requestId: requestFile,
                fileNames: fileNames,
              },
              files
            )
              .then(() =>
                enqueueSnackbar(`Files are uploaded successfully`, {
                  variant: 'success',
                })
              )
              .catch((error) => {
                enqueueSnackbar(`Failed uploading files`, {
                  variant: 'error',
                });
              });
          }
        });
      }
    };
    fileSelector.click();
  };

  const getAttachmentName = (url: string) => {
    return url.split('/')[fileNameIndex].split('?')[0];
  };


  const attachmentRenderer = (row: any) => {
    const {api, data} = row;
    const {attachments, itemCode} = data;

    const url = `${clickedPurchaseRequest!.requestId}_${itemCode}`;
    return (
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={2}>
          <Tooltip title="Upload Purchase Request File/Image">
            <IconButton
              onClick={() => onUploadFile(url)}
              disabled={clickedPurchaseRequest!.requestStatus === PurchaseRequestStatus.CLOSED}>
              <CloudUploadIcon className={classes.uploadIcon} color="inherit" />
            </IconButton>
          </Tooltip>
        </Grid>

        {attachments && attachments[0] && (
          <Grid item xs={10} >
            <SelectComponent
              title={'Attachments...'}
              selectMap={attachments.map((attachmentUrl: string, index: number) => {
                return {
                  key: getAttachmentName(attachmentUrl),
                  value: {
                    name: getAttachmentName(attachmentUrl),
                    index: index,
                    api: api,
                    data: data
                  },
                };
              })}
              setter={(attachment: AttachmentInfo) => {
                setSelectedAttachment(attachment);
                setOpenSelectAttachment(true);
              }}
            />
          </Grid>
        )}
      </Grid>
    );
};

  const attachmentModal = () => {
    return (
      <div>
        <Dialog
          disableEscapeKeyDown
          open={openSelectAttachment}
          onClose={() => {
            setOpenSelectAttachment(false);
          }}
        >
          <DialogTitle>{selectedAttachment.name}</DialogTitle>
          <DialogContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                <Button
                  fullWidth
                  onClick={() => {
                    window.open(selectedAttachment.data.attachments[selectedAttachment.index] as string);
                    setOpenSelectAttachment(false);
                  }}
                  color="primary"
                  variant="contained"
                >
                  Download
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  onClick={() => {
                    if (onDeleteFile(selectedAttachment)) {
                        let {data} = selectedAttachment
                        data.attachments.splice(selectedAttachment.index, 1)

                        selectedAttachment!.api!.updateRowData({ update: [data] })
                        selectedAttachment!.api!.refreshCells({ force: true });
                    }

                    setOpenSelectAttachment(false);
                  }}
                  fullWidth
                  color="secondary"
                  variant="contained"
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </div>
    );
  };

  let columnDefs = [
    {
      headerName: 'Item Code',
      field: 'itemCode',
      minWidth: 100,
      width: 100,
      filter: 'agTextColumnFilter',
      headerTooltip: 'Item Code',
    },
    {
      headerName: 'Description',
      field: 'itemDescription',
      minWidth: 150,
      width: 150,
      filter: 'agTextColumnFilter',
      headerTooltip: 'Item Description',
    },
    {
      headerName: 'Type',
      field: 'itemType',
      minWidth: 80,
      width: 80,
      filter: 'agTextColumnFilter',
      headerTooltip: 'Item Type',
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
          values: ['RM', 'SP', 'Others'],
      },
    },
    {
      headerName: 'Unit',
      field: 'itemUnit',
      minWidth: 80,
      width: 80,
      filter: 'agTextColumnFilter',
      headerTooltip: 'Unit',
    },
    {
      headerName: 'Quantity',
      field: 'itemQuantity',
      minWidth: 100,
      width: 100,
      filter: 'agTextColumnFilter',
      headerTooltip: 'Quantity',
    },
    {
      headerName: 'Attachment',
      minWidth: 100,
      width: 150,
      headerTooltip: 'Attachment',
      cellRendererFramework: attachmentRenderer,
    },
  ];

  const renderPurchaseRequestDetails = () => {
    return (
      <Fragment>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={6}>
            <TextField
              label={'Originating Department'}
              value={clickedPurchaseRequest!.originDept}
              inputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={'Request Status'}
              value={clickedPurchaseRequest!.requestStatus}
              inputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TableGrid
              rowData={clickedPurchaseRequest!.itemsList}
              columnDefs={columnDefs}
              height={300}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label={'Purpose'}
              value={clickedPurchaseRequest!.purpose}
              inputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePicker
                ampm={false}
                fullWidth
                margin="dense"
                value={
                    clickedPurchaseRequest && clickedPurchaseRequest!.needDate
                    ? clickedPurchaseRequest!.needDate
                    : null
                }
                onChange={(x) => x}
                label={'Date Needed'}
                format="MMM DD, YYYY HH:mm"
                inputVariant="standard"
                readOnly={true}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={'Requisitioned By'}
              value={clickedPurchaseRequest!.requisitionedBy}
              inputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
              <TextField
                  label={'Remarks'}
                  value={clickedPurchaseRequest!.remarks}
                  inputProps={{
                      readOnly: true,
                  }}
                  fullWidth
              />
          </Grid>
          <Grid item xs={6}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DateTimePicker
                ampm={false}
                fullWidth
                margin="dense"
                value={
                    clickedPurchaseRequest && clickedPurchaseRequest!.approveDate
                    ? clickedPurchaseRequest!.approveDate
                    : null
                }
                onChange={(x) => x}
                label={'Date Approved'}
                format="MMM DD, YYYY HH:mm"
                inputVariant="standard"
                readOnly={true}
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label={'Approved By'}
              value={clickedPurchaseRequest!.approvedBy}
              inputProps={{
                readOnly: true,
              }}
              fullWidth
            />
          </Grid>
          {clickedPurchaseRequest!.requestStatus ===
            PurchaseRequestStatus.CLOSED && (
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DateTimePicker
                  ampm={false}
                  fullWidth
                  margin="dense"
                  value={
                    clickedPurchaseRequest && clickedPurchaseRequest!.closeDate
                      ? clickedPurchaseRequest!.closeDate
                      : null
                  }
                  onChange={(x) => x}
                  label={'Date Closed'}
                  format="MMM DD, YYYY HH:mm"
                  inputVariant="standard"
                  readOnly={true}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          )}
          {clickedPurchaseRequest!.requestStatus ===
            PurchaseRequestStatus.CLOSED && (
            <Grid item xs={6}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DateTimePicker
                  ampm={false}
                  fullWidth
                  margin="dense"
                  value={
                    clickedPurchaseRequest && clickedPurchaseRequest!.installedUsedDate
                      ? clickedPurchaseRequest!.installedUsedDate
                      : null
                  }
                  onChange={(x) => x}
                  label={'Date Installed / Used'}
                  format="MMM DD, YYYY HH:mm"
                  inputVariant="standard"
                  readOnly={true}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          )}
          {clickedPurchaseRequest!.requestStatus !==
            PurchaseRequestStatus.CLOSED && (
            <Grid item xs={12}>
              <Button
                onClick={onClickedUpdateButton}
                color="primary"
                variant="contained"
                fullWidth={true}
              >
                Update
              </Button>
            </Grid>
          )}
        </Grid>
        {attachmentModal()}
      </Fragment>
    );
  };

  return <div style={{ padding: 10 }}>{renderPurchaseRequestDetails()}</div>;
};

const styles = (theme: Theme) =>
  createStyles({
    '@keyframes pulse': {
      '0%': { background: 'rgba(31,49,69, 0)' },
      '20%': { background: 'rgba(31,49,69, 0.5)' },
    },
    attention: {
      animation: `$pulse 1.5s infinite cubic-bezier(0.66, 0.33, 0, 1)`,
    },
    block: {
      display: 'block',
    },
    uploadIcon: {
      color: '#009fdb',
      display: 'block',
    },
    downloadIcon: {
      color: '#1c3149',
      display: 'block',
    },
  });

export default withStyles(styles)(PurchaseRequestDetailsView);
