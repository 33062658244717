import { Button, Grid, TextField } from "@material-ui/core";
import { ItemInfo, SupplierInfoRequest, SupplierLocationOption } from "redux/reducers/purchasing/types";
import React, { Fragment, useEffect, useState } from "react";
import { Theme, WithStyles, createStyles, withStyles } from "@material-ui/core/styles";

import AddCircleIcon from "@material-ui/icons/AddCircle";
import Alert from "@material-ui/lab/Alert";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteRowRenderer from "components/common/renderers/DeleteRowRenderer";
import { RowNode } from "ag-grid";
import SelectComponent from "components/common/Select";
import TableGrid from "components/common/TableGrid";
import { addSupplierAction } from "redux/reducers/purchasing/actions";
import { useDispatch } from "react-redux";

export interface ContentProps extends WithStyles<typeof styles> {
    onFormClose: () => void;
    onAddFormSetData: () => void;
    onCancel: () => void;
}

const SupplierAddView = (props: ContentProps) => {
    const { onFormClose, onAddFormSetData, onCancel } = props;
    const [newSupplierName, setNewSupplierName] = useState("");
    const [newSupplierNameHelper, setNewSupplierNameHelper] = useState("");
    const [newLocation, setNewLocation] = useState(SupplierLocationOption.LOCAL);
    const [newItemsList, setNewItemsList] = useState<Array<ItemInfo>>([]);
    const [errorMessage, setErrorMessage] = useState("");
    const dispatch = useDispatch();

    let columnDefs = [
        {
            headerName: "Action",
            width: 100,
            cellRenderer: "deleteRowRenderer",
            suppressSizeToFit: true,
        },
        {
            headerName: "Item Code",
            field: "itemCode",
            minWidth: 100,
            filter: "agTextColumnFilter",
            headerTooltip: "Item Code",
            editable: true,
        },
        {
            headerName: "Description",
            field: "itemDescription",
            minWidth: 200,
            filter: "agTextColumnFilter",
            headerTooltip: "Item Description",
            editable: true,
        },
    ];

    useEffect(() => {
        if (newSupplierName !== "" || newItemsList.length > 0) {
            onAddFormSetData();
        }
    }, [newSupplierName, newItemsList, onAddFormSetData]);

    const onCellValueChanged = (event: any) => {
        let rowData: Array<ItemInfo> = [];
        event.api.forEachNode((node: RowNode) => rowData.push(node.data));
        setNewItemsList(rowData);
    };

    const onRowDataUpdated = (event: any) => {
        let rowData: Array<ItemInfo> = [];
        event.api.forEachNode((node: RowNode) => rowData.push(node.data));
        setNewItemsList(rowData);
    };

    const isItemsListValid = () => {
        let ret = true;

        // Copy the contents of the items list but remove empty rows
        let filteredItemsList: Array<ItemInfo> = [];
        newItemsList.forEach((element) => {
            // if at least one attribute is not undefined
            if (element.itemCode || element.itemDescription) {
                let addItem = {
                    itemCode: element.itemCode ? element.itemCode! : "",
                    itemDescription: element.itemDescription ? element.itemDescription! : "",
                };
                filteredItemsList = [...filteredItemsList, addItem];
            }
        });

        // Check if empty list
        if (filteredItemsList.length === 0) {
            setErrorMessage("No items added");
            return false;
        }

        // Check for duplicate item codes
        let seen = new Set();
        var hasDuplicates = filteredItemsList.some(function(currentItem) {
            return seen.size === seen.add(currentItem.itemCode).size;
        });

        if (!hasDuplicates) {
            // Check for empty value
            for (let i = 0; i < filteredItemsList.length; i++) {
                let element = filteredItemsList[i];
                let rowItemCode = element.itemCode;
                if (rowItemCode === "") {
                    setErrorMessage("Please set the item code for : Item " + (i + 1));
                    ret = false;
                    break;
                }
                if (element.itemDescription === "") {
                    setErrorMessage("Please set the description for " + rowItemCode);
                    ret = false;
                    break;
                }
            }
        } else {
            setErrorMessage("Item Code duplicates detected");
            ret = false;
        }

        return ret;
    };

    const resetHelpers = () => {
        setNewSupplierNameHelper("");
        setErrorMessage("");
    };

    const checkInputValidity = () => {
        let isItemsValid = isItemsListValid();
        if (newSupplierName === "" || isItemsValid === false) {
            if (newSupplierName === "") setNewSupplierNameHelper("Please set value");
            return false;
        }
        return true;
    };

    const addNewRequest = () => {
        if (checkInputValidity()) {
            let filteredItemsList: Array<ItemInfo> = [];
            newItemsList.forEach((element) => {
                if (element.itemCode || element.itemDescription) {
                    let addItem = {
                        itemCode: element.itemCode ? element.itemCode! : "",
                        itemDescription: element.itemDescription ? element.itemDescription! : "",
                    };
                    filteredItemsList = [...filteredItemsList, addItem];
                }
            });
            let addSupplier: SupplierInfoRequest = {
                supplierName: newSupplierName,
                supplierLocation: newLocation,
                itemsList: filteredItemsList,
            };
            dispatch(addSupplierAction(addSupplier));
            resetHelpers();
            onFormClose();
        }
    };

    const renderPurchaseRequestDetails = () => {
        return (
            <Fragment>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <TextField
                            label={"Name"}
                            value={newSupplierName}
                            helperText={newSupplierNameHelper}
                            error={newSupplierNameHelper !== ""}
                            onChange={(event) => {
                                setNewSupplierName(event.target.value);
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectComponent
                            title={"Location"}
                            selectList={Object.values(SupplierLocationOption)}
                            currentValue={newLocation}
                            setter={setNewLocation}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableGrid
                            rowData={newItemsList}
                            columnDefs={columnDefs}
                            height={300}
                            showAddRowButton={true}
                            onCellValueChanged={onCellValueChanged}
                            onRowDataUpdated={onRowDataUpdated}
                            frameworkComponents={{
                                deleteRowRenderer: DeleteRowRenderer,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            onClick={() => {
                                resetHelpers();
                                addNewRequest();
                            }}
                            color="primary"
                            variant="contained"
                            fullWidth={true}
                            startIcon={<AddCircleIcon />}
                        >
                            Create
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            onClick={onCancel}
                            color="primary"
                            variant="contained"
                            startIcon={<CancelIcon />}
                            fullWidth={true}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        {errorMessage !== "" && <Alert severity="error">{errorMessage}</Alert>}
                    </Grid>
                </Grid>
            </Fragment>
        );
    };

    return <div style={{ padding: 10 }}>{renderPurchaseRequestDetails()}</div>;
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: "99%",
            margin: "auto",
            overflow: "hidden",
            alignContent: "center",
        },
        searchBar: {
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: "block",
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: "rgb(31,49,69)",
            alignSelf: "center",
        },
        contentWrapper: {
            margin: "0px 0px",
        },
    });

export default withStyles(styles)(SupplierAddView);
