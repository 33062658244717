import { AppBar, Button, Grid, IconButton, Paper, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import { FGBatchInfo, FGQAAnalysisInfo } from 'redux/reducers/finishedGoods/types';
import React, { Fragment, useEffect, useState } from 'react';
import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import { getFGQASampleAnalysisAction, getFgBatchPerLineAction, deleteFGBatchByIDAction } from 'redux/reducers/finishedGoods/actions';

import AppController from 'controller/application';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AssignmentReturnedIcon from '@material-ui/icons/AssignmentReturned';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import FinishedGoodsBatchEntry from './FinishedGoodBatchEntry';
import FinishedGoodsListTable from './FinishedGoodsListTable';
import FinishedGoodsQAChecklistForm from './FinishedGoodsQAChecklistForm';
import FinishedGoodsUnloadingDetails from './FinishedGoodsUnloadingDetails';
import { LoggedUser } from 'utils/loggedUser';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import SearchIcon from '@material-ui/icons/Search';
import SelectComponent from 'components/common/Select';
import { finishedGoodsTypedSelector } from 'redux/reducers/finishedGoods/finishedGoodsReducer';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';

export interface ContentProps extends WithStyles<typeof styles> {}

const viewState = {
    fgList: 'fgList',
    fgEntry: 'fgEntry',
    qaFGChecklist: 'qaFGChecklist',
    truckFGChecklist: 'truckFGChecklist',
};

const FinishedGoodsListMainView = (props: ContentProps) => {
    const { classes } = props;
    const role = LoggedUser.getRole();
    const dispatcher = useDispatch();

    const [filterText, setfilterText] = useState('');
    const [view, setView] = useState(viewState.fgList);
    const [exportCsv, setExportCsv] = useState(false);
    const [exportCsvFileName, setExportCsvFileName] = useState('');
    const [line, setLine] = useState('1');
    const [clickedBatchID, setClickedBatchID] = useState<string | null>(null);
    const [clickedBatchInfo, setClickedBatchInfo] = useState<FGBatchInfo | null>(null);

    const finishedGoodsList = finishedGoodsTypedSelector<Array<FGBatchInfo>>((state) => state.finishedGoods.fgBatch);
    const [fgList, setfgList] = useState<Array<FGBatchInfo>>([]);
    const [fgListAcquire, setFgListAcquire] = useState(false);

    const [submit, setSubmit] = useState(false);

    useEffect(() => {
        if (finishedGoodsList.length === 0 && !fgListAcquire) {
            dispatcher(getFgBatchPerLineAction(line));
            setFgListAcquire(true);
        } else {
            setfgList(
                finishedGoodsList.sort((a, b) => {
                    if (a.mfgDate > b.mfgDate) return 1;
                    if (a.mfgDate < b.mfgDate) return -1;
                    return 0;
                })
            );
        }
    }, [finishedGoodsList, dispatcher, fgListAcquire, line]);

    useEffect(() => {}, [fgList]);

    const isView = (checkView: string) => {
        return view === checkView;
    };

    const renderListTableSearch = () => {
        return (
            <Fragment>
                <Grid item>
                    <SearchIcon className={classes.block} color='inherit' />
                </Grid>
                <Grid item xs>
                    <TextField
                        fullWidth
                        placeholder='Search'
                        InputProps={{
                            disableUnderline: true,
                            className: classes.searchInput,
                        }}
                        onChange={(event) => setfilterText(event.target.value)}
                    />
                </Grid>
            </Fragment>
        );
    };

    const handleSelectedLine = (line: any) => {
        const selectedLine = line === 'Reworked' ? line : line.replace('Line ', '');
        setLine(selectedLine);
        dispatcher(getFgBatchPerLineAction(selectedLine));
    };

    const lineMenu = () => {
        let lines = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'Reworked'];
        return (
            <SelectComponent
                title={'Select Line'}
                selectList={lines.map((line) => (line === 'Reworked' ? line : `Line ${line}`))}
                setter={handleSelectedLine}
                currentValue={line === 'Reworked' ? line : `Line ${line}`}
                style={{ width: 300 }}
            />
        );
    };

    const onExportCsv = () => {
        setExportCsv(false);
    };

    const revertToListView = () => {
        setView(viewState.fgList);
        setClickedBatchID(null);
        setClickedBatchInfo(null);
        setQAFile('');
        setTimeout(() => {
            dispatcher(getFgBatchPerLineAction(line));
        }, 1000);
    };

    const renderListTableButton = () => {
        return (
            <Grid item>
                <Tooltip title='Line'>{lineMenu()}</Tooltip>
                {(role === 'SMD Representative' || role === 'Administrator') && (
                    <Tooltip title='New Finished Good'>
                        <IconButton onClick={() => setView(viewState.fgEntry)}>
                            <NoteAddIcon className={classes.block} color='inherit' />
                        </IconButton>
                    </Tooltip>
                )}
                <Tooltip title='Export to CSV'>
                    <IconButton
                        onClick={() => {
                            setExportCsvFileName(
                                `FGList-Line${line}-${moment(new Date()).format('MM-DD-YYYY:HH:mm:ss')}`
                            );
                            setExportCsv(true);
                        }}>
                        <FileCopyIcon className={classes.block} color='inherit' />
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    };

    const renderFgListContainer = () => {
        return (
            <Grid container spacing={2} alignItems='center'>
                {renderListTableSearch()}
                {renderListTableButton()}
            </Grid>
        );
    };

    const qaAnalyisInfo = finishedGoodsTypedSelector<FGQAAnalysisInfo>((state) => state.finishedGoods.qaAnalysis);
    useEffect(() => {
        if (clickedBatchID) {
            dispatcher(getFGQASampleAnalysisAction(clickedBatchID));
        }
    }, [clickedBatchID, dispatcher]);
    const { enqueueSnackbar } = useSnackbar();
    const onUploadFile = () => {
        const fileSelector = document.createElement('input');
        fileSelector.setAttribute('type', 'file');
        fileSelector.setAttribute('accept', 'image/*,application/pdf');
        fileSelector.onchange = (e: any) => {
            const { target } = e;
            const { files } = target;
            if (files && files[0]) {
                let reader = new FileReader();
                reader.readAsDataURL(files[0]);
                reader.onload = (event) => {
                    if (reader && reader.result && clickedBatchID !== null) {
                        enqueueSnackbar(`${files[0].name} is being uploaded`, { variant: 'info' });
                        AppController.postFGQAPresigned(
                            {
                                batchId: clickedBatchID,
                                fileName: files[0].name,
                            },
                            files[0]
                        )
                            .then(() =>
                                enqueueSnackbar(`${files[0].name} is uploaded successfully`, { variant: 'success' })
                            )
                            .catch((error) =>
                                enqueueSnackbar(`failed uploading ${files[0].name}`, { variant: 'error' })
                            );
                    }
                };
                reader.onerror = (error: any) => {
                    enqueueSnackbar(`Error encountered in opening the FG QA File/Image`, { variant: 'error' });
                };
            }
        };
        fileSelector.click();
    };

    const [qaFile, setQAFile] = useState('');
    const renderOtherButton = () => {
        return (
            <Grid item>
                {clickedBatchID !== null && isView(viewState.fgEntry) && (
                    <Tooltip title='QA Checklist'>
                        <IconButton
                            className={qaAnalyisInfo && qaAnalyisInfo.analysisTime ? '' : classes.attention}
                            onClick={() => {
                                setView(viewState.qaFGChecklist);
                            }}>
                            <AssignmentTurnedInIcon className={classes.block} color='inherit' />
                        </IconButton>
                    </Tooltip>
                )}
                {clickedBatchID !== null && isView(viewState.fgEntry) && (
                    <Tooltip title='Unload Finished Good'>
                        <IconButton
                            className={
                                clickedBatchInfo &&
                                clickedBatchInfo.qaAnalysisResult &&
                                clickedBatchInfo.truckInspectionInfo &&
                                clickedBatchInfo.truckInspectionInfo.inspectionDatetime
                                    ? classes.attention
                                    : ''
                            }
                            onClick={() => setOpenUloading(true)}>
                            <AssignmentReturnedIcon className={classes.block} color='inherit' />
                        </IconButton>
                    </Tooltip>
                )}

                {isView(viewState.qaFGChecklist) && qaFile === '' && clickedBatchID !== null && (
                    <Tooltip title='Upload QA File/Image'>
                        <IconButton onClick={() => onUploadFile()} className={classes.attention}>
                            <CloudUploadIcon className={classes.block} color='inherit' />
                        </IconButton>
                    </Tooltip>
                )}
                {isView(viewState.qaFGChecklist) && qaFile !== '' && (
                    <Tooltip title='Download QA File/Image'>
                        <IconButton href={qaFile}>
                            <CloudDownloadIcon className={classes.block} color='inherit' />
                        </IconButton>
                    </Tooltip>
                )}
            </Grid>
        );
    };

    const renderFinishedGoodEntryContainer = () => {
        return (
            <Grid container spacing={2} alignItems='center'>
                <Grid item>
                    <Tooltip title='Return / Cancel'>
                        <IconButton onClick={revertToListView}>
                            <ArrowBackIosIcon className={classes.block} color='inherit' />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Typography color='primary' variant='h5' component='h1'>
                        {clickedBatchID === null
                            ? `[NEW] Finished Goods Batch`
                            : `Finished Goods Batch # ${clickedBatchID}`}
                    </Typography>
                </Grid>
                <Grid item xs />
                {renderOtherButton()}
            </Grid>
        );
    };

    const renderFGTruckInspectionContainer = () => {
        return (
            <Grid container spacing={2} alignItems='center'>
                <Grid item>
                    <Tooltip title='Return / Cancel'>
                        <IconButton
                            onClick={() => {
                                setView(viewState.fgEntry);
                            }}>
                            <ArrowBackIosIcon className={classes.block} color='inherit' />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Typography color='primary' variant='h5' component='h1'>
                        {`Truck Cleanlines Record for Batch # ${clickedBatchID}`}
                    </Typography>
                </Grid>
                <Grid item xs />
                {renderOtherButton()}
            </Grid>
        );
    };

    const renderFGQAChecklist = () => {
        return (
            <Grid container spacing={2} alignItems='center'>
                <Grid item>
                    <Tooltip title='Return / Cancel'>
                        <IconButton
                            onClick={() => {
                                setView(viewState.fgEntry);
                            }}>
                            <ArrowBackIosIcon className={classes.block} color='inherit' />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item>
                    <Typography color='primary' variant='h5' component='h1'>
                        {`QA Information Sheet for Batch # ${clickedBatchID}`}
                    </Typography>
                </Grid>
                <Grid item xs />
                {renderOtherButton()}
            </Grid>
        );
    };

    const onClickedBatchID = (id: string) => {
        setView(viewState.fgEntry);
        setClickedBatchID(id);
    };

    useEffect(() => {
        const filtered = fgList.find((fg) => fg.batchId === clickedBatchID);
        if (filtered) {
            setClickedBatchInfo(filtered);
            setQAFile(filtered.qaAttachment ? filtered.qaAttachment : '');
        }
    }, [clickedBatchID, fgList]);

    useEffect(() => {}, [clickedBatchInfo]);

    const isSubmitAvailable = () => {
        if (isView(viewState.fgEntry) && clickedBatchID === null) {
            return true;
        }
        if (isView(viewState.truckFGChecklist) && clickedBatchInfo && !clickedBatchInfo.truckInspectionInfo) {
            return true;
        }
        if (isView(viewState.qaFGChecklist) && clickedBatchInfo && !qaAnalyisInfo.analysisTime) {
            return true;
        }
        return false;
    };

    const onDeleteFGBatch = (batchID : string) => {
        dispatcher(deleteFGBatchByIDAction(batchID));
        return true;
    }

    const [openUnloading, setOpenUloading] = useState(false);

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.searchBar} position='static' color='default' elevation={0}>
                <Toolbar>
                    {isView(viewState.fgList) && renderFgListContainer()}
                    {isView(viewState.fgEntry) && renderFinishedGoodEntryContainer()}
                    {isView(viewState.truckFGChecklist) && renderFGTruckInspectionContainer()}
                    {isView(viewState.qaFGChecklist) && renderFGQAChecklist()}
                </Toolbar>
            </AppBar>
            {isView(viewState.fgList) && (
                <FinishedGoodsListTable
                    filterText={filterText}
                    fgList={fgList}
                    exportCsv={exportCsv}
                    onExportCsv={onExportCsv}
                    exportCsvFileName={exportCsvFileName}
                    onClickedBatchID={onClickedBatchID}
                    onDeleteFGBatch={onDeleteFGBatch}
                />
            )}
            {isView(viewState.fgEntry) && (
                <FinishedGoodsBatchEntry
                    submit={submit}
                    onSubmit={() => setSubmit(false)}
                    onRevert={() => revertToListView()}
                    clickedBatchID={clickedBatchID}
                />
            )}
            {isView(viewState.qaFGChecklist) && (
                <FinishedGoodsQAChecklistForm
                    submit={submit}
                    batchId={clickedBatchID}
                    onSubmit={() => {
                        setSubmit(false);
                        setView(viewState.fgEntry);
                    }}
                />
            )}
            {isSubmitAvailable() && (
                <Paper style={{ padding: 10 }}>
                    <Button
                        fullWidth
                        variant='contained'
                        color='primary'
                        className={classes.contentButton}
                        onClick={() => setSubmit(true)}>
                        Submit
                    </Button>
                </Paper>
            )}
            <FinishedGoodsUnloadingDetails
                batchId={clickedBatchID}
                open={openUnloading}
                batchInfo={clickedBatchInfo}
                onClose={() => setOpenUloading(false)}
            />
        </Paper>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: '99%',
            margin: 'auto',
            overflow: 'hidden',
            alignContent: 'center',
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: 'rgb(31,49,69)',
            alignSelf: 'center',
        },
        contentWrapper: {
            margin: '0px 0px',
        },
        '@keyframes pulse': {
            '0%': { background: 'rgba(31,49,69, 0)' },
            '20%': { background: 'rgba(31,49,69, 0.5)' },
        },
        attention: {
            animation: `$pulse 1.5s infinite cubic-bezier(0.66, 0.33, 0, 1)`,
        },
    });

export default withStyles(styles)(FinishedGoodsListMainView);
