import { AppBar, Grid, IconButton, Paper, Toolbar, Tooltip } from '@material-ui/core';
import { LocationInfo, RawMaterial } from 'redux/reducers/rmShipment/types';
import React, { Fragment, useEffect, useState } from 'react';
import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core/styles';
import { clearStockTransactions, getStockTransactionsRawMaterialLocation } from 'redux/reducers/finishedGoods/actions';
import {
    clearStocksAction,
    getRmStocksAction,
    getWarehouseLocationStocksAction,
} from 'redux/reducers/rmStocks/actions';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import SelectComponent from 'components/common/Select';
import { StockInfo } from 'redux/reducers/rmStocks/types';
import { TransferTransactionInfo } from 'redux/reducers/finishedGoods/types';
import WarehouseStockListTable from './WarehouseStockListTable';
import { finishedGoodsTypedSelector } from 'redux/reducers/finishedGoods/finishedGoodsReducer';
import moment from 'moment';
import { rmShipmentTypedSelector } from 'redux/reducers/rmShipment/rmShipmentReducer';
import { stocksTypedSelector } from 'redux/reducers/rmStocks/stocksReducer';
import { useDispatch } from 'react-redux';

export interface ContentProps extends WithStyles<typeof styles> {
    pageReload: boolean;
    onPageReload: () => void;
}

const WarehouseStockCardView = (props: ContentProps) => {
    const { classes } = props;
    const dispatcher = useDispatch();

    const warehouseInfo = rmShipmentTypedSelector<Array<LocationInfo>>((state) => state.rmshipment.warehouseInfo);
    const rmList = rmShipmentTypedSelector<Array<RawMaterial>>((state) => state.rmshipment.rmList);
    const stocks = stocksTypedSelector<Array<StockInfo>>((state) => state.stocks.stocks);
    const transactions = finishedGoodsTypedSelector<Array<TransferTransactionInfo>>(
        (state) => state.finishedGoods.transactions
    );

    const [warehouse, setWarehouse] = useState('');
    const [location, setLocation] = useState('');
    const [rmName, setRmName] = useState('');
    const [selectedStocks, setSelectedStocks] = useState<Array<StockInfo>>([]);

    const [exportCsv, setExportCsv] = useState(false);

    const sortDate = (first: any, second: any) => {
        let firstDate = first.inputDateTime ? first.inputDateTime : first.transferDatetime;
        let secondDate = second.inputDateTime ? second.inputDateTime : second.transferDatetime;
        if (firstDate > secondDate) return 1;
        if (firstDate < secondDate) return -1;
        return 0;
    };

    useEffect(() => {
        if (stocks.length > 0) {
            let sorted = stocks.sort(sortDate);
            dispatcher(
                getStockTransactionsRawMaterialLocation(
                    sorted[0].warehouseId,
                    sorted[0].warehouseLocId,
                    sorted[0].rmName
                )
            );
        }
    }, [stocks, dispatcher]);

    useEffect(() => {
        let total = 0;
        let combined: any[] = [...stocks, ...transactions];
        combined.sort(sortDate);
        let filtered = combined.filter((a) => {
            if (a.transferredWeight) {
                return true; // This means this is a transfer transaction
            } else if (a.rmName) {
                return true; // This means this is a stock IN information
            }
            return false; // This is Warehouse/Location stock with NULL data
        });
        filtered.forEach((a) => {
            total += a.netWeight ? a.netWeight : 0;
            if (a.transferredWeight) {
                if (a.shift === '1' || a.shift === 1) {
                    a.firstShift = a.transferredWeight;
                } else if (a.shift === '2' || a.shift === 2) {
                    a.secondShift = a.transferredWeight;
                } else if (a.shift === '3' || a.shift === 3) {
                    a.thirdShift = a.transferredWeight;
                } else {
                    a.salesShift = a.transferredWeight;
                }
                total -= a.transferredWeight;
            }
            a.total = total;
        });
        setSelectedStocks(filtered);
    }, [stocks, transactions]);

    useEffect(() => {}, [selectedStocks]);

    const warehouseNameSelect = () => {
        let warehouseList: Array<string> = [];
        warehouseInfo.forEach((info) => {
            if (!warehouseList.some((warehouseId) => warehouseId === info.warehouseId)) {
                warehouseList.push(info.warehouseId);
            }
        });

        return (
            <Fragment>
                <Grid item xs>
                    <SelectComponent
                        title={'Warehouse'}
                        selectList={warehouseList}
                        setter={setWarehouse}
                        currentValue={warehouse}
                    />
                </Grid>
            </Fragment>
        );
    };

    const handleLocationChange = (event: any) => {
        setLocation(event as string);
        dispatcher(clearStocksAction());
        dispatcher(clearStockTransactions());
        setTimeout(() => {
            dispatcher(getWarehouseLocationStocksAction(warehouse, event as string));
        }, 100);

        setRmName('');
    };

    const locationSelect = () => {
        let locationList = warehouseInfo
            .filter((info) => {
                return info.warehouseId === warehouse;
            })
            .map((info) => info.locationId);

        return (
            <Fragment>
                <Grid item xs>
                    <SelectComponent
                        title={'Location'}
                        selectList={locationList}
                        setter={handleLocationChange}
                        currentValue={location}
                    />
                </Grid>
            </Fragment>
        );
    };

    const handleRawMaterialNameChange = (event: any) => {
        setRmName(event as string);
        dispatcher(clearStocksAction());
        dispatcher(clearStockTransactions());
        setTimeout(() => {
            dispatcher(getRmStocksAction(event as string));
        }, 100);
        setLocation('');
        setWarehouse('');
    };

    const rawMaterialNameSelect = () => {
        return (
            <Fragment>
                <Grid item xs>
                    <SelectComponent
                        title={'Raw Material Name'}
                        selectList={rmList.map((info) => info.rmCode)}
                        setter={handleRawMaterialNameChange}
                        currentValue={rmName}
                    />
                </Grid>
            </Fragment>
        );
    };

    const onExportCsv = () => {
        setExportCsv(false);
    };
    const [exportCsvFileName, setExportCsvFileName] = useState('');
    const renderWarehouseCardButton = () => {
        return (
            <Grid item>
                <Tooltip title='Export to CSV'>
                    <IconButton
                        onClick={() => {
                            let csvName = 'RMWarehouseStockCard';
                            if (rmName) {
                                csvName += '-' + rmName;
                            }
                            if (warehouse) {
                                csvName += '-' + warehouse;
                            }
                            if (location) {
                                csvName += '-' + location;
                            }
                            setExportCsvFileName(`${csvName}-${moment(new Date()).format('MM-DD-YYYY:HH:mm:ss')}`);
                            setExportCsv(true);
                        }}>
                        <FileCopyIcon className={classes.block} color='inherit' />
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    };

    const renderWarehouseCardHeader = () => {
        return (
            <Grid container spacing={3} alignItems='center'>
                {warehouseNameSelect()}
                {locationSelect()}
                {rawMaterialNameSelect()}
                {renderWarehouseCardButton()}
            </Grid>
        );
    };

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.searchBar} position='static' color='default' elevation={0}>
                <Toolbar>{renderWarehouseCardHeader()}</Toolbar>
            </AppBar>
            <WarehouseStockListTable
                data={selectedStocks}
                exportCsv={exportCsv}
                onExportCsv={onExportCsv}
                exportCsvFileName={exportCsvFileName}
            />
        </Paper>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: '99%',
            margin: 'auto',
            overflow: 'hidden',
            alignContent: 'center',
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: 'rgb(31,49,69)',
            alignSelf: 'center',
        },
        contentWrapper: {
            margin: '0px 16px',
        },
    });

export default withStyles(styles)(WarehouseStockCardView);
