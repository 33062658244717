import { LogoutAction } from 'redux/reducers/rootReducer/types';

export interface SparePartsBase {
    code: string;
    description?: string;
    quantity?: number;
    unit?: string;
    line?: number;
    unitPrice?: number;
    remarks?: string;
}

export interface SparePartsInfo extends SparePartsBase {
    createDate: string;
    updateDate: string;
}

export interface SparePartsInfoList {
    infoList: Array<SparePartsInfo>;
    LastEvaluatedKey: null /* TODO */;
}

export interface SparePartsTransactionBase {
    /* In and Out */
    transactionType: 'in' | 'out';
    transactionItems: Array<SparePartsBase>;
    purpose: string;
    requestedBy: string;
    /* IN */
    receivedBy?: string;
    /* Out */
    materialType?: string;
    notedBy?: string;
    approvedBy?: string;
    issuedBy?: string;
}

export interface SparePartsTransaction extends SparePartsTransactionBase {
    transactionId: string;
    createDate: string;
    updateDate: string;
}

export interface SparePartsTransactionRequest
    extends SparePartsTransactionBase {}

export interface SparePartsTransactionResponse {
    transactions: Array<SparePartsTransaction>;
    LastEvaluatedKey: null /* TODO */;
}

export interface AddSpCode {
    code: string;
}

export interface SparePartsInfoState {
    sparePartsTransaction: SparePartsTransactionResponse;
    sparePartsInfo: SparePartsInfoList;
}

export interface SparePartsReducerType {
    spareParts: SparePartsInfoState;
}

export const GET_ALL_SPAREPARTS_TRANSACTION = 'GET_ALL_SPAREPARTS_TRANSACTION';
export const GET_ALL_SPAREPARTS_INFO = 'GET_ALL_SPAREPARTS_INFO';
export const POST_SPAREPARTS_TRANSACTION = 'POST_SPAREPARTS_TRANSACTION';
export const ADD_NEW_SPAREPARTS_CODE = 'ADD_NEW_SPAREPARTS_CODE';

interface GetAllSparePartsTransaction {
    type: typeof GET_ALL_SPAREPARTS_TRANSACTION;
    payload: SparePartsTransactionResponse;
}

interface GetAllSparePartsInfo {
    type: typeof GET_ALL_SPAREPARTS_INFO;
    payload: SparePartsInfoList;
}

export type SparePartsActionTypes =
    | GetAllSparePartsTransaction
    | GetAllSparePartsInfo
    | LogoutAction;
