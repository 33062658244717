import ApiController from './api-controller';
import { AxiosPromise } from 'axios';
import { Canvass } from 'redux/reducers/canvass/types';

class CanvassController extends ApiController {
    createNewCanvass<Data = any>(canvass: Canvass): AxiosPromise<Data> {
        return this._instance.post(`/services/purchase/canvass`, canvass);
    }

    getMultipleCanvass<Data = any>(lastEvaluatedKey: string): AxiosPromise<Data> {
        return this._instance.get(`/services/purchase/canvass`);
    }

    getCanvassByID<Data = any>(id: string): AxiosPromise<Data> {
        return this._instance.get(`/services/purchase/canvass/${id}`);
    }

    updateCanvassByID<Data = any>(id: string, canvass: Canvass): AxiosPromise<Data> {
        return this._instance.patch(`/services/purchase/canvass/${id}`, canvass);
    }
    
    deleteCanvassByID<Data = any>(requestId: string): AxiosPromise<Data> {
        return this._instance.delete(`/services/purchase/canvass/${requestId}`);
    }

}

export default CanvassController;
