import { Button, Grid, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
    ItemsToRequisitionInfo,
    PurchaseRequestInfo,
    PurchaseRequestInfoRequest,
    PurchaseRequestStatus,
} from 'redux/reducers/purchasing/types';
import {
    RoleDepartments
} from "redux/reducers/users/types";
import React, { Fragment, useEffect, useState } from 'react';
import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core/styles';

import Alert from '@material-ui/lab/Alert';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteRowRenderer from 'components/common/renderers/DeleteRowRenderer';
import MomentUtils from '@date-io/moment';
import NumericEditor from 'components/common/editors/NumericEditor';
import { RowNode } from 'ag-grid';
import SelectComponent from 'components/common/Select';
import TableGrid from 'components/common/TableGrid';
import TodayIcon from '@material-ui/icons/Today';
import UpdateIcon from '@material-ui/icons/Update';
import moment from 'moment';
import { patchPurchaseRequestInfoAction } from 'redux/reducers/purchasing/actions';
import { useDispatch } from 'react-redux';

// Icons




export interface ContentProps extends WithStyles<typeof styles> {
    onFormClose: () => void;
    onCancel: () => void;
    clickedPurchaseRequest?: PurchaseRequestInfo;
}

const PurchaseRequestUpdateView = (props: ContentProps) => {
    const { onFormClose, onCancel, clickedPurchaseRequest } = props;
    const [newOrigDept, setNewOrigDept] = useState('');
    const [newOrigDeptHelper, setNewOrigDeptHelper] = useState('');
    const [newPurpose, setNewPurpose] = useState('');
    const [newPurposeHelper, setNewPurposeHelper] = useState('');
    const [newRequisitionedBy, setNewRequisitionedBy] = useState('');
    const [newRequisitionedByHelper, setNewRequisitionedByHelper] = useState('');
    const [newDateNeeded, setNewDateNeeded] = useState<string | null>(null);
    const [newDateNeededHelper, setNewDateNeededHelper] = useState('');
    const [newRequestStatus, setNewRequestStatus] = useState(PurchaseRequestStatus.OPEN);
    const [newRequestStatusHelper, setNewRequestStatusHelper] = useState('');
    const [newApprovedBy, setNewApprovedBy] = useState('');
    const [newDateApproved, setNewDateApproved] = useState<string | null>(null);
    const [newDateApprovedHelper, setNewDateApprovedHelper] = useState('');
    const [newDateClosed, setNewDateClosed] = useState<string | null>(null);
    const [newDateClosedHelper, setNewDateClosedHelper] = useState('');
    const [newDateInstalledUsed, setNewDateInstalledUsed] = useState<string | null>(null);
    const [newDateInstalledUsedHelper, setNewDateInstalledUsedHelper] = useState('');
    const [newItemsList, setNewItemsList] = useState<Array<ItemsToRequisitionInfo>>([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [newRemarks, setNewRemarks] = useState('');
    const dispatch = useDispatch();

    const requestDepartmentOptions: RoleDepartments[] = Object.values(RoleDepartments);
    const requestStatusOptions: PurchaseRequestStatus[] = Object.values(PurchaseRequestStatus);

    useEffect(() => {
        if (clickedPurchaseRequest) {
            setNewOrigDept(clickedPurchaseRequest.originDept);
            setNewPurpose(clickedPurchaseRequest.purpose);
            setNewRequisitionedBy(clickedPurchaseRequest.requisitionedBy);
            setNewDateNeeded(clickedPurchaseRequest.needDate);
            setNewApprovedBy(clickedPurchaseRequest.approvedBy);
            setNewDateApproved(clickedPurchaseRequest.approveDate);
            setNewDateClosed(clickedPurchaseRequest.closeDate);
            setNewDateInstalledUsed(clickedPurchaseRequest.installedUsedDate);
            setNewRequestStatus(clickedPurchaseRequest.requestStatus);
            setNewRemarks(clickedPurchaseRequest.remarks);
            setNewItemsList(clickedPurchaseRequest.itemsList);
        }
    }, [clickedPurchaseRequest]);

    let columnDefs = [
        {
            headerName: 'Action',
            width: 100,
            cellRenderer: 'deleteRowRenderer',
            suppressSizeToFit: true,
        },
        {
            headerName: 'Item Code',
            field: 'itemCode',
            minWidth: 100,
            filter: 'agTextColumnFilter',
            headerTooltip: 'Item Code',
            editable: true,
        },
        {
            headerName: 'Description',
            field: 'itemDescription',
            minWidth: 150,
            filter: 'agTextColumnFilter',
            headerTooltip: 'Item Description',
            editable: true,
        },
        {
            headerName: "Type",
            field: "itemType",
            minWidth: 80,
            filter: "agTextColumnFilter",
            headerTooltip: "Item Type",
            editable: true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: ['RM', 'SP', 'Others'],
            },
        },
        {
            headerName: 'Unit',
            field: 'itemUnit',
            minWidth: 80,
            filter: 'agTextColumnFilter',
            headerTooltip: 'Unit',
            editable: true,
        },
        {
            headerName: 'Quantity',
            field: 'itemQuantity',
            minWidth: 100,
            filter: 'agTextColumnFilter',
            headerTooltip: 'Quantity',
            editable: true,
            cellEditor: 'numericEditor',
        },
    ];

    const onCellValueChanged = (event: any) => {
        let rowData: Array<ItemsToRequisitionInfo> = [];
        event.api.forEachNode((node: RowNode) => rowData.push(node.data));
        setNewItemsList(rowData);
    };

    const onRowDataUpdated = (event: any) => {
        let rowData: Array<ItemsToRequisitionInfo> = [];
        event.api.forEachNode((node: RowNode) => rowData.push(node.data));
        setNewItemsList(rowData);
    };

    const isItemsListValid = () => {
        let ret = true;

        // Copy the contents of the items list but remove empty rows
        let filteredItemsList: Array<ItemsToRequisitionInfo> = [];
        newItemsList.forEach((element) => {
            // if at least one attribute is not undefined
            if (element.itemCode || element.itemDescription || element.itemQuantity || element.itemUnit || element.itemType) {
                let addItem = {
                    itemCode: element.itemCode ? element.itemCode! : '',
                    itemDescription: element.itemDescription ? element.itemDescription! : '',
                    itemUnit: element.itemUnit ? element.itemUnit! : '',
                    itemQuantity: element.itemQuantity ? element.itemQuantity! : 0,
                    itemType: element.itemType ? element.itemType! : '',
                };
                filteredItemsList = [...filteredItemsList, addItem];
            }
        });

        // Check if empty list
        if (filteredItemsList.length === 0) {
            setErrorMessage('No items added');
            return false;
        }

        // Check for duplicate item codes
        let seen = new Set();
        var hasDuplicates = filteredItemsList.some(function (currentItem) {
            return seen.size === seen.add(currentItem.itemCode).size;
        });

        if (!hasDuplicates) {
            // Check for empty value
            for (let i = 0; i < filteredItemsList.length; i++) {
                let element = filteredItemsList[i];
                let rowItemCode = element.itemCode;
                if (rowItemCode === '') {
                    setErrorMessage('Please set the item code for : Item ' + (i + 1));
                    ret = false;
                    break;
                }
                if (element.itemDescription === '') {
                    setErrorMessage('Please set the description for ' + rowItemCode);
                    ret = false;
                    break;
                }
                if (element.itemUnit === '') {
                    setErrorMessage('Please set the unit for ' + rowItemCode);
                    ret = false;
                    break;
                }
                if (element.itemQuantity <= 0) {
                    setErrorMessage('Please set the quantity for ' + rowItemCode);
                    ret = false;
                    break;
                }
            }
        } else {
            setErrorMessage('Item Code duplicates detected');
            ret = false;
        }

        return ret;
    };

    const resetHelpers = () => {
        setNewOrigDeptHelper('');
        setNewPurposeHelper('');
        setNewRequisitionedByHelper('');
        setNewDateNeededHelper('');
        setNewDateApprovedHelper('');
        setNewDateClosedHelper('');
        setNewDateInstalledUsedHelper('');
        setNewRequestStatusHelper('');
        setErrorMessage('');
    };

    const checkInputValidity = () => {
        let isItemsValid = isItemsListValid();

        let retVal = true;

        if (
            newPurpose === '' ||
            newRequisitionedBy === '' ||
            newDateNeeded === undefined ||
            isItemsValid === false
        ) {
            if (newPurpose === '') setNewPurposeHelper('Please set value');
            if (newRequisitionedBy === '') setNewRequisitionedByHelper('Please set value');
            if (newDateNeeded === undefined) setNewDateNeededHelper('Please set value');
            retVal = false;
        }

        if ( (newRequestStatus === PurchaseRequestStatus.APPROVED || newRequestStatus === PurchaseRequestStatus.CLOSED) &&
              ((newDateApproved === undefined) || (newApprovedBy === ''))
        ) {
            if (newDateApproved === undefined) setNewDateApprovedHelper('Please set value');
            if (newApprovedBy === '') setNewApprovedBy('Please set value');
            retVal = false;
        }

        // Add handling for closed status
        if ( newRequestStatus === PurchaseRequestStatus.CLOSED &&
            (newDateClosed === undefined || newDateInstalledUsed === undefined)
        ) {
            if (newDateClosed === undefined) setNewDateClosedHelper('Please set value');
            if (newDateInstalledUsed === undefined) setNewDateInstalledUsedHelper('Please set value');
            retVal = false;
        }

        return retVal;
    };

    const updateRequest = () => {
        let filteredItemsList: Array<ItemsToRequisitionInfo> = [];
        newItemsList.forEach((element) => {
            if (
                element.itemCode ||
                element.itemDescription ||
                element.itemQuantity ||
                element.itemUnit ||
                element.itemType ||
                element.itemQuantity > 0
            ) {
                let addItem = {
                    itemCode: element.itemCode ? element.itemCode! : '',
                    itemDescription: element.itemDescription ? element.itemDescription! : '',
                    itemUnit: element.itemUnit ? element.itemUnit! : '',
                    itemQuantity: element.itemQuantity ? element.itemQuantity! : 0,
                    itemType: element.itemType ? element.itemType! : '',
                };
                filteredItemsList = [...filteredItemsList, addItem];
            }
        });
        if (checkInputValidity()) {
            let updatedPurchaseRequest: PurchaseRequestInfoRequest = {
                originDept: newOrigDept,
                requisitionedBy: newRequisitionedBy,
                purpose: newPurpose,
                needDate: newDateNeeded,
                requestStatus: newRequestStatus,
                approvedBy: newApprovedBy,
                approveDate: newDateApproved,
                closeDate: newDateClosed,
                installedUsedDate: newDateInstalledUsed,
                remarks: newRemarks,
                itemsList: filteredItemsList,
            };

            dispatch(patchPurchaseRequestInfoAction(updatedPurchaseRequest, clickedPurchaseRequest!.requestId));
            resetHelpers();
            onFormClose();
        }
    };

    const renderPurchaseRequestDetails = () => {
        return (
            <Fragment>
                <Grid container spacing={2} alignItems='center'>
                    <Grid item xs={6}>
                        <SelectComponent
                            title={'Originating Department'}
                            selectList={requestDepartmentOptions}
                            setter={setNewOrigDept}
                            currentValue={newOrigDept}
                            codeHelperText={newOrigDeptHelper}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <SelectComponent
                            title={'Request Status'}
                            selectList={requestStatusOptions}
                            setter={setNewRequestStatus}
                            currentValue={newRequestStatus}
                            codeHelperText={newRequestStatusHelper}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TableGrid
                            rowData={newItemsList}
                            columnDefs={columnDefs}
                            height={300}
                            showAddRowButton={true}
                            onCellValueChanged={onCellValueChanged}
                            onRowDataUpdated={onRowDataUpdated}
                            frameworkComponents={{
                                deleteRowRenderer: DeleteRowRenderer,
                                numericEditor: NumericEditor,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={'Purpose'}
                            value={newPurpose}
                            helperText={newPurposeHelper}
                            error={newPurposeHelper !== ''}
                            onChange={(event) => {
                                setNewPurpose(event.target.value);
                            }}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DateTimePicker
                                ampm={false}
                                fullWidth
                                margin='dense'
                                value={newDateNeeded}
                                onChange={(event) => {
                                    setNewDateNeededHelper('');
                                    setNewDateNeeded(moment(event).toISOString());
                                }}
                                label={'Date Needed'}
                                format='MMM DD, YYYY HH:mm'
                                inputVariant='standard'
                                helperText={newDateNeededHelper}
                                error={newDateNeededHelper !== ''}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <IconButton>
                                                <TodayIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label={'Requisitioned By'}
                            value={newRequisitionedBy}
                            helperText={newRequisitionedByHelper}
                            error={newRequisitionedByHelper !== ''}
                            onChange={(event) => {
                                setNewRequisitionedBy(event.target.value);
                            }}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={'Remarks'}
                            value={newRemarks}
                            onChange={(event) => {
                                setNewRemarks(event.target.value);
                            }}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DateTimePicker
                                ampm={false}
                                fullWidth
                                margin='dense'
                                value={ newDateApproved === undefined ? null : newDateApproved }
                                onChange={(event) => {
                                    setNewDateApprovedHelper('');
                                    setNewDateApproved(moment(event).toISOString());
                                }}
                                label={'Date Approved'}
                                format='MMM DD, YYYY HH:mm'
                                inputVariant='standard'
                                helperText={newDateApprovedHelper}
                                error={newDateApprovedHelper !== ''}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <IconButton>
                                                <TodayIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label={'Approved By'}
                            value={newApprovedBy}
                            onChange={(event) => {
                                setNewApprovedBy(event.target.value);
                            }}
                            fullWidth={true}
                        />
                    </Grid>
                    {newRequestStatus === PurchaseRequestStatus.CLOSED && (
                        <Grid item xs={6}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DateTimePicker
                                    ampm={false}
                                    fullWidth
                                    margin='dense'
                                    value={ newDateClosed === undefined ? null : newDateClosed }
                                    onChange={(event) => {
                                        setNewDateClosedHelper('');
                                        setNewDateClosed(moment(event).toISOString());
                                    }}
                                    label={'Date Closed'}
                                    format='MMM DD, YYYY HH:mm'
                                    inputVariant='standard'
                                    helperText={newDateClosedHelper}
                                    error={newDateClosedHelper !== ''}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <IconButton>
                                                    <TodayIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    )}
                    {newRequestStatus === PurchaseRequestStatus.CLOSED && (
                        <Grid item xs={6}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DateTimePicker
                                    ampm={false}
                                    fullWidth
                                    margin='dense'
                                    value={ newDateInstalledUsed === undefined ? null : newDateInstalledUsed }
                                    onChange={(event) => {
                                        setNewDateInstalledUsedHelper('');
                                        setNewDateInstalledUsed(moment(event).toISOString());
                                    }}
                                    label={'Date Installed / Used'}
                                    format='MMM DD, YYYY HH:mm'
                                    inputVariant='standard'
                                    helperText={newDateInstalledUsedHelper}
                                    error={newDateInstalledUsedHelper !== ''}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <IconButton>
                                                    <TodayIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    )}
                    {newRequestStatus === PurchaseRequestStatus.CLOSED }
                    <Grid item xs={6}>
                        <Button
                            onClick={() => {
                                resetHelpers();
                                updateRequest();
                            }}
                            color='primary'
                            variant='contained'
                            fullWidth={true}
                            startIcon={<UpdateIcon />}>
                            Update
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            onClick={onCancel}
                            color='primary'
                            variant='contained'
                            startIcon={<CancelIcon />}
                            fullWidth={true}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        {errorMessage !== '' && <Alert severity='error'>{errorMessage}</Alert>}
                    </Grid>
                </Grid>
            </Fragment>
        );
    };

    return <div style={{ padding: 10 }}>{renderPurchaseRequestDetails()}</div>;
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: '99%',
            margin: 'auto',
            overflow: 'hidden',
            alignContent: 'center',
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: 'rgb(31,49,69)',
            alignSelf: 'center',
        },
        contentWrapper: {
            margin: '0px 0px',
        },
    });

export default withStyles(styles)(PurchaseRequestUpdateView);
