import { AppBar, Grid, IconButton, Paper, TextField, Toolbar, Tooltip } from '@material-ui/core';
import React, { Fragment, useState } from 'react';
import { Theme, WithStyles, createStyles, withStyles } from '@material-ui/core/styles';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import SearchIcon from '@material-ui/icons/Search';
import WarehouseReportBinDetails from './WarehouseReportBinDetails';
import WarehouseReportBinListTable from './WarehouseReportBinListTable';
import WarehouseReportBinTransfers from './WarehouseReportBinTransfers';
import moment from 'moment';

const viewState = {
    materialList: 'materialList',
    reportBin: 'reportBin',
};

export interface ContentProps extends WithStyles<typeof styles> {
    pageReload: boolean;
    onPageReload: () => void;
}

const WarehouseReportBinView = (props: ContentProps) => {
    const { classes } = props;

    const [clickedRMRRID, setClickedRMRRID] = useState('');
    const [view, setView] = useState(viewState.materialList);
    const [exportCsv, setExportCsv] = useState(false);
    const onExportCsv = () => {
        setExportCsv(false);
    };

    const onClickedRMRRID = (rmrrId: string) => {
        setClickedRMRRID(rmrrId);
        setView(viewState.reportBin);
    };
    const [exportCsvFileName, setExportCsvFileName] = useState('');
    const renderWarehouseCardButton = () => {
        return (
            <Grid item>
                <Tooltip title='Export to CSV'>
                    <IconButton
                        onClick={() => {
                            setExportCsvFileName(
                                `RMWarehouseReportBin-${moment(new Date()).format('MM-DD-YYYY:HH:mm:ss')}`
                            );
                            setExportCsv(true);
                        }}>
                        <FileCopyIcon className={classes.block} color='inherit' />
                    </IconButton>
                </Tooltip>
            </Grid>
        );
    };

    const renderWarehouseCardHeader = () => {
        return (
            <Grid container spacing={2} alignItems='center'>
                {renderWarehouseReportForBin()}
                {renderWarehouseCardButton()}
            </Grid>
        );
    };

    const isView = (checkView: string) => {
        return view === checkView;
    };

    const [filterText, setFilterText] = useState('');
    const renderWarehouseReportForBin = () => {
        return (
            <Fragment>
                <Grid item>
                    {isView(viewState.materialList) && <SearchIcon className={classes.block} color='inherit' />}
                    {isView(viewState.reportBin) && (
                        <Tooltip title='Return / Cancel'>
                            <IconButton onClick={revertToListView}>
                                <ArrowBackIosIcon className={classes.block} color='inherit' />
                            </IconButton>
                        </Tooltip>
                    )}
                </Grid>
                <Grid item xs>
                    <TextField
                        fullWidth
                        placeholder='Search'
                        InputProps={{
                            disableUnderline: true,
                            className: classes.searchInput,
                        }}
                        onChange={(event) => setFilterText(event.target.value)}
                    />
                </Grid>
            </Fragment>
        );
    };

    const revertToListView = () => {
        setClickedRMRRID('');
        setView(viewState.materialList);
    };

    const renderContent = () => {
        return (
            <Fragment>
                {isView(viewState.materialList) && (
                    <WarehouseReportBinListTable
                        exportCsv={exportCsv}
                        exportCsvFileName={exportCsvFileName}
                        onExportCsv={onExportCsv}
                        filterText={filterText}
                        onClickedRMRRID={onClickedRMRRID}
                    />
                )}
                {isView(viewState.reportBin) && (
                    <Fragment>
                        <WarehouseReportBinDetails rmrrId={clickedRMRRID} />
                        <WarehouseReportBinTransfers rmrrId={clickedRMRRID} />
                    </Fragment>
                )}
            </Fragment>
        );
    };

    return (
        <Paper className={classes.paper}>
            <AppBar className={classes.searchBar} position='static' color='default' elevation={0}>
                <Toolbar>{renderWarehouseCardHeader()}</Toolbar>
            </AppBar>
            {renderContent()}
        </Paper>
    );
};

const styles = (theme: Theme) =>
    createStyles({
        paper: {
            maxWidth: '99%',
            margin: 'auto',
            overflow: 'hidden',
            alignContent: 'center',
        },
        searchBar: {
            borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        },
        searchInput: {
            fontSize: theme.typography.fontSize,
        },
        block: {
            display: 'block',
        },
        contentButton: {
            marginRight: theme.spacing(1),
            background: 'rgb(31,49,69)',
            alignSelf: 'center',
        },
        contentWrapper: {
            margin: '0px 16px',
        },
    });

export default withStyles(styles)(WarehouseReportBinView);
