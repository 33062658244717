import {
    CLEAR_RELOCATE_WAREHOUSE,
    CLEAR_STOCKS,
    GET_RM_STOCKS,
    GET_WAREHOUSE_LOCATION_STOCKS,
    RELOCATE_RM_WAREHOUSE,
    StockRelocationInfo,
    StocksInfoState,
} from './types';

import { Action } from 'redux';
import AppController from 'controller/application';
import { SET_API_MESSAGE } from '../apiMessage/types';
import { ThunkAction } from 'redux-thunk';

async function processGetWarehouseLocationStocks(warehouse: string, location: string) {
    return AppController.getWarehouseLocationStocks(warehouse, location);
}

async function processGetRmStocks(rmName: string) {
    return AppController.getRmStocks(rmName);
}

async function processRelocateRMWarehouseAction(relocationInfo: StockRelocationInfo, rm: string) {
    return AppController.relocateRMWarehouse(relocationInfo, rm);
}

export function getWarehouseLocationStocksAction(
    warehouse: string,
    location: string
): ThunkAction<void, StocksInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processGetWarehouseLocationStocks(warehouse, location);
        dispatch({
            type: GET_WAREHOUSE_LOCATION_STOCKS,
            payload: resp,
        });
    };
}

export function getRmStocksAction(rmName: string): ThunkAction<void, StocksInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        let resp = await processGetRmStocks(rmName);
        dispatch({
            type: GET_RM_STOCKS,
            payload: resp,
        });
    };
}

export function clearStocksAction(): ThunkAction<void, StocksInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        dispatch({
            type: CLEAR_STOCKS,
            payload: [],
        });
    };
}

export function relocateRMWarehouseAction(
    relocationInfo: StockRelocationInfo,
    rm: string
): ThunkAction<void, StocksInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        const resp = await processRelocateRMWarehouseAction(relocationInfo, rm);
        if (resp.reason) {
            dispatch({
                type: SET_API_MESSAGE,
                payload: {
                    message: `Failed to get relocate stock ${rm}. Reason: ${resp.reason}`,
                    variant: 'error',
                },
            });
        } else {
            dispatch({
                type: RELOCATE_RM_WAREHOUSE,
                payload: resp,
            });
        }
    };
}

export function clearRelocateAction(): ThunkAction<void, StocksInfoState, unknown, Action<string>> {
    return async (dispatch) => {
        dispatch({
            type: CLEAR_RELOCATE_WAREHOUSE,
            payload: undefined,
        });
    };
}
